import { Toggle } from "@app/hooks/useToggle";

export interface Child {
  children: React.ReactNode;
}

export interface eFormDocument {
  category: string;
  date_of_filing: string;
  file_name: string;
  year: number;
  status: boolean;
}

export interface ErrorResponse {
  status: number;
  data: {
    detail: string;
  };
}

interface Address {
  full_address: string | null;
  address_line1: string | null;
  address_line2: string | null;
  city: string | null;
  pincode: string | null;
  state: string | null;
  country: string | null;
}

interface LEI {
  number: string;
  status: string;
  registration_date: string;
  last_updated_date: string;
  next_renewal_date: string;
}

export interface Company {
  cin: string;
  llpin: string;
  legal_name: string;
  efiling_status: string;
  incorporation_date: string;
  paid_up_capital: number;
  sum_of_charges: number;
  authorized_capital: number;
  active_compliance: string;
  classification: COMPANY_TYPE;
  status: string;
  last_agm_date: string;
  last_filing_date: string;
  email: string;
  registered_address: Address;
  business_address: Address;
  lei: LEI;
  cirp_status: string | null;
  previous_address: string | null;
  pan: string | null;
  website: string | null;
  next_cin: string | null;
  number_of_directors: number;
}

export interface QaDbApiResponse<T> {
  message: string;
  status: string;
  response_data: T;
  total: number;
}

export interface ParserApiResponse<T> {
  message: string;
  data: T;
}

export enum IdentifierTypeEnum {
  CIN = "CIN",
  GST = "GST",
  UDYAM = "UDYAM",
  PAN = "PAN",
  LLPIN = "LLPIN",
  LEI = "LEI",
  EPFO = "EPFO",
  OTHER = "OTHER",
}

export enum COMPANY_TYPE {
  PublicLimitedCompany = "Public Limited Company",
  PrivateLimitedCompany = "Private Limited Company",
  GovernmentOfIndiaCompany = "Government of India Company",
  StateGovernmentCompany = "State Government Company",
  FinancialTrustCompany = "Financial Trust Company",
  GeneralAssociationTrust = "General Association Trust",
  UnlimitedCompany = "Unlimited Company",
  LimitedLiabilityPartnership = "Limited Liability Partnership",
  ForeignCompany = "Foreign Company",
  OnePersonCompany = "One Person Company",
}

export interface Pagination {
  skip: number;
  limit: number;
}

export type RequiredKeysPartialRest<T, K extends keyof T> = Required<
  Pick<T, K>
> &
  Partial<Omit<T, K>>;

export interface ModalProps {
  isOpen: boolean;
  toggleModal: Toggle;
}
