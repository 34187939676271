import { NotFound } from "@components/common/NotFound";
import { Table } from "@components/common/table";
import { useMemo } from "react";

interface McaAccountTableProps {
  data: any[];
  total: number;
  skip: number;
  setSkip: (skip: number) => void;
  isError: boolean;
  limit: number;
  isQa: boolean;
}

const headers = [
  { name: "Account Name", value: "name" },
  { name: "Status", value: "status" },
  { name: "MCA Version", value: "mcaVersion" },
  { name: "Account Owner", value: "accountOwner" },
  { name: "Assign", value: "assign", classes: "min-w-30 max-w-30" },
  { name: "Total Incomplete Tasks", value: "totalTasks" },
  { name: "Created At", value: "createdAt" },
];

export function McaAccountTable({
  data,
  total,
  skip,
  setSkip,
  isError,
  limit,
  isQa,
}: McaAccountTableProps) {
  const computedHeaders = useMemo(() => {
    if (isQa) {
      return headers.filter((header) => header.value !== "assign");
    }
    return headers;
  }, [isQa]);

  if (isError) {
    return (
      <div>
        <h2 className="text-xl">Error Loading the data</h2>
      </div>
    );
  }

  if (data.length === 0) {
    return <NotFound message="No data found" />;
  }

  return (
    <Table
      currentLimit={limit}
      skip={skip}
      paginate
      maxSize={total}
      nextHandler={() => setSkip(skip + limit)}
      prevHandler={() => setSkip(skip - limit)}
      headers={computedHeaders}
      rows={data}
    />
  );
}
