import { ParsingStatusEnum } from "@app/store/api/qaTasksApi/types";
import classNames from "classnames";

interface RenderParsingTaskProps {
  status: ParsingStatusEnum;
  className?: string;
}

export function RenderParsingTask({
  status,
  className,
}: RenderParsingTaskProps) {
  const getBadgeConfig = (status: ParsingStatusEnum) => {
    switch (status) {
      case ParsingStatusEnum.PARSING_NOT_STARTED:
        return {
          text: "Not Started",
          className: "bg-gray-100 text-gray-700",
        };
      case ParsingStatusEnum.PARSING_INITIALISED:
        return {
          text: "Initialised",
          className: "bg-blue-100 text-blue-700",
        };
      case ParsingStatusEnum.PARSING_IN_PROGRESS:
        return {
          text: "In Progress",
          className: "bg-yellow-100 text-yellow-700",
        };
      case ParsingStatusEnum.PARSING_COMPLETED:
        return {
          text: "Completed",
          className: "bg-green-100 text-green-700",
        };
      case ParsingStatusEnum.PARSING_FAILED:
        return {
          text: "Failed",
          className: "bg-red-100 text-red-700",
        };
      case ParsingStatusEnum.PARSING_COMPLETED_WITH_ERRORS:
        return {
          text: "Completed with Errors",
          className: "bg-orange-100 text-orange-700",
        };
      default:
        return {
          text: "Unknown",
          className: "bg-gray-100 text-gray-700",
        };
    }
  };

  const { text, className: badgeClassName } = getBadgeConfig(status);

  return (
    <span
      className={classNames(
        "inline-flex items-center rounded-md px-3 py-0.5 text-xs font-medium",
        badgeClassName,
        className,
      )}
    >
      {text}
    </span>
  );
}
