import { apiHandler } from "@app/config/apiHandler";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as Yup from "yup";
import type { AxiosError } from "axios";
import { Input } from "@components/common";

const schema = Yup.object({
  company_id: Yup.string().required("Company id is required"),
  name: Yup.string().required("Company team name is required"),
});

type ISchema = Yup.InferType<typeof schema>;

export interface CreateTeamProps {
  onClose: () => void;
}

export const CreateTeam = ({ onClose }: CreateTeamProps) => {
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");
  const teamName = searchParams.get("team-name");

  const form = useForm<ISchema>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const submitHandler: SubmitHandler<ISchema> = async (data) => {
    try {
      await apiHandler.post("/customer-team/create", data);
      toast.success("Team Created Successfully");
      form.reset();
      onClose();
    } catch (error) {
      const issue = error as AxiosError;
      const responseData = issue.response?.data as {
        detail?: {
          error: string;
        };
      };

      toast.error(responseData.detail?.error || "Error Creating New Team");
    }
  };

  return (
    <div>
      <h2 className="text-start text-2xl font-bold">Add New Team</h2>
      <div className="mt-8">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitHandler)}>
            <div>
              <Input
                className="w-full"
                name="company_id"
                label="Customer Company Id"
                placeholder="company id"
                defaultValue={companyId || ""}
              />
              <Input
                className="w-full"
                name="name"
                label="Customer Team Name"
                placeholder="sales"
                defaultValue={teamName || ""}
              />
            </div>
            <button
              type="submit"
              className="bg-blue-700 text-white py-1 px-4 hover:bg-blue-600 w-full text-md rounded-md inline-flex justify-around items-center font-semibold"
            >
              Add Team
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
