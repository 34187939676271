import React from "react";
import Modal from "react-modal";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@components/common/Button";
import { ratingSchema } from "./RatingGroupEditor";
import { RatingFields } from "./RatingFields";
import { useCreateCreditRatingMutation } from "@app/store/api/creditRating";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { useCreateNonCorpCreditRatingsMutation } from "@app/store/api/indiaNonCorpApi/credit-ratings";
import { useParams } from "react-router-dom";
import { CorporateType } from "@app/store/slices/companySlice";
import toast from "react-hot-toast";

export const AddRatingModal: React.FC<{
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
  isOpen: boolean;
}> = ({ setSkip, onClose, isOpen }) => {
  const { cin } = useParams();
  const { activeCorporate } = useActiveCompany();

  const form = useForm({
    resolver: yupResolver(ratingSchema),
  });
  const [createCreditRating] = useCreateCreditRatingMutation();
  const [createCreditRatingForNonCorp] =
    useCreateNonCorpCreditRatingsMutation();

  const handleSubmit = form.handleSubmit((data) => {
    if (activeCorporate === CorporateType.NonCorporate) {
      toast.promise(
        createCreditRatingForNonCorp({
          ...data,
          ratings_date: data.ratings_date || "",
          rating_agency: data.rating_agency || "",
          entity_id: cin as string,
        }).unwrap(),
        {
          loading: "Creating rating...",
          success: () => {
            setSkip(0);
            return "Rating created successfully";
          },
          error: "Failed to create rating",
        },
      );
    } else {
      toast.promise(
        createCreditRating({
          ...data,
          ratings_date: data.ratings_date,
          rating_agency: data.rating_agency || null,
          identifier_value: cin as string,
        }).unwrap(),
        {
          loading: "Creating rating...",
          success: () => {
            setSkip(0);
            return "Rating created successfully";
          },
          error: "Failed to create rating",
        },
      );
    }
    onClose();
    form.reset();
  });

  return (
    <Modal
      isOpen={isOpen}
      className="flex items-center justify-center"
      overlayClassName="modal-overlay"
      onRequestClose={() => {
        form.reset();
        onClose();
      }}
      shouldCloseOnOverlayClick
    >
      <div className="bg-white p-6 rounded-lg w-[500px] h-[calc(100vh-50px)] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Add Rating</h2>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <RatingFields control={form.control} />
            <div className="flex justify-end gap-2 mt-4">
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
