import React from "react";
import { useParams } from "react-router-dom";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { Globe, MapPin, Pencil, Save, Search, CreditCard } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@app/components/common/ToolTip";
import { Input } from "@app/components/common/Input";
import { StatusBox } from "@app/components/common/StatusBox";
import { useToggle } from "@app/hooks/useToggle";
import { Company } from "@app/types";
import { useLazyGetPANByIdentifierQuery } from "@app/store/api/entityApi/entityApi";
import {
  useUpdatePanInfoMutation,
  useGetWebsiteInfoByCinMutation,
  useUpdateWebsiteInfoMutation,
  type UpdatePanPayload,
  type UpdateWebsitePayload,
} from "@app/store/api/scrapperApi";

interface Props {
  company: Company;
}

const headerSchema = yup.object({
  location: yup.string(),
  website: yup.string().url("Please enter a valid URL"),
  panCard: yup.string(),
});

type HeaderSchema = yup.InferType<typeof headerSchema>;

export const CompanyHeader: React.FC<Props> = ({ company }) => {
  const [getWebsite] = useGetWebsiteInfoByCinMutation();
  const [updatePanInfo] = useUpdatePanInfoMutation();
  const [updateWebsite] = useUpdateWebsiteInfoMutation();
  const [getPanByIdentifier] = useLazyGetPANByIdentifierQuery();
  const [isEditing, toggleEditing] = useToggle();
  const { cin } = useParams<{ cin: string }>();

  const form = useForm<HeaderSchema>({
    resolver: yupResolver(headerSchema),
    mode: "onChange",
    defaultValues: {
      website: company?.website || "",
      location: company?.registered_address?.city || "",
      panCard: company?.pan || "",
    },
  });

  const handleCompanyPanSearch = async () => {
    if (!cin) return;

    toast
      .promise(
        getPanByIdentifier(cin).unwrap(),
        {
          loading: "Searching for company PAN...",
          success: "Company PAN found!",
          error: "Company PAN not found",
        },
        {
          style: {
            minWidth: "250px",
          },
        },
      )
      .then((data) => {
        form.setValue("panCard", data.response_data.pan, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      });
  };

  const handleCompanyWebsiteSearch = async () => {
    if (!cin) return;

    toast
      .promise(
        getWebsite(cin).unwrap(),
        {
          loading: "Searching for company website...",
          success: "Company website found!",
          error: "Company website not found",
        },
        {
          style: {
            minWidth: "250px",
          },
        },
      )
      .then((data) => {
        form.setValue("website", data.website, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      });
  };

  const updateWebsiteHandler = async (payload: UpdateWebsitePayload) => {
    try {
      await updateWebsite(payload).unwrap();
      toast.success("Website updated successfully");
    } catch (error) {
      toast.error("Failed to update website");
      throw error;
    }
  };

  const updatePanHandler = async (payload: UpdatePanPayload) => {
    try {
      await updatePanInfo(payload).unwrap();
      toast.success("PAN card updated successfully");
    } catch (error) {
      toast.error("Failed to update PAN card");
      throw error;
    }
  };

  const onSubmit: SubmitHandler<HeaderSchema> = async (data) => {
    const { formState } = form;
    const { dirtyFields } = formState;

    try {
      if (dirtyFields.website) {
        if (!data.website || !cin) {
          throw new Error("Missing data for website update");
        }
        await updateWebsiteHandler({
          cin,
          website: data.website,
          overwrite: true,
        });
      }

      if (dirtyFields.panCard) {
        if (!data.panCard || !cin) {
          throw new Error("Missing data for PAN card update");
        }
        await updatePanHandler({ cin, pan: data.panCard, overwrite: true });
      }

      if (dirtyFields.location) {
        if (!data.location || !cin) {
          throw new Error("Missing data for location update");
        }
        // update location data
      }

      toggleEditing.off();
      toast.success("All updates completed successfully");
    } catch (error) {
      toast.error(
        "An error occurred during form submission. Please try again.",
      );
    }
  };

  return (
    <section className="border p-3 rounded-md">
      <div className="flex gap-2 items-center">
        <h4 className="text-md font-semibold">{company?.legal_name}</h4>
        <StatusBox statusText="Active" />
      </div>
      <p className="text-gray-600">
        {company?.llpin ? `LLPIN: ${company.llpin}` : `CIN: ${company.cin}`}
      </p>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-4">
          {!isEditing ? (
            <div className="flex flex-wrap gap-4">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="px-2 py-1 border rounded-md hover:cursor-pointer flex items-center gap-2 max-w-[600px]">
                      <MapPin size={15} />
                      <span className="truncate">
                        {company?.registered_address?.full_address ||
                          "No address available"}
                      </span>
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-800 text-xs text-white">
                    {company?.registered_address?.full_address ||
                      "No address available"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span
                      onClick={() =>
                        company.website &&
                        window.open(company.website, "_blank")
                      }
                      className="px-2 py-1 border rounded-md hover:cursor-pointer flex items-center gap-2 max-w-96"
                    >
                      <Globe size={15} />
                      <span className="truncate">
                        {company.website || "No website available"}
                      </span>
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-800 text-xs text-white">
                    {company.website || "No website available"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="px-2 py-1 border rounded-md hover:cursor-pointer flex items-center gap-2 max-w-96">
                      <CreditCard size={15} />
                      <span className="truncate">
                        {company.pan || "PAN Number not available"}
                      </span>
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-800 text-xs text-white">
                    {company.pan || "PAN Number not available"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <button
                onClick={toggleEditing.on}
                className="ml-auto bg-blue-500 text-white px-4 py-1 mr-4 rounded hover:bg-blue-600 flex items-center gap-2"
              >
                <Pencil size={15} />
                Edit
              </button>
            </div>
          ) : (
            <>
              <div className="space-y-4">
                <Input
                  label="Location"
                  placeholder="Enter location"
                  {...form.register("location")}
                />
                <div className="flex items-center gap-4">
                  <div className="flex-grow">
                    <Input
                      label="Website"
                      placeholder="https://www.example.com"
                      {...form.register("website")}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleCompanyWebsiteSearch}
                    className="bg-blue-500 text-white mt-3 px-4 py-2 rounded hover:bg-blue-600 w-1/4 flex items-center justify-center"
                  >
                    <Search size={15} className="mr-2" />
                    Find Website
                  </button>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex-grow">
                    <Input
                      label="PAN Card"
                      placeholder="Enter PAN Card Number"
                      {...form.register("panCard")}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleCompanyPanSearch}
                    className="bg-blue-500 text-white px-4 py-2 mt-3 rounded hover:bg-blue-600 w-1/4 flex items-center justify-center"
                  >
                    <Search size={15} className="mr-2" />
                    Find PAN
                  </button>
                </div>
              </div>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  onClick={toggleEditing.off}
                  className="border border-red-500 text-red-500 px-4 py-1 rounded hover:bg-red-50 flex-1"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600 flex-1 flex items-center justify-center"
                >
                  <Save size={15} className="mr-2" />
                  Save
                </button>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </section>
  );
};
