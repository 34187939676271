import {
  FinancialMetaDataResponseObject,
  FinancialTypes,
} from "@app/store/api/financialApi/types";
import {
  useCreateFinancialDocumentMutation,
  useCreateShareholderDocumentMutation,
} from "@app/store/api/documentCreationApi";
import { DropDown } from "@components/common/dropdown";
import { Input } from "@components/common";
import { Button } from "@components/common/Button";
import { Icon } from "@components/common/Icon";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Modal from "react-modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface WarningModalProps {
  isOpen: boolean;
  onRequestClose: (forceClose?: boolean) => void;
  data: FinancialMetaDataResponseObject | null;
  dataType: "shareholdings" | "financials";
}

const schema = yup.object({
  nature: yup.string().required("Form nature is required"),
  filingType: yup.string().required("Filing type is required"),
});

export const FillFinancialYearModal = ({
  isOpen,
  onRequestClose,
  data,
  dataType,
}: WarningModalProps) => {
  if (!data) return null;

  const [financialYear, setFinancialYear] = useState<string>("");

  const [createFinancialDocument, { isLoading }] =
    useCreateFinancialDocumentMutation();

  const [createShareholderDocument, { isLoading: isShareholderLoading }] =
    useCreateShareholderDocumentMutation();

  const handleSave: SubmitHandler<yup.InferType<typeof schema>> = (payload) => {
    if (dataType === "financials") {
      toast.promise(
        createFinancialDocument({
          document_id: data.id,
          nature: payload.nature as FinancialTypes,
          filing_type: payload.filingType,
        }).unwrap(),
        {
          loading: "Creating document...",
          success: () => {
            onRequestClose(true);
            return "Document created successfully";
          },
          error: "Failed to create document",
        },
      );
    }
  };

  const handleCreateShareholderDocument = (
    document: FinancialMetaDataResponseObject,
  ) => {
    if (dataType === "shareholdings") {
      if (!financialYear) {
        toast.error("Please fill the financial year first");
        return;
      }
      toast.promise(createShareholderDocument(document.id).unwrap(), {
        loading: "Creating document...",
        error: "Failed to create document",
        success: () => {
          onRequestClose(true);
          return "Document created successfully";
        },
      });
    }
  };

  const form = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      className="modal-content min-w-[600px] max-w-10xl min-h-[400px]  overflow-x-hidden"
      overlayClassName="modal-overlay"
    >
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          Document Information - Enter Info
        </h2>
        <span className=" border rounded-full p-1">
          <Icon
            name="X"
            className="cursor-pointer hover:opacity-70 transition-opacity"
            onClick={() => onRequestClose(false)}
          />
        </span>
      </div>

      <div className="flex flex-col gap-4 mt-4">
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-md">
            <div>
              <p className="text-sm text-gray-500">Document Name</p>
              <p className="text-sm font-medium">{data.file_name}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Document Type</p>
              <p className="text-sm font-medium">{data.document_type}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Entity ID</p>
              <p className="text-sm font-medium">{data.entity_id}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Document ID</p>
              <p className="text-sm font-medium">{data.id}</p>
            </div>
          </div>
        </div>

        {dataType === "shareholdings" && (
          <div className="flex flex-col gap-2">
            <label htmlFor="financialYear" className="text-sm font-medium">
              Financial Year
            </label>
            <input
              type="date"
              id="financialYear"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              defaultValue={data.financial_year}
              value={financialYear}
              onChange={(e) => setFinancialYear(e.target.value)}
            />
            <Button
              className="mt-3"
              onClick={() => handleCreateShareholderDocument(data)}
              isLoading={isShareholderLoading}
            >
              Save
            </Button>
          </div>
        )}
        {dataType === "financials" && (
          <div className="flex flex-col gap-2">
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(handleSave)}
                className="flex flex-col gap-2"
              >
                <p className="block text-gray-700 truncate">Form Nature</p>
                <DropDown
                  options={Object.values(FinancialTypes).map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  defaultValue={FinancialTypes.STANDALONE}
                  onChange={(value) => {
                    form.setValue("nature", value.value);
                  }}
                  value={{
                    label: form.watch("nature"),
                    value: form.watch("nature"),
                  }}
                />
                <Input label="Filing Type" name="filingType" className="mt-3" />
                <Button
                  className="mt-3 w-full"
                  type="submit"
                  isLoading={isLoading}
                >
                  Save
                </Button>
              </form>
            </FormProvider>
          </div>
        )}
      </div>
    </Modal>
  );
};
