import { HolderLoader } from "../../shareholder/components/HolderLoader";
import { NotFound } from "@components/common/NotFound";
import * as Yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Icon } from "@components/common";
import { useEffect, useMemo } from "react";
import { useToggle } from "@app/hooks/useToggle";
import toast from "react-hot-toast";
import { Button } from "@app/components/common/Button";
import {
  FinancialMetaDataResponseObject,
  FinancialTypes,
} from "@app/store/api/financialApi";
import {
  useGetAuditorQuery,
  useUpdateAuditorMutation,
  useAddAuditorMutation,
} from "@app/store/api/financialApi";
import { AuditorResponse } from "@app/store/api/financialApi/types";
import { TextArea } from "@app/components/common/TextArea";

export const AuditorSchema = Yup.object().shape({
  auditor_name: Yup.string().nullable(),
  auditor_firm_name: Yup.string().nullable(),
  pan: Yup.string().nullable(),
  membership_number: Yup.string().nullable(),
  firm_registration_number: Yup.string().nullable(),
  address: Yup.string().nullable(),
  address_line1: Yup.string().nullable(),
  address_line2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  pincode: Yup.string().nullable(),
  email: Yup.string().email("Invalid email").nullable().default(""),
  country: Yup.string().nullable(),
});

export type Auditor = Yup.InferType<typeof AuditorSchema>;

export interface FinancialProps {
  formNature?: FinancialTypes;
  activeInfo: FinancialMetaDataResponseObject;
  toggleEditable?: ReturnType<typeof useToggle>[1];
}

export const PrimaryAuditor = ({ formNature, activeInfo }: FinancialProps) => {
  if (!activeInfo) return;

  const [isEditable, toggleEditable] = useToggle();
  const {
    data,
    isLoading: isPvtLoading,
    isError: isPvtError,
  } = useGetAuditorQuery(
    {
      document_id: activeInfo.id,
      year: new Date(activeInfo.financial_year).getFullYear().toString(),
      nature: formNature as FinancialTypes,
    },
    {
      // For LLP There is no form nature so it will be skipped for llp rendering
      skip: !formNature,
    },
  );

  const [updatePrimaryAuditor, { isLoading: isAuditorUpdateLoading }] =
    useUpdateAuditorMutation();

  const [createAuditor, { isLoading: isAuditorCreateLoading }] =
    useAddAuditorMutation();

  const primaryAuditor = useMemo<AuditorResponse | null>(() => {
    if (!data || !data.response_data) return null;

    return data.response_data.find((auditor) => auditor.is_primary) || null;
  }, [data]);

  const form = useForm<Auditor>({
    resolver: yupResolver(AuditorSchema),
    defaultValues: primaryAuditor || {},
  });
  useEffect(() => {
    if (primaryAuditor) {
      form.reset(primaryAuditor);
    }
  }, [data]);

  const onSubmit: SubmitHandler<Auditor> = async (payload) => {
    if (!primaryAuditor) {
      // For Creation
      try {
        await createAuditor({
          ...payload,
          document_id: activeInfo.id,
          is_primary: true,
          entity_id: activeInfo.entity_id,
          year: new Date(activeInfo.financial_year).getFullYear(),
          financial_year: activeInfo.financial_year,
          nature: formNature as FinancialTypes,
        }).unwrap();
        toast.success("Create success");
      } catch {
        toast.error("Create Failed");
      } finally {
        toggleEditable.off();
      }
      return;
    }

    // For Updation
    try {
      await updatePrimaryAuditor({
        ...payload,
        auditor_id: primaryAuditor.id,
      }).unwrap();
      toast.success("Update success");
    } catch {
      toast.error("Update Failed");
    } finally {
      toggleEditable.off();
    }
  };

  if (isPvtLoading) {
    return <HolderLoader title="PrimaryAuditor" />;
  }
  if (isPvtError) {
    return <NotFound message="Error Getting Primary Auditor Info" />;
  }
  if (!data?.response_data) {
    return (
      <div className="w-full h-[calc(100vh-200px)] flex justify-center items-center">
        <NotFound message="No Primary Auditor Data Found" />
      </div>
    );
  }

  return (
    <div className="mt-8 border rounded-md p-3">
      <div className="mt-3">
        <div className="flex justify-between">
          <h2 className="text-xl font-bold">Primary Auditor</h2>
          {!isEditable && (
            <button
              onClick={toggleEditable.on}
              type="button"
              className="bg-blue-500 text-white px-3 rounded-lg py-2 hover:bg-blue-60 inline-flex gap-2"
            >
              Edit
              <Icon name="Pen" size={18} className="self-center" />
            </button>
          )}
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4">
            <div className="flex flex-col gap-1">
              <Input
                name="auditor_name"
                label="Auditor Name"
                disabled={!isEditable}
              />
              <Input
                name="auditor_firm_name"
                label="Auditor Firm Name"
                disabled={!isEditable}
              />
              <Input name="pan" label="PAN" disabled={!isEditable} />
              <Input
                name="membership_number"
                label="Membership Number"
                disabled={!isEditable}
              />
              <Input
                name="firm_registration_number"
                label="Firm Registration Number"
                disabled={!isEditable}
              />
              <Input name="email" label="Email" disabled={!isEditable} />
              <Input name="country" label="Country" disabled={!isEditable} />
              <TextArea name="address" label="Address" disabled={!isEditable} />
              <TextArea
                name="address_line1"
                label="Address line 1"
                disabled={!isEditable}
              />
              <TextArea
                name="address_line2"
                label="Address line 2"
                disabled={!isEditable}
              />
              <Input name="city" label="City" disabled={!isEditable} />
              <Input name="state" label="State" disabled={!isEditable} />
              <Input name="pincode" label="pincode" disabled={!isEditable} />
            </div>
            <div>
              {isEditable && (
                <div className="flex gap-2">
                  <Button
                    className="px-2 py-2 w-full border text-blue-600 border-blue-600 text-md rounded-md font-semibold mt-2 hover:border-blue-500 hover:text-blue-500"
                    type="reset"
                    variant="secondary"
                    onClick={() => {
                      form.reset(primaryAuditor ? primaryAuditor : {});
                      toggleEditable.off();
                    }}
                  >
                    Cancel
                  </Button>
                  {form.formState.isDirty && (
                    <Button
                      className="px-2 py-2 w-full bg-blue-600 text-md text-white rounded-md font-semibold mt-2 hover:bg-blue-500"
                      type="submit"
                      isLoading={
                        isAuditorUpdateLoading || isAuditorCreateLoading
                      }
                    >
                      Save
                    </Button>
                  )}
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
