import { useState } from "react";
import { Icon } from "@app/components/common/Icon";
import classNames from "classnames";
import type { Header, Row } from "./table.types";
import type { TableProps } from "./table.types";

export const Table = <T,>({
  isLoading = false,
  tableDivClassname,
  className,
  headers = [],
  rows = [],
  fixedColumnIndex,
  fixedRowIndex,
  tableHeaderClassname,
  tableBodyClassname,
  onSelectRows,
  paginate = false,
  prevHandler,
  nextHandler,
  maxSize,
  currentLimit,
  skip,
}: TableProps & { onSelectRows?: (selectedRows: Row<T>[]) => void }) => {
  const stickyStyle =
    "sticky left-0 z-10 bg-surface-base dark:bg-surface-base-dark";
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<number[]>([]);

  const handleCheckboxChange = (rowIndex: number) => {
    const newSelectedRowIndexes = selectedRowIndexes.includes(rowIndex)
      ? selectedRowIndexes.filter((index) => index !== rowIndex)
      : [...selectedRowIndexes, rowIndex];

    setSelectedRowIndexes(newSelectedRowIndexes);

    if (onSelectRows) {
      onSelectRows(
        rows.filter((_, index) => newSelectedRowIndexes.includes(index)),
      );
    }
  };

  const handleDownload = (attachments: string[]) => {
    for (const attachment of attachments) {
      const link = document.createElement("a");
      link.href = `./documents/${attachment}`;
      link.download = attachment;
      link.click();
    }
  };

  const isNextDisabled = Boolean(
    typeof currentLimit === "number" &&
      typeof skip === "number" &&
      typeof maxSize === "number" &&
      currentLimit + skip >= maxSize,
  );

  const isPrevDisabled = Boolean(typeof skip === "number" && skip === 0);

  const renderCell = (
    header: Header<T>,
    headerIndex: number,
    row: Row<T>,
    rowIndex: number,
  ) => {
    // @ts-ignore | TODO - @sreehari-credhive Fix the ts error
    const cellValue = row[header.value as keyof T];

    if (header.value === "checkbox") {
      return (
        <td key={`table-${headerIndex}`} className="p-2">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={selectedRowIndexes.includes(rowIndex)}
            onChange={() => handleCheckboxChange(rowIndex)}
          />
        </td>
      );
    }

    if (header.value === "download") {
      return (
        <td
          key={`table-${headerIndex}`}
          className="p-2 text-center !w-32 !max-w-32"
        >
          <button
            type="button"
            className="p-2 text-blue-600 bg-transparent"
            onClick={() =>
              handleDownload(row.attachments as unknown as string[])
            }
          >
            <Icon name="Download" size={20} color="lightgray" />
          </button>
        </td>
      );
    }

    if (Array.isArray(cellValue)) {
      const files = cellValue as string[];
      if (files.length === 0) {
        return (
          <td
            key={`table-${headerIndex}`}
            className={classNames(
              "p-2 whitespace-nowrap overflow-x-auto !w-94 !max-w-96 text-truncate py-4 text-nowrap",
              "text-sm font-normal",
              header.classes,
              tableBodyClassname,
              "relative",
              { [stickyStyle]: headerIndex === fixedColumnIndex },
            )}
          >
            -
          </td>
        );
      }
    }

    return (
      <td
        key={`table-${headerIndex}`}
        className={classNames(
          "p-2",
          "text-sm font-normal truncate py-4",
          header.classes,
          tableBodyClassname,
          "relative",
          { [stickyStyle]: headerIndex === fixedColumnIndex },
        )}
      >
        {cellValue as string}
      </td>
    );
  };

  return (
    <div
      className={classNames(
        "overflow-auto relative w-full border !p-0 rounded-md",
        {
          "border-none": rows.length === 0,
          tableDivClassname,
        },
      )}
    >
      {isLoading ? (
        <div className="rounded-md p-4">
          <div className="space-y-1">
            {Array.from({ length: currentLimit || 15 }).map((_, index) => (
              <div
                key={index}
                className="flex items-center justify-between h-12 border-b border-slate-100"
              >
                <div className="w-1/4 px-2">
                  <div className="h-4 bg-slate-200 rounded animate-pulse"></div>
                </div>
                <div className="w-1/4 px-2">
                  <div className="h-4 bg-slate-200 rounded animate-pulse"></div>
                </div>
                <div className="w-1/4 px-2">
                  <div className="h-4 bg-slate-200 rounded animate-pulse"></div>
                </div>
                <div className="w-1/4 px-2">
                  <div className="h-4 bg-slate-200 rounded animate-pulse"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        rows.length > 0 && (
          <>
            <div className={classNames("overflow-x-auto", className)}>
              <table
                className={classNames(
                  "w-full border-separate border-spacing-0 overflow-auto bg-surface-1 dark:bg-surface-1-dark",
                )}
              >
                <thead>
                  <tr
                    className={classNames(
                      "text-left bg-surface-2 dark:bg-surface-2-dark",
                      { [stickyStyle]: fixedRowIndex === 0 },
                    )}
                  >
                    {headers.map((header, index) => (
                      <th
                        key={`table-${index}`}
                        className={classNames(
                          "bg-gray-100 px-2 py-2 text-md font-medium text-gray-600 truncate",
                          tableHeaderClassname,
                          "text-sm font-medium font-sans",
                          { [stickyStyle]: index === fixedColumnIndex },
                        )}
                      >
                        <b>{header.name}</b>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr
                      key={`table-${rowIndex}`}
                      className={classNames(
                        { "bg-gray-100/50": rowIndex % 2 !== 0 },
                        { [stickyStyle]: fixedRowIndex === rowIndex + 1 },
                      )}
                    >
                      {headers.map((header, headerIndex) =>
                        renderCell(header, headerIndex, row, rowIndex),
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginate && (
              <div className="sticky  bg-white mt-4 bottom-2 border-t border-gray-300">
                <div className="flex justify-between mr-3 mt-2 items-center px-2">
                  <div className="font-semibold text-sm">
                    {skip !== undefined &&
                    currentLimit !== undefined &&
                    maxSize !== undefined
                      ? `Showing ${skip + 1} - ${Math.min(
                          skip + currentLimit,
                          maxSize,
                        )} of ${maxSize}`
                      : "Loading..."}
                  </div>
                  <div className="gap-2 flex">
                    <button
                      className={classNames(
                        "px-3 py-1 border bg-gray-200 rounded-md",
                        {
                          "hover:cursor-not-allowed": isPrevDisabled,
                        },
                      )}
                      onClick={prevHandler}
                      disabled={isPrevDisabled}
                    >
                      Previous
                    </button>
                    <button
                      disabled={isNextDisabled}
                      className={classNames(
                        "px-3 py-1 border bg-gray-200 rounded-md",
                        {
                          "hover:cursor-not-allowed": isNextDisabled,
                        },
                      )}
                      onClick={nextHandler}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};
