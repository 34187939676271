import { Link } from "react-router-dom";

const FallbackPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="bg-white p-10 rounded-md  text-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <p className="text-xl text-gray-600 mt-4">
          Oops! The page you are looking for does not exist.
        </p>
        <Link
          to="/labelling"
          className="mt-6 inline-block px-6 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition"
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default FallbackPage;
