import { useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllScrapingTasksGroupByProjectsQuery } from "@app/store/api/qaTasksApi/qaTasksApi";
import { useTriggerScrapingTasksMutation } from "@app/store/api/gengisScraperApi";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { useToggle } from "@app/hooks/useToggle";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { NotFound } from "@components/common/NotFound";
import { ToolTip } from "@components/common/table";
import { Button } from "@components/common/Button";
import { RenderScrapeButton } from "@app/components/tasks/pending/components/RenderScrapeButton";
import { EpfoHintModal } from "@components/tasks/pending/components/EpfoHintModal";
import { UploadInvoiceModal } from "@app/components/tasks/pending";
import { ProjectInfo } from "@components/tasks/pending/components/UploadInvoiceModal";
import { toast } from "react-hot-toast";
import { POLLING_INTERVAL } from "./Labelling";
import {
  ScrapeTask,
  ScrapingDataSource,
  ScrapingStatus,
} from "@app/store/api/qaTasksApi";
import { ScrapingTaskType } from "@app/store/api/gengisScraperApi/types";
import { AlertTriangleIcon, CheckIcon } from "lucide-react";
import { ChangeStatusModal } from "@components/tasks/pending/components/ChangeStatusModal";
import { MetadataPopOver } from "@components/tasks/pending/components/MetadataPopOver";
import { useGroupedTasks } from "@app/hooks/useGroupedTasks";
import { GroupedTaskAccordion } from "@app/components/tasks/pending/components/GroupedTaskAccordion";
import { PageLoader } from "@components/loader";

const SCRAPING_HEADERS = [
  { name: "Task Nature", value: "project_name" },
  { name: "Status", value: "status" },
  { name: "Company CIN ID", value: "id" },
  { name: "", value: "retrigger" },
  { name: "Epfo Hint", value: "epfoHint" },
  { name: "Force Complete", value: "forceComplete" },
  { name: "Force Fail", value: "forceFailed" },
];

export const Scraping = () => {
  const LIMIT = 5;
  const [skip, setSkip] = useState(0);
  const [isEpfoHintModalActive, toggleEpfoHintModal] = useToggle();
  const [isChangeStatusModalActive, toggleChangeStatusModal] = useToggle();

  const [typeOfChangeStatus, setTypeOfChangeStatus] = useState<ScrapingStatus>(
    ScrapingStatus.SCRAPING_FORCE_COMPLETED,
  );

  const {
    data: completedScrapeTasks,
    isLoading: isCompletedScrapeTasksLoading,
    isError,
    startedTimeStamp,
  } = useGetAllScrapingTasksGroupByProjectsQuery(
    {
      status: [
        ScrapingStatus.SCRAPING_COMPLETED,
        ScrapingStatus.SCRAPING_FORCE_COMPLETED,
      ],
      skip: skip,
      limit: LIMIT,
    },
    {
      pollingInterval: POLLING_INTERVAL,
    },
  );

  const { activeCountry, activeCorporate } = useActiveCompany();
  const [isModalActive, toggleModal] = useToggle();
  const [activeData, setActiveData] = useState<ProjectInfo>({
    cinId: "",
    company: "",
    taskNature: ScrapingDataSource.SCRAPING_MCA_V2,
    task_id: "",
    project_id: "",
  });

  const [
    triggerScrapingTasks,
    { isLoading: isTriggerScrapingTasksLoading, originalArgs },
  ] = useTriggerScrapingTasksMutation();

  const handleScrapingAction = async (task: ScrapeTask) => {
    if (
      task.task_nature_name === "SCRAPING_MCA_V2" ||
      task.task_nature_name === "SCRAPING_MCA_V3"
    ) {
      setActiveData({
        cinId: task.identifier_value,
        company: task.company_name.toUpperCase(),
        taskNature: task.task_nature_name,
        task_id: task.id,
        project_id: task.project_id,
      });
      toggleModal.on();
    } else {
      try {
        await triggerScrapingTasks({
          task_id: task.id,
          scraping_type: ScrapingTaskType.NORMAL,
        }).unwrap();
        toast.success("Scraping task started successfully");
      } catch {
        toast.error("Failed to start scraping task");
      }
    }
  };

  const { dataGroupedByProject } = useGroupedTasks<ScrapeTask>(
    completedScrapeTasks?.response_data || {},
  );

  const computedIsLoading = (taskId: string) => {
    return originalArgs?.task_id === taskId && isTriggerScrapingTasksLoading;
  };

  if (isCompletedScrapeTasksLoading) {
    return <PageLoader />;
  }

  if (
    isError ||
    Object.keys(completedScrapeTasks?.response_data || {}).length === 0
  ) {
    return (
      <div className="flex justify-center items-center h-full flex-col">
        <div className="mt-20">
          <NotFound message="No Completed Scraping Tasks" />
        </div>
      </div>
    );
  }

  return (
    <>
      <ChangeStatusModal
        isOpen={isChangeStatusModalActive}
        onClose={toggleChangeStatusModal.off}
        projectInfo={activeData}
        scrapingType={ScrapingTaskType.NORMAL}
        status={typeOfChangeStatus}
      />
      <EpfoHintModal
        isOpen={isEpfoHintModalActive}
        onClose={toggleEpfoHintModal.off}
        info={activeData}
        scrapingType={ScrapingTaskType.NORMAL}
      />
      <UploadInvoiceModal
        data={activeData}
        isOpen={isModalActive}
        onClose={toggleModal.off}
        scrapingType={ScrapingTaskType.NORMAL}
      />

      <div className="space-y-2">
        <GroupedTaskAccordion<ScrapeTask>
          data={dataGroupedByProject}
          headers={SCRAPING_HEADERS}
          skip={skip}
          setSkip={setSkip}
          limit={LIMIT}
          total={completedScrapeTasks?.total || 0}
          startedTimeStamp={startedTimeStamp ?? 0}
          renderContent={(task) => {
            return {
              id: (
                <Link
                  to={`/labelling/${activeCountry}/${activeCorporate}/${task.identifier_value}/documents`}
                >
                  <ToolTip value={task.identifier_value} />
                </Link>
              ),
              project_name: (
                <ToolTip
                  value={removeUnderscores(task.task_nature_name)}
                  trimValue={35}
                />
              ),
              status: (
                <MetadataPopOver
                  metadata={task.scraping_metadata}
                  status={task.status}
                  showWhen={Object.values(ScrapingStatus)}
                />
              ),
              retrigger: (
                <RenderScrapeButton
                  task={task}
                  isLoading={computedIsLoading(task.id)}
                  handleScrapingAction={handleScrapingAction}
                />
              ),
              forceComplete: (
                <Button
                  leftIcon={CheckIcon}
                  variant="destructive"
                  className="px-1"
                  onClick={() => {
                    setTypeOfChangeStatus(
                      ScrapingStatus.SCRAPING_FORCE_COMPLETED,
                    );
                    setActiveData({
                      cinId: task.identifier_value,
                      company: task.company_name.toUpperCase(),
                      taskNature: task.task_nature_name,
                      task_id: task.id,
                      project_id: task.project_id,
                    });
                    toggleChangeStatusModal.on();
                  }}
                >
                  Force Complete
                </Button>
              ),
              forceFailed: (
                <Button
                  leftIcon={AlertTriangleIcon}
                  variant="destructive"
                  className="px-1"
                  onClick={() => {
                    setActiveData({
                      cinId: task.identifier_value,
                      company: task.company_name.toUpperCase(),
                      taskNature: task.task_nature_name,
                      task_id: task.id,
                      project_id: task.project_id,
                    });
                    setTypeOfChangeStatus(ScrapingStatus.SCRAPING_FORCE_FAILED);
                    toggleChangeStatusModal.on();
                  }}
                >
                  Force Fail
                </Button>
              ),
              epfoHint: task.task_nature_name === "SCRAPING_EPFO" && (
                <Button
                  onClick={() => {
                    setActiveData({
                      cinId: task.identifier_value,
                      company: task.company_name.toUpperCase(),
                      taskNature: task.task_nature_name,
                      task_id: task.id,
                      project_id: task.project_id,
                    });
                    toggleEpfoHintModal.on();
                  }}
                  variant="destructive"
                  className="text-white bg-red-500 hover:bg-red-600"
                >
                  Epfo Hint
                </Button>
              ),
            };
          }}
        />
      </div>
    </>
  );
};
