import { apiHandler } from "@app/config/apiHandler";
import { Input } from "@components/common";
import { DropDown } from "@components/common/dropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  type SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import toast from "react-hot-toast";
import { countries } from "@app/constants/country";
import * as Yup from "yup";
import classNames from "classnames";
import { useEffect, useMemo } from "react";
import type { Option } from "@app/components/common/dropdown";
import type { AxiosError } from "axios";
import customerCompaniesApi from "@app/store/api/customerCompany/useCustomerCompany";
import { useDispatch } from "react-redux";
import { CreateTeamProps } from "./CreateTeam";
import { useSearchParams } from "react-router-dom";

// Regular expressions for validating phone numbers
const indiaPhoneRegExp = /^\+91\d{10}$/;
const usPhoneRegExp = /^\+1\d{10}$/;

const schema = Yup.object({
  name: Yup.string().required("Company name is required"),
  city: Yup.string().required("Company city is required"),
  state: Yup.string().required("Company state is required"),
  country: Yup.string().required("Company country is required"),
  contact_person: Yup.string().required("Customer person name is required"),
  company_category: Yup.string().required("Company category is required"),
  contact_email: Yup.string()
    .email()
    .required("Customer Contact Email is required"),
  contact_number: Yup.string()
    .required("Customer phone number is required")
    .test("is-valid-phone", "Phone number is not valid", function (value) {
      const { country } = this.parent;
      if (country === "IN") {
        return indiaPhoneRegExp.test(value);
      }
      if (country === "USA") {
        return usPhoneRegExp.test(value);
      }
      return false;
    }),
});

type ISchema = Yup.InferType<typeof schema>;

export const NewCompany = ({ onClose }: CreateTeamProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const companyName = searchParams.get("company-name");

  const availableCountries: Option[] = useMemo(
    () =>
      Object.entries(countries).map(([key, value]) => ({
        value: key,
        label: value.name,
      })) || [],
    [],
  );

  const companyCategoryOptions: Option[] = useMemo(
    () =>
      ["IN_A", "IN_B"].map((value) => ({
        value,
        label: value,
      })) || [],
    [],
  );

  const form = useForm<ISchema>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const selectedCountry = useWatch({
    control: form.control,
    name: "country",
  });

  useEffect(() => {
    if (selectedCountry) {
      // @ts-ignore
      const phoneCode = countries[selectedCountry]?.phoneCode || "";
      form.setValue("contact_number", phoneCode);
    }
  }, [selectedCountry, form]);

  const submitHandler: SubmitHandler<ISchema> = async (data) => {
    try {
      await apiHandler.post("/customer-company/create", data);
      dispatch(
        customerCompaniesApi.util.invalidateTags(["all-customer-companies"]),
      );
      toast.success("Company Created Successfully");
      form.reset();
      onClose();
    } catch (error) {
      const issue = error as AxiosError;
      const responseData = issue.response?.data as {
        detail?: {
          error: string;
        };
      };

      toast.error(responseData.detail?.error || "Error Creating New Company");
    }
  };

  return (
    <div>
      <h2 className="text-start text-2xl font-bold">Add New Company</h2>
      <div className="mt-8 py-4">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitHandler)}>
            <div>
              <Input
                className="w-full"
                name="name"
                label="Customer Company Name"
                placeholder="Customer Company Name"
                defaultValue={companyName || ""}
              />
              <Input
                className="w-full"
                name="city"
                label="Customer Company City"
                placeholder="Mumbai"
              />
              <Input
                className="w-full"
                name="state"
                label="Customer Company State"
                placeholder="Maharashtra"
              />
              <Input
                className="w-full"
                name="contact_email"
                label="Customer Email"
                placeholder="jhon@credhive.in"
                type="email"
              />
              <div className="flex flex-col gap-2 mt-2 mb-3">
                <label htmlFor="country">Customer Company Country</label>
                <Controller
                  name="country"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <DropDown
                      options={availableCountries}
                      value={
                        availableCountries.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      onChange={(option: Option | null) =>
                        field.onChange(option?.value)
                      }
                      disabled={false}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-2 mt-2 mb-3">
                <label htmlFor="company_category">Company Category</label>
                <Controller
                  name="company_category"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <DropDown
                      options={companyCategoryOptions}
                      value={
                        companyCategoryOptions.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      onChange={(option: Option | null) =>
                        field.onChange(option?.value)
                      }
                      disabled={false}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
              <Input
                className="w-full"
                name="contact_person"
                label="Customer Company Representative"
                placeholder="John Doe"
              />
              <div className="flex mb-3 flex-col gap-2">
                <label htmlFor="contact_number">
                  Customer Company Phone Number
                </label>
                <div className="flex gap-2 items-center">
                  <Controller
                    name="contact_number"
                    control={form.control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={classNames(
                          "w-full py-2 border rounded-lg focus:outline-none px-2",
                          form.formState.errors.contact_number
                            ? "border-red-500"
                            : "border-gray-300",
                        )}
                        name="contact_number"
                        placeholder="Phone Number"
                        type="text"
                      />
                    )}
                  />
                </div>
                <span className="text-red-500 text-sm mt-1">
                  {form.formState.errors.contact_number?.message}
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-700 text-white py-1 px-4 hover:bg-blue-600 w-full text-md rounded-md inline-flex justify-around items-center font-semibold"
            >
              Add Company
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
