import { Tab } from "@app/components/common/tabs";
import { Labelling, Scraping } from "@app/components/tasks/completed";

const CompletedTasks = () => {
  return (
    <div className="min-h-screen">
      <h2 className="text-start text-3xl font-bold">Completed Tasks</h2>
      <Tab
        content={[
          {
            label: "labelling",
            children: <Labelling />,
          },
          {
            label: "scraping",
            children: <Scraping />,
          },
        ]}
      />
    </div>
  );
};

export default CompletedTasks;
