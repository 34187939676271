import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import { LucideIcon, Loader } from "lucide-react";

export type Variant = "primary" | "secondary" | "destructive" | "transparent";

const variantClasses: Record<Variant, string> = {
  primary: "bg-blue-500 text-white hover:bg-blue-600 transition-1 transition",
  secondary:
    "border-blue-500 text-blue-500 border hover:bg-blue-50 transition-1 transition",
  destructive:
    "border-red-500 text-red-500 border hover:bg-red-50 transition-1 transition",
  transparent:
    "bg-transparent text-blue-500 hover:bg-blue-50 transition-1 transition",
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant?: Variant;
  isLoading?: boolean;
  leftIcon?: LucideIcon;
  rightIcon?: LucideIcon;
}

export const Button = ({
  children,
  variant = "primary",
  isLoading = false,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        "inline-flex justify-center items-center gap-2 rounded px-3 py-1 disabled:cursor-not-allowed",
        variantClasses[variant],
        className,
      )}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {LeftIcon && <LeftIcon size={16} />}
      <span className="relative flex-grow text-center w-full">
        <span className={classNames("visible", { invisible: isLoading })}>
          {children}
        </span>
        {isLoading && (
          <Loader size={16} className="animate-spin absolute inset-0 m-auto" />
        )}
      </span>
      {RightIcon && <RightIcon size={16} />}
    </button>
  );
};
