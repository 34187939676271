export const ENV = {
  api_base_url: import.meta.env.VITE_BASE_URL,
  web_base_url: import.meta.env.VITE_WEB_URL,
  auth_secret_key: import.meta.env.VITE_SECRET_KEY,
  qa_db_base_url: import.meta.env.VITE_QA_DB_BASE_URL,
  parser_api_url: import.meta.env.VITE_PARSER_BASE_URL,
  credhive_data_api_url: import.meta.env.VITE_CREDHIVE_DATA_API_URL,
  scraper_api_url: import.meta.env.VITE_SCRAPER_BASE_URL,
  sentry_dsn: import.meta.env.VITE_SENTRY_DSN,
  india_noncorp_api_url: import.meta.env.VITE_INDIA_NONCORP_API_URL,
  environment: import.meta.env.VITE_ENVIRONMENT,
};
