import { FormProvider, useFormContext } from "react-hook-form";
import { aoc4ProfitAndLossWithHeading } from "./aoc4Validation";
import { InputGroup } from "../InputGroup";

interface Aoc4ProfitAndLossInputsProps {
  isEditable: boolean;
  isPdfOpen: boolean;
}

export const Aoc4ProfitAndLossInputs = ({
  isEditable,
  isPdfOpen,
}: Aoc4ProfitAndLossInputsProps) => {
  const form = useFormContext();
  return (
    <FormProvider {...form}>
      <InputGroup
        title="Line Items"
        fields={aoc4ProfitAndLossWithHeading.line_items}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Revenue"
        fields={aoc4ProfitAndLossWithHeading.revenue}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Expenses"
        fields={aoc4ProfitAndLossWithHeading.expenses}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Revenue Breakup"
        fields={aoc4ProfitAndLossWithHeading.revenue_breakup}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
    </FormProvider>
  );
};
