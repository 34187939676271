import {
  PendingCompanyRequest,
  CompletedCompanyRequest,
} from "../components/admin/company-requests";
import { Tab } from "@app/components/common/tabs";

const CompanyRequests = () => {
  return (
    <div className="h-full w-full">
      <h2 className="text-start text-3xl font-bold mb-2">Company Requests</h2>
      <div className="flex w-full gap-2 flex-col">
        <Tab
          content={[
            {
              label: "pending",
              children: <PendingCompanyRequest />,
            },
            {
              label: "completed",
              children: <CompletedCompanyRequest />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CompanyRequests;
