import {
  useGetEntityWIthOutPanQuery,
  useLazyGetPANByIdentifierQuery,
  useUpdateEntityMutation,
} from "@app/store/api/entityApi/entityApi";
import entityApi from "@app/store/api/entityApi/entityApi";
import { isDataEmpty } from "@app/utils/isDataEmpty";
import { Input, StatusBox } from "@components/common";
import { Button } from "@components/common/Button";
import { NotFound } from "@components/common/NotFound";
import { Table } from "@components/common/table";
import { PageLoader } from "@components/loader";
import { Save, Search } from "lucide-react";
import { useMemo } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const headers = [
  {
    value: "company",
    name: "Company",
  },
  {
    value: "status",
    name: "Status",
  },
  {
    value: "input",
    name: "Input",
  },
  {
    value: "save",
    name: "Save",
  },
  {
    value: "search",
    name: "Search",
  },
];

export const PanTasks = () => {
  const { data, isLoading, isError } = useGetEntityWIthOutPanQuery(undefined, {
    pollingInterval: 15000,
  });
  const [getPanByIdentifier] = useLazyGetPANByIdentifierQuery();
  const [savePan, { isLoading: isPanSaving }] = useUpdateEntityMutation();
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      tasks: data?.response_data?.map((el) => ({ id: el.id, pan: "" })) || [],
    },
  });

  // Need to track the pan number in the form
  // Can be Helpfull when we do bulk save
  const { fields } = useFieldArray({
    control: form.control,
    name: "tasks",
  });

  const searchPan = (cin: string, index: number) => {
    toast.promise(
      getPanByIdentifier(cin)
        .unwrap()
        .then((response) => {
          const panNumber = response?.response_data?.pan;
          if (panNumber) {
            form.setValue(`tasks.${index}.pan`, panNumber);
          }
          return response;
        }),
      {
        loading: "Searching Pan Info",
        success: "Pan Found Successfully",
        error: "Error Finding Pan Info",
      },
    );
  };

  const savePanInfo = (id: number, taskId: string) => {
    const pan = form.getValues("tasks")[id].pan;
    if (!pan) {
      toast.error("Please enter pan number");
      return;
    }
    toast.promise(
      savePan([
        {
          [taskId]: {
            value: pan,
          },
        },
      ]).unwrap(),
      {
        loading: "Pan Info Saving",
        success: "Pan Saved Successfully",
        error: "Error Saving Pan Info",
      },
    );
    dispatch(entityApi.util.invalidateTags(["no-pan-company"]));
  };

  const rows = useMemo(() => {
    if (!data) return [];

    return data.response_data.map((el, index) => ({
      company: el.entity.name,
      status: <StatusBox statusText={el.status} />,
      input: (
        <Input
          name={`tasks.${index}.pan`}
          label=""
          className="mt-2"
          placeholder="Enter Pan No"
        />
      ),
      search: (
        <Button
          className="px-4 py-2"
          variant="secondary"
          type="button"
          rightIcon={Search}
          onClick={() => searchPan(el.entity.entity_readable_id, index)}
        >
          Search
        </Button>
      ),
      save: (
        <Button
          className="px-4 py-2"
          rightIcon={Save}
          type="button"
          isLoading={isPanSaving}
          onClick={() => savePanInfo(index, el.id)}
        >
          Save
        </Button>
      ),
    }));
  }, [data, form.control, fields]);

  if (isLoading) return <PageLoader />;

  if (isError)
    return (
      <div className="h-full flex justify-center items-center">
        <NotFound message="Error getting the data" />
      </div>
    );

  return (
    <section className="rounded-md p-4 h-full">
      {isDataEmpty(data) ? (
        <div className="h-full flex justify-center items-center">
          <NotFound message="No Tasks Found" />
        </div>
      ) : (
        <div className="mt-4">
          <FormProvider {...form}>
            <form>
              <Table
                rows={rows}
                headers={headers}
                tableDivClassname="w-full border border-gray-200 !p-0 rounded-lg"
                tableHeaderClassname="bg-gray-100 px-2 py-2 text-md font-bold text-gray-600 truncate"
                tableBodyClassname="truncate"
              />
            </form>
          </FormProvider>
        </div>
      )}
    </section>
  );
};
