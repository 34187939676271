import React, { useState } from "react";
import { GSTDetail } from "@app/store/api/compliancesApi/types";
import { Accordion } from "@components/common/Accordion";
import { GSTEntry } from "./GSTEntry";
import { PageLoader } from "@components/loader/components/PageLoader";
import { NotFound } from "@components/common/NotFound";

interface GSTDetailsProps {
  data?: GSTDetail[];
  isReportFetching: boolean;
}

export const GSTDetails: React.FC<GSTDetailsProps> = ({
  data,
  isReportFetching,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<"all" | "active" | "cancelled">(
    "active",
  );
  const itemsPerPage = 10;

  const gstData = data || [];

  const filteredGSTData = React.useMemo(() => {
    if (!gstData) return [];
    return gstData.filter((gst: GSTDetail) => {
      if (filter === "all") return true;
      return filter === "active"
        ? gst.status === "ACTIVE"
        : gst.status !== "ACTIVE";
    });
  }, [gstData, filter]);

  if (isReportFetching) {
    return <PageLoader />;
  }

  const totalPages = Math.ceil(filteredGSTData.length / itemsPerPage);
  const paginatedGSTData = filteredGSTData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <div className="py-4 flex flex-col gap-4 border rounded-lg p-4">
      {gstData.length === 0 ? (
        <NotFound message="GST details not available for this company." />
      ) : (
        <>
          <div className="flex justify-between">
            <div className="text-lg w-max text-heading font-semibold">
              GST Details
            </div>

            <div className="flex gap-2">
              {(["all", "active", "cancelled"] as const).map((filterType) => (
                <button
                  key={filterType}
                  onClick={() => {
                    setFilter(filterType);
                    setCurrentPage(1);
                  }}
                  className={`px-4 py-2 rounded ${
                    filter === filterType
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 text-gray-600"
                  }`}
                >
                  {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <Accordion
            title="GST Entries"
            defaultOpen={true}
            count={filteredGSTData.length}
          >
            {paginatedGSTData.map((gst: GSTDetail, index: number) => (
              <Accordion
                key={index}
                title={`GSTIN: ${gst.gstin}`}
                status={gst.status === "ACTIVE" ? "Active" : "Cancelled"}
              >
                <GSTEntry gstDetail={gst} />
              </Accordion>
            ))}

            {totalPages > 1 && (
              <div className="mt-4 flex justify-between items-center">
                <span className="text-sm text-gray-600">
                  Total Entries: {filteredGSTData.length}
                </span>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 rounded bg-blue-600 text-white disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <span className="px-4 py-2">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 rounded bg-blue-600 text-white disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </Accordion>
        </>
      )}
    </div>
  );
};
