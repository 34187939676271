import {
  useListCustomerCompaniesQuery,
  useLazyGetCustomerCompanyByNameQuery,
} from "@app/store/api/customerCompany";
import { Button } from "@components/common/Button";
import { Table, ToolTip } from "@components/common/table";
import { PageLoader } from "@components/loader";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { TeamDrawer } from "@app/components/admin/company-list";
import { useToggle } from "@app/hooks/useToggle";
import { Plus } from "lucide-react";
import { formatDate } from "@app/utils/formatDate";
import { debounce } from "@app/utils/debounce";
import { NotFound } from "@components/common/NotFound";

const CompanyList = () => {
  const { data, error, isLoading, isError } = useListCustomerCompaniesQuery();
  const [
    getCustomerCompanyByName,
    { data: searchData, isFetching: searchLoading },
  ] = useLazyGetCustomerCompanyByNameQuery();

  const handleSearchByCompanyName = debounce((name: string) => {
    getCustomerCompanyByName(name);
  }, 500);

  const [typeOfDrawer, setTypeOfDrawer] = useState<
    "team-info" | "create-team" | "create-company"
  >("team-info");
  const [isTeamDrawerOpen, toggleTeamDrawerOpen] = useToggle();
  const [selectedCompany, setSelectedCompany] = useState({
    name: "",
    id: "",
  });

  const copyId = (id: string) => {
    window.navigator.clipboard.writeText(id);
    toast.success("Customer Company ID Was Copied To Clipboard");
  };

  const customerData = useMemo(() => {
    const dataToUse = searchData || data;
    if (dataToUse) {
      return dataToUse?.response_data.map((el) => ({
        id: <ToolTip value={el.id} handler={copyId} />,
        name: el.name,
        state: el.state,
        city: el.city,
        contact_person: el.contact_person,
        contact_person_number: el.contact_number,
        contact_person_email: el.contact_email,
        createdAt: formatDate(el.created_at),
        members: (
          <Button
            onClick={() => {
              setSelectedCompany({ name: el.name, id: el.id });
              setTypeOfDrawer("team-info");
              toggleTeamDrawerOpen.on();
            }}
          >
            View Members
          </Button>
        ),
        team: (
          <Button
            rightIcon={Plus}
            variant="secondary"
            onClick={() => {
              setTypeOfDrawer("create-team");
              setSelectedCompany({ name: el.name, id: el.id });
              toggleTeamDrawerOpen.on();
            }}
          >
            Add Team
          </Button>
        ),
      }));
    }
  }, [data, searchData]);

  if (isLoading) {
    return (
      <div className="p-6">
        <div>
          <h2 className="text-start text-2xl font-bold">
            All Customer Companies
          </h2>
        </div>
        <PageLoader
          className="w-full h-full"
          style={{ width: "calc(100% - 50px)", height: "calc(100vh - 285px)" }}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-start text-2xl font-bold">
          All Customer Companies
        </h2>
        <Button
          variant="secondary"
          rightIcon={Plus}
          onClick={() => {
            setTypeOfDrawer("create-company");
            toggleTeamDrawerOpen.on();
          }}
        >
          Add Customer Company
        </Button>
      </div>
      <TeamDrawer
        typeOfDrawer={typeOfDrawer}
        isOpen={isTeamDrawerOpen}
        onClose={toggleTeamDrawerOpen.off}
        company={selectedCompany}
      />
      <div className="w-full mt-6">
        {isError && (
          <div>
            <h2 className="text-xl">Error Loading the data</h2>
          </div>
        )}
        {!error && (
          <div className="flex flex-col gap-2">
            <input
              type="text"
              onChange={(e) => handleSearchByCompanyName(e.target.value)}
              className="w-1/4 border border-gray-200 rounded-lg p-2 ring-blue-500 focus:ring-2 outline-none"
              placeholder="Search by company name"
            />
            {!customerData?.length ? (
              <div className="w-full h-full flex justify-center items-center mt-40">
                <NotFound message="No Customer Companies Found" />
              </div>
            ) : (
              <Table
                isLoading={searchLoading}
                headers={[
                  {
                    name: "Company Name",
                    value: "name",
                  },
                  {
                    name: "Company ID",
                    value: "id",
                  },
                  {
                    name: "Company State",
                    value: "state",
                  },
                  {
                    name: "Contact Person",
                    value: "contact_person",
                  },
                  {
                    name: "Contact Person Number",
                    value: "contact_person_number",
                  },
                  {
                    name: "Contact Person Email",
                    value: "contact_person_email",
                  },
                  {
                    name: "Created At",
                    value: "createdAt",
                  },
                  {
                    name: "Members",
                    value: "members",
                  },
                  {
                    name: "Team",
                    value: "team",
                  },
                ]}
                rows={customerData || []}
                tableDivClassname="w-full border border-gray-200 !p-0 rounded-lg"
                tableHeaderClassname="bg-gray-100 px-2 py-2 text-md font-bold text-gray-600 truncate"
                tableBodyClassname="truncate"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyList;
