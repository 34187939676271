import { PageLoader } from "@components/loader";
import { useEffect } from "react";
import type { StatementProps } from "./Statement";
import { FormProvider, useForm } from "react-hook-form";
import classNames from "classnames";
import {
  incomeAndExpenditureSchema,
  IncomeAndExpenditure,
  IncomeAndExpenditureType,
} from "../validation/incomeandexpenditure";
import { InputGroup } from "@components/labelling/finances/components/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useGetStatementOfIncomeAndExpenditureQuery,
  useUpdateStatementOfIncomeAndExpenditureMutation,
} from "@app/store/api/llp-financialsApi";
import { Button } from "@app/components/common/Button";
import toast from "react-hot-toast";

export const StatementOfIncomeAndExpenditure = ({
  financialInfo,
  selectedYear,
  activePdf,
  isPdfOpen,
  isEditable,
  toggleEditable,
  selectedNature,
}: StatementProps) => {
  if (!financialInfo) {
    return null;
  }

  const form = useForm({
    resolver: yupResolver(incomeAndExpenditureSchema),
    mode: "all",
  });

  const [updateStatementOfIncomeAndExpenditure, { isLoading: isUpdating }] =
    useUpdateStatementOfIncomeAndExpenditureMutation();

  const { data, isLoading } = useGetStatementOfIncomeAndExpenditureQuery({
    document_id: financialInfo.id,
    year: selectedYear.value,
    nature: selectedNature,
  });

  useEffect(() => {
    if (data && data.response_data) {
      form.reset({
        ...data.response_data,
      });
    }
  }, [data]);

  const onSave = (submittedData: Partial<IncomeAndExpenditureType>) => {
    toast.promise(
      updateStatementOfIncomeAndExpenditure({
        ...submittedData,
        statement_of_income_and_expenditure_id: data?.response_data
          ?.id as string,
      }).unwrap(),
      {
        loading: "Updating...",
        success: () => {
          toast.success("Updated successfully");
          toggleEditable.off();
          return "Updated successfully";
        },
        error: () => {
          if (data) {
            form.reset(data.response_data);
          }
          toast.error("Failed to update");
          return "Failed to update";
        },
      },
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="mt-5">
      <div className="mt-5 flex">
        <div
          className={classNames("border p-4 rounded-md", {
            "w-1/2": isPdfOpen,
            "w-full": !isPdfOpen,
          })}
        >
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSave)}>
              <InputGroup
                isPdfOpen={false}
                title="Line Items"
                fields={IncomeAndExpenditure.line_items}
                isEditable={isEditable}
              />
              <InputGroup
                isPdfOpen={false}
                title="Revenue Breakup"
                fields={IncomeAndExpenditure.revenue_breakup}
                isEditable={isEditable}
              />
              <div className="flex gap-2">
                {isEditable && (
                  <Button
                    type="button"
                    className="w-full mt-4"
                    onClick={toggleEditable.off}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                )}

                {form.formState.isDirty && (
                  <Button
                    type="submit"
                    className="w-full mt-4"
                    isLoading={isUpdating}
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
        {isPdfOpen && activePdf && (
          <div className="w-1/2 pl-4">
            <object
              data={activePdf}
              type="application/pdf"
              className="w-full h-full"
            >
              <p>Form PDF</p>
            </object>
          </div>
        )}
      </div>
    </div>
  );
};
