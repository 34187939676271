import Modal from "react-modal";
import {
  useGetChargesQuery,
  useUpdateChargesMutation,
  useCreateChargesMutation,
} from "@app/store/api/chargesApi";
import { TypeOfModal } from "@components/labelling/shareholder/components/types";
import { useEffect, useMemo, useState, lazy } from "react";
import { Button } from "@components/common/Button";
import { X } from "lucide-react";
import { Charges, Status } from "@app/store/api/chargesApi/types";
import { IdentifierTypeEnum } from "@app/types";
import { useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ChargeCard } from "./ChargeCard";
import { Skelton } from "@components/common/Skelton";
import { Input } from "@components/common/Input";
import { NotFound } from "@components/common/NotFound";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useCreatePresignedUrlByDocumentIdMutation } from "@app/store/api/documentsApi/documentsApi";
import { DropDown } from "@components/common/dropdown";
import { formFields, FormField } from "./formFields";
import { isAfter } from "date-fns";
import classNames from "classnames";

const PdfViewer = lazy(() => import("@components/pdf-viewer/PdfViewer"));

interface EditOrConnectChargesModalProps {
  isOpen: boolean;
  onClose: () => void;
  typeOfModal: TypeOfModal;
  data: Partial<Charges>;
}

const schema = yup.object({
  charge_id: yup.string().required("Charge ID is required"),
  applicant: yup.string().nullable(),
  date: yup.string().nullable(),
  modification_particulars: yup.string().nullable(),
  created_or_modified_outside_india: yup.string().nullable(),
  consortium_holding: yup.string().nullable(),
  joint_holding: yup.string().nullable(),
  number_of_chargeholder: yup
    .number()
    .nullable()
    .typeError("Number of chargeholder is required"),
  holder_name: yup.string().required("Holder Name is required"),
  amount: yup.number().required("Amount is required"),
  rate_of_interest: yup.string().nullable(),
  term_of_payment: yup.string().nullable(),
  instrument_description: yup.string().nullable(),
  extent_and_operation: yup.string().nullable(),
  other_terms: yup.string().nullable(),
  property_particulars: yup.string().nullable(),
  uncalled_share_capital: yup.string().nullable(),
  immovable_property: yup.string().nullable(),
  floating_charge: yup.string().nullable(),
  property_for_securing_issue_of_secured_deposits: yup.string().nullable(),
  patent: yup.string().nullable(),
  trade_mark: yup.string().nullable(),
  book_debts: yup.string().nullable(),
  solely_of_property_outside_india: yup.string().nullable(),
  event_type: yup.string().nullable(),
  calls_made_but_not_paid: yup.string().nullable(),
  movable_property: yup.string().nullable(),
  motor_vehicle: yup.string().nullable(),
  goodwill: yup.string().nullable(),
  license_under_patent: yup.string().nullable(),
  copyright: yup.string().nullable(),
  ship_or_share_in_ship: yup.string().nullable(),
  others: yup.string().nullable(),
});

export const EditOrConnectChargesModal = ({
  isOpen,
  onClose,
  typeOfModal,
  data,
}: EditOrConnectChargesModalProps) => {
  const { cin } = useParams();
  const [search, setSearch] = useState("");
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
  const [updateCharges, { isLoading: isUpdating }] = useUpdateChargesMutation();
  const [createPresignedUrlByDocumentIdAndEntityId] =
    useCreatePresignedUrlByDocumentIdMutation();
  const [createCharges, { isLoading: isCreatingCharges }] =
    useCreateChargesMutation();

  const isAssignedCharge = data.charge_id !== null;

  const openPdf = (document_id: string) => {
    if (!document_id) {
      toast.error("No document found");
      return;
    }

    toast.promise(
      createPresignedUrlByDocumentIdAndEntityId({
        document_id,
      }).unwrap(),
      {
        loading: "Creating Document...",
        success: (data) => {
          setSelectedDocument(data.pre_signed_url);
          return "Document created successfully";
        },
        error: "Failed to create document",
      },
    );
  };

  const title = useMemo(() => {
    if (typeOfModal === "EDIT") return "Edit Charges";
    return "Add Charges";
  }, [typeOfModal]);

  const {
    data: chargesData,
    isLoading,
    isError,
  } = useGetChargesQuery(
    {
      identifier_type: IdentifierTypeEnum.CIN,
      identifier_value: cin as string,
      unassigned: false,
    },
    {
      skip: !isOpen,
    },
  );

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const uniqueCharges = useMemo(() => {
    if (!chargesData?.response_data) return [];
    if (typeOfModal === "ADD")
      return chargesData.response_data.filter(
        (el) => el && el.document_id && el.document_id.length > 0,
      );
    const uniqueChargesMap = new Map();

    if (data.charge_id) {
      return chargesData.response_data
        .filter((charge) => charge.charge_id === data.charge_id)
        .sort((prev, next) =>
          isAfter(new Date(prev.date), new Date(next.date)) ? 1 : -1,
        );
    }

    chargesData.response_data.forEach((charge) => {
      if (!uniqueChargesMap.has(charge.charge_id)) {
        uniqueChargesMap.set(charge.charge_id, charge);
      }
    });
    return Array.from(uniqueChargesMap.values()).filter(
      (item) => item.event_type !== data.event_type,
    ) as Charges[];
  }, [chargesData, typeOfModal, data]);

  useEffect(() => {
    if (typeOfModal === "EDIT") form.reset(data);
    else {
      const clearObject = Object.fromEntries(
        Object.keys(form.getValues()).map((key) => [key, null]),
      );
      form.reset(clearObject);
    }
  }, [data, typeOfModal]);

  const checkBoxFields = useMemo(() => {
    return formFields
      .filter((field) => field.type === "checkbox")
      .reduce((acc: FormField[][], curr, i, arr) => {
        if (i % 2 === 0) {
          acc.push([curr, arr[i + 1]].filter(Boolean));
        }
        return acc;
      }, []);
  }, []);

  const nonCheckBoxFields = useMemo(() => {
    return formFields.filter((field) => field.type !== "checkbox");
  }, []);

  const handleSubmit: SubmitHandler<yup.InferType<typeof schema>> = (
    payload,
  ) => {
    // Convert string boolean values to actual booleans - Check box convert boolean to "true" or "false"
    const booleanFields = [
      "created_or_modified_outside_india",
      "consortium_holding",
      "joint_holding",
      "uncalled_share_capital",
      "immovable_property",
      "floating_charge",
      "trade_mark",
      "book_debts",
      "calls_made_but_not_paid",
      "movable_property",
      "motor_vehicle",
      "goodwill",
      "license_under_patent",
      "copyright",
      "ship_or_share_in_ship",
      "others",
    ];
    const processedPayload = {
      ...payload,
      ...Object.fromEntries(
        booleanFields.map((field) => [
          field,
          payload[field as keyof typeof payload] === "true",
        ]),
      ),
    };

    if (typeOfModal === "EDIT") {
      toast.promise(
        updateCharges({
          ...processedPayload,
          event_type: processedPayload.event_type as Status,
          id: data.id as string,
        }).unwrap(),
        {
          loading: "Updating...",
          success: () => {
            form.reset();
            onClose();
            return "Updated successfully";
          },
          error: "Failed to update",
        },
      );
    } else {
      toast.promise(
        createCharges({
          ...processedPayload,
          event_type: payload.event_type as Status,
          identifier_value: cin as string,
        }).unwrap(),
        {
          loading: "Creating...",
          success: () => {
            form.reset();
            onClose();
            return "Created successfully";
          },
          error: "Failed to create",
        },
      );
    }
  };

  const filteredCharges = useMemo(() => {
    if (!search) return uniqueCharges;
    return uniqueCharges?.filter(
      (item) =>
        item?.holder_name
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toLowerCase()) ?? false,
    );
  }, [uniqueCharges, search]);

  const fillCharges = (chargeId: string) => {
    form.setValue("charge_id", chargeId);
    toast.success("Charge id filled successfully");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-content w-11/12 max-w-10xl h-full"
      overlayClassName="modal-overlay"
    >
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">{title}</h1>
        <div className="flex gap-2">
          {selectedDocument && (
            <Button
              variant="secondary"
              onClick={() => setSelectedDocument(null)}
            >
              Close PDF
            </Button>
          )}
          <Button variant="primary" rightIcon={X} onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
      {isError ? (
        <NotFound message="Error getting the data" />
      ) : (
        <main className="flex gap-4 h-[calc(100vh-6rem)] ">
          <section
            className={classNames(
              "border border-gray-200 rounded-lg p-4 mt-4 w-2/3 overflow-y-auto",
              selectedDocument ? "w-1/3" : "w-2/3",
            )}
          >
            <div className="overflow-y-auto px-2">
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  {nonCheckBoxFields.map((field) => {
                    if (field.type === "select") {
                      return (
                        <div key={field.id}>
                          <label
                            htmlFor={field.id}
                            className="mb-2 font-normal text-sm"
                          >
                            {field.label}
                          </label>
                          <DropDown
                            className="my-2"
                            id={field.id}
                            options={field.options || []}
                            value={{
                              label: form.watch(field.id as any) || "",
                              value: form.watch(field.id as any) || "",
                            }}
                            onChange={(data) =>
                              form.setValue(field.id as any, data.value)
                            }
                          />
                        </div>
                      );
                    } else {
                      return (
                        <Input
                          key={field.id}
                          id={field.id}
                          name={field.id}
                          label={field.label}
                          type={field.type}
                          style={field.style}
                        />
                      );
                    }
                  })}
                  <div className="border p-2 mb-3 rounded-md mt-3">
                    {checkBoxFields.map((field) => {
                      return (
                        <div key={field[0].id} className="grid grid-cols-2 ">
                          <div id="input-parent" className="text-center">
                            <Input
                              id={field[0].id}
                              name={field[0].id}
                              label={field[0].label}
                              type={field[0].type}
                              style={field[0].style}
                            />
                          </div>
                          {field[1] && (
                            <div id="input-parent" className="text-center">
                              <Input
                                id={field[1].id}
                                name={field[1].id}
                                label={field[1].label}
                                type={field[1].type}
                                style={field[1].style}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    variant="primary"
                    isLoading={isUpdating || isCreatingCharges}
                    type="submit"
                    className="w-full"
                  >
                    Update
                  </Button>
                </form>
              </FormProvider>
            </div>
          </section>
          <section
            className={classNames(
              "border border-gray-200 rounded-lg mt-4 w-1/3",
              selectedDocument ? "w-full" : "w-1/3 p-4",
            )}
          >
            {!selectedDocument && (
              <h2 className="text-lg font-bold w-full">Assigned Charges</h2>
            )}
            <div className="overflow-y-auto px-2 h-[calc(100%-1rem)] relative">
              {isLoading ? (
                <Skelton />
              ) : (
                <>
                  {selectedDocument ? (
                    <PdfViewer
                      pdfLink={selectedDocument}
                      className="h-full overflow-y-auto"
                    />
                  ) : (
                    <div>
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search by holder name"
                        className="w-full border border-gray-200 rounded-lg p-2 mt-4 ring-blue-500 focus:ring-2 focus:outline-none"
                      />
                      {filteredCharges.map((item, index) => (
                        <ChargeCard
                          key={item.charge_id + item.date + index}
                          charge_id={item.charge_id}
                          date={item.date}
                          holder_name={item.holder_name}
                          amount={item.amount.toString()}
                          fillCharges={fillCharges}
                          active={item.charge_id === form.watch("charge_id")}
                          status={item.event_type}
                          shouldOpenPDf={
                            isAssignedCharge || typeOfModal === "ADD"
                          }
                          document_id={item.document_id as string}
                          openPdf={openPdf}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </section>
        </main>
      )}
    </Modal>
  );
};
