import { useToggle } from "@app/hooks/useToggle";
import { Icon, Input } from "@components/common";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { Crosshair } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import Modal from "react-modal";
import * as Yup from "yup";
import { WarningModal } from "./WarningModal";
import toast from "react-hot-toast";
import { FileTypes, Upload } from "@components/common/Upload";
import { ScrapingDataSource } from "@app/store/api/qaTasksApi/types";
import { useUploadInvoiceAndTriggerMCAv2OrV3ScrapeMutation } from "@app/store/api/gengisScraperApi/gengisScraperApi";
import { useGetMcaAccountByStatusQuery } from "@app/store/api/mcaAccount/mcaAccountsApi";
import { ScrapingTaskType } from "@app/store/api/gengisScraperApi/types";
import {
  GengisMcaAccountVersion,
  McaAccountStatus,
} from "@app/store/api/mcaAccount/types";
import { IdentifierTypeEnum } from "@app/types";
import { Button } from "@components/common/Button";

export interface ProjectInfo {
  cinId: string;
  company: string;
  task_id?: string;
  project_id?: string;
  taskNature: ScrapingDataSource;
  identifier_type?: IdentifierTypeEnum;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: ProjectInfo;
  scrapingType: ScrapingTaskType;
}

const schema = Yup.object({
  cinId: Yup.string().required("Cin Id is required"),
});

const mcaDocument = Yup.object({
  file: Yup.mixed().required("File is required"),
  account: Yup.string().required("Account is required"),
  version: Yup.string().required("MCA Version is required"),
  srn: Yup.string().required("SRN ID is required"),
  download_primary: Yup.boolean(),
  download_secondary: Yup.boolean(),
});

enum mcaVersion {
  v2 = "v2",
  v3 = "v3",
}

type IMca = Yup.InferType<typeof mcaDocument>;

type PayLoadState = Omit<IMca, "file"> & {
  cin: string;
  file: File;
  srn: string;
  mca_version: mcaVersion.v2 | mcaVersion.v3;
  company_name: string;
  account: string;
  taskNature: ScrapingDataSource;
  download_primary: boolean;
  download_secondary: boolean;
};

export const UploadInvoiceModal = ({
  isOpen,
  onClose,
  data,
  scrapingType,
}: Props) => {
  if (!isOpen) return null;

  const [isWarningModalActive, toggleWarningModalActive] = useToggle();

  const computedMcaVersion =
    data.taskNature === ScrapingDataSource.SCRAPING_MCA_V2
      ? mcaVersion.v2
      : mcaVersion.v3;

  const computedAvailableAccounts =
    data.taskNature === ScrapingDataSource.SCRAPING_MCA_V2
      ? GengisMcaAccountVersion.MCA_V2
      : GengisMcaAccountVersion.MCA_V3;

  const { data: mcaAccounts, isLoading: isMcaAccountsLoading } =
    useGetMcaAccountByStatusQuery(
      {
        status: [
          McaAccountStatus.FREE,
          McaAccountStatus.IN_USE,
          McaAccountStatus.ALLOCATED,
        ],
        mcaVersion: computedAvailableAccounts,
      },
      {
        pollingInterval: 15000,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      },
    );

  // To Pass data to warning modal
  const [scrapingData, setScrapingData] = useState<PayLoadState | null>(null);
  const [uploadInvoiceAndTriggerMCAv2OrV3Scrape, { isLoading }] =
    useUploadInvoiceAndTriggerMCAv2OrV3ScrapeMutation();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cinId: data.cinId,
    },
  });

  const mcaForm = useForm({
    resolver: yupResolver(mcaDocument),
    mode: "onChange",
  });

  useEffect(() => {
    if (data) {
      form.setValue("cinId", data.cinId);
      mcaForm.setValue(
        "version",
        data.taskNature === "SCRAPING_MCA_V2" ? mcaVersion.v2 : mcaVersion.v3,
      );
    }
  }, [data, form, mcaForm]);

  const availableAccounts = useMemo(() => {
    return mcaAccounts?.response_data
      .filter((type) => type.mca_version === computedAvailableAccounts)
      ?.map((account) => ({
        label: account.name,
        value: account.id,
      }));
  }, [mcaAccounts, computedAvailableAccounts]);

  // doing this to pass data to warning modal
  const onMcaSubmit: SubmitHandler<IMca> = (submitData) => {
    if (!submitData.download_primary && !submitData.download_secondary) {
      toast.error("Please select at least one document type to download");
      return;
    }

    toggleWarningModalActive.on();
    setScrapingData({
      ...submitData,
      cin: data.cinId,
      file: submitData.file as File,
      mca_version: submitData.version as mcaVersion,
      company_name: data.company,
      account:
        availableAccounts?.find((option) => option.value === submitData.account)
          ?.label || "",
      download_primary: submitData.download_primary || false,
      download_secondary: submitData.download_secondary || false,
      taskNature: data.taskNature,
    });
  };

  const processScraping = async () => {
    if (scrapingData) {
      try {
        const payload = new FormData();

        payload.append("srn", scrapingData.srn);
        payload.append("file", scrapingData.file);
        if (data.task_id) {
          payload.append("task_id", data.task_id);
        }
        payload.append("mca_account_id", mcaForm.getValues("account"));
        payload.append("scraping_type", scrapingType);
        payload.append(
          "download_primary",
          scrapingData.download_primary.toString(),
        );
        payload.append(
          "download_secondary",
          scrapingData.download_secondary.toString(),
        );
        await uploadInvoiceAndTriggerMCAv2OrV3Scrape(payload).unwrap();
        toast.success("Scraping Started Successfully");
      } catch {
        toast.error("Error Starting Scraping");
      } finally {
        setScrapingData(null);
        toggleWarningModalActive.off();
        onClose();
      }
    }
  };

  const handleClose = () => {
    form.reset();
    mcaForm.reset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="modal-content"
      overlayClassName="modal-overlay"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
    >
      <WarningModal
        isOpen={isWarningModalActive}
        onClose={toggleWarningModalActive.off}
        onConfirm={processScraping}
        isLoading={isLoading}
        info={{
          ...data,
          account: scrapingData?.account || "",
          version: mcaForm.getValues("version"),
          srn: mcaForm.getValues("srn"),
          download_primary: mcaForm.getValues("download_primary") || false,
          download_secondary: mcaForm.getValues("download_secondary") || false,
        }}
      />
      <div className="bg-white sm:w-[550px]  md:w-[800px] pb-8 flex flex-col rounded-md justify-around h-[650px] overflow-y-scroll">
        <div className="border-b flex justify-between items-center">
          <h4 className="ml-2 p-2 text-md font-bold">Trigger Data Scraping</h4>
          <span onClick={handleClose}>
            <Icon
              name="X"
              size={25}
              className="border  p-1 rounded-full hover:cursor-pointer"
            />
          </span>
        </div>
        <div className="flex gap-0 flex-col px-5">
          <FormProvider {...form}>
            <div className="mt-4">
              <Input
                name="cinId"
                label="Cin ID"
                placeholder="cin id"
                disabled
              />
            </div>
          </FormProvider>
        </div>
        <div className="flex gap-0 flex-col px-5">
          <FormProvider {...mcaForm}>
            <form
              onSubmit={mcaForm.handleSubmit(onMcaSubmit)}
              className="mt-2 flex flex-col gap-2 w-full"
            >
              <div>
                <Input
                  name="version"
                  label="MCA Version"
                  placeholder="MCA Version"
                  value={computedMcaVersion}
                  disabled
                />
              </div>
              <div>
                <Input
                  name="srn"
                  label="SRN ID"
                  placeholder="14 letter id in invoice"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="account">
                  Invoice
                </label>
                <Controller
                  name="file"
                  control={mcaForm.control}
                  rules={{ required: "File is required" }}
                  render={({ field }) => (
                    <Upload
                      className="h-44"
                      onFileSelect={(file: File) => field.onChange(file)}
                      error={mcaForm.formState.errors.file?.message}
                      accept={[FileTypes.PDF, FileTypes.JPEG, FileTypes.PNG]}
                    />
                  )}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="account">
                  Account to use
                </label>
                <Controller
                  name="account"
                  control={mcaForm.control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        maxMenuHeight={200}
                        options={availableAccounts}
                        value={
                          availableAccounts?.find(
                            (option) => option.value === field.value,
                          ) || null
                        }
                        onChange={(option) => field.onChange(option?.value)}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: fieldState.error?.message
                              ? "red"
                              : base.borderColor,
                            boxShadow: "none",
                          }),
                        }}
                        isSearchable
                      />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <section className="flex gap-2">
                <div className="w-full mt-4 flex self-start">
                  <Input
                    className="w-5 h-5 cursor-pointer mt-2"
                    name="download_primary"
                    type="checkbox"
                    label="Download Primary Documents"
                  />
                </div>
                <div className="w-full mt-4 flex self-start">
                  <Input
                    className="w-5 h-5 cursor-pointer mt-2"
                    name="download_secondary"
                    type="checkbox"
                    label="Download Secondary Documents"
                  />
                </div>
              </section>
              <Button
                type="submit"
                isLoading={isMcaAccountsLoading}
                className="mt-4"
              >
                <span>Trigger Scraping</span>
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};
