import { Status } from "@app/store/api/chargesApi/types";
import { Option } from "@components/common/dropdown";

export interface FormField {
  id: string;
  label: string;
  type?: "text" | "date" | "number" | "select" | "checkbox";
  options?: Option<string>[];
  style?: React.CSSProperties;
}

export const formFields: FormField[] = [
  { id: "charge_id", label: "Charge ID" },
  { id: "holder_name", label: "Holder Name" },
  {
    id: "event_type",
    label: "Event Type",
    type: "select",
    options: Object.values(Status).map((status) => ({
      label: status,
      value: status,
    })),
  },
  { id: "date", label: "Date", type: "date" },
  { id: "amount", label: "Amount", type: "number" },
  {
    id: "applicant",
    label: "Applicant",
    type: "select",
    options: [
      { label: "Company", value: "Company" },
      { label: "ChargeHolder", value: "ChargeHolder" },
    ],
  },
  { id: "modification_particulars", label: "Modification Particulars" },
  {
    id: "number_of_chargeholder",
    label: "Number of Chargeholder",
    type: "number",
  },
  { id: "rate_of_interest", label: "Rate of Interest" },
  { id: "term_of_payment", label: "Term of Payment" },
  { id: "instrument_description", label: "Instrument Description" },
  { id: "extent_and_operation", label: "Extent and Operation" },
  { id: "other_terms", label: "Other Terms" },
  { id: "property_particulars", label: "Property Particulars" },
  { id: "patent", label: "Patent", type: "checkbox", style: { zoom: 1.5 } },
  {
    id: "solely_of_property_outside_india",
    label: "Solely of Property Outside India",
    type: "checkbox",
    style: { zoom: 1.5 },
  },
  { id: "status", label: "Status" },
  {
    id: "property_for_securing_issue_of_secured_deposits",
    label: "Property for Securing Issue of Secured Deposits",
    type: "checkbox",
    style: { zoom: 1.5 },
  },
  {
    id: "created_or_modified_outside_india",
    label: "Created or Modified Outside India",
    type: "checkbox",
    style: { zoom: 1.5, cursor: "pointer" },
  },
  {
    id: "consortium_holding",
    label: "Consortium Holding",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "joint_holding",
    label: "Joint Holding",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "uncalled_share_capital",
    label: "Uncalled Share Capital",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "immovable_property",
    label: "Immovable Property",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "floating_charge",
    label: "Floating Charge",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "trade_mark",
    label: "Trade Mark",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "book_debts",
    label: "Book Debts",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "calls_made_but_not_paid",
    label: "Calls Made But Not Paid",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "movable_property",
    label: "Movable Property",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "motor_vehicle",
    label: "Motor Vehicle",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "goodwill",
    label: "Goodwill",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "license_under_patent",
    label: "License Under Patent",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "copyright",
    label: "Copyright",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  {
    id: "ship_or_share_in_ship",
    label: "Ship or Share in Ship",
    style: { zoom: 1.5 },
    type: "checkbox",
  },
  { id: "others", label: "Others", style: { zoom: 1.5 }, type: "checkbox" },
];
