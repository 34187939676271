import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import classNames from "classnames";

interface Props {
  handler?: (el: string) => void;
  value: string;
  className?: string;
  trimValue?: number;
  tooltipStyle?: string;
}

export const ToolTip = ({
  handler,
  value,
  className,
  trimValue,
  tooltipStyle,
}: Props) => {
  if (!trimValue && value && value.length) {
    trimValue = value.length;
  }
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <span
            className={classNames(
              "px-2 py-1 border rounded-md hover:cursor-pointer flex justify-center items-center gap-2 max-w-96",
              {
                className,
              },
            )}
            onClick={() => (handler ? handler(value) : null)}
          >
            <span className="truncate">{value.slice(0, trimValue)}</span>
          </span>
        </TooltipTrigger>
        <TooltipContent
          className={classNames(
            "bg-gray-800 text-xs text-white",
            tooltipStyle && tooltipStyle,
          )}
        >
          {value}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
