import { useMaxToasts } from "@app/hooks/useMaxToas";
import { Toaster } from "react-hot-toast";

// USED TO LIMIT THE NUMBER OF TOAST ACTIVE AT A TIME
export function ToasterWithMax({
  max = 10,
  ...props
}: React.ComponentProps<typeof Toaster> & {
  max?: number;
}) {
  useMaxToasts(max);

  return <Toaster {...props} />;
}
