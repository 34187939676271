import { apiHandler } from "@app/config/apiHandler";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import Modal from "react-modal";
import CreatableSelect from "react-select/creatable";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Input } from "@components/common";
import {
  useGetCustomerCompanyDetailsMutation as useGetCustomerCompanyTeamsDetailsMutation,
  useListCustomerCompaniesQuery,
} from "@app/store/api/customerCompany/useCustomerCompany";
import { useEffect, useMemo } from "react";
import { Option } from "@components/common/dropdown";
import { useDispatch } from "react-redux";
import usersListApi from "@app/store/api/usersList/usersList";
import { TeamDrawerCompany, UsersListDrawerType } from "@pages/UsersList";
import { useSearchParams } from "react-router-dom";

export interface BaseOptionType {
  label: string;
  value: string;
}

type CreatableSelectOption = BaseOptionType & {
  __isNew__?: boolean;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  openTeamDrawer: (
    type: UsersListDrawerType,
    company: TeamDrawerCompany,
  ) => void;
  data: {
    name: string;
    email: string;
    user_id: string;
    phone_number: string;
  };
}

interface CustomerInfoPayload {
  company_id: string;
  team_id: string;
  user_id: string;
}

const schema = Yup.object({
  customer_company_id: Yup.string().required("Customer company ID is required"),
  customer_team_id: Yup.string().required("Customer team ID is required"),
  user_id: Yup.string().required("User ID is required"),
  name: Yup.string().required("Customer name is required"),
  email: Yup.string().required("Email is required"),
  phone_number: Yup.string().required("Phone number is required"),
});

type Ischema = Yup.InferType<typeof schema>;

export const ApproveUserModal = ({
  isOpen,
  onClose,
  data,
  openTeamDrawer,
}: Props) => {
  const [_, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    data: allCustomerCompanies,
    isLoading,
    isError,
  } = useListCustomerCompaniesQuery();
  const [getTeam, { data: teamInfo }] =
    useGetCustomerCompanyTeamsDetailsMutation();

  const approveUser = async (data: CustomerInfoPayload) => {
    try {
      await apiHandler.post("/user-management/approve", data);
      toast.success("User Approved Successfully");
    } catch {
      toast.error("Failed to approve user");
    }
  };

  const transformedCustomerCompanies = useMemo(() => {
    return allCustomerCompanies?.response_data.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  }, [allCustomerCompanies]);

  const transformedTeamDetails = useMemo(() => {
    return teamInfo?.response_data.company_teams.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  }, [teamInfo]);

  const form = useForm<Ischema>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      ...data,
    },
  });

  useEffect(() => {
    if (data) {
      form.setValue("user_id", data.user_id);
      form.setValue("email", data.email);
      form.setValue("phone_number", data.phone_number);
      form.setValue("name", data.name);
    }
  }, [data]);

  const isCompanyNameSelected = Boolean(form.getValues("customer_company_id"));

  const selectedCompanyId = useWatch({
    control: form.control,
    name: "customer_company_id",
  });

  useEffect(() => {
    if (selectedCompanyId) {
      getTeam(selectedCompanyId);
    } else {
      form.reset();
    }
  }, [selectedCompanyId, getTeam]);

  const handleSubmit: SubmitHandler<Ischema> = async (payload) => {
    try {
      await approveUser({
        company_id: payload.customer_company_id,
        team_id: payload.customer_team_id,
        user_id: payload.user_id,
      });
      form.reset();
      dispatch(usersListApi.util.invalidateTags(["unapproved-users"]));
      toast.success("User Approved Successfully");
      onClose();
    } catch {
      toast.error("Error Approving User");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="modal-content"
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <h4 className="text-xl font-semibold ml-4 border-b pb-2">Approve User</h4>
      <div className="flex flex-col px-4 py-3 w-[600px]">
        {isLoading ? (
          <h2>Loading...</h2>
        ) : isError ? (
          <h2>Error loading customer companies</h2>
        ) : (
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex flex-col gap-1"
            >
              <Input name="name" label="Customer Name" />
              <Input name="email" label="Customer Email" type="email" />
              <Input name="user_id" label="User ID" disabled />
              <Input
                name="phone_number"
                label="Customer Phone Number"
                type="text"
                disabled
              />
              <div className="flex flex-col gap-1">
                <label htmlFor="customer_company_id">
                  Customer Company Name
                </label>
                <Controller
                  name="customer_company_id"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <CreatableSelect<CreatableSelectOption>
                        onCreateOption={(input) => {
                          setSearchParams({
                            "company-name": input,
                          });
                          openTeamDrawer("create-company", {
                            name: "",
                            id: "",
                          });
                        }}
                        maxMenuHeight={200}
                        {...field}
                        options={transformedCustomerCompanies}
                        className="rounded-md"
                        value={
                          transformedCustomerCompanies?.find(
                            (option) => option.value === field.value,
                          ) || null
                        }
                        onChange={(option: Option | null) =>
                          field.onChange(option?.value)
                        }
                        styles={{
                          option: (styles, { data }) => ({
                            ...styles,
                            fontWeight: data?.__isNew__ ? "bold" : "normal",
                            color: data?.__isNew__ ? "black" : styles.color,
                          }),
                        }}
                      />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1 mt-6">
                <label htmlFor="customer_team_id">Customer Team Name</label>
                <Controller
                  name="customer_team_id"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <CreatableSelect<CreatableSelectOption>
                        onFocus={() => {
                          getTeam(selectedCompanyId);
                        }}
                        onCreateOption={(input) => {
                          setSearchParams({
                            companyId: selectedCompanyId,
                            "team-name": input,
                          });
                          openTeamDrawer("create-team", {
                            name: "",
                            id: selectedCompanyId,
                          });
                        }}
                        maxMenuHeight={150}
                        {...field}
                        options={transformedTeamDetails}
                        className="rounded-md"
                        value={
                          transformedTeamDetails?.find(
                            (option) => option.value === field.value,
                          ) || null
                        }
                        onChange={(option: Option | null) =>
                          field.onChange(option?.value)
                        }
                        isDisabled={!isCompanyNameSelected}
                        styles={{
                          option: (styles, { data }) => ({
                            ...styles,

                            fontWeight: data?.__isNew__ ? "bold" : "normal",
                            color: data?.__isNew__ ? "black" : styles.color,
                          }),
                        }}
                      />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex justify-end mt-4 gap-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex justify-center items-center bg-red-500 px-3 py-2 rounded-md text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center items-center bg-blue-500 px-3 py-2 rounded-md text-white"
                >
                  Approve
                </button>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </Modal>
  );
};
