import { useGetAllScrapingTasksHistoryQuery } from "@app/store/api/qaTasksApi/qaTasksApi";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { useParams, useSearchParams } from "react-router-dom";
import { AdhocTaskNature } from "@app/store/api/qaTasksApi/types";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { RenderAdhocStatus } from "@components/admin/company-requests";
import { RenderScrapingStatus } from "@components/tasks/pending";

import { isEmpty } from "@app/utils/isEmpty";
import { Skelton } from "@components/common/Skelton";
import { formatDateWithSeconds } from "@components/admin/projects/utils";

export const ProjectHistory = () => {
  const { cin } = useParams();
  const { activeTypeOfCompany } = useActiveCompany();
  const [searchParams] = useSearchParams();

  const scrapingType =
    searchParams.get("tab") === "mcav3"
      ? AdhocTaskNature.SCRAPING_MCA_V3_TASK
      : AdhocTaskNature.SCRAPING_MCA_V2_TASK;

  const { data: scrapingTasksHistory, isLoading } =
    useGetAllScrapingTasksHistoryQuery({
      identifier_type: activeTypeOfCompany,
      scraping_type: scrapingType,
      identifier_value: cin as string,
    });

  return (
    <section className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 min-w-[400px] max-h-[600px] outline-none overflow-y-auto ">
      {isLoading ? (
        <Skelton count={2} />
      ) : (
        <div className="space-y-4 p-4 overflow-y-auto">
          {isEmpty(scrapingTasksHistory?.response_data.adhoc_scraping_tasks) &&
          isEmpty(scrapingTasksHistory?.response_data.scraping_tasks) ? (
            <p className="text-gray-500 text-center py-4">No tasks found</p>
          ) : (
            <div className="space-y-4">
              <h1 className="text-lg font-bold border-b pb-2">
                Project History
              </h1>
              {!isEmpty(scrapingTasksHistory?.response_data.scraping_tasks) && (
                <>
                  <h4 className="text-sm text-gray-500">
                    Scraping Tasks History
                  </h4>
                  {scrapingTasksHistory?.response_data.scraping_tasks.map(
                    (task) => (
                      <div
                        key={task.id}
                        className="border rounded-lg p-4 space-y-2"
                      >
                        <div className="flex justify-between">
                          <span className="font-medium">
                            {removeUnderscores(task.task_nature_name)}
                          </span>
                          <span className="text-sm text-gray-500">
                            <RenderScrapingStatus status={task.status} />
                          </span>
                        </div>
                        <div className="text-sm text-gray-600">
                          <p>
                            Started:{" "}
                            {task.started_at
                              ? formatDateWithSeconds(task.started_at)
                              : "-"}
                          </p>
                          <p>
                            Completed:{" "}
                            {task.completed_at
                              ? formatDateWithSeconds(task.completed_at)
                              : "-"}
                          </p>
                          {task.assigned_to_name && (
                            <p>Assigned to: {task.assigned_to_name}</p>
                          )}
                        </div>
                        {task.scraping_metadata && (
                          <div className="text-sm text-gray-600">
                            <p>
                              Company: {task.scraping_metadata.company_name}
                            </p>
                            {task.scraping_metadata.srn && (
                              <p>SRN: {task.scraping_metadata.srn}</p>
                            )}
                          </div>
                        )}
                      </div>
                    ),
                  )}
                </>
              )}
              {!isEmpty(
                scrapingTasksHistory?.response_data.adhoc_scraping_tasks,
              ) && (
                <>
                  <h4 className="text-sm text-gray-500">Adhoc Tasks History</h4>
                  {scrapingTasksHistory?.response_data.adhoc_scraping_tasks.map(
                    (task) => (
                      <div
                        key={task.id}
                        className="border rounded-lg p-4 space-y-2"
                      >
                        <div className="flex justify-between">
                          <span className="font-medium">
                            {removeUnderscores(task.task_nature_name)}
                          </span>
                          <span className="text-sm text-gray-500">
                            <RenderAdhocStatus status={task.status} />
                          </span>
                        </div>
                        <div className="text-sm text-gray-600">
                          <p>
                            Started:{" "}
                            {task.started_at
                              ? formatDateWithSeconds(task.started_at)
                              : "-"}
                          </p>
                          <p>
                            Completed:{" "}
                            {task.completed_at
                              ? formatDateWithSeconds(task.completed_at)
                              : "-"}
                          </p>
                        </div>
                        {task.adhoc_metadata && (
                          <div className="text-sm text-gray-600">
                            <p>Company: {task.adhoc_metadata.company_name}</p>
                            {task.adhoc_metadata.srn && (
                              <p>SRN: {task.adhoc_metadata.srn}</p>
                            )}
                          </div>
                        )}
                      </div>
                    ),
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </section>
  );
};
