import Modal from "react-modal";
import { ProjectInfo } from "./UploadInvoiceModal";
import { Input } from "@components/common/Input";
import * as Yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@components/common/Button";
import { Icon } from "@components/common";
import { useTriggerScrapingTasksMutation } from "@app/store/api/gengisScraperApi";
import { ScrapingTaskType } from "@app/store/api/gengisScraperApi/types";
import { toast } from "react-hot-toast";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  info: ProjectInfo;
  scrapingType: ScrapingTaskType;
}

const schema = Yup.object({
  exact_company_name: Yup.string().required("Exact Company Name is required"),
});

type FormData = Yup.InferType<typeof schema>;

export const EpfoHintModal = ({
  isOpen,
  onClose,
  info,
  scrapingType,
}: Props) => {
  const [triggerScrapingTasks, { isLoading }] =
    useTriggerScrapingTasksMutation();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onConfirm: SubmitHandler<FormData> = (data) => {
    if (!info.task_id) return;
    try {
      triggerScrapingTasks({
        task_id: info.task_id,
        scraping_type: scrapingType,
        exact_company_name: data.exact_company_name,
      }).unwrap();
      toast.success("Scraping task triggered successfully");
    } catch {
      toast.error("Error triggering scraping task");
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="modal-content"
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div className="bg-white w-[400px] h-46 2 flex flex-col rounded-md justify-between">
        <div className="border-b flex justify-between">
          <h4 className="text-md font-bold  inline-flex gap-2 items-center mb-2">
            EPFO
          </h4>

          <Icon
            name="X"
            size={15}
            className="border rounded-full hover:cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="flex gap-2 flex-col mt-2">
          <h4 className="text-sm font-bold">
            Please Provide the hint for the EPFO Scraper
          </h4>
          <ul className="text-sm">
            <li className="font-semibold">CIN ID - {info.cinId}</li>
            <li className="font-semibold">Task Nature - SCRAPING EPFO</li>
            <li className="font-semibold">Company - {info.company}</li>
          </ul>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onConfirm)}>
              <Input label="Exact Search Name Hint" name="exact_company_name" />
              <Button
                type="submit"
                className="w-full mt-4"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};
