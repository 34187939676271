import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import Modal from "react-modal";
import { Button } from "@app/components/common/Button";
import { Input } from "@app/components/common/Input";
import { DropDown } from "@app/components/common/dropdown";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@components/common";
import {
  EntityIdentifierType,
  EntityType,
  EntityStatus,
} from "@app/types/non-corp";
import { Controller } from "react-hook-form";
import { useMemo } from "react";

const schema = yup.object().shape({
  identifier_value: yup.string().required("Identifier value is required"),
  identifier_type: yup
    .string()
    .oneOf(Object.values(EntityIdentifierType))
    .required("Identifier type is required"),
  entity_name: yup.string().required("Entity name is required"),
  entity_type: yup
    .string()
    .oneOf(Object.values(EntityType))
    .required("Entity type is required"),
  entity_status: yup
    .string()
    .oneOf(Object.values(EntityStatus))
    .required("Entity status is required"),
  industry_nature: yup.string().required("Industry nature is required"),
  registration_date: yup.string().optional(),
});

type FormData = yup.InferType<typeof schema>;

interface CreateNewOrgModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateNewOrgModal = ({
  isOpen,
  onClose,
}: CreateNewOrgModalProps) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      identifier_value: "",
      entity_name: "",
      industry_nature: "",
      registration_date: "",
    },
  });

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data);
    onClose();
  };

  const [identifierTypeOptions, entityTypeOptions, entityStatusOptions] =
    useMemo(
      () => [
        Object.entries(EntityIdentifierType).map(([label, value]) => ({
          label,
          value,
        })),
        Object.entries(EntityType).map(([label, value]) => ({
          label,
          value,
        })),
        Object.entries(EntityStatus).map(([label, value]) => ({
          label,
          value,
        })),
      ],
      [],
    );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="bg-white rounded-lg  w-[500px]">
        <div className="flex justify-between items-center py-4 border-b">
          <h2 className="text-xl font-semibold">Create New Organization</h2>
          <Icon name="X" onClick={onClose} className="cursor-pointer" />
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmit)}
            className="p-4 space-y-4"
          >
            <Input
              name="entity_name"
              label="Entity Name"
              placeholder="Enter entity name"
            />

            <div className="space-y-2">
              <label className="block text-gray-700">
                Entity Identifier Type
              </label>
              <Controller
                name="identifier_type"
                control={methods.control}
                render={({ field }) => (
                  <DropDown
                    options={identifierTypeOptions}
                    value={
                      field.value
                        ? {
                            label: field.value,
                            value: field.value,
                          }
                        : null
                    }
                    onChange={(option) => field.onChange(option.value)}
                    error={methods.formState.errors.identifier_type?.message}
                  />
                )}
              />
            </div>

            <div className="space-y-4">
              <Input
                name="identifier_value"
                label="Entity Identifier Value"
                placeholder="Enter identifier value"
              />

              <div className="space-y-2">
                <label className="block text-gray-700">Entity Type</label>
                <Controller
                  name="entity_type"
                  control={methods.control}
                  render={({ field }) => (
                    <DropDown
                      options={entityTypeOptions}
                      value={
                        field.value
                          ? {
                              label: field.value,
                              value: field.value,
                            }
                          : null
                      }
                      onChange={(option) => field.onChange(option.value)}
                      error={methods.formState.errors.entity_type?.message}
                    />
                  )}
                />
              </div>

              <div className="space-y-2">
                <label className="block text-gray-700">Entity Status</label>
                <Controller
                  name="entity_status"
                  control={methods.control}
                  render={({ field }) => (
                    <DropDown
                      options={entityStatusOptions}
                      value={
                        field.value
                          ? {
                              label: field.value,
                              value: field.value,
                            }
                          : null
                      }
                      onChange={(option) => field.onChange(option.value)}
                      error={methods.formState.errors.entity_status?.message}
                    />
                  )}
                />
              </div>

              <Input
                name="industry_nature"
                label="Industry Nature"
                placeholder="Enter industry nature"
              />

              <Input
                name="registration_date"
                label="Registration Date"
                type="date"
              />
            </div>

            <div className="flex justify-end gap-2 pt-4 border-t">
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
