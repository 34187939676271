import { McaV2Table, McaV3Table } from "@app/components/labelling/documents";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { ProjectHistory } from "@app/components/labelling/documents";
import { Popover } from "@components/common/Popover";
import { History } from "lucide-react";

type DocumentType = "mcav2" | "mcav3";

const DocumentsPage = () => {
  const [activeTab, setActiveTab] = useState<DocumentType>("mcav2");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const tab = searchParams.get("tab") as DocumentType;
    if (tab === "mcav2" || tab === "mcav3") {
      setActiveTab(tab);
    } else {
      // If no valid tab in query params, set default to "mcav2"
      navigate("?tab=mcav2", { replace: true });
    }
  }, [searchParams]);

  const handleTabChange = (tab: DocumentType) => {
    setActiveTab(tab);
    navigate(`?tab=${tab}`);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Company Documents</h2>
        <Popover content={<ProjectHistory />} side="bottom" align="end">
          <span className="flex items-center gap-2">
            <button className="flex items-center gap-2 text-sm text-gray-500 border border-gray-200 rounded-md p-2">
              <History />
              View {activeTab === "mcav2" ? "MCA - V2" : "MCA - V3"} History
            </button>
          </span>
        </Popover>
      </div>

      <div className="flex border-b">
        <button
          type="button"
          className={classNames("flex-1 py-2 text-center", {
            "border-b-2 border-blue-500 text-blue-500": activeTab === "mcav2",
            "text-gray-500": activeTab !== "mcav2",
          })}
          onClick={() => handleTabChange("mcav2")}
        >
          MCA - V2 Documents
        </button>
        <button
          type="button"
          className={classNames("flex-1 py-2 text-center", {
            "border-b-2 border-blue-500 text-blue-500": activeTab === "mcav3",
            "text-gray-500": activeTab !== "mcav3",
          })}
          onClick={() => handleTabChange("mcav3")}
        >
          MCA - V3 Documents
        </button>
      </div>
      {activeTab === "mcav2" && <McaV2Table />}
      {activeTab === "mcav3" && <McaV3Table />}
    </div>
  );
};

export default DocumentsPage;
