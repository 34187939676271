import * as Yup from "yup";

/*
  - In order to render financial we are storing the keys which is heading of each section
  to a object and its value as array
  - The value which start with "COMPUTED" is a computed property and should not be editable
  - We only add yup validation for keys which does not start with "COMPUTED"
  - This is done to filter out computed keys when sending to backend
  - Computed keys are computed in backend on fly
*/

export const xbrlAsBalanceSheetSchema = Yup.object().shape({
  tangible_assets: Yup.number()
    .required("Tangible assets is required")
    .typeError("Tangible assets must be a number"),
  intangible_assets: Yup.number()
    .required("Intangible assets is required")
    .typeError("Intangible assets must be a number"),
  tangible_assets_capital_work_in_progress: Yup.number()
    .required("Tangible assets capital work in progress is required")
    .typeError("Tangible assets capital work in progress must be a number"),
  intangible_assets_under_development: Yup.number()
    .required("Intangible assets under development is required")
    .typeError("Intangible assets under development must be a number"),
  noncurrent_investments: Yup.number()
    .required("Non-current investments is required")
    .typeError("Non-current investments must be a number"),
  deferred_tax_assets_net: Yup.number()
    .required("Deferred tax assets net is required")
    .typeError("Deferred tax assets net must be a number"),
  long_term_loans_and_advances: Yup.number()
    .required("Long term loans and advances is required")
    .typeError("Long term loans and advances must be a number"),
  other_noncurrent_assets: Yup.number()
    .required("Other non-current assets is required")
    .typeError("Other non-current assets must be a number"),
  current_investments: Yup.number()
    .required("Current investments is required")
    .typeError("Current investments must be a number"),
  inventories: Yup.number()
    .required("Inventories is required")
    .typeError("Inventories must be a number"),
  trade_receivables: Yup.number()
    .required("Trade receivables is required")
    .typeError("Trade receivables must be a number"),
  cash_and_bank_balances: Yup.number()
    .required("Cash and bank balances is required")
    .typeError("Cash and bank balances must be a number"),
  short_term_loans_and_advances: Yup.number()
    .required("Short term loans and advances is required")
    .typeError("Short term loans and advances must be a number"),
  other_current_assets: Yup.number()
    .required("Other current assets is required")
    .typeError("Other current assets must be a number"),
  foreign_curr_monetary_item_trans_diff_asset_account: Yup.number()
    .required(
      "Foreign currency monetary item translation difference asset account is required",
    )
    .typeError(
      "Foreign currency monetary item translation difference asset account must be a number",
    ),
  producing_properties: Yup.number()
    .required("Producing properties is required")
    .typeError("Producing properties must be a number"),
  preproducing_properties: Yup.number()
    .required("Preproducing properties is required")
    .typeError("Preproducing properties must be a number"),
  share_capital: Yup.number()
    .required("Share capital is required")
    .typeError("Share capital must be a number"),
  reserves_and_surplus: Yup.number()
    .required("Reserves and surplus is required")
    .typeError("Reserves and surplus must be a number"),
  money_received_against_share_warrants: Yup.number()
    .required("Money received against share warrants is required")
    .typeError("Money received against share warrants must be a number"),
  share_application_money_pending_allotment: Yup.number()
    .required("Share application money pending allotment is required")
    .typeError("Share application money pending allotment must be a number"),
  long_term_borrowings: Yup.number()
    .required("Long term borrowings is required")
    .typeError("Long term borrowings must be a number"),
  deferred_tax_liabilities_net: Yup.number()
    .required("Deferred tax liabilities net is required")
    .typeError("Deferred tax liabilities net must be a number"),
  other_long_term_liabilities: Yup.number()
    .required("Other long term liabilities is required")
    .typeError("Other long term liabilities must be a number"),
  long_term_provisions: Yup.number()
    .required("Long term provisions is required")
    .typeError("Long term provisions must be a number"),
  short_term_borrowings: Yup.number()
    .required("Short term borrowings is required")
    .typeError("Short term borrowings must be a number"),
  trade_payables: Yup.number()
    .required("Trade payables is required")
    .typeError("Trade payables must be a number"),
  short_term_provisions: Yup.number()
    .required("Short term provisions is required")
    .typeError("Short term provisions must be a number"),
  other_current_liabilities: Yup.number()
    .required("Other current liabilities is required")
    .typeError("Other current liabilities must be a number"),
  given_assets_total: Yup.number()
    .required("Given assets total is required")
    .typeError("Given assets total must be a number"),
  given_liabilities_total: Yup.number()
    .required("Given liabilities total is required")
    .typeError("Given liabilities total must be a number"),
});

export type xbrlAsBalanceSheetType = Yup.InferType<
  typeof xbrlAsBalanceSheetSchema
>;

export const xbrlAsBalanceSheetWIthHeading = {
  non_current_assets: [
    "tangible_assets",
    "intangible_assets",
    "tangible_assets_capital_work_in_progress",
    "intangible_assets_under_development",
    "noncurrent_investments",
    "deferred_tax_assets_net",
    "long_term_loans_and_advances",
    "other_noncurrent_assets",
  ],
  current_assets: [
    "current_investments",
    "inventories",
    "trade_receivables",
    "cash_and_bank_balances",
    "short_term_loans_and_advances",
    "other_current_assets",
  ],
  extra_assets: [
    "foreign_curr_monetary_item_trans_diff_asset_account",
    "producing_properties",
    "preproducing_properties",
  ],
  liabilities: {
    equity: [
      "share_capital",
      "reserves_and_surplus",
      "money_received_against_share_warrants",
      "share_application_money_pending_allotment",
    ],
    non_current_liabilities: [
      "long_term_borrowings",
      "deferred_tax_liabilities_net",
      "other_long_term_liabilities",
      "long_term_provisions",
    ],
    current_liabilities: [
      "short_term_borrowings",
      "trade_payables",
      "short_term_provisions",
      "other_current_liabilities",
    ],
  },
  subTotals: [
    "given_assets_total",
    "given_liabilities_total",
    "COMPUTED_total_equity",
    "COMPUTED_total_non_current_liabilities",
    "COMPUTED_total_current_liabilities",
    "COMPUTED_net_fixed_assets",
    "COMPUTED_total_current_assets",
    "COMPUTED_total_debt",
    "COMPUTED_total_other_non_current_assets",
    "COMPUTED_difference_value_of_assets",
    "COMPUTED_difference_value_of_liabilities",
  ],
};

export const xbrlAsProfitAndLossWithHeading = {
  line_items: [
    "COMPUTED_operating_profit",
    "depreciation_and_amortization_expense",
    "COMPUTED_profit_before_interest_and_tax",
    "finance_cost",
    "profit_before_tax_and_exceptional_items_before_tax",
    "exceptional_items_before_tax",
    "prior_period_items_before_tax",
    "extraordinary_items_before_tax",
    "COMPUTED_exceptional_items_before_tax",
    "profit_before_tax",
    "COMPUTED_income_tax",
    "profit_after_tax",
    "COMPUTED_difference_in_value_line_items",
    "profit_loss_minority_interest",
    "share_of_profit_loss_of_associates",
    "COMPUTED_minority_interest_and_profit_from_associates_and_joint_ventures",
  ],
  revenue: [
    "sales_of_goods_manufactured_domestic",
    "sales_of_goods_traded_domestic",
    "sales_of_services_domestic",
    "sales_of_goods_manufactured_export",
    "sales_of_goods_traded_export",
    "sales_of_services_export",
    "other_income",
    "COMPUTED_net_revenue",
  ],
  expenses: [
    "cost_material_consumed",
    "purchases_stock_traded",
    "changes_in_inventories_finished_goods",
    "changes_in_inventories_work_in_progress",
    "changes_in_inventories_stock_in_trade",
    "COMPUTED_total_changes_in_inventories_or_finished_goods",
    "employee_benefits_expense",
    "other_expenses",
    "managerial_remuneration",
    "payment_to_auditors",
    "power_and_fuel",
    "COMPUTED_total_other_expenses",
    "total_expenses",
  ],
  revenue_breakup: [
    "revenue_from_operations",
    "COMPUTED_revenue_from_operations",
    "revenue_from_sale_of_products",
    "COMPUTED_revenue_from_sale_of_services",
  ],
};

export const xbrlAsProfitAndLossSchema = Yup.object().shape({
  // line_items
  depreciation_and_amortization_expense: Yup.number()
    .required("Depreciation is required")
    .typeError("Depreciation must be a number"),
  finance_cost: Yup.number()
    .required("Finance cost is required")
    .typeError("Finance cost must be a number"),
  profit_before_tax_and_exceptional_items_before_tax: Yup.number()
    .required("Profit before tax and exceptional items is required")
    .typeError("Profit before tax and exceptional items must be a number"),
  exceptional_items_before_tax: Yup.number()
    .required("Exceptional items before tax is required")
    .typeError("Exceptional items before tax must be a number"),
  prior_period_items_before_tax: Yup.number()
    .required("Prior period items before tax is required")
    .typeError("Prior period items before tax must be a number"),
  extraordinary_items_before_tax: Yup.number()
    .required("Extraordinary items before tax is required")
    .typeError("Extraordinary items before tax must be a number"),
  profit_before_tax: Yup.number()
    .required("Profit before tax is required")
    .typeError("Profit before tax must be a number"),
  profit_after_tax: Yup.number()
    .required("Profit after tax is required")
    .typeError("Profit after tax must be a number"),
  profit_loss_minority_interest: Yup.number()
    .required("Profit/loss minority interest is required")
    .typeError("Profit/loss minority interest must be a number"),
  share_of_profit_loss_of_associates: Yup.number()
    .required("Share of profit/loss of associates is required")
    .typeError("Share of profit/loss of associates must be a number"),

  // revenue
  sales_of_goods_manufactured_domestic: Yup.number()
    .required("Sales of goods manufactured (domestic) is required")
    .typeError("Sales of goods manufactured (domestic) must be a number"),
  sales_of_goods_traded_domestic: Yup.number()
    .required("Sales of goods traded (domestic) is required")
    .typeError("Sales of goods traded (domestic) must be a number"),
  sales_of_services_domestic: Yup.number()
    .required("Sales of services (domestic) is required")
    .typeError("Sales of services (domestic) must be a number"),
  sales_of_goods_manufactured_export: Yup.number()
    .required("Sales of goods manufactured (export) is required")
    .typeError("Sales of goods manufactured (export) must be a number"),
  sales_of_goods_traded_export: Yup.number()
    .required("Sales of goods traded (export) is required")
    .typeError("Sales of goods traded (export) must be a number"),
  sales_of_services_export: Yup.number()
    .required("Sales of services (export) is required")
    .typeError("Sales of services (export) must be a number"),
  other_income: Yup.number()
    .required("Other income is required")
    .typeError("Other income must be a number"),

  // expenses
  cost_material_consumed: Yup.number()
    .required("Cost of material consumed is required")
    .typeError("Cost of material consumed must be a number"),
  purchases_stock_traded: Yup.number()
    .required("Purchases of stock traded is required")
    .typeError("Purchases of stock traded must be a number"),
  changes_in_inventories_finished_goods: Yup.number()
    .required("Changes in inventories of finished goods is required")
    .typeError("Changes in inventories of finished goods must be a number"),
  changes_in_inventories_work_in_progress: Yup.number()
    .required("Changes in inventories of work in progress is required")
    .typeError("Changes in inventories of work in progress must be a number"),
  changes_in_inventories_stock_in_trade: Yup.number()
    .required("Changes in inventories of stock in trade is required")
    .typeError("Changes in inventories of stock in trade must be a number"),
  employee_benefits_expense: Yup.number()
    .required("Employee benefit expense is required")
    .typeError("Employee benefit expense must be a number"),
  other_expenses: Yup.number()
    .required("Other expenses is required")
    .typeError("Other expenses must be a number"),
  managerial_remuneration: Yup.number()
    .required("Managerial remuneration is required")
    .typeError("Managerial remuneration must be a number"),
  payment_to_auditors: Yup.number()
    .required("Payment to auditors is required")
    .typeError("Payment to auditors must be a number"),
  power_and_fuel: Yup.number()
    .required("Power and fuel expense is required")
    .typeError("Power and fuel expense must be a number"),
  total_expenses: Yup.number()
    .required("Total expenses is required")
    .typeError("Total expenses must be a number"),

  // revenue_breakup
  revenue_from_operations: Yup.number()
    .required("Revenue from operations is required")
    .typeError("Revenue from operations must be a number"),
  revenue_from_sale_of_products: Yup.number()
    .required("Revenue from sale of products is required")
    .typeError("Revenue from sale of products must be a number"),
});

export type xbrlAsProfitAndLoseType = Yup.InferType<
  typeof xbrlAsProfitAndLossSchema
>;
