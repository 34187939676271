import { useMemo, useState } from "react";
import {
  Calendar as CalendarIcon,
  BarChart2,
  Table as TableIcon,
} from "lucide-react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { DayPicker, DateRange } from "react-day-picker";
import * as Popover from "@radix-ui/react-popover";
import "react-day-picker/style.css";
import { useGetCompanyHistoryQuery } from "@app/store/api/accountManagementApi/accountManagementApi";
import { format, subMonths, addDays } from "date-fns";
import { Table } from "@app/components/common/table";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { PageLoader } from "@components/loader";
import { NotFound } from "@components/common/NotFound";
import classNames from "classnames";
import { LineGraph } from "./LineGraph";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
  companyId: string;
}

export const CreditHistory = ({
  isOpen,
  onClose,
  companyName,
  companyId,
}: Props) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: subMonths(new Date(), 1),
    to: addDays(new Date(), 1),
  });
  const [viewMode, setViewMode] = useState<"table" | "graph">("table");

  const { data, isLoading } = useGetCompanyHistoryQuery(
    {
      from_datetime: format(dateRange.from ?? new Date(), "yyyy-MM-dd"),
      to_datetime: format(dateRange.to ?? addDays(new Date(), 1), "yyyy-MM-dd"),
      company_id: companyId,
    },
    {
      skip: !isOpen,
      refetchOnMountOrArgChange: true,
    },
  );
  const formatDateRange = (range: DateRange) => {
    return `${format(range.from ?? new Date(), "PPP")} - ${format(
      range.to ?? addDays(new Date(), 1),
      "PPP",
    )}`;
  };

  const formatDateTime = (dateTimeString: string) => {
    return format(new Date(dateTimeString), "PPP p");
  };

  const tableData = useMemo(() => {
    return (
      data?.response_data?.map((item) => ({
        tokens: item?.number_of_tokens ?? "N/A",
        price:
          item?.token_price != null ? `₹${item.token_price.toFixed(2)}` : "N/A",
        date: item?.created_at ? formatDateTime(item.created_at) : "N/A",
        addedBy: item?.order_source_metadata?.name ?? "N/A",
        total:
          item?.number_of_tokens != null && item?.token_price != null
            ? `₹${(item.number_of_tokens * item.token_price).toFixed(2)}`
            : "N/A",
        source: item?.order_source
          ? removeUnderscores(item.order_source)
          : "N/A",
        reason: item?.order_reason
          ? removeUnderscores(item.order_reason)
          : "N/A",
      })) ?? []
    );
  }, [data]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      size={1000}
      className="p-6"
    >
      <div className="flex gap-4 justify-between mb-6">
        <h2 className="text-xl font-bold truncate">
          Credit History - {companyName}
        </h2>
        <div className="flex gap-2">
          <button
            onClick={() => setViewMode("table")}
            className={classNames(
              "p-2 rounded-md",
              viewMode === "table" ? "bg-gray-200" : "bg-white",
            )}
          >
            <TableIcon className="w-5 h-5" />
          </button>
          <button
            onClick={() => setViewMode("graph")}
            className={classNames(
              "p-2 rounded-md",
              viewMode === "graph" ? "bg-gray-200" : "bg-white",
            )}
          >
            <BarChart2 className="w-5 h-5" />
          </button>
          <Popover.Root>
            <Popover.Trigger asChild>
              <button className="flex items-center justify-start min-w-[280px] px-3 py-2 text-left font-normal border rounded-md text-nowrap">
                <CalendarIcon className="mr-2 h-4 w-4" />
                {formatDateRange(dateRange)}
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className={classNames(
                  "bg-white border rounded-md shadow-lg p-3 mr-6 mt-1",
                  "z-[9999]",
                )}
                align="start"
              >
                <DayPicker
                  mode="range"
                  selected={dateRange}
                  onSelect={(range) => range && setDateRange(range)}
                  numberOfMonths={2}
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : data && data.response_data && data.response_data.length > 0 ? (
        viewMode === "table" ? (
          <Table
            headers={[
              { name: "Date", value: "date" },
              { name: "Tokens Added", value: "tokens" },
              { name: "Price per Token", value: "price" },
              { name: "Total Amount", value: "total" },
              { name: "Source", value: "source" },
              { name: "Reason", value: "reason" },
              { name: "Added By", value: "addedBy" },
            ]}
            rows={tableData || []}
          />
        ) : (
          <LineGraph data={data.response_data} dateRange={dateRange} />
        )
      ) : (
        <div className="flex justify-center items-center h-full">
          <NotFound message="No credit history found" />
        </div>
      )}
    </Drawer>
  );
};
