import { IdentifierTypeEnum } from "@app/types";
import { Input } from "@components/common";
import { Button } from "@components/common/Button";
import { DropDown } from "@components/common/dropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { CreateOrderWarningModal } from "@components/admin/company-list/components/CreateOrderWarningModal";
import {
  useCreateOrderForCustomerMutation,
  useLazyGetReportOrderStatusQuery,
} from "@app/store/api/accountManagementApi";
import * as yup from "yup";
import { useToggle } from "@app/hooks/useToggle";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";
import { AlertTriangle, Info, X } from "lucide-react";
import { CompanyRequestCustomerInfo } from "@components/admin/company-requests/components/PendingCompanyRequest";
import { format } from "date-fns";
import {
  AdhocTaskStatus,
  useUpdateAdhocTaskStatusForRequestCompanyMutation,
} from "@app/store/api/qaTasksApi";

const validationSchema = yup.object({
  user_id: yup.string().required("User ID is required"),
  identifier_value: yup.string().required("Identifier value is required"),
  identifier_type: yup
    .object({
      label: yup.string().required("Identifier type is required"),
      value: yup.string().required("Identifier type is required"),
    })
    .required("Identifier type is required"),
});

type CreateOrderForCustomerFormData = yup.InferType<typeof validationSchema>;

interface CreateOrderForCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  customerInfo?: CompanyRequestCustomerInfo;
  taskId?: string;
}

export const CreateOrderForCustomerModal = ({
  isOpen,
  onClose,
  userId,
  customerInfo,
  taskId,
}: CreateOrderForCustomerModalProps) => {
  const methods = useForm<CreateOrderForCustomerFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      user_id: userId,
    },
  });

  const [updateAdhocTaskStatus] =
    useUpdateAdhocTaskStatusForRequestCompanyMutation();

  const [createReportOrder, { isLoading: isCreateReportOrderLoading }] =
    useCreateOrderForCustomerMutation();

  const [getReportOrderStatus, { isSuccess: isReportOrderApiCompleted }] =
    useLazyGetReportOrderStatusQuery();

  const handleSubmit = (data: CreateOrderForCustomerFormData) => {
    toggleIsCreateOrderWarningModalOpen.on();
    setActiveData(data);
  };

  const [isCreateOrderWarningModalOpen, toggleIsCreateOrderWarningModalOpen] =
    useToggle();

  const [activeData, setActiveData] =
    useState<CreateOrderForCustomerFormData>();

  const handleCreateOrder = async (companyName: string) => {
    try {
      await createReportOrder({
        identifier_type: activeData?.identifier_type
          .value as IdentifierTypeEnum,
        identifier_value: activeData?.identifier_value as string,
        user_id: activeData?.user_id as string,
        company_name: companyName,
      }).unwrap();
      if (taskId) {
        // Update the Adhoc task status to completed - This is for company request for now
        updateAdhocTaskStatus({
          task_id: taskId,
          status: AdhocTaskStatus.ADHOC_COMPLETED,
          identifier_value: activeData?.identifier_value as string,
          identifier_type: activeData?.identifier_type
            .value as IdentifierTypeEnum,
        });
      }
      toggleIsCreateOrderWarningModalOpen.off();
      onClose();
      methods.reset();
      toast.success("Order created successfully");
    } catch (error) {
      toast.error("Error creating order");
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    methods.setValue("user_id", userId);
    if (customerInfo) {
      if (customerInfo.identifier_type === IdentifierTypeEnum.CIN) {
        methods.setValue("identifier_type", {
          label: IdentifierTypeEnum.CIN,
          value: IdentifierTypeEnum.CIN,
        });
      } else if (customerInfo.identifier_type === IdentifierTypeEnum.LLPIN) {
        methods.setValue("identifier_type", {
          label: IdentifierTypeEnum.LLPIN,
          value: IdentifierTypeEnum.LLPIN,
        });
      } else {
        methods.resetField("identifier_type");
        toast(
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 font-medium">
              <AlertTriangle className="h-5 w-5 text-yellow-600" />
              <span>Unsupported identifier type</span>
            </div>
            <p className="text-sm text-gray-700">
              Please Choose either CIN or LLPIN and proceed
            </p>
          </div>,
        );
      }
    }

    return () => {
      // https://github.com/timolins/react-hot-toast/issues/199
      // Track this issue and add support for dismissing toast by id after it is implemented
      toast.remove();
    };
  }, [userId, customerInfo]);

  useEffect(() => {
    if (!isOpen) return;
    const userEnteredData = methods.getValues();
    if (
      userEnteredData.identifier_value &&
      userEnteredData.identifier_type.value &&
      userEnteredData.identifier_value.length > 8
    ) {
      (async () => {
        try {
          const reportInfo = await getReportOrderStatus({
            user_id: userId,
            identifier_type: userEnteredData.identifier_type
              .value as IdentifierTypeEnum,
            identifier_value: userEnteredData.identifier_value,
          }).unwrap();

          if (reportInfo.response_data.last_purchased_date) {
            toast(
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2">
                  <div className="rounded-full bg-yellow-50 p-2">
                    <Info className="h-5 w-5 text-yellow-600" />
                  </div>
                  <h3 className="font-semibold text-gray-900">
                    Report already purchased At{" "}
                    {format(
                      new Date(reportInfo.response_data.last_purchased_date),
                      "dd/MM/yyyy",
                    )}
                  </h3>
                </div>
                <p className="text-sm text-gray-600 pl-1">
                  Do you want to continue?
                </p>
              </div>,
            );
          }
        } catch (error) {
          console.warn("Error fetching report info");
        }
      })();
    }
  }, [methods.watch("identifier_value", "identifier_type")]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <section className="bg-white rounded-xl shadow-2xl w-full max-w-lg pt-4">
        <CreateOrderWarningModal
          isOpen={isCreateOrderWarningModalOpen}
          onClose={(closeAll = false) => {
            toggleIsCreateOrderWarningModalOpen.off();
            if (closeAll) {
              onClose();
            }
          }}
          data={{
            userId: activeData?.user_id as string,
            cin: activeData?.identifier_value as string,
            taskId: taskId,
            identifier_type: activeData?.identifier_type
              .value as IdentifierTypeEnum,
          }}
          onConfirm={handleCreateOrder}
          isLoading={isCreateReportOrderLoading}
        />
        <div className="animate-fadeIn bg-gradient-to-br from-white to-blue-50 w-full max-w-lg rounded-xl shadow-2xl">
          <div className="border-b rounded-t-xl">
            <div className="flex items-center justify-between p-4">
              <h4 className="text-lg font-bold text-black flex items-center gap-2">
                <Info className="w-5 h-5" />
                Purchase Report
              </h4>
              <X
                onClick={onClose}
                className="w-5 h-5 cursor-pointer hover:text-gray-600"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 max-w-xl mt-2 px-4 py-2 rounded-md">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="flex flex-col gap-4">
                  <Input
                    label="User ID"
                    name="user_id"
                    disabled
                    value={userId}
                    placeholder={userId}
                  />
                  <Controller
                    control={methods.control}
                    name="identifier_type"
                    render={({ field, fieldState }) => (
                      <>
                        <label>Identifier Type</label>
                        <DropDown
                          options={[
                            IdentifierTypeEnum.CIN,
                            IdentifierTypeEnum.LLPIN,
                          ].map((type) => ({
                            label: type,
                            value: type,
                          }))}
                          error={fieldState.error?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Input label="Identifier Value" name="identifier_value" />
                  <Button type="submit" disabled={!isReportOrderApiCompleted}>
                    Create Order
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </section>
    </Modal>
  );
};
