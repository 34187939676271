import { CompanyList } from "./components/CompanyList";
import { useAuth } from "@app/hooks/useAuth";
import { CreateNewOrgModal } from "./components/CreateNewOrgModal";
import { useToggle } from "@app/hooks/useToggle";
import { Button } from "@components/common/Button";
import { Plus } from "lucide-react";
import { isNonCorpReleaseDone } from "@app/index";

export const Labelling = () => {
  const { userDetails } = useAuth();
  const [isCreateNewOrgModalOpen, setIsCreateNewOrgModalOpen] = useToggle();
  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold">👋Hi, {userDetails?.name}</h2>
      <CompanyList />
      {isNonCorpReleaseDone && (
        <>
          <hr className="mt-4" />
          <div className="mt-8 border">
            <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-4">Create New Entity</h3>
              <p className="text-gray-600 mb-4">
                Add a new company or organization
              </p>
              <Button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors flex items-center gap-2"
                onClick={setIsCreateNewOrgModalOpen.on}
                leftIcon={Plus}
              >
                Create Entity
              </Button>
            </div>
          </div>
          <CreateNewOrgModal
            isOpen={isCreateNewOrgModalOpen}
            onClose={setIsCreateNewOrgModalOpen.off}
          />
        </>
      )}
    </div>
  );
};
