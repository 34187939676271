import { FC, memo, useCallback, useEffect } from "react";
import {
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import classNames from "classnames";
import { TaskListLinks } from "./components/tasklist";
import { Icon } from "@components/common";
import { useProjectModal } from "@app/hooks/useProjectModal";
import { ProjectsInfoDrawer } from "@components/admin/projects";

const TaskListLayoutBase: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openProjectModal } = useProjectModal();

  const navLinkClasses = useCallback(
    (isActive: boolean) =>
      classNames(
        "block py-2 px-4 rounded hover:bg-blue-700 flex justify-between items-center",
        {
          "bg-blue-700": isActive,
        },
      ),
    [],
  );

  const handleGoBack = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    const projectId = searchParams.get("project_id");
    if (projectId) {
      openProjectModal(projectId);
    }
  }, [searchParams]);

  return (
    <div className="flex h-screen overflow-hidden">
      <ProjectsInfoDrawer />
      <div className="bg-blue-800 text-white w-60 min-h-screen p-4 flex flex-col">
        <h2
          className="text-2xl font-bold mb-6 hover:cursor-pointer flex justify-between"
          onClick={() => navigate("/labelling")}
        >
          <span>Credhive</span>
          <img
            src="/logo.svg"
            alt="Logo"
            className="rounded-full w-5 items-center"
          />
        </h2>
        <button
          type="button"
          onClick={handleGoBack}
          className="flex items-center mb-6 text-white hover:text-gray-300 gap-3"
        >
          <ArrowLeft size="20" />
          Go Back
        </button>
        <h2 className="text-xl font-bold mb-6">Task Lists</h2>
        <nav>
          <ul>
            {TaskListLinks.map(({ name, to, icon }) => (
              <li key={name}>
                <NavLink
                  to={to}
                  className={({ isActive }) => navLinkClasses(isActive)}
                >
                  <span>{name}</span>
                  {icon && (
                    <span>
                      <Icon name={icon} size={15} />
                    </span>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="flex-1 overflow-y-auto px-6 py-8 w-[84%]">
        <Outlet />
      </div>
    </div>
  );
};

export const TaskListLayout = memo(TaskListLayoutBase);
