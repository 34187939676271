import { Icon } from "@components/common";
import { FileTypes, Upload } from "@components/common/Upload";
import { UploadCloud } from "lucide-react";
import {
  Controller,
  FormProvider,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import * as Yup from "yup";
import Modal from "react-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToolTip } from "@components/common/table";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useForceUploadDocumentMutation } from "@app/store/api/scrapperApi/scraperApi";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  documentName: string;
  category: string;
  version: "v2" | "v3";
}

const schema = Yup.object({
  file: Yup.mixed().required("File is required"),
});

type ISchema = Yup.InferType<typeof schema>;

export const UploadModal = ({
  isOpen,
  onClose,
  documentName,
  version,
  category,
}: Props) => {
  const { cin } = useParams();
  const [forceUploadDocument] = useForceUploadDocumentMutation();

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const submitHandler: SubmitHandler<ISchema> = async (data) => {
    try {
      const payload = new FormData();
      payload.append("cin", cin as string);
      payload.append("file", data.file as string);
      payload.append("mca_version", version);
      payload.append("category", category);
      payload.append("document_name", documentName);
      await forceUploadDocument(payload).unwrap();
      toast.success("Document Uploaded Successfully");
    } catch {
      toast.error("Failed to Upload Document");
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="modal-content"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={true}
      preventScroll={true}
    >
      <div className="w-96 flex flex-col gap-3">
        <div className="flex justify-between">
          <h4 className="flex items-center">
            Upload Document -{" "}
            <span className="ml-2">
              <ToolTip value={documentName} trimValue={10} />
            </span>
          </h4>
          <span onClick={handleClose}>
            <Icon
              name="X"
              size={25}
              className="border  p-1 rounded-full hover:cursor-pointer"
            />
          </span>
        </div>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(submitHandler)}
            className="flex flex-col gap-2"
          >
            <Controller
              name="file"
              control={form.control}
              rules={{ required: "File is required" }}
              render={({ field }) => (
                <Upload
                  className="h-44"
                  onFileSelect={(file: File) => field.onChange(file)}
                  error={form.formState.errors.file?.message}
                  accept={[FileTypes.PDF]}
                />
              )}
            />
            <button
              type="submit"
              className="p-2 text-blue-600 w-full bg-blue-600 rounded-md inline-flex justify-center"
            >
              <UploadCloud className="text-white" />
            </button>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
