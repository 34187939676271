import { IdentifierTypeEnum } from "@app/types";

export const getCompanyType = (identifierValue: string): IdentifierTypeEnum => {
  if (!identifierValue) return IdentifierTypeEnum.CIN;

  if (identifierValue.startsWith("AA")) return IdentifierTypeEnum.LLPIN;

  if (identifierValue.startsWith("UDAYAM")) return IdentifierTypeEnum.UDYAM;

  if (identifierValue.match(/^[A-Z]{5}\d{4}[A-Z]$/))
    return IdentifierTypeEnum.PAN;

  if (
    identifierValue.match(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    )
  )
    return IdentifierTypeEnum.GST;

  return IdentifierTypeEnum.CIN;
};
