import { useState } from "react";
import classNames from "classnames";
import { StatementOfIncomeAndExpenditure } from "./StatementOfIncomeAndExpenditure";
import { StateMentOfAssetsAndLiabilities } from "./StateMentOfAssetsAndLiabilities";
import {
  FinancialMetaDataResponseObject,
  FinancialTypes,
} from "@app/store/api/financialApi";
import { Option } from "@components/common/dropdown";
import { Toggle } from "@app/hooks/useToggle";

export interface StatementProps {
  financialInfo: FinancialMetaDataResponseObject;
  selectedYear: Option;
  activePdf?: string;
  isPdfOpen: boolean;
  isEditable: boolean;
  toggleEditable: Toggle;
  selectedNature: FinancialTypes;
}

type StatementTab = "income-and-expenditure" | "assets-and-liabilities";

export const Statement = ({
  financialInfo,
  selectedYear,
  activePdf,
  isPdfOpen,
  isEditable,
  toggleEditable,
  selectedNature,
}: StatementProps) => {
  const [activeTab, setActiveTab] = useState<StatementTab>(
    "income-and-expenditure",
  );

  return (
    <div>
      <div className="flex border-b mt-4">
        <button
          className={classNames("flex-1 py-2 text-center", {
            "border-b-2 border-blue-500 text-blue-500":
              activeTab === "income-and-expenditure",
            "text-gray-500": activeTab !== "income-and-expenditure",
          })}
          onClick={() => setActiveTab("income-and-expenditure")}
          type="button"
        >
          Income and Expenditure
        </button>
        <button
          type="button"
          className={classNames("flex-1 py-2 text-center", {
            "border-b-2 border-blue-500 text-blue-500":
              activeTab === "assets-and-liabilities",
            "text-gray-500": activeTab !== "assets-and-liabilities",
          })}
          onClick={() => setActiveTab("assets-and-liabilities")}
        >
          Assets and Liabilities
        </button>
      </div>

      {activeTab === "income-and-expenditure" && (
        <StatementOfIncomeAndExpenditure
          selectedNature={selectedNature}
          financialInfo={financialInfo}
          selectedYear={selectedYear}
          activePdf={activePdf}
          isPdfOpen={isPdfOpen}
          isEditable={isEditable}
          toggleEditable={toggleEditable}
        />
      )}
      {activeTab === "assets-and-liabilities" && (
        <StateMentOfAssetsAndLiabilities
          selectedNature={selectedNature}
          financialInfo={financialInfo}
          selectedYear={selectedYear}
          activePdf={activePdf}
          isPdfOpen={isPdfOpen}
          isEditable={isEditable}
          toggleEditable={toggleEditable}
        />
      )}
    </div>
  );
};
