import { useState } from "react";
import { ChevronDownIcon } from "lucide-react";
import { StatusBox } from "./StatusBox";

interface AccordionItemProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  status?: string;
  count?: number;
}

export function Accordion({
  title,
  children,
  defaultOpen = false,
  status,
  count,
}: AccordionItemProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="border rounded-lg my-2">
      <button
        className="flex w-full justify-between items-center p-4 hover:bg-gray-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <span className="text-lg font-semibold">{title}</span>
          {count != undefined && count != null && (
            <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-white text-gray-600 rounded-md border border-gray-200">
              {count}
            </span>
          )}
        </div>
        <div className="flex items-center gap-2">
          {status && <StatusBox statusText={status} />}
          <ChevronDownIcon
            className={`h-5 w-5 transform ${isOpen ? "rotate-180" : ""}`}
          />
        </div>
      </button>
      {isOpen && <div className="p-4 pt-0">{children}</div>}
    </div>
  );
}
