import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  useGetCustomerCompanyDetailsMutation,
  useListCustomerCompaniesQuery,
} from "@app/store/api/customerCompany/useCustomerCompany";
import usersList from "@app/store/api/usersList/usersList";
import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { apiHandler } from "@app/config/apiHandler";
import { Input } from "@components/common";
import Select from "react-select";
import { Option } from "@components/common/dropdown";
import { PageLoader } from "@components/loader";
import * as Yup from "yup";

const schema = Yup.object({
  customer_company_id: Yup.string().required("Customer company ID is required"),
  customer_team_id: Yup.string().required("Customer team ID is required"),
  cognito_user_id: Yup.string().required("User ID is required"),
  name: Yup.string().required("Customer name is required"),
  email: Yup.string().required("Email is required"),
  phone_number: Yup.string().required("Phone number is required"),
});

type Ischema = Yup.InferType<typeof schema>;

const EditUser = () => {
  const dispatch = useDispatch();
  const {
    data: allCustomerCompanies,
    isLoading,
    isError,
  } = useListCustomerCompaniesQuery();

  const form = useForm<Ischema>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [getTeam, { data: teamInfo }] = useGetCustomerCompanyDetailsMutation();

  const handleSubmit: SubmitHandler<Ischema> = async (payload) => {
    try {
      await apiHandler.post("/customer/create-customer-user", payload);
      toast.success("User Added Successfully");
      form.reset();
      // refetching the unapprooved users api
      dispatch(usersList.util.invalidateTags(["unapproved-users"]));
    } catch {
      toast.error("Error Approving User");
    }
  };

  useEffect(() => {
    if (isError) {
      toast.success("Error Loading The Company Names");
    }
  }, [isError]);

  const selectedCompanyId = useWatch({
    control: form.control,
    name: "customer_company_id",
  });

  useEffect(() => {
    if (selectedCompanyId) {
      getTeam(selectedCompanyId);
    } else {
      form.reset();
    }
  }, [selectedCompanyId, getTeam]);

  const transformedCustomerCompanies = useMemo(() => {
    return allCustomerCompanies?.response_data.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  }, [allCustomerCompanies]);

  const tranformedTeamDetails = useMemo(() => {
    return teamInfo?.response_data.company_teams.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  }, [teamInfo]);

  const isCompanyNameSelected = form.getValues("customer_company_id")
    ? true
    : false;
  return (
    <div>
      <h2 className="text-start text-2xl font-bold">
        Edit Customer Company / Team
      </h2>
      <div className="w-screen max-w-md mt-8">
        {isLoading ? (
          <PageLoader
            style={{
              width: "calc(100vw - 285px)",
              height: "calc(100vh - 250px)",
            }}
          />
        ) : (
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex flex-col gap-1"
            >
              <Input name="name" label="Customer Name" />
              <Input name="email" label="Customer Email" type="email" />
              <Input name="cognito_user_id" label="User ID" />
              <Input
                name="phone_number"
                label="Customer Phone Number"
                type="text"
              />
              <div className="flex flex-col gap-1">
                <label htmlFor="customer_company_id">
                  Customer Company Name
                </label>
                <Controller
                  name="customer_company_id"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <Select
                        {...field}
                        options={transformedCustomerCompanies}
                        className="rounded-md"
                        value={
                          transformedCustomerCompanies?.find(
                            (option) => option.value === field.value,
                          ) || null
                        }
                        onChange={(option: Option | null) =>
                          field.onChange(option?.value)
                        }
                      />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1 mt-6">
                <label htmlFor="customer_team_id">Customer Team Name</label>
                <Controller
                  name="customer_team_id"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <div>
                      <Select
                        {...field}
                        options={tranformedTeamDetails}
                        className="rounded-md"
                        value={
                          tranformedTeamDetails?.find(
                            (option) => option.value === field.value,
                          ) || null
                        }
                        onChange={(option: Option | null) =>
                          field.onChange(option?.value)
                        }
                        isDisabled={!isCompanyNameSelected}
                      />
                      {fieldState.error && (
                        <p className="text-red-500 text-sm mt-1">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex justify-end mt-4 gap-2">
                <button
                  disabled
                  type="submit"
                  className="inline-flex w-full justify-center items-center bg-blue-500 px-3 py-2 rounded-md text-white"
                >
                  Add New User
                </button>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};

export default EditUser;
