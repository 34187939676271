import { useParams } from "react-router-dom";
import { EPFO } from "@components/labelling/compliances/EPFO/EPFO";
import { GSTDetails } from "@components/labelling/compliances/GST/GSTDetails";
import { LegalHistory } from "@components/labelling/compliances/Legal/LegalHistory";
import {
  useGetEPFODetailsQuery,
  useGetGSTDetailsQuery,
  useGetLegalHistoryQuery,
} from "@app/store/api/compliancesApi/compliancesApi";
import { PageLoader } from "@components/loader";

export function Compliances() {
  const { cin } = useParams();

  const {
    data: epfoData,
    isLoading: isEpfoLoading,
    isFetching: isEpfoFetching,
  } = useGetEPFODetailsQuery({
    identifier_value: cin as string,
  });

  const {
    data: gstData,
    isLoading: isGstLoading,
    isFetching: isGstFetching,
  } = useGetGSTDetailsQuery({
    identifier_value: cin as string,
  });

  const {
    data: legalHistoryData,
    isLoading: isLegalHistoryLoading,
    isFetching: isLegalHistoryFetching,
  } = useGetLegalHistoryQuery({
    identifier_value: cin as string,
  });

  const isLoading = isEpfoLoading || isGstLoading || isLegalHistoryLoading;

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="space-y-4">
      <h4 className="text-2xl font-bold">Compliances</h4>
      {epfoData && (
        <EPFO data={epfoData.response_data} isReportFetching={isEpfoFetching} />
      )}
      {gstData && (
        <GSTDetails
          data={gstData.response_data}
          isReportFetching={isGstFetching}
        />
      )}
      {legalHistoryData && (
        <LegalHistory
          data={legalHistoryData.response_data}
          isReportFetching={isLegalHistoryFetching}
        />
      )}
    </div>
  );
}

export default Compliances;
