import { DropDown } from "@components/common/dropdown";
import { AddressType } from "@app/types/non-corp";
import { TextArea } from "@components/common/TextArea";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { Input } from "@components/common/Input";

export const AddressBuilder = () => {
  const methods = useForm();

  return (
    <div className="mt-6 border rounded-md p-4">
      <h3 className="text-xl font-semibold mb-4">Address</h3>
      <FormProvider {...methods}>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="col-span-2">
              <label className="text-sm font-medium text-gray-700">
                Address Type
              </label>
              <Controller
                name="address_type"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                  <DropDown
                    options={Object.values(AddressType).map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    value={value ? { value, label: value } : null}
                    onChange={(option) => onChange(option.value)}
                    className="w-full mt-2"
                  />
                )}
              />
            </div>
            <div className="col-span-2">
              <TextArea
                name="address_line1"
                label="Address Line 1"
                placeholder="Enter Address Line 1"
              />
            </div>
            <div className="col-span-2">
              <TextArea
                name="address_line2"
                label="Address Line 2"
                placeholder="Enter Address Line 2"
              />
            </div>
            <Input name="city" label="City" placeholder="Enter City" />
            <Input name="state" label="State" placeholder="Enter State" />
            <Input name="country" label="Country" placeholder="Enter Country" />
            <Input
              name="pin_code"
              label="PIN Code"
              placeholder="Enter PIN Code"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
