import { FC, memo } from "react";
import { NavLink } from "react-router-dom";
import { Route } from "./sidebar";
import { Icon } from "@app/components/common/Icon";
import classNames from "classnames";

interface BaseNavigationSectionProps {
  title: string;
  navLinkClasses: (isActive: boolean) => string;
}

interface LabellingNavigationProps extends BaseNavigationSectionProps {
  items: Route[];
}

export const NavigationSection: FC<LabellingNavigationProps> = memo(
  ({ title, items, navLinkClasses }) => {
    return (
      <nav className="mt-2">
        <h2 className="text-sm font-bold">{title}</h2>
        <hr className="mt-2" />
        <ul className="flex flex-col gap-2 mt-2">
          {items.map((item) => (
            <li key={item.name}>
              {item.children ? (
                <details className="group">
                  <summary
                    className={classNames(
                      navLinkClasses(false),
                      "cursor-pointer marker:content-none flex justify-between items-center",
                    )}
                  >
                    <span>{item.name}</span>
                    <Icon
                      name="ChevronDown"
                      size={15}
                      className="self-center transition-transform duration-200 group-open:rotate-180"
                    />
                  </summary>
                  <ul className="pl-4 mt-2 flex flex-col gap-2">
                    {item.children.map((child) => (
                      <li key={child.name}>
                        <NavLink
                          to={child.to}
                          className={({ isActive }) => navLinkClasses(isActive)}
                        >
                          <span>{child.name}</span>
                          {child.icon && (
                            <Icon
                              name={child.icon}
                              size={15}
                              className="self-center"
                            />
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </details>
              ) : (
                <NavLink
                  to={item.to}
                  className={({ isActive }) => navLinkClasses(isActive)}
                >
                  <span>{item.name}</span>
                  {item.icon && (
                    <Icon name={item.icon} size={15} className="self-center" />
                  )}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  },
);

NavigationSection.displayName = "NavigationSection";
