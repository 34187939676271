import { IdentifierTypeEnum } from "@app/types";
import { OrNull } from "../shareHoldingsApi/types";

export interface ChargesPayload {
  identifier_type: IdentifierTypeEnum.CIN | IdentifierTypeEnum.LLPIN;
  identifier_value: string;
  unassigned: boolean;
}

export enum Status {
  CREATION = "CREATION",
  MODIFICATION = "MODIFICATION",
  SATISFACTION = "SATISFACTION",
}

export interface Charges {
  charge_id?: string;
  applicant?: string;
  date: string;
  modification_particulars?: string;
  created_or_modified_outside_india?: string;
  consortium_holding?: string;
  joint_holding?: string;
  number_of_chargeholder?: number;
  holder_name: string;
  amount: number;
  rate_of_interest?: string;
  term_of_payment?: string;
  instrument_description?: string;
  extent_and_operation?: string;
  other_terms?: string;
  property_particulars?: string;
  uncalled_share_capital?: string;
  immovable_property?: string;
  floating_charge?: string;
  property_for_securing_issue_of_secured_deposits?: string;
  patent?: string;
  trade_mark?: string;
  book_debts?: string;
  solely_of_property_outside_india?: string;
  calls_made_but_not_paid?: string;
  movable_property?: string;
  motor_vehicle?: string;
  goodwill?: string;
  license_under_patent?: string;
  copyright?: string;
  ship_or_share_in_ship?: string;
  others?: string;
  document_id?: string;
  entity_id: string;
  event_type: Status;
  id: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateChargesPayload extends Partial<OrNull<Charges>> {
  id: string;
}
