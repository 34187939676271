import {
  useGetAllLabellingTasksGroupByProjectsQuery,
  useTriggerLabellingUpdateStatusMutation,
} from "@app/store/api/qaTasksApi/qaTasksApi";
import { NotFound } from "@components/common/NotFound";
import { ToolTip } from "@components/common/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { findRoutes } from "./findRoute";
import { Popover } from "@components/common/Popover";
import {
  LabellingTaskWithVersion,
  TaskStatus,
} from "@app/store/api/qaTasksApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { PopoverClose } from "@radix-ui/react-popover";
import qaTasksApi from "@app/store/api/qaTasksApi/qaTasksApi";
import { IdentifierTypeEnum } from "@app/types";
import { Button } from "@components/common/Button";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { formatDateWithSeconds } from "@components/admin/projects/utils";
import { GroupedTaskAccordion } from "./GroupedTaskAccordion";
import { PageLoader } from "@components/loader";
import { useGroupedTasks } from "@app/hooks/useGroupedTasks";

const headers = [
  {
    name: "Task Nature",
    value: "task_nature",
  },
  {
    name: "Created At",
    value: "created_at",
  },
  {
    name: "Action",
    value: "edit",
  },
  {
    name: "",
    value: "mark_completed",
  },
];

const LIMIT = 5;
const POLLING_INTERVAL = 15000;

export const Labelling = () => {
  const [skip, setSkip] = useState(0);
  const dispatch = useDispatch();
  const { activeCountry, activeCorporate } = useActiveCompany();
  const [triggerLabellingUpdateStatus] =
    useTriggerLabellingUpdateStatusMutation();

  const navigate = useNavigate();
  const {
    data: pendingTasks,
    isLoading,
    startedTimeStamp,
  } = useGetAllLabellingTasksGroupByProjectsQuery(
    {
      status: [
        TaskStatus.QA_LABELING_IN_PROGRESS,
        TaskStatus.QA_LABELING_NOT_STARTED,
      ],
      skip,
      limit: LIMIT,
    },
    {
      pollingInterval: POLLING_INTERVAL,
    },
  );

  const updateStatus = ({
    task_id,
    status,
    projectId,
    identifierValue,
    identifierType,
  }: {
    task_id: string;
    status: TaskStatus;
    projectId: string;
    identifierValue: string;
    identifierType: IdentifierTypeEnum;
  }) => {
    toast.promise(
      triggerLabellingUpdateStatus({
        task_id,
        status,
        project_id: projectId,
        identifier_value: identifierValue,
        identifier_type: identifierType,
      }).unwrap(),
      {
        loading: "Updating status...",
        success: "Status updated successfully",
        error: "Failed to update status",
      },
    );
    dispatch(qaTasksApi.util.invalidateTags(["incomplete-label-tasks"]));
    document.body.click();
  };

  // Get the latest task date for each project
  const { dataGroupedByProject } = useGroupedTasks<LabellingTaskWithVersion>(
    pendingTasks?.response_data || {},
  );

  return (
    <div className="mt-5">
      {isLoading ? (
        <PageLoader />
      ) : dataGroupedByProject.length > 0 ? (
        <GroupedTaskAccordion<LabellingTaskWithVersion>
          startedTimeStamp={startedTimeStamp || 0}
          data={dataGroupedByProject}
          headers={headers}
          skip={skip}
          setSkip={setSkip}
          limit={LIMIT}
          total={pendingTasks?.total || 0}
          renderContent={(task) => {
            return {
              created_at: formatDateWithSeconds(task.created_at),
              task_nature: (
                <ToolTip
                  value={removeUnderscores(task.task_nature_name)}
                  trimValue={35}
                />
              ),
              edit: (
                <Button
                  type="submit"
                  variant="destructive"
                  className="bg-red-500 text-white hover:bg-red-600 transition-1 transition"
                  onClick={() => {
                    navigate(
                      findRoutes[
                        task.task_nature_name as keyof typeof findRoutes
                      ](task.identifier_value, activeCountry, activeCorporate),
                    );
                  }}
                >
                  Edit
                </Button>
              ),
              mark_completed: (
                <Popover
                  side="left"
                  content={
                    <PopoverClose asChild>
                      <div className="w-48 bg-white border border-gray-200 rounded-md shadow-lg p-2 flex flex-col">
                        <button
                          className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md mb-2"
                          onClick={() => {
                            updateStatus({
                              task_id: task.id,
                              status: TaskStatus.QA_LABELING_NOT_STARTED,
                              projectId: task.project_id,
                              identifierValue: task.identifier_value,
                              identifierType: task.identifier_type,
                            });
                          }}
                        >
                          Not Started
                        </button>
                        <hr className="my-1" />
                        <button
                          className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md mb-2"
                          onClick={() => {
                            updateStatus({
                              task_id: task.id,
                              status: TaskStatus.QA_LABELING_IN_PROGRESS,
                              projectId: task.project_id,
                              identifierValue: task.identifier_value,
                              identifierType: task.identifier_type,
                            });
                          }}
                        >
                          In Progress
                        </button>
                        <hr className="my-1" />
                        <button
                          className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md"
                          onClick={() => {
                            updateStatus({
                              task_id: task.id,
                              status: TaskStatus.QA_LABELING_COMPLETED,
                              projectId: task.project_id,
                              identifierValue: task.identifier_value,
                              identifierType: task.identifier_type,
                            });
                          }}
                        >
                          Completed
                        </button>
                      </div>
                    </PopoverClose>
                  }
                >
                  <button className="inline-flex justify-center items-center bg-blue-500 px-3 py-2 rounded-md text-white">
                    Mark As
                  </button>
                </Popover>
              ),
            };
          }}
        />
      ) : (
        <div className="mt-40">
          <NotFound message="No Pending Tasks" />
        </div>
      )}
    </div>
  );
};
