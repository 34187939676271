import { useForm, FormProvider, type SubmitHandler } from "react-hook-form";
import { Input } from "@app/components/common/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "@app/hooks/useAuth";
import * as Yup from "yup";
import { useToggle } from "@app/hooks/useToggle";
import { Button } from "@components/common/Button";

const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter a valid email"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
});

type ILogin = Yup.InferType<typeof loginSchema>;

export const LoginForm = () => {
  const [showPassword, togglePassword] = useToggle();
  const { login } = useAuth();

  const methods = useForm<ILogin>({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    await login(data.email, data.password);
  };

  return (
    <FormProvider {...methods}>
      <main className="flex flex-col gap-5">
        <div className="self-center flex gap-3 justify-around items-center border px-10 w-96 py-2 rounded-md shadow-md">
          <h1 className="text-xl font-bold text-center">CredHive Admin</h1>
          <img
            src="logo.svg"
            alt="Logo"
            className="rounded-full w-10 items-center"
          />
        </div>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="bg-white p-8 rounded-lg shadow-md w-96"
        >
          <h2 className="text-2xl font-bold mb-6 text-start">Login</h2>
          <Input name="email" label="Email" errorMessageStyle="text-xs" />
          <Input
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            errorMessageStyle="text-xs"
            suffixIcon={showPassword ? "EyeOff" : "Eye"}
            suffixIconHandler={togglePassword.toggle}
          />
          <Button
            type="submit"
            className="w-full mt-4"
            isLoading={methods.formState.isSubmitting}
          >
            Login
          </Button>
        </form>
      </main>
    </FormProvider>
  );
};
