import Axios from "axios";
import { ENV } from "./ENV";
import { setupInterceptors } from "./interceptor";

export const apiHandler = Axios.create({
  baseURL: ENV.api_base_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": "x",
  },
});

export const qaDbApiHandler = Axios.create({
  baseURL: ENV.qa_db_base_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": "x",
  },
});

export const parserApiHandler = Axios.create({
  baseURL: ENV.parser_api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": "x",
  },
});

export const scraperApiHandler = Axios.create({
  baseURL: ENV.scraper_api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": "x",
  },
});

export const credhiveDataApiHandler = Axios.create({
  baseURL: ENV.credhive_data_api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": "x",
  },
});

export const indiaNonCorpApiHandler = Axios.create({
  baseURL: ENV.india_noncorp_api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": "x",
  },
});

setupInterceptors(apiHandler);
setupInterceptors(qaDbApiHandler);
setupInterceptors(parserApiHandler);
setupInterceptors(scraperApiHandler);
setupInterceptors(credhiveDataApiHandler);
setupInterceptors(indiaNonCorpApiHandler);
