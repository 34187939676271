import { FC, memo, useCallback, useMemo } from "react";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import { LogOut, Settings } from "lucide-react";
import classNames from "classnames";
import { useAuth } from "@app/hooks/useAuth";
import { NavigationSection } from "./components/NavigationSection";
import {
  LabellingSideBar,
  AdminSidebar,
  VerifierSidebar,
} from "./components/sidebar";
import { ProjectsInfoDrawer } from "@components/admin/projects";

const HomeLayoutBase: FC = () => {
  const { logOut, role } = useAuth();
  const router = useNavigate();

  const navLinkClasses = useCallback(
    (isActive: boolean) =>
      classNames("flex justify-between py-2 px-4 rounded hover:bg-blue-700", {
        "bg-blue-700": isActive,
      }),
    [],
  );

  const isAdmin = useMemo(() => role.includes("Admin"), [role]);
  const isVerifier = useMemo(() => role.includes("Verifier"), [role]);

  const handleLogoClick = useCallback(() => router("/labelling"), [router]);

  return (
    <div className="flex h-screen overflow-hidden">
      <ProjectsInfoDrawer />
      <div className="bg-blue-800 text-white w-60 h-full p-4 flex flex-col justify-between overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-blue-800">
        <div>
          <h2
            className="text-2xl font-bold mb-6 hover:cursor-pointer flex justify-between"
            onClick={handleLogoClick}
          >
            <span>Credhive</span>
            <img
              src="/logo.svg"
              alt="Logo"
              className="rounded-full w-5 items-center"
            />
          </h2>
          <section className="w-full">
            <NavigationSection
              title="Labelling"
              items={LabellingSideBar}
              navLinkClasses={navLinkClasses}
            />
            {(isVerifier || isAdmin) && (
              <NavigationSection
                title="Verifier"
                items={VerifierSidebar}
                navLinkClasses={navLinkClasses}
              />
            )}
            {isAdmin && (
              <NavigationSection
                title="Admin"
                items={AdminSidebar}
                navLinkClasses={navLinkClasses}
              />
            )}
          </section>
        </div>
        <div>
          <NavLink
            to="/settings"
            className="bg-blue-700 text-white py-1 px-4 hover:bg-blue-600 w-full text-lg rounded-md inline-flex justify-around items-center mt-4"
          >
            Settings
            <Settings size={15} />
          </NavLink>
          <button
            onClick={logOut}
            type="button"
            className="bg-blue-700 text-white py-1 px-4 hover:bg-blue-600 w-full text-lg rounded-md inline-flex justify-around items-center mt-4"
          >
            Logout
            <LogOut size={15} />
          </button>
        </div>
      </div>
      <div className="flex-1 p-6 overflow-y-auto w-[84%]">
        <Outlet />
      </div>
    </div>
  );
};

export const HomeLayout = memo(HomeLayoutBase);
