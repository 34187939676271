import { Tab } from "@app/components/common/tabs";
import {
  CompletedProjects,
  IncompletedProjects,
} from "@components/admin/projects";

const ProjectsPage = () => {
  return (
    <div>
      <h2 className="text-start text-2xl font-bold">Projects</h2>
      <Tab
        content={[
          {
            children: <CompletedProjects />,
            label: "completed",
          },
          {
            children: <IncompletedProjects />,
            label: "incompleted",
          },
        ]}
      />
    </div>
  );
};

export default ProjectsPage;
