import { useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Table } from "@components/common/table/Table";
import { useGetPerformanceMetricsByUserIdQuery } from "@app/store/api/qaUsersApi";
import { PageLoader } from "@components/loader";
import { useWindowSize } from "@app/hooks/useWindowSize";

const tableHeaders = [
  { name: "Task Nature", value: "taskNature" },
  { name: "Tasks Completed", value: "tasksCompleted" },
  { name: "Tasks Verified", value: "tasksVerified" },
  { name: "Avg Time per Task", value: "avgTimePerTask" },
  { name: "Accuracy Score", value: "accuracyScore" },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data?: {
    userId: string;
    userName: string;
  };
}

export const PerformanceChart = ({ isOpen, onClose, data }: Props) => {
  if (!data) return null;
  const { width } = useWindowSize();
  const [viewMode, setViewMode] = useState<"chart" | "table">("table");
  const {
    data: performanceResponse,
    isLoading,
    isFetching,
  } = useGetPerformanceMetricsByUserIdQuery(data.userId, {
    refetchOnMountOrArgChange: true,
  });

  const isDataLoading = isFetching || isLoading;

  const chartData = useMemo(() => {
    if (!performanceResponse) return [];
    return performanceResponse.response_data.map((item) => ({
      taskNature: item.task_nature.name,
      tasksCompleted: item.tasks_completed,
      tasksVerified: item.tasks_verified,
      avgTimePerTask: parseFloat(item.avg_time_per_task.toFixed(2)),
      accuracyScore: parseFloat(item.accuracy_score.toFixed(2)),
    }));
  }, [performanceResponse]);

  const isDataEmpty = performanceResponse?.response_data.length === 0;

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === "chart" ? "table" : "chart"));
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      size={width <= 768 ? "100%" : 900}
    >
      <div className="w-full h-full p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            Performance Metrics for {data.userName}
          </h2>
          <button
            onClick={toggleViewMode}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            View {viewMode === "chart" ? "Table" : "Chart"}
          </button>
        </div>
        <div className="w-full h-[calc(100%-60px)]">
          {isDataLoading ? (
            <PageLoader className="w-full h-full" />
          ) : isDataEmpty ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-lg text-gray-500">
                No performance data available for this user.
              </p>
            </div>
          ) : (
            <>
              {viewMode === "chart" ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="taskNature" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar
                      yAxisId="left"
                      dataKey="tasksCompleted"
                      fill="#8884d8"
                      name="Tasks Completed"
                    />
                    <Bar
                      yAxisId="left"
                      dataKey="tasksVerified"
                      fill="#82ca9d"
                      name="Tasks Verified"
                    />
                    <Bar
                      yAxisId="right"
                      dataKey="avgTimePerTask"
                      fill="#ffc658"
                      name="Avg Time per Task"
                    />
                    <Bar
                      yAxisId="right"
                      dataKey="accuracyScore"
                      fill="#ff7300"
                      name="Accuracy Score"
                    />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Table headers={tableHeaders} rows={chartData} />
              )}
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};
