import { Icon } from "@components/common";
import classNames from "classnames";

interface Props {
  title: string;
  className?: string;
}

export const HolderLoader = ({ title, className }: Props) => {
  return (
    <section className={classNames("mt-8", className)}>
      <div className="flex justify-between items-baseline flex-col">
        <h4 className="text-2xl font-bold">{title}</h4>
        <div className="flex justify-center items-center w-full min-h-64 border mt-3 rounded-md">
          <Icon name="Loader" className="animate-spin" />
        </div>
      </div>
    </section>
  );
};
