import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { qaDbApiHandler } from "@app/config/apiHandler";
import { QaDbApiResponse } from "@app/types";
import { Pagination } from "@app/types";
import {
  GengisMcaAccountVersion,
  McaAccount,
  McaAccountStatus,
  CreateMcaAccountPayload,
} from "./types";

const mcaAccountsApi = createApi({
  reducerPath: "mcaAccountsApi",
  baseQuery: axiosBaseQuery(qaDbApiHandler),
  tagTypes: ["mca-admin-get-all", "qa-users-get-all"],
  endpoints: (builder) => ({
    getAllMcaAccounts: builder.query<
      QaDbApiResponse<McaAccount[]>,
      Pagination & {
        mca_version?: GengisMcaAccountVersion;
      }
    >({
      query: (payload) => ({
        url: "/mca-accounts/admin-get-all",
        method: "GET",
        params: {
          skip: payload.skip,
          limit: payload.limit,
          mca_version: payload.mca_version,
        },
      }),
      providesTags: ["mca-admin-get-all"],
    }),
    getMcaAccountByStatus: builder.query<
      QaDbApiResponse<McaAccount[]>,
      {
        status: McaAccountStatus[];
        mcaVersion: GengisMcaAccountVersion;
      }
    >({
      query: ({ status, mcaVersion }) => {
        const statusString = new URLSearchParams();
        status.forEach((s) => statusString.append("status", s));
        return {
          url: `/mca-accounts/get-all?${statusString}`,
          method: "GET",
          params: {
            mca_version: mcaVersion,
          },
        };
      },
      providesTags: ["qa-users-get-all"],
    }),
    createMcaAccount: builder.mutation<
      QaDbApiResponse<McaAccount>,
      CreateMcaAccountPayload
    >({
      query: (payload) => ({
        url: "/mca-accounts/create",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["mca-admin-get-all"],
    }),
    updateMcaAccount: builder.mutation<
      QaDbApiResponse<McaAccount>,
      CreateMcaAccountPayload & {
        mca_account_id: string;
      }
    >({
      query: (payload) => ({
        url: "/mca-accounts/update",
        method: "PUT",
        data: payload,
        params: {
          mca_account_id: payload.mca_account_id,
        },
      }),
      invalidatesTags: ["mca-admin-get-all", "qa-users-get-all"],
    }),
  }),
});

export default mcaAccountsApi;

export const {
  useGetAllMcaAccountsQuery,
  useGetMcaAccountByStatusQuery,
  useCreateMcaAccountMutation,
  useUpdateMcaAccountMutation,
} = mcaAccountsApi;
