import Modal from "react-modal";
import { Button } from "@app/components/common/Button";
import { X, MoveRight, Info } from "lucide-react";
import { useSearchCompanyByCinQuery } from "@app/store/api/cinSearchApi";
import { useGetCustomerInfoQuery } from "@app/store/api/customerCompany";
import { Icon } from "@components/common";
import classNames from "classnames";
import {
  AdhocTaskStatus,
  useUpdateAdhocTaskStatusForRequestCompanyMutation,
} from "@app/store/api/qaTasksApi";
import toast from "react-hot-toast";
import { IdentifierTypeEnum } from "@app/types";

interface CreateOrderWarningModalProps {
  isOpen: boolean;
  onClose: (closeAll?: boolean) => void;
  onConfirm: (companyName: string) => void;
  data: {
    userId: string;
    cin: string;
    taskId?: string;
    identifier_type?: IdentifierTypeEnum;
  };
  isLoading: boolean;
}

export const CreateOrderWarningModal = ({
  isOpen,
  onClose,
  onConfirm,
  data,
  isLoading,
}: CreateOrderWarningModalProps) => {
  const {
    data: companyData,
    isFetching: isCompanyLoading,
    isError: isCompanyFailed,
  } = useSearchCompanyByCinQuery(data.cin, {
    refetchOnMountOrArgChange: true,
    skip: !data.cin,
  });
  const {
    data: customerInfo,
    isFetching: isCustomerInfoLoading,
    isError: isCustomerFailed,
  } = useGetCustomerInfoQuery(data.userId, {
    refetchOnMountOrArgChange: true,
    skip: !data.userId,
  });

  const isDataLoading = isCompanyLoading || isCustomerInfoLoading;

  const isError = isCompanyFailed || isCustomerFailed;

  const [updateAdhocTaskStatus, { isLoading: isMarkingInvalid }] =
    useUpdateAdhocTaskStatusForRequestCompanyMutation();

  const markAsInvalid = () => {
    if (!data.taskId || !data.identifier_type) return;
    try {
      updateAdhocTaskStatus({
        task_id: data.taskId,
        status: AdhocTaskStatus.ADHOC_FAILED,
        identifier_type: data.identifier_type,
        identifier_value: data.cin,
      }).unwrap();
      toast.success("Request processed successfully");
    } catch {
      toast.error("Failed to complete the request");
    } finally {
      onClose(true);
    }
  };

  if (isError) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onClose(true)}
        className="fixed inset-0 flex items-center justify-center outline-none"
        overlayClassName="fixed inset-0"
      >
        <div className="animate-fadeIn bg-gradient-to-br from-white to-blue-50 w-full max-w-lg rounded-xl shadow-2xl">
          <div className="border-b rounded-t-xl">
            <div className="flex items-center justify-between p-4">
              <h4 className="text-lg font-bold text-red-600 flex items-center gap-2">
                <Info className="w-5 h-5" />
                Error
              </h4>
              <X
                onClick={() => onClose(false)}
                className="w-5 h-5 cursor-pointer hover:text-gray-600"
              />
            </div>
          </div>
          <div
            className={classNames("h-[380px] flex justify-center px-16 py-4", {
              isCustomerFailed: "items-center",
            })}
          >
            {isCustomerFailed && (
              <p className="text-red-600 font-medium text-wrap">
                An error occurred while fetching company or customer
                information.
              </p>
            )}
            {isCompanyFailed && (
              <div>
                <h4 className="text-red-600 font-medium text-xl">
                  Could not find the company info
                </h4>
                <div className="border px-4 py-2 rounded-md mt-4">
                  <ul className="list-disc px-2 gap-2 flex flex-col">
                    <li>Try Searching the company Info On Google</li>
                    <li>
                      <a
                        href="https://www.zaubacorp.com/"
                        className="underline"
                        target="_blank"
                      >
                        Zauba Corp
                      </a>{" "}
                      Website is a good Website to start with
                    </li>
                    <li>
                      If still couldnt find the company try contacting the
                      customer via email
                    </li>
                  </ul>
                </div>
                {data.taskId && (
                  <Button
                    className="w-full mt-8 bg-red-600 hover:bg-red-500 delay-75"
                    onClick={markAsInvalid}
                    isLoading={isMarkingInvalid}
                  >
                    Mark As Invalid
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose(false)}
      className="fixed inset-0 flex items-center justify-center outline-none"
      overlayClassName="fixed inset-0"
    >
      <div className="animate-fadeIn bg-gradient-to-br from-white to-blue-50 w-full max-w-lg rounded-xl shadow-2xl">
        <div className="border-b rounded-t-xl">
          <div className="flex items-center justify-between p-4">
            <h4 className="text-lg font-bold text-black flex items-center gap-2">
              <Info className="w-5 h-5" />
              Warning: Purchase Confirmation
            </h4>
            <X
              onClick={() => onClose(false)}
              className="w-5 h-5 cursor-pointer hover:text-gray-600"
            />
          </div>
        </div>

        {isDataLoading ? (
          <div className="h-96 flex justify-center items-center">
            <Icon name="Loader" className="animate-spin" />
          </div>
        ) : (
          <>
            <div className="p-6 space-y-4">
              <div className="flex items-start space-x-4">
                <div className="space-y-3">
                  <p className="text-gray-700 leading-relaxed">
                    Are you sure you want to continue? You are about to buy a
                    report for:
                  </p>
                  <div className="bg-white p-3 rounded-lg border border-gray-200">
                    <h5 className="font-semibold text-gray-900 mb-2">
                      Company Information
                    </h5>
                    <p className="text-gray-700">
                      Name:
                      <span className="font-medium">
                        {companyData?.legal_name}
                      </span>
                    </p>
                  </div>
                  <div className="bg-white p-3 rounded-lg border border-gray-200">
                    <h5 className="font-semibold text-gray-900 mb-2">
                      Customer Information
                    </h5>
                    <div className="space-y-1">
                      <p className="text-gray-700">
                        Name:{" "}
                        <span className="font-medium">
                          {customerInfo?.response_data.name}
                        </span>
                      </p>
                      <p className="text-gray-700">
                        Email:{" "}
                        <span className="font-medium">
                          {customerInfo?.response_data.email}
                        </span>
                      </p>
                      <p className="text-gray-700">
                        Company:{" "}
                        <span className="font-medium">
                          {customerInfo?.response_data.company_name}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-3 p-4 bg-gray-50 rounded-b-xl w-full">
              <Button
                variant="secondary"
                onClick={() => onClose(false)}
                className="flex items-center gap-2"
                rightIcon={X}
              >
                Cancel
              </Button>
              <Button
                onClick={() => onConfirm(companyData?.legal_name as string)}
                className="flex items-center gap-2 bg-blue-600 text-white hover:bg-blue-700"
                rightIcon={MoveRight}
                isLoading={isLoading}
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
