import { IdentifierTypeEnum } from "@app/types";
import type { OrNull } from "@app/store/api/shareHoldingsApi/types";
import { MCAVersionEnum } from "../documentsApi/types";
import { ParsingStatusEnum } from "../qaTasksApi";

export interface FinancialMetaDataPayload {
  identifier_type: IdentifierTypeEnum;
  identifier_value: string;
}

export interface FinancialMetaDataResponseObject {
  entity_id: string;
  s3_url: string;
  flatten: boolean;
  status: ParsingStatusEnum;
  document_type: MCAFormType;
  category: string;
  filing_date: string;
  file_name: string;
  financial_year: string;
  mca_version: MCAVersionEnum;
  parser_version: string;
  parent_document_id?: string | null;
  pdf_form_id?: string | null;
  id: string;
  created_at: string;
  updated_at: string;
  attachments: Omit<FinancialMetaDataResponseObject, "attachments">[];
}

export enum FinancialTypes {
  STANDALONE = "STANDALONE",
  CONSOLIDATED = "CONSOLIDATED",
}

export interface FinancialMetaDataResponse {
  [year: string]: {
    [FinancialTypes.STANDALONE]?: FinancialMetaDataResponseObject;
    [FinancialTypes.CONSOLIDATED]?: FinancialMetaDataResponseObject;
  };
}

export enum MCAFormType {
  MGT7 = "MGT-7",
  AOC4 = "AOC-4",
  CHG1 = "CHG-1",
  FORM8 = "FORM8",
  LLP_FORM8 = "LLP_FORM8",
  LLP_FORM11 = "LLP_FORM11",
  FORM18 = "FORM18",
  FORM32 = "FORM32",
  FORM67 = "FORM67",
  MGT7A = "MGT-7A",
  DPT3 = "DPT-3",
  ADT1 = "ADT-1",
  PAS3 = "PAS-3",
  MSME1 = "MSME-1",
  DIR11 = "DIR-11",
  DIR12 = "DIR-12",
  AOC4_XBRL = "AOC-4_XBLR",
  AOC4_XBRL_AS = "AOC-4_XBLR_AS",
  AOC4_XBRL_IND_AS = "AOC-4_XBLR_IND_AS",
  INC22 = "INC-22",
  UNKNOWN = "UNKNOWN",
  LLP_FORM8_FINANCIALS = "LLP_FORM8_FINANCIALS",
  LLP_FORM8_CHARGE = "LLP_FORM8_CHARGE",
}

export interface BalanceSheetResponse {
  filing_type: string;
  tangible_assets_capital_work_in_progress: number;
  intangible_assets_under_development: number;
  noncurrent_investments: number;
  deferred_tax_assets_net: number;
  property_plant_equipment: number;
  right_of_use_assets: number;
  investment_property: number;
  biological_assets_other_bearer_plants: number;
  investments_accounted_for_using_equity_method: number;
  goodwill: number;
  other_intangible_assets: number;
  trade_receivables_non_current: number;
  other_non_current_financial_assets: number;
  current_tax_assets_non_current: number;
  contract_assets_non_current: number;
  noncurrent_loans: number;
  other_noncurrent_assets: number;
  long_term_loans_and_advances: number;
  tangible_assets: number;
  intangible_assets: number;
  total_other_non_current_assets: number;
  current_investments: number;
  inventories: number;
  trade_receivables: number;
  bank_balances_other_than_cash_cash_equivalents: number;
  short_term_loans_and_advances: number;
  other_current_assets: number;
  cash_and_bank_balances: number;
  given_assets_total: number;
  foreign_curr_monetary_item_trans_diff_asset_account: number;
  producing_properties: number;
  preproducing_properties: number;
  share_capital: number;
  reserves_and_surplus: number;
  money_received_against_share_warrants: number;
  share_application_money_pending_allotment: number;
  minority_interest: number;
  long_term_borrowings: number;
  deferred_tax_liabilities_net: number;
  long_term_provisions: number;
  noncurrent_lease_liabilities: number;
  trade_payables_non_current: number;
  other_non_current_financial_liabilities: number;
  contract_liabilities_non_current: number;
  other_non_current_liabilities: number;
  non_current_financial_liabilities: number;
  other_long_term_liabilities: number;
  total_non_current_liabilities: number;
  short_term_borrowings: number;
  trade_payables: number;
  short_term_provisions: number;
  deferred_government_grants_current: number;
  deferred_government_grants_non_current: number;
  current_lease_liabilities: number;
  other_current_financial_liabilities: number;
  current_tax_liabilities: number;
  contract_liabilities_current: number;
  liabilities_associated_with_assets_classified_as_held_for_sale: number;
  regulatory_deferred_liabilities: number;
  other_current_liabilities: number;
  total_current_liabilities: number;
  given_liabilities_total: number;
  document_id: string;
  entity_id: string;
  year: number;
  financial_year: string;
  nature: FinancialTypes;
  id: string;
  created_at: string;
  updated_at: string;
  COMPUTED_tangible_assets: number;
  COMPUTED_intangible_assets: number;
  COMPUTED_long_term_loans_and_advances: number;
  COMPUTED_other_noncurrent_assets: number;
  COMPUTED_cash_and_bank_balances: number;
  COMPUTED_deferred_government_grants: number;
  COMPUTED_other_long_term_liabilities: number;
  COMPUTED_other_current_liabilities: number;
  COMPUTED_total_equity: number;
  COMPUTED_total_non_current_liabilities: number;
  COMPUTED_total_current_liabilities: number;
  COMPUTED_net_fixed_assets: number;
  COMPUTED_total_current_assets: number;
  COMPUTED_total_debt: number;
  COMPUTED_total_other_non_current_assets: number;
  COMPUTED_difference_value_of_assets: number;
  COMPUTED_difference_value_of_liabilities: number;
}

export interface BalanceSheetResponsePayload
  extends Partial<OrNull<BalanceSheetResponse>> {
  balance_sheet_id: string;
}

export interface GetAuditorPayload {
  document_id: string;
  year: string;
  nature: FinancialTypes;
}

export interface AuditorResponse {
  auditor_name: string;
  auditor_firm_name: string;
  is_primary: boolean;
  pan: string;
  membership_number: string;
  firm_registration_number: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
  email?: string;
  address?: string;
  document_id: string;
  entity_id: string;
  year: number;
  nature: string;
  financial_year: string;
  id: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateAuditor extends Partial<OrNull<AuditorResponse>> {
  auditor_id: string;
}

export interface ProfitAndLoseResponse {
  filing_type: string;
  sales_of_goods_manufactured_domestic: number;
  sales_of_goods_traded_domestic: number;
  sales_of_services_domestic: number;
  sales_of_goods_manufactured_export: number;
  sales_of_goods_traded_export: number;
  sales_of_services_export: number;
  revenue_from_sale_of_products: number;
  revenue_from_operations: number;
  cost_material_consumed: number;
  purchases_stock_traded: number;
  changes_in_inventories_finished_goods: number;
  changes_in_inventories_work_in_progress: number;
  changes_in_inventories_stock_in_trade: number;
  employee_benefits_expense: number;
  managerial_remuneration: number;
  payment_to_auditors: number;
  insurance_expenses: number;
  power_and_fuel: number;
  finance_cost: number;
  depreciation_and_amortization_expense: number;
  other_expenses: number;
  total_expenses: number;
  other_income: number;
  profit_loss_minority_interest: number;
  share_of_profit_loss_of_associates: number;
  profit_for_period_from_continuing_operations: number;
  profit_from_discontinuing_operations: number;
  income_tax: number;
  total_changes_in_inventories_or_finished_goods: number;
  total_other_expenses: number;
  exceptional_items_before_tax: number;
  profit_before_tax_and_exceptional_items_before_tax: number;
  exceptional_items: number;
  extraordinary_items: number;
  prior_period_items_before_tax: number;
  extraordinary_items_before_tax: number;
  tax_expense_current: number;
  tax_expense_deferred: number;
  tax_expense_discontinued_operations: number;
  profit_from_discontinuing_operation_after_tax: number;
  profit_loss_for_the_period: number;
  earnings_per_share_before_extraordinary_items_basic: number;
  earnings_per_share_before_extraordinary_items_diluted: number;
  earnings_per_share_after_extraordinary_items_basic: number;
  earnings_per_share_after_extraordinary_items_diluted: number;
  profit_after_tax: number;
  profit_before_tax: number;
  document_id: string;
  entity_id: string;
  year: number;
  financial_year: string;
  nature: string;
  id: string;
  created_at: string;
  updated_at: string;
  COMPUTED_net_revenue: number;
  COMPUTED_total_changes_in_inventories_or_finished_goods: number;
  COMPUTED_minority_interest_and_profit_from_associates_and_joint_ventures: number;
  COMPUTED_total_other_expenses: number;
  COMPUTED_operating_profit: number;
  COMPUTED_exceptional_items_before_tax: number;
  COMPUTED_profit_before_interest_and_tax: number;
  COMPUTED_profit_before_tax_and_exceptional_items_before_tax: number;
  COMPUTED_profit_before_tax: number;
  COMPUTED_income_tax: number;
  COMPUTED_profit_after_tax: number;
  COMPUTED_difference_in_value_line_items: number;
  COMPUTED_revenue_from_sale_of_products: number;
  COMPUTED_revenue_from_operations: number;
  COMPUTED_revenue_from_sale_of_services: number;
}

export interface UpdateProfitAndLose
  extends Partial<OrNull<ProfitAndLoseResponse>> {
  profit_and_loss_id: string;
}

export interface FinancialParams {
  document_id: string;
  entity_id: string;
  year: number;
  financial_year: string;
  nature: string;
  earning_fc: number;
  expenditure_fc: number;
  transaction_related_parties_as_18: number;
  prescribed_csr_expenditure: number;
  total_amount_csr_spent_for_financial_year: number;
  proposed_dividend: number;
  companies_caro_applicable: boolean;
  highest_turnover_contributing_product_or_service_code: string;
  id: string;
}

export interface UpdateFinancialParams
  extends Partial<OrNull<FinancialParams>> {
  financial_params_id: string;
}

export interface AddAuditor {
  auditor_name: string;
  auditor_firm_name: string;
  is_primary: boolean;
  pan: string;
  membership_number: string;
  firm_registration_number: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
  email: string;
  address: string;
  document_id: string;
  entity_id: string;
  year: number;
  nature: string;
  financial_year: string;
}

export interface RelatedParty {
  type: string;
  filing_type: MCAFormType;
  name_of_related_party: string;
  country_of_incorporation_or_residence: string;
  pan?: string;
  cin: string;
  description_of_nature_of_related_party?: string;
  description_of_nature_of_transaction_with_related_party: string;
  amounts_payable: number;
  transfers_under_finance_agreements_from_entity: number;
  transfers_under_finance_agreements_to_entity: number;
  purchase_of_goods: number;
  expense_recognised_during_period_for_bad_and_doubtful_debts: number;
  income?: string;
  transactions_expenses: number;
  contributions_made: number;
  contributions_received: number;
  additional_information: Record<string, string>;
  document_id: string;
  entity_id: string;
  year: number;
  financial_year: string;
  nature: FinancialTypes;
  id: string;
  created_at: string;
  updated_at: string;
}
