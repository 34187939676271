import {
  DocumentScrapResponse,
  useGetMc2DocumentQuery,
} from "@app/store/api/mcDocuments";
import { UploadModal } from "@app/components/labelling/documents";
import { useToggle } from "@app/hooks/useToggle";
import { Icon, StatusBox } from "@components/common";
import { Table, ToolTip } from "@components/common/table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageLoader } from "@components/loader";
import { useParams } from "react-router-dom";
import { headers } from "./constants";
import { eFormDocument } from "@app/types";
import toast from "react-hot-toast";
import { useTriggerFormNameScrapingMutation } from "@app/store/api/scrapperApi/scraperApi";
import { useCreatePresignedUrlMutation } from "@app/store/api/documentsApi";
import { Button } from "@components/common/Button";
import { LastUpdatedTooltip } from "./LastUpdatedTooltip";
import { PollingPreview } from "@components/common/PollingPreview";

export const LIMIT = 200;

interface DocumentStats {
  total: number;
  downloaded: number;
}

const POLLING_INTERVAL = 15000;

export const McaV2Table = () => {
  const [activeForm, setActiveForm] = useState<eFormDocument | null>(null);
  const [primarySkip, setPrimarySkip] = useState(0);
  const [secondarySkip, setSecondarySkip] = useState(0);
  const [startFormNameScraping, { isLoading: isScrapingLoading }] =
    useTriggerFormNameScrapingMutation();
  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [shouldStopPolling, toggleStopPolling] = useToggle(false);
  const [accordionOpen, toggleAccordion] = useState({
    primaryDocuments: true,
    secondaryDocuments: false,
  });

  const toggleAccordionHandler = useCallback(
    (type: "primary" | "secondary") => {
      toggleAccordion((prev) => ({
        ...prev,
        [type === "primary" ? "primaryDocuments" : "secondaryDocuments"]:
          !prev[type === "primary" ? "primaryDocuments" : "secondaryDocuments"],
      }));
    },
    [toggleAccordion],
  );

  const [isOpen, toggleOpen] = useToggle();
  const params = useParams();
  const { cin } = params;
  const {
    data: apiData,
    isLoading,
    isError,
    startedTimeStamp,
  } = useGetMc2DocumentQuery(
    {
      cin: cin as string,
      sort: "asc",
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: shouldStopPolling,
    },
  );

  const successCount = useMemo(() => {
    if (!apiData?.data?.data?.document_list) return 0;

    return apiData.data.data.document_list.reduce((count, el) => {
      return el.status ? count + 1 : count;
    }, 0);
  }, [apiData]);

  const sizeOfData = useMemo(() => {
    return apiData?.data.data.document_list.length;
  }, [apiData]);

  useEffect(() => {
    if (successCount === sizeOfData) {
      toggleStopPolling.on();
    }
  }, [successCount, sizeOfData]);

  const triggerFormNameScraping = async () => {
    try {
      if (!cin) throw new Error();
      await startFormNameScraping(cin).unwrap();
      toast.success("Scraping File Names Started");
    } catch {
      toast.error("Failed to start the Scraping");
    }
  };

  const handleOpenFile = async (el: eFormDocument) => {
    const constructedUrl = `companies/${cin}/deep_data/mca_v2/${el.category}/${
      el.file_name.toLowerCase().includes(".pdf")
        ? el.file_name
        : `${el.file_name}.pdf`
    }`;

    toast.promise(
      createPresignedUrl({
        s3_url: constructedUrl,
      }).unwrap(),
      {
        loading: "Opening file...",
        success: (url) => {
          window.open(url.pre_signed_url, "_blank");
          return "File opened successfully";
        },
        error: (error) => {
          console.error("Error opening file:", error);
          return "Failed to open file";
        },
      },
    );
  };

  const processDocuments = (
    documents: DocumentScrapResponse["data"]["data"]["document_list"],
    type: "primary" | "secondary",
  ) => {
    const skip = type === "primary" ? primarySkip : secondarySkip;
    return documents
      .filter((document) => {
        if (type === "primary") {
          return document.isPrimary && document.isPrimary === true;
        }
        return !document.isPrimary;
      })
      .slice(skip, skip + LIMIT)
      .map((el) => ({
        category: el.category,
        status: (
          <StatusBox
            statusText={el.status ? "Done" : "Not Found"}
            color={el.status ? "bg-green-500" : "bg-orange-500"}
          />
        ),
        filing: el.date_of_filing,
        year: el.year,
        fileName: (
          <ToolTip
            value={el.file_name}
            handler={() => {
              handleOpenFile(el);
            }}
          />
        ),
        action: (
          <button
            type="button"
            className="p-2 text-blue-600"
            onClick={() => {
              setActiveForm(el);
              toggleOpen.on();
            }}
          >
            <Icon name="Upload" size={20} color="lightgray" />
          </button>
        ),
      }));
  };

  const {
    primaryDocuments,
    secondaryDocuments,
    primaryMaxSize,
    secondaryMaxSize,
  } = useMemo(() => {
    const primaryDocuments = processDocuments(
      apiData?.data.data.document_list || [],
      "primary",
    );
    const secondaryDocuments = processDocuments(
      apiData?.data.data.document_list || [],
      "secondary",
    );
    const primaryMaxSize = apiData?.data.data.document_list.filter(
      (el) => el.isPrimary,
    ).length;
    const secondaryMaxSize = apiData?.data.data.document_list.filter(
      (el) => !el.isPrimary,
    ).length;

    return {
      primaryDocuments,
      secondaryDocuments,
      primaryMaxSize,
      secondaryMaxSize,
    };
  }, [apiData, primarySkip, secondarySkip]);

  const { primaryStats, secondaryStats } = useMemo(() => {
    if (!apiData?.data.data.document_list) {
      return {
        primaryStats: { total: 0, downloaded: 0 },
        secondaryStats: { total: 0, downloaded: 0 },
      };
    }

    const documents = apiData.data.data.document_list;

    const calculateStats = (docs: typeof documents): DocumentStats => {
      const total = docs.length;
      const downloaded = docs.filter((doc) => doc.status).length;

      return { total, downloaded };
    };

    const primaryDocs = documents.filter((doc) => doc.isPrimary);
    const secondaryDocs = documents.filter((doc) => !doc.isPrimary);

    return {
      primaryStats: calculateStats(primaryDocs),
      secondaryStats: calculateStats(secondaryDocs),
    };
  }, [apiData]);

  if (isLoading) {
    return (
      <PageLoader
        className="w-full h-full"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 180px)" }}
      />
    );
  }

  if (isError || !apiData) {
    return (
      <div
        className="flex w-full border mt-5 rounded-lg justify-center items-center"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 180px)" }}
      >
        <div className="flex flex-col justify-center items-center gap-3">
          <h2 className="text-xl text-red-600">No Documents Found To Scrape</h2>
          <button
            onClick={triggerFormNameScraping}
            className="px-3 py-2  bg-blue-500 hover:bg-blue-400  inline-flex justify-center items-center rounded-md text-white"
          >
            Scrape Documents Name
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-2">
      <UploadModal
        isOpen={isOpen}
        onClose={toggleOpen.off}
        documentName={activeForm?.file_name as string}
        category={activeForm?.category as string}
        version="v2"
      />
      <div className="flex justify-between w-full gap-2">
        <div>
          <div className="flex items-center gap-2">
            <LastUpdatedTooltip
              lastUpdated={apiData?.data.metadata.last_updated as string}
            />

            <Button
              onClick={triggerFormNameScraping}
              isLoading={isScrapingLoading}
            >
              Refresh Documents Name
            </Button>
            {startedTimeStamp && !shouldStopPolling && (
              <PollingPreview
                lastFetchedAt={startedTimeStamp}
                pollingInterval={POLLING_INTERVAL}
              />
            )}
          </div>
        </div>
        <div className="self-end border px-3 py-2 rounded-md bg-green-100 shadow-md">
          <h4 className="font-semibold text-green-700">
            Success : {successCount} / {sizeOfData}
          </h4>
        </div>
      </div>
      <div className="mt-3">
        <div className="border rounded-lg overflow-hidden mb-4">
          <div className="w-full bg-gray-50">
            <button
              onClick={() => toggleAccordionHandler("primary")}
              className="w-full flex items-center justify-between p-4 hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center gap-4">
                <h4 className="text-xl font-bold">Primary Documents</h4>
                <div className="flex items-center gap-2 text-sm">
                  <span className="px-2 py-1 rounded bg-blue-100 text-blue-700">
                    Total: {primaryStats.total}
                  </span>
                  <span className="px-2 py-1 rounded bg-green-100 text-green-700">
                    Downloaded: {primaryStats.downloaded}
                  </span>
                </div>
              </div>
              <Icon
                name={
                  accordionOpen.primaryDocuments ? "ChevronUp" : "ChevronDown"
                }
                className="w-5 h-5 text-gray-600"
              />
            </button>
          </div>
          {accordionOpen.primaryDocuments && (
            <div className="p-4">
              <Table
                className="max-h-[800px]"
                paginate={true}
                headers={headers}
                rows={primaryDocuments || []}
                currentLimit={LIMIT}
                skip={primarySkip}
                nextHandler={() => setPrimarySkip(primarySkip + LIMIT)}
                prevHandler={() => setPrimarySkip(primarySkip - LIMIT)}
                maxSize={primaryMaxSize}
              />
            </div>
          )}
        </div>

        <div className="border rounded-lg overflow-hidden">
          <div className="w-full bg-gray-50">
            <button
              onClick={() => toggleAccordionHandler("secondary")}
              className="w-full flex items-center justify-between p-4 hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center gap-4">
                <h4 className="text-xl font-bold">Secondary Documents</h4>
                <div className="flex items-center gap-2 text-sm">
                  <span className="px-2 py-1 rounded bg-blue-100 text-blue-700">
                    Total: {secondaryStats.total}
                  </span>
                  <span className="px-2 py-1 rounded bg-green-100 text-green-700">
                    Downloaded: {secondaryStats.downloaded}
                  </span>
                </div>
              </div>
              <Icon
                name={
                  accordionOpen.secondaryDocuments ? "ChevronUp" : "ChevronDown"
                }
                className="w-5 h-5 text-gray-600"
              />
            </button>
          </div>
          {accordionOpen.secondaryDocuments && (
            <div className="p-4">
              <Table
                className="max-h-[800px]"
                paginate={true}
                headers={headers}
                rows={secondaryDocuments}
                currentLimit={LIMIT}
                skip={secondarySkip}
                nextHandler={() => setSecondarySkip(secondarySkip + LIMIT)}
                prevHandler={() => setSecondarySkip(secondarySkip - LIMIT)}
                maxSize={secondaryMaxSize}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
