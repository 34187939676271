import Modal from "react-modal";

interface Props {
  isOpen: boolean;
  proceed: () => void;
  cancel: () => void;
}

Modal.setAppElement("#root");

export const WarningModal = ({ isOpen, proceed, cancel }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      className="fixed inset-0 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[400px] h-46 py-2 flex flex-col rounded-md justify-between">
        <div className="border-b">
          <h4 className="ml-2 p-2 text-md font-bold">Unsaved Changes</h4>
        </div>
        <div className="flex gap-0 flex-col">
          <h4 className="ml-2 p-2 text-sm font-normal">
            Are you sure you want to leave the page ?
          </h4>
          <h4 className="ml-2 p-2 text-sm font-normal">
            Changes won't be saved.
          </h4>
        </div>
        <div className="self-end p-4 flex gap-2">
          <button
            type="button"
            className="text-md inline-flex bg-gray-200 py-2 px-3 rounded-lg hover:bg-gray-100"
            onClick={cancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-md inline-flex bg-red-500 text-white py-2 px-3 rounded-lg hover:bg-red-400"
            onClick={proceed}
          >
            Leave Page
          </button>
        </div>
      </div>
    </Modal>
  );
};
