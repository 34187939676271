import { FormProvider, useFormContext } from "react-hook-form";
import { xblInddAsprofitAndLossWithHeading } from "./xbrlIndAsValidation";
import { InputGroup } from "../InputGroup";

interface XbrRlIndAsProfitAndLossInputsProps {
  isEditable: boolean;
  isPdfOpen: boolean;
}

export const XbrRlIndAsProfitAndLossInputs = ({
  isEditable,
  isPdfOpen,
}: XbrRlIndAsProfitAndLossInputsProps) => {
  const form = useFormContext();
  return (
    <FormProvider {...form}>
      <InputGroup
        title="Line Items"
        fields={xblInddAsprofitAndLossWithHeading.line_items}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Revenue"
        fields={xblInddAsprofitAndLossWithHeading.revenue}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Expenses"
        fields={xblInddAsprofitAndLossWithHeading.expenses}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Revenue Breakup"
        fields={xblInddAsprofitAndLossWithHeading.revenue_breakup}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
    </FormProvider>
  );
};
