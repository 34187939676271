import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useGetCompanyMembersQuery } from "@app/store/api/useCustomerTeams";
import { PageLoader } from "@components/loader";
import { CustomerCard } from "./CustomerCard";
import { CreateOrderForCustomerModal } from "./PlaceOrderModal";
import { CreateTeam } from "./CreateTeam";
import { NewCompany } from "./NewCompany";
import { useToggle } from "@app/hooks/useToggle";
import { useState } from "react";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";

export type SupportedTeamDrawerTypes =
  | "team-info"
  | "create-team"
  | "create-company";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  company: {
    name: string;
    id: string;
  };
  typeOfDrawer: SupportedTeamDrawerTypes;
}

export const TeamDrawer = ({
  isOpen,
  onClose,
  company,
  typeOfDrawer,
}: Props) => {
  const [_, setSearchParams] = useSearchParams();
  const [
    isCreateOrderForCustomerModalOpen,
    toggleIsCreateOrderForCustomerModalOpen,
  ] = useToggle();

  const { data, isFetching } = useGetCompanyMembersQuery(
    {
      customer_company_id: company.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: typeOfDrawer === "create-team",
    },
  );

  const handleClose = () => {
    onClose();
    setSearchParams({});
  };

  const [userId, setUserId] = useState("");

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      direction="right"
      size={1000}
      className="p-6 overflow-y-auto"
    >
      <CreateOrderForCustomerModal
        isOpen={isCreateOrderForCustomerModalOpen}
        onClose={toggleIsCreateOrderForCustomerModalOpen.off}
        userId={userId}
      />
      {isFetching ? (
        <PageLoader />
      ) : (
        <div className="h-full ">
          <h2 className="text-xl font-bold truncate">
            {typeOfDrawer === "team-info" && "Customer Company Members"}
          </h2>
          <section
            className={classNames({
              "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4 h-full":
                typeOfDrawer === "team-info",
              "w-full": typeOfDrawer === "create-team",
            })}
          >
            {typeOfDrawer === "create-company" && (
              <NewCompany onClose={handleClose} />
            )}
            {typeOfDrawer === "team-info" &&
              data?.response_data?.map((item) => (
                <CustomerCard
                  key={item.id}
                  name={item.name}
                  id={item.id}
                  email={item.email}
                  teamName={item.team_name}
                  lastActive={item.last_login}
                  toggleIsCreateOrderForCustomerModalOpen={() => {
                    onClose();
                    toggleIsCreateOrderForCustomerModalOpen.on();
                    setUserId(item.id);
                  }}
                />
              ))}
            {typeOfDrawer === "create-team" && (
              <CreateTeam onClose={handleClose} />
            )}
          </section>
        </div>
      )}
    </Drawer>
  );
};
