import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCreditRatingQuery } from "@app/store/api/creditRating";
import { PageLoader } from "@components/loader";
import { NotFound } from "@components/common/NotFound";
import { CreditRatingEditor } from "@components/labelling/credit-ratings";
import { CreditAgency, CreditRating } from "@app/store/api/creditRating/types";

const LIMIT = 5;

const CreditRatings: React.FC = () => {
  const { cin } = useParams<{ cin: string }>();
  const [skip, setSkip] = useState(0);

  const { data, isLoading } = useGetCreditRatingQuery(
    {
      identifier_value: cin as string,
      skip,
      limit: LIMIT,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const groupedRatings = useMemo(() => {
    if (!data) return {};

    const groups: Record<string, CreditRating[]> = {
      [CreditAgency.BRICKWORK]: [],
      [CreditAgency.CARE]: [],
      [CreditAgency.CRISIL]: [],
      [CreditAgency.ICRA]: [],
      [CreditAgency.INDIA]: [],
      [CreditAgency.INFOMERICS]: [],
    };

    data.response_data.forEach((rating) => {
      groups[rating.rating_agency].push(rating);
    });

    return groups;
  }, [data]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!data) {
    return (
      <NotFound
        message="Credit Ratings Not Found"
        style={{
          height: "calc(100vh - 100px)",
        }}
      />
    );
  }

  return (
    <CreditRatingEditor
      data={groupedRatings}
      skip={skip}
      setSkip={setSkip}
      total={data.total}
      limit={LIMIT}
    />
  );
};

export default CreditRatings;
