import { Status } from "@app/store/api/chargesApi/types";

export const getBadgeStyle = (status: Status) => {
  switch (status) {
    case "MODIFICATION":
      return "bg-orange-50 text-orange-700 border border-orange-200";
    case "CREATION":
      return "bg-blue-50 text-blue-700 border border-blue-200";
    case "SATISFACTION":
      return "bg-red-50 text-red-600 border border-red-300";
    default:
      return "bg-gray-100 text-gray-600 border border-gray-300";
  }
};
