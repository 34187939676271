import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { Table } from "@components/common/table";
import { Pagination, usePagination } from "@components/common/Pagination";
import { PollingPreview } from "@components/common/PollingPreview";
import { SCRAPING_POLLING_INTERVAL } from "./Scraping";
import { Header, Row } from "@components/common/table/table.types";
import { useProjectModal } from "@app/hooks/useProjectModal";
import { Button } from "@components/common/Button";
import { Icon } from "@components/common";

type TransformedTask<T> = {
  project_name: string;
  version: string;
  tasks: T[];
  project_id: string;
};

interface GroupedAccordionProps<T> {
  data: TransformedTask<T>[];
  skip: number;
  setSkip: (skip: number) => void;
  limit: number;
  total: number;
  startedTimeStamp: number;
  renderContent: (task: T, projectIndex: number, taskIndex: number) => Row;
  headers: Header[];
}

export function GroupedTaskAccordion<T>({
  startedTimeStamp,
  data,
  skip,
  setSkip,
  limit,
  renderContent,
  total,
  headers,
}: GroupedAccordionProps<T>) {
  const { currentPage, totalPages } = usePagination(total | 0, skip, limit);
  const [openIndexes, setOpenIndexes] = useState<Set<number>>(
    new Set(Array.from({ length: data.length }, (_, i) => i)),
  );
  const { openProjectModal } = useProjectModal();

  const toggleAccordion = (index: number) => {
    setOpenIndexes((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) newSet.delete(index);
      else newSet.add(index);
      return newSet;
    });
  };

  return (
    <div className="space-y-4">
      <section className="flex justify-between items-center gap-2">
        <PollingPreview
          lastFetchedAt={startedTimeStamp}
          pollingInterval={SCRAPING_POLLING_INTERVAL}
        />
        <div className="flex items-center gap-2">
          <span className="text-md text-gray-600">
            Showing {skip + 1} to {Math.min(skip + limit, total)} of {total}{" "}
            tasks
          </span>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setSkip((page - 1) * 5)}
          />
        </div>
      </section>
      {data.map((group: TransformedTask<T>, projectIndex: number) => (
        <div
          key={`${group.project_name}-${group.version}`}
          className="border rounded-lg overflow-hidden"
          onClick={() => toggleAccordion(projectIndex)}
        >
          <div className="bg-gray-50 px-4 py-3 border-b flex items-center justify-between">
            <h3 className="text-sm font-semibold text-gray-900">
              {group.project_name} - Version {group.version}
            </h3>
            <div className="flex items-center gap-2">
              <button
                className="cursor-pointer p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                onClick={(e) => {
                  e.stopPropagation();
                  openProjectModal(group.project_id);
                }}
              >
                <Icon name="PanelRightOpen" className="h-6 w-6 text-gray-600" />
              </button>
              <span className="text-sm text-gray-500">
                {group.tasks.length} tasks
              </span>
              {openIndexes.has(projectIndex) ? (
                <ChevronUpIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
              )}
            </div>
          </div>
          {openIndexes.has(projectIndex) && (
            <div className="transition-all duration-300 ease-in-out">
              <div onClick={(e) => e.stopPropagation()}>
                <Table
                  rows={group.tasks.map((task, index) =>
                    renderContent(task, projectIndex, index),
                  )}
                  headers={headers}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
