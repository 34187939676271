import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Icon, Input, StatusBox } from "@components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { PageLoader } from "@components/loader";
import { useLazySearchCompanyByCinQuery } from "@app/store/api/cinSearchApi";
import { NotFound } from "@components/common/NotFound";
import { COMPANY_TYPE } from "@app/types";
import { useActiveCompany } from "@app/hooks/useActiveCompany";

const schema = Yup.object({
  company_id: Yup.string()
    .required("Company Id is required")
    .matches(/^\S*$/, "CIN id should not contain space"),
});

type ISchema = Yup.InferType<typeof schema>;

export const CompanyList = () => {
  const navigate = useNavigate();
  const { activeCountry, activeCorporate } = useActiveCompany();
  const [validationError, setError] = useState<string | null>(null);
  const [getCompanyByCin, { data, isLoading, isError }] =
    useLazySearchCompanyByCinQuery();

  const form = useForm<ISchema>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        form.handleSubmit((data) => handleSearch(data.company_id))();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [form]);

  useEffect(() => {
    const input = form.getValues("company_id");
    if (!input.length) {
      setError(null);
    }
  }, [form.getValues("company_id")]);

  const handleOpen = (id: string) => {
    navigate(`/labelling/${activeCountry}/${activeCorporate}/${id}/overview`);
  };

  const handleSearch = async (id: string) => {
    try {
      await getCompanyByCin(id.toUpperCase());
    } catch (error) {
      setError("Invalid Customer Id");
    }
  };

  const copyId = (id: string) => {
    window.navigator.clipboard.writeText(id);
    toast.success("Id copied to clipboard");
  };

  const getCompanyIdentifier = useCallback((companyData: any) => {
    if (!companyData) return "";
    return companyData.classification ===
      COMPANY_TYPE.LimitedLiabilityPartnership
      ? companyData.llpin
      : companyData.cin;
  }, []);

  const identifier = useMemo(
    () => (data ? getCompanyIdentifier(data) : ""),
    [data, getCompanyIdentifier],
  );
  const idType =
    data?.classification === COMPANY_TYPE.LimitedLiabilityPartnership
      ? "LLPIN"
      : "CIN";

  return (
    <div className="w-screen max-w-md mt-6">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit((el) => handleSearch(el.company_id))}>
          <Input
            name="company_id"
            label="Search Company"
            placeholder="company id"
            suffixIconHandler={() => handleSearch(form.getValues("company_id"))}
            suffixIcon="Search"
            onPaste={(e) => handleSearch(e.clipboardData.getData("Text"))}
          />
        </form>
      </FormProvider>

      {isError && <NotFound message="Company Not Found" />}

      {data && (
        <div className="border border-sm p-4 rounded-md flex flex-col gap-2 mt-4">
          <h1 className="text-xl font-bold">{data.legal_name}</h1>
          <h2 className="inline-flex justify-between items-center">
            <span>
              <span className="text-md font-semibold">{idType}</span> :{" "}
              {identifier}
            </span>
            <span onClick={() => copyId(identifier)}>
              <Icon
                name="Copy"
                size={15}
                className="self-center hover:cursor-pointer"
              />
            </span>
          </h2>
          <h2>
            <span className="text-md font-semibold">Status</span> :{" "}
            <StatusBox statusText="Active" />
          </h2>
          <h2>
            <span className="text-md font-semibold">Address</span> :{" "}
            {data.registered_address?.full_address}
          </h2>
          <button
            onClick={() => handleOpen(identifier)}
            className="px-2 py-2 bg-blue-600 text-md text-white rounded-md font-semibold mt-2 hover:bg-blue-500"
            type="button"
          >
            Edit
          </button>
        </div>
      )}

      {isLoading && (
        <PageLoader
          style={{
            width: "calc(100vw - 285px)",
            height: "calc(100vh - 250px)",
          }}
        />
      )}

      {validationError && (
        <div className="flex justify-center items-center">
          <h2 className="text-red-600 font-semibold">{validationError}</h2>
        </div>
      )}
    </div>
  );
};
