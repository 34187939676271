import { FinancialParams } from "@app/components/labelling/finances";
import { useParams } from "react-router-dom";
import { FormNature } from "./FinancialPage";
import { useEffect, useState } from "react";
import { Option } from "@components/common/dropdown";
import { useMemo } from "react";
import {
  FinancialTypes,
  useGetFinancialParamsMetaDataQuery,
} from "@app/store/api/financialApi";
import { Icon } from "@components/common";
import { NotFound } from "@components/common/NotFound";
import { FinancialNavBar } from "@app/components/labelling/llp/financials";
import { useCreatePresignedUrlMutation } from "@app/store/api/documentsApi";
import { toast } from "react-hot-toast";
import { valueOrEmpty } from "@app/utils/valueOrEmpty";
import { useToggle } from "@app/hooks/useToggle";

const FinancialParameter = () => {
  const { cin } = useParams();
  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [activeDocument, setActiveDocument] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<string>("");

  const isPdfOpen = activeDocument.length !== 0;

  const {
    data: financialMetaData,
    isLoading,
    isError,
  } = useGetFinancialParamsMetaDataQuery(cin as string);

  const [isEditable, toggleEditable] = useToggle();

  const [selectedYear, setSelectedYear] = useState<Option>({
    label: "",
    value: "",
  });

  const [selectedType, setSelectedType] = useState<Option<FormNature>>({
    label: "STANDALONE",
    value: "STANDALONE",
  });

  const financialInfo = useMemo(() => {
    if (
      !financialMetaData ||
      !financialMetaData.response_data ||
      !selectedYear.value.length
    )
      return null;

    // // TODO Remove the ts "as"
    return financialMetaData.response_data[selectedYear.value][
      selectedType.value.toLowerCase() as FinancialTypes
    ];
  }, [selectedYear, selectedType, financialMetaData]);

  const availableDocuments = useMemo(() => {
    const documents = [];

    documents.push({
      fileName: valueOrEmpty(financialInfo?.file_name),
      s3Url: valueOrEmpty(financialInfo?.s3_url),
    });

    financialInfo?.attachments.forEach((docs) => {
      documents.push({
        fileName: valueOrEmpty(docs.file_name),
        s3Url: valueOrEmpty(docs.s3_url),
      });
    });

    return documents;
  }, [financialInfo]);

  const availableFormType: Option<FormNature>[] = useMemo(() => {
    // Retrieve financial types for the selected year
    const financialTypes =
      financialMetaData?.response_data?.[selectedYear.value];

    const options: Option<FormNature>[] = [];

    // Check if financialTypes is defined and is an object
    if (!financialTypes || typeof financialTypes !== "object") {
      return [
        {
          label: FinancialTypes.STANDALONE,
          value: FinancialTypes.STANDALONE,
        },
      ];
    }

    // Use Object.entries to iterate and filter the entries
    Object.entries(financialTypes)
      .filter(([, value]) => typeof value === "object" && value !== null)
      .forEach(([key]) => {
        // Custom labels for specific keys
        if (key === "standalone") {
          options.push({
            label: FinancialTypes.STANDALONE,
            value: FinancialTypes.STANDALONE,
          });
        }
        if (key === "consolidated") {
          options.push({
            label: FinancialTypes.CONSOLIDATED,
            value: FinancialTypes.CONSOLIDATED,
          });
        }
      });

    return options;
  }, [selectedYear, financialMetaData]);

  const generatePreSignedUrl = (s3Url: string) => {
    toast.promise(
      createPresignedUrl({
        s3_url: s3Url,
      }).unwrap(),
      {
        loading: "Opening Pdf",
        error: "Error Loading Pdf",
        success: (data) => {
          setActiveDocument(data.pre_signed_url);
          return "";
        },
      },
    );

    return "";
  };

  const availableYears = useMemo((): Option<string>[] => {
    if (!financialMetaData?.response_data) return [];

    const sortedAvailableYears = Object.keys(financialMetaData.response_data)
      .map((year) => Number(year))
      .sort((a, b) => b - a);

    return sortedAvailableYears.map((year) => ({
      label: year.toString(),
      value: year.toString(),
    }));
  }, [financialMetaData]);

  // Default value for year
  useEffect(() => {
    if (availableYears.length && selectedYear.value.length === 0) {
      setSelectedYear(availableYears[0]);
    }
  }, [availableYears]);

  useEffect(() => {
    if (availableDocuments) {
      setSelectedFile(availableDocuments[0].fileName);
    }
  }, [availableDocuments]);

  const activeFormType =
    selectedType.value === "STANDALONE"
      ? FinancialTypes.STANDALONE
      : FinancialTypes.CONSOLIDATED;

  if (isLoading) {
    return (
      <section className="flex flex-col">
        <h2 className="text-start text-2xl font-bold">Financial Parameter</h2>
        <div className="h-screen flex justify-center items-center">
          <Icon name="Loader" className="animate-spin" />
        </div>
      </section>
    );
  }

  if (isError) {
    return (
      <section>
        <h2 className="text-start text-2xl font-bold text-nowrap">
          Financial Parameter
        </h2>
        <div className="mt-40">
          <NotFound message="No Financial Data Found" />
        </div>
      </section>
    );
  }

  return (
    <div>
      <FinancialNavBar
        setSelectedFile={setSelectedFile}
        availableYears={availableYears}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        availableDocuments={availableDocuments}
        selectedFile={selectedFile}
        generatePreSignedUrl={generatePreSignedUrl}
        isEditable={isEditable}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        isPdfOpen={isPdfOpen}
        closePdf={() => setActiveDocument("")}
        toggleEditable={toggleEditable}
        title="Financial Parameter"
        formNatureOptions={availableFormType}
        showDocumentCreationModal={false}
      />
      {financialInfo && (
        <section className="flex w-full gap-4">
          <FinancialParams
            activeInfo={financialInfo}
            formNature={activeFormType}
            activeYear={selectedYear.value}
            isEditable={isEditable}
            toggleEditable={toggleEditable}
          />
          {isPdfOpen && activeDocument && (
            <div className="w-full mt-8 h-full">
              <object
                data={activeDocument}
                type="application/pdf"
                className="w-full h-[calc(100vh-10px)]"
              >
                <p>Form PDF</p>
              </object>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default FinancialParameter;
