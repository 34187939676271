import React, { useState, useMemo } from "react";
import { Table } from "@components/common/table";
import { Accordion } from "@components/common/Accordion";
import { format } from "date-fns";
import { PageLoader } from "@components/loader/components/PageLoader";
import { NotFound } from "@components/common/NotFound";
import {
  LegalHistoryData,
  CourtData,
  CaseData,
} from "@app/store/api/compliancesApi/types";
import classNames from "classnames";

interface LegalHistoryProps {
  data?: LegalHistoryData;
  isReportFetching: boolean;
}

const headers = [
  { name: "Case Number", value: "case_number", classes: "p-4 text-sm" },
  { name: "Case Year", value: "case_year", classes: "p-4 text-sm" },
  { name: "Petitioner", value: "petitioner", classes: "p-4 text-sm" },
  { name: "Respondent", value: "respondent", classes: "p-4 text-sm" },
  { name: "Filing Date", value: "filing_date", classes: "p-4 text-sm" },
  { name: "Case Link", value: "link", classes: "p-4 text-sm" },
];

export const LegalHistory: React.FC<LegalHistoryProps> = ({
  data,
  isReportFetching,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState<"respondent" | "petitioner">(
    "respondent",
  );
  const itemsPerPage = 10;
  const ignoreKeys = ["overview", "legal", "snapshot"];

  const createRows = useMemo(
    () => (cases: Record<string, CaseData>) => {
      if (!cases) return [];
      return Object.values(cases).map((caseItem) => ({
        case_number: caseItem.case_number || "-",
        case_year: caseItem.case_year || "-",
        petitioner: caseItem.petitioner || "-",
        respondent: caseItem.respondent || "-",
        filing_date: caseItem.filing_date
          ? format(new Date(caseItem.filing_date), "dd MMM yyyy")
          : "-",
        link: caseItem.link ? (
          <a
            href={caseItem.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800"
          >
            View
          </a>
        ) : (
          "-"
        ),
      }));
    },
    [],
  );

  const renderTable = (courtData: CourtData) => {
    const cases =
      activeTab === "respondent"
        ? courtData.as_respondent
        : courtData.as_petitioner;
    const rows = createRows(cases as Record<string, CaseData>);

    if (rows.length === 0) {
      return (
        <div className="text-center py-4 text-gray-500">
          No cases found for {activeTab}
        </div>
      );
    }

    return (
      <Table
        headers={headers}
        rows={rows.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage,
        )}
        tableDivClassname="w-full border border-gray-200 !p-0 rounded mt-4"
        paginate
        skip={(currentPage - 1) * itemsPerPage}
        currentLimit={itemsPerPage}
        maxSize={rows.length}
        nextHandler={() =>
          setCurrentPage((prev) =>
            Math.min(prev + 1, Math.ceil(rows.length / itemsPerPage)),
          )
        }
        prevHandler={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
      />
    );
  };

  if (isReportFetching) {
    return <PageLoader />;
  }

  if (!data) {
    return (
      <NotFound
        message="Legal History data not available for this company."
        className="h-full"
      />
    );
  }
  return (
    <div className="py-4 flex flex-col gap-4 border rounded-lg p-4">
      <div className="text-lg w-max text-heading font-semibold">
        Legal History
      </div>
      {Object.entries(data).map(([key, value]) => {
        if (ignoreKeys.includes(key)) return null;
        if (!value) return null;

        const hasRespondentData =
          value.as_respondent && Object.keys(value.as_respondent).length > 0;
        const hasPetitionerData =
          value.as_petitioner && Object.keys(value.as_petitioner).length > 0;

        const totalCases =
          (hasRespondentData ? Object.keys(value.as_respondent).length : 0) +
          (hasPetitionerData ? Object.keys(value.as_petitioner).length : 0);

        return (
          <Accordion
            key={key}
            title={key.replace("_", " ").toUpperCase()}
            count={totalCases}
          >
            <div className="flex my-4 border-2 border-slate-100 bg-slate-100 p-1 w-fit rounded-lg">
              {["respondent", "petitioner"].map((tab) => {
                const hasData =
                  tab === "respondent" ? hasRespondentData : hasPetitionerData;
                const caseCount = hasData
                  ? Object.keys(
                      tab === "respondent"
                        ? value.as_respondent
                        : value.as_petitioner,
                    ).length
                  : 0;

                return (
                  <button
                    key={tab}
                    className={classNames(
                      "px-4 py-2 text-sm rounded-lg font-medium w-48",
                      {
                        "ml-2": tab === "petitioner",
                      },
                      activeTab === tab
                        ? "bg-blue-600 text-white"
                        : "bg-gray-100 text-slate-500",
                    )}
                    onClick={() => {
                      setActiveTab(tab as "respondent" | "petitioner");
                      setCurrentPage(1);
                    }}
                  >
                    As {tab.charAt(0).toUpperCase() + tab.slice(1)}{" "}
                    {caseCount > 0 && (
                      <span
                        className={`px-2 border rounded-lg ${
                          activeTab === tab
                            ? "bg-blue-600 border-[#ffffff70]"
                            : "bg-gray-200 border-gray-300"
                        } !text-sm`}
                      >
                        {caseCount}
                      </span>
                    )}
                  </button>
                );
              })}
            </div>
            {renderTable(value as CourtData)}
          </Accordion>
        );
      })}
    </div>
  );
};
