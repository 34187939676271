import { useToggle } from "@app/hooks/useToggle";
import { UploadInvoiceModal } from "@app/components/tasks/pending";
import { useMemo, useState, useEffect } from "react";
import { useGetAllScrapingTasksGroupByProjectsQuery } from "@app/store/api/qaTasksApi/qaTasksApi";
import { ProjectInfo } from "./UploadInvoiceModal";
import toast from "react-hot-toast";
import { useTriggerFormNameScrapingMutation } from "@app/store/api/scrapperApi";
import {
  ScrapingDataSource,
  ScrapeTask,
  ScrapingStatus,
} from "@app/store/api/qaTasksApi/types";
import { ChangeStatusModal } from "./ChangeStatusModal";
import { PageLoader } from "@components/loader";
import { NotFound } from "@components/common/NotFound";
import { GroupedTaskAccordion } from "./GroupedTaskAccordion";
import { useTriggerScrapingTasksMutation } from "@app/store/api/gengisScraperApi";
import { ScrapingTaskType } from "@app/store/api/gengisScraperApi/types";
import { Button } from "@components/common/Button";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { Link } from "react-router-dom";
import { ToolTip } from "@components/common/table/ToolTip";
import { RenderDocumentCountAndStatus } from "./RenderDocumentCountAndStatus";
import { RenderScrapeButton } from "./RenderScrapeButton";
import { MetadataPopOver } from "./MetadataPopOver";
import { CheckIcon, AlertTriangleIcon, AlertTriangle } from "lucide-react";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { addDays, isAfter } from "date-fns";
import { MCAVersionEnum } from "@app/store/api/documentsApi/types";
import {
  useLazyGetMc2DocumentQuery,
  useLazyGetMc3DocumentQuery,
} from "@app/store/api/mcDocuments";
import { useGroupedTasks } from "@app/hooks/useGroupedTasks";
export const SCRAPING_POLLING_INTERVAL = 15000;

const LIMIT = 5;

export interface HandleScrapingActionProps {
  company_name: string;
  project_id?: string;
  task_id: string;
  identifier_value: string;
  task_nature_name: ScrapingDataSource;
}

export const Scraping = () => {
  const [skip, setSkip] = useState(0);
  const { activeCountry, activeCorporate } = useActiveCompany();
  const [typeOfChangeStatus, setTypeOfChangeStatus] = useState<ScrapingStatus>(
    ScrapingStatus.SCRAPING_FORCE_COMPLETED,
  );

  const [
    getMc2Document,
    { isFetching: isMc2Loading, originalArgs: mc2Payload },
  ] = useLazyGetMc2DocumentQuery();
  const [
    getMc3Document,
    { isFetching: isMc3Loading, originalArgs: mc3Payload },
  ] = useLazyGetMc3DocumentQuery();

  const [scrapingStatus, setScrapingStatus] = useState<
    {
      projectIndex: number;
      taskIndex: number;
      total: number;
      completed: number;
    }[]
  >([]);

  const [
    triggerScrapingTasks,
    { isLoading: isTriggerScrapingTasksLoading, originalArgs },
  ] = useTriggerScrapingTasksMutation();

  const [startV2FormNameScraping] = useTriggerFormNameScrapingMutation();

  const {
    data,
    isLoading: isAllScrapingTasksLoading,
    startedTimeStamp,
  } = useGetAllScrapingTasksGroupByProjectsQuery(
    {
      status: [
        ScrapingStatus.SCRAPING_IN_PROGRESS,
        ScrapingStatus.SCRAPING_INITIALISED,
        ScrapingStatus.SCRAPING_FAILED,
        ScrapingStatus.SCRAPING_FORCE_FAILED,
        ScrapingStatus.SCRAPING_NOT_STARTED,
        ScrapingStatus.SCRAPING_COMPLETED_WITH_ERRORS,
      ],
      skip,
      limit: LIMIT,
    },
    { pollingInterval: SCRAPING_POLLING_INTERVAL },
  );

  const [isModalActive, toggleModal] = useToggle();
  const [isChangeStatusModalActive, toggleChangeStatusModal] = useToggle();
  const [activeData, setActiveData] = useState<ProjectInfo>({
    cinId: "",
    company: "",
    taskNature: ScrapingDataSource.SCRAPING_MCA_V2,
    task_id: "",
    project_id: "",
  });

  const handleScrapingAction = async (props: HandleScrapingActionProps) => {
    if (
      props.task_nature_name === ScrapingDataSource.SCRAPING_MCA_V2 ||
      props.task_nature_name === ScrapingDataSource.SCRAPING_MCA_V3
    ) {
      setActiveData({
        cinId: props.identifier_value,
        company: props.company_name.toUpperCase(),
        taskNature: props.task_nature_name,
        task_id: props.task_id,
        project_id: props.project_id,
      });
      toggleModal.on();
    } else {
      try {
        await triggerScrapingTasks({
          task_id: props.task_id,
          scraping_type: ScrapingTaskType.NORMAL,
        }).unwrap();
        toast.success("Scraping task started successfully");
      } catch {
        toast.error("Failed to start scraping task");
      }
    }
  };

  const getLoadingStatus = (task: ScrapeTask) => {
    switch (task.task_nature_name) {
      case ScrapingDataSource.SCRAPING_MCA_V2:
        return isMc2Loading && mc2Payload?.cin === task.identifier_value;
      case ScrapingDataSource.SCRAPING_MCA_V3:
        return isMc3Loading && mc3Payload?.cin === task.identifier_value;
      case ScrapingDataSource.SCRAPING_INDIAN_CREDIT_RATINGS:
        return (
          isTriggerScrapingTasksLoading && originalArgs?.task_id === task.id
        );
      case ScrapingDataSource.SCRAPING_EPFO:
        return (
          isTriggerScrapingTasksLoading && originalArgs?.task_id === task.id
        );
      case ScrapingDataSource.SCRAPING_LEGALS:
        return (
          isTriggerScrapingTasksLoading && originalArgs?.task_id === task.id
        );
      default:
        return false;
    }
  };

  const getStatus = async (
    cin: string,
    type:
      | ScrapingDataSource.SCRAPING_MCA_V2
      | ScrapingDataSource.SCRAPING_MCA_V3,
    projectIndex: number,
    taskIndex: number,
  ) => {
    const fetchFunction =
      type === ScrapingDataSource.SCRAPING_MCA_V2
        ? getMc2Document
        : getMc3Document;
    const loadingMessage = `Fetching ${
      type === ScrapingDataSource.SCRAPING_MCA_V2 ? "MCA V2" : "MCA V3"
    } document...`;

    await toast.promise(
      fetchFunction({ cin, sort: "asc" }).unwrap(),
      {
        loading: loadingMessage,
        success: (res) => {
          const result = res.data.data.document_list;
          const activeTaskIndex = scrapingStatus.findIndex(
            (el) =>
              el.projectIndex === projectIndex && el.taskIndex === taskIndex,
          );

          if (activeTaskIndex !== -1) {
            setScrapingStatus((prev) => {
              const newStatus = [...prev];
              newStatus[activeTaskIndex] = {
                ...newStatus[activeTaskIndex],
                total: result.length,
                completed: result.filter((el) => el.status === true).length,
              };
              return newStatus;
            });
          }

          const isMoreThan15DaysOld = isAfter(
            new Date(),
            addDays(new Date(res.data.metadata.last_updated), 15),
          );

          if (isMoreThan15DaysOld) {
            if (res.data.metadata.source === MCAVersionEnum.MCA_V2) {
              startV2FormNameScraping(cin).unwrap();
            }
            toast(
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 font-medium">
                  <AlertTriangle className="h-5 w-5 text-yellow-600" />
                  <span>Document list is more than 15 days old</span>
                </div>

                <p className="text-sm text-gray-700">
                  We have started to get new documents from{" "}
                  <span className="font-medium">
                    {res.data.metadata.source === "mca_v2"
                      ? "MCA V2"
                      : "MCA V3"}
                  </span>
                </p>

                <p className="text-sm text-gray-600">
                  Please check again after some time or check the docs page for
                  more info
                </p>
              </div>,
              {
                duration: 10000,
                className: "bg-yellow-50 border-2 border-orange-500",
                style: {
                  minWidth: "380px",
                  padding: "16px",
                },
              },
            );

            return "Warning: Old Document";
          }

          return "Success";
        },
        error: `Failed to fetch ${
          type === ScrapingDataSource.SCRAPING_MCA_V2 ? "MCA V2" : "MCA V3"
        } document`,
      },
      {
        success: {
          duration: 3000,
        },
      },
    );
  };

  const { dataGroupedByProject } = useGroupedTasks<ScrapeTask>(
    data?.response_data || {},
  );

  const initialScrapingStatus = useMemo(
    () =>
      dataGroupedByProject.flatMap((group, projectIndex) =>
        group.tasks.map((_, taskIndex) => ({
          projectIndex,
          taskIndex,
          total: 0,
          completed: 0,
        })),
      ),
    [dataGroupedByProject],
  );

  useEffect(() => {
    setScrapingStatus(initialScrapingStatus);
  }, []);

  if (isAllScrapingTasksLoading) return <PageLoader />;

  return (
    <div className="mt-6">
      <UploadInvoiceModal
        data={activeData}
        isOpen={isModalActive}
        onClose={toggleModal.off}
        scrapingType={ScrapingTaskType.NORMAL}
      />
      <ChangeStatusModal
        isOpen={isChangeStatusModalActive}
        onClose={toggleChangeStatusModal.off}
        projectInfo={activeData}
        scrapingType={ScrapingTaskType.NORMAL}
        status={typeOfChangeStatus}
      />
      {data && Object.keys(data.response_data).length === 0 && (
        <NotFound
          message="No Scraping Tasks Found"
          className="h-[calc(100vh-200px)]"
        />
      )}
      {data && Object.keys(data.response_data).length > 0 && (
        <>
          <GroupedTaskAccordion<ScrapeTask>
            headers={[
              { name: "Task Nature", value: "projectName" },
              { name: "Status", value: "status" },
              { name: "Company CIN ID", value: "cinId" },
              { name: "Count", value: "count" },
              { name: "Account In Use", value: "accountName" },
              { name: "Action", value: "btn" },
              { name: "Force Complete", value: "forceComplete" },
              { name: "", value: "forceFailed" },
            ]}
            startedTimeStamp={startedTimeStamp || 0}
            data={dataGroupedByProject}
            renderContent={(task, projectIndex, taskIndex) => {
              return {
                cinId: (
                  <Link
                    to={`/labelling/${activeCountry}/${activeCorporate}/${task.identifier_value}/documents`}
                  >
                    <ToolTip value={task.identifier_value} />
                  </Link>
                ),
                projectName: (
                  <ToolTip
                    value={removeUnderscores(task.task_nature_name)}
                    trimValue={35}
                  />
                ),
                count: (
                  <RenderDocumentCountAndStatus
                    task={{
                      task_nature_name: task.task_nature_name,
                      identifier_value: task.identifier_value,
                    }}
                    projectIndex={projectIndex}
                    taskIndex={taskIndex}
                    scrapingStatus={scrapingStatus}
                    getLoadingStatus={getLoadingStatus}
                    getStatus={getStatus}
                  />
                ),
                status: (
                  <MetadataPopOver
                    metadata={task.scraping_metadata}
                    status={task.status}
                    showWhen={Object.values(ScrapingStatus)}
                  />
                ),
                btn: (
                  <RenderScrapeButton
                    task={task}
                    isLoading={getLoadingStatus(task)}
                    handleScrapingAction={() => {
                      handleScrapingAction({
                        company_name: task.company_name,
                        task_id: task.id,
                        identifier_value: task.identifier_value,
                        task_nature_name: task.task_nature_name,
                      });
                    }}
                  />
                ),
                forceComplete: (
                  <Button
                    leftIcon={CheckIcon}
                    variant="destructive"
                    className="px-1"
                    onClick={() => {
                      setTypeOfChangeStatus(
                        ScrapingStatus.SCRAPING_FORCE_COMPLETED,
                      );
                      setActiveData({
                        cinId: task.identifier_value,
                        company: task.company_name.toUpperCase(),
                        taskNature: task.task_nature_name,
                        task_id: task.id,
                        project_id: task.project_id,
                      });
                      toggleChangeStatusModal.on();
                    }}
                  >
                    Force Complete
                  </Button>
                ),
                accountName: task.scraping_metadata?.account_name ? (
                  <span className="text-gray-700 text-center font-medium">
                    {task.scraping_metadata.account_name.toUpperCase()}
                  </span>
                ) : (
                  <span className="text-gray-700 text-center font-medium">
                    N/A
                  </span>
                ),
                forceFailed: (
                  <Button
                    className="bg-red-500 hover:bg-red-600 text-white"
                    leftIcon={AlertTriangleIcon}
                    onClick={() => {
                      setTypeOfChangeStatus(
                        ScrapingStatus.SCRAPING_FORCE_FAILED,
                      );
                      setActiveData({
                        cinId: task.identifier_value,
                        company: task.company_name.toUpperCase(),
                        taskNature: task.task_nature_name,
                        task_id: task.id,
                        project_id: task.project_id,
                      });
                      toggleChangeStatusModal.on();
                    }}
                  >
                    Force Fail
                  </Button>
                ),
              };
            }}
            skip={skip}
            setSkip={setSkip}
            limit={LIMIT}
            total={data.total}
          />
        </>
      )}
    </div>
  );
};
