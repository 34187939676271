import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import type { QaDbApiResponse } from "@app/types";
import { CompanyList, CompanyTeam, CustomerInfo } from "./types";

const customerCompaniesApi = createApi({
  reducerPath: "customerCompaniesApi",
  tagTypes: ["all-customer-companies"],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    listCustomerCompanies: builder.query<QaDbApiResponse<CompanyList[]>, void>({
      query: () => ({
        url: "/customer-company/get-all?limit=100&skip=0",
        method: "GET",
      }),
      providesTags: ["all-customer-companies"],
    }),
    getCustomerCompanyDetails: builder.mutation<
      QaDbApiResponse<{
        company: CompanyList;
        company_teams: CompanyTeam[];
      }>,
      string
    >({
      query: (id) => ({
        url: `/customer-company/get-all-teams-by-company-id?customer_company_id=${id}`,
        method: "GET",
      }),
    }),
    getCustomerInfo: builder.query<QaDbApiResponse<CustomerInfo>, string>({
      query: (userId) => ({
        url: "/user-management/get",
        method: "GET",
        params: {
          customer_user_id: userId,
        },
      }),
    }),
    getCustomerCompanyByName: builder.query<
      QaDbApiResponse<CompanyList[]>,
      string
    >({
      query: (name) => ({
        url: "/customer-company/get-all",
        method: "GET",
        params: {
          name,
        },
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useListCustomerCompaniesQuery,
  useGetCustomerCompanyDetailsMutation,
  useGetCustomerInfoQuery,
  useLazyGetCustomerCompanyByNameQuery,
} = customerCompaniesApi;

export default customerCompaniesApi;
