import "./styles/tailwind.css";
import "./styles/index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { AuthContext } from "@app/context/AuthContext";
import { routes } from "@app/routes";
import { ToasterWithMax } from "@app/components/utils/ToasterWithMax";
import { store } from "@app/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { ENV } from "@app/config/ENV";
import ErrorBoundary from "./pages/ErrorBoundary";

Sentry.init({
  dsn: ENV.sentry_dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: ENV.environment,
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const isNonCorpReleaseDone = false;

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    Component() {
      return (
        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
          <Provider store={store}>
            <AuthContext>
              <ToasterWithMax
                position="bottom-center"
                reverseOrder={false}
                max={1}
              />
              <Outlet />
            </AuthContext>
          </Provider>
        </Sentry.ErrorBoundary>
      );
    },
    children: routes,
  },
]);

// biome-ignore lint/style/noNonNullAssertion:This will never be undefined
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
