import { FC, memo, useCallback, useEffect, useMemo } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import classNames from "classnames";
import { LabellingLinks } from "./components/dashboard";
import { ProjectsInfoDrawer } from "@components/admin/projects";
import { useDispatch } from "react-redux";
import {
  CorporateType,
  setCorporateType,
  setCurrentCompany,
} from "@app/store/slices/companySlice";
import { getCompanyType } from "@app/utils/getCompanyType";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { toast } from "react-hot-toast";
import { Icon } from "@components/common/Icon";

const DashBoardLayoutBase: FC = () => {
  const { cin, corporateType } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { activeTypeOfCompany, activeCorporate, activeCountry } =
    useActiveCompany();

  const navLinkClasses = useCallback(
    (isActive: boolean) =>
      classNames("block py-2 px-4 rounded hover:bg-blue-700", {
        "bg-blue-700": isActive,
      }),
    [],
  );

  const handleGoBack = useCallback(() => navigate(-1), [navigate]);

  const activePathHash = useMemo(() => {
    return location?.hash.replace("#", "");
  }, [location?.hash]);

  useEffect(() => {
    if (corporateType) {
      if (
        Object.values(CorporateType).includes(corporateType as CorporateType)
      ) {
        dispatch(setCorporateType(corporateType as CorporateType));
      } else {
        navigate("/labelling");
        toast.error("Invalid corporate type");
      }
    }
    if (cin) {
      dispatch(setCurrentCompany(getCompanyType(cin)));
    }
  }, [corporateType, cin]);

  return (
    <div className="flex h-screen">
      <ProjectsInfoDrawer />
      <div className="bg-blue-800 text-white w-60 h-full p-4 fixed flex flex-col">
        <h2
          className="text-2xl font-bold mb-6 hover:cursor-pointer flex justify-between"
          onClick={() => navigate("/labelling")}
        >
          <span>Credhive</span>
          <img
            src="/logo.svg"
            alt="Logo"
            className="rounded-full w-5 items-center"
          />
        </h2>
        <button
          type="button"
          onClick={handleGoBack}
          className="flex items-center mb-6 text-white hover:text-gray-300 gap-3"
        >
          <ArrowLeft size="20" />
          Go Back
        </button>
        <h2 className="text-xl font-bold mb-6">Company Details</h2>
        <nav className="flex-grow overflow-y-auto">
          <ul>
            {LabellingLinks.map(
              ({ name, to, children, supportedIdentifiers, icon }) => {
                if (children) {
                  return (
                    <li key={name}>
                      <details className="group">
                        <summary
                          className={classNames(
                            navLinkClasses(false),
                            "cursor-pointer marker:content-none flex justify-between items-center",
                          )}
                        >
                          <span>{name}</span>
                          <Icon
                            name="ChevronDown"
                            size={15}
                            className="self-center transition-transform duration-200 group-open:rotate-180"
                          />
                        </summary>

                        <ul className="pl-4 mt-2 flex flex-col gap-2">
                          {children.map((child) => {
                            const isSupported =
                              child.supportedIdentifiers?.includes(
                                activeTypeOfCompany,
                              );
                            if (!isSupported) return null;
                            return (
                              <li key={child.name}>
                                <NavLink
                                  to={child.to(
                                    cin as string,
                                    activeCountry,
                                    activeCorporate,
                                  )}
                                  className={() =>
                                    navLinkClasses(
                                      activePathHash ===
                                        child
                                          .to(
                                            cin as string,
                                            activeCountry,
                                            activeCorporate,
                                          )
                                          .split("#")[1],
                                    )
                                  }
                                >
                                  <span>{child.name}</span>
                                  {child.icon && (
                                    <Icon
                                      name={child.icon}
                                      size={15}
                                      className="self-center"
                                    />
                                  )}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </details>
                    </li>
                  );
                }

                const isSupported =
                  supportedIdentifiers?.includes(activeTypeOfCompany);
                if (!isSupported) return null;

                return (
                  <li key={name}>
                    <NavLink
                      to={to(cin as string, activeCountry, activeCorporate)}
                      className={({ isActive }) => navLinkClasses(isActive)}
                    >
                      <span>{name}</span>
                      {icon && (
                        <Icon name={icon} size={15} className="self-center" />
                      )}
                    </NavLink>
                  </li>
                );
              },
            )}
          </ul>
        </nav>
      </div>
      <div className="ml-60 flex-1 p-6 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export const DashBoardLayout = memo(DashBoardLayoutBase);
