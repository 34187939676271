import { RootState } from "@app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveCountry,
  setCorporateType,
  setCurrentCompany,
  supportedCountry,
} from "@app/store/slices/companySlice";
import type {
  SupportedCountry,
  CorporateType,
} from "@app/store/slices/companySlice";
import { IdentifierTypeEnum } from "@app/types";

type UseActiveCountry = {
  changeCountry: (country: SupportedCountry) => void;
  changeCorporateType: (corporateType: CorporateType) => void;
  changeTypeOfCompany: (typeOfCompany: IdentifierTypeEnum) => void;
  activeCountry: SupportedCountry;
  activeCorporate: CorporateType;
  activeTypeOfCompany: IdentifierTypeEnum;
  supportedCountry: SupportedCountry[];
};

export const useActiveCompany = (): UseActiveCountry => {
  const dispatch = useDispatch();
  const activeCountry = useSelector(
    (activeSlices: RootState) => activeSlices.company.activeCountry,
  );

  const activeCorporate = useSelector(
    (activeSlices: RootState) => activeSlices.company.corporateType,
  );

  const activeTypeOfCompany = useSelector(
    (activeSlices: RootState) => activeSlices.company.typeOfCompany,
  );

  const changeCountry = (countryName: SupportedCountry) => {
    dispatch(setActiveCountry(countryName));
  };

  const changeCorporateType = (corporateType: CorporateType) => {
    setCorporateType(corporateType);
  };

  const changeTypeOfCompany = (typeOfCompany: IdentifierTypeEnum) => {
    dispatch(setCurrentCompany(typeOfCompany));
  };

  return {
    changeCountry,
    activeCountry,
    activeCorporate,
    activeTypeOfCompany,
    changeCorporateType,
    supportedCountry,
    changeTypeOfCompany,
  };
};
