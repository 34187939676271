import React, { useState, useMemo } from "react";
import { EPFOData, PaymentDetail } from "@app/store/api/compliancesApi/types";
import { Table } from "@components/common/table";
import { Accordion } from "@components/common/Accordion";
import { CompanyDetailsSection } from "./CompanyDetailsSection";
import { format } from "date-fns";
import { NotFound } from "@components/common/NotFound";
import { PageLoader } from "@components/loader/components/PageLoader";

interface EPFOProps {
  data?: EPFOData[];
  isReportFetching: boolean;
}

const headers = [
  { name: "TRRN", value: "trrn", classes: "p-4 text-sm" },
  { name: "Date of Credit", value: "date_of_credit", classes: "p-4 text-sm" },
  { name: "Amount", value: "amount", classes: "p-4 text-sm" },
  { name: "Wage Month", value: "wage_month", classes: "p-4 text-sm" },
  {
    name: "Number of Employees",
    value: "number_of_employee",
    classes: "p-4 text-sm",
  },
  { name: "ECR", value: "ecr", classes: "p-4 text-sm" },
];

export const EPFO: React.FC<EPFOProps> = ({ data, isReportFetching }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const epfoData = data || [];

  const createRows = useMemo(
    () => (paymentDetails: PaymentDetail[]) => {
      return paymentDetails.map((item: PaymentDetail) => ({
        trrn: item.trrn,
        date_of_credit: format(new Date(item.date_of_credit), "dd MMM yyyy"),
        amount: item.amount,
        wage_month: item.wage_month,
        number_of_employee: item.number_of_employee,
        ecr: item.ecr,
      }));
    },
    [],
  );

  if (isReportFetching) {
    return <PageLoader />;
  }

  return (
    <div className="py-4 flex flex-col gap-4 border rounded-lg p-4">
      <div className="text-lg w-max text-heading font-semibold">
        EPFO Payment Details
      </div>
      {epfoData.length === 0 ? (
        <NotFound message="EPFO details not available for this company." />
      ) : (
        <Accordion
          title="EPFO Entries"
          defaultOpen={true}
          count={epfoData.length + 1}
        >
          {epfoData.map((epfo, index) => {
            const rows = createRows(epfo.payment_details);
            const status =
              epfo.company_details.working_status === "LIVE ESTABLISHMENT"
                ? "Active"
                : "Cancelled";
            const totalPages = Math.ceil(rows.length / itemsPerPage);
            const paginatedRows = rows.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage,
            );

            return (
              <Accordion
                key={index}
                title={"EPFO Entry"}
                status={status}
                count={epfo.payment_details.length}
              >
                <CompanyDetailsSection
                  details={epfo.company_details}
                  latestPayment={epfo.payment_details[0]}
                />
                <Table
                  headers={headers}
                  rows={paginatedRows}
                  tableDivClassname="w-full border border-gray-200 !p-0 rounded mt-4"
                  paginate
                  skip={(currentPage - 1) * itemsPerPage}
                  currentLimit={itemsPerPage}
                  maxSize={rows.length}
                  nextHandler={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  prevHandler={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                />
              </Accordion>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};
