import React from "react";
import { Button } from "@app/components/common/Button";
import { Target, Upload } from "lucide-react";
import { ScrapeTask } from "@app/store/api/qaTasksApi/types";

type LucideIcon = typeof Target | typeof Upload;

interface ButtonConfig {
  icon: LucideIcon;
  text: string;
}

interface RenderScrapeButtonProps {
  task: ScrapeTask;
  isLoading: boolean;
  handleScrapingAction: (task: ScrapeTask) => void;
}

const buttonConfig: Record<string, ButtonConfig> = {
  SCRAPING_EPFO: {
    icon: Target,
    text: "Trigger",
  },
  SCRAPING_MCA_V2: {
    icon: Upload,
    text: "Upload",
  },
  SCRAPING_MCA_V3: {
    icon: Upload,
    text: "Upload",
  },
  SCRAPING_INDIAN_CREDIT_RATINGS: {
    icon: Target,
    text: "Trigger",
  },
  SCRAPING_LEGALS: {
    icon: Target,
    text: "Trigger",
  },
};

export const RenderScrapeButton: React.FC<RenderScrapeButtonProps> = ({
  task,
  isLoading,
  handleScrapingAction,
}) => {
  const config =
    buttonConfig[task.task_nature_name] ||
    buttonConfig.SCRAPING_INDIAN_CREDIT_RATINGS;

  return (
    <Button
      variant="primary"
      disabled={isLoading}
      onClick={() => handleScrapingAction(task)}
      leftIcon={config.icon}
      className="px-2"
      isLoading={isLoading}
    >
      <span>{config.text}</span>
    </Button>
  );
};
