import { useFormContext, type RegisterOptions } from "react-hook-form";
import { forwardRef, TextareaHTMLAttributes } from "react";
import classNames from "classnames";

interface BaseInputProps {
  name: string;
  label: string;
  validation?: RegisterOptions;
  wrapperClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
}

type TextAreaProps = BaseInputProps &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, keyof BaseInputProps>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name,
      label,
      validation,
      className = "",
      wrapperClassName = "",
      labelClassName = "",
      errorClassName = "",
      rows = 3,
      ...textareaProps
    },
    ref,
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const error = errors[name];

    const { ref: registerRef, ...registerRest } = register(name, validation);

    const wrapperClasses = classNames("mb-4", wrapperClassName);

    const labelClasses = classNames(
      "block",
      "text-sm",
      "font-medium",
      "text-gray-700",
      labelClassName,
    );

    const textareaClasses = classNames(
      "w-full",
      "py-2",
      "px-2",
      "mt-2",
      "border",
      "rounded-lg",
      "focus:outline-none",
      {
        "border-red-500": error,
        "ring-blue-500": !error,
        "focus:ring-2": !error,
      },
      className,
    );

    const errorClasses = classNames(
      "text-red-500",
      "text-sm",
      "mt-2",
      errorClassName,
    );

    return (
      <div className={wrapperClasses}>
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>

        <textarea
          id={name}
          rows={rows}
          className={textareaClasses}
          ref={(element) => {
            registerRef(element);
            if (typeof ref === "function") {
              ref(element);
            } else if (ref) {
              ref.current = element;
            }
          }}
          {...registerRest}
          {...textareaProps}
        />

        {error && (
          <span className={errorClasses}>{error.message as string}</span>
        )}
      </div>
    );
  },
);

// Add display name for better debugging
TextArea.displayName = "TextArea";
