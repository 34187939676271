import {
  useGetMc3DocumentQuery,
  useLazyGetMc3DocumentQuery,
} from "@app/store/api/mcDocuments";
import { UploadModal } from "@app/components/labelling/documents";
import { useToggle } from "@app/hooks/useToggle";
import { Icon, StatusBox } from "@components/common";
import { Table, ToolTip } from "@components/common/table";
import { useEffect, useMemo, useState } from "react";
import { PageLoader } from "@components/loader";
import { useParams } from "react-router-dom";
import { headers } from "./constants";
import { useCreatePresignedUrlMutation } from "@app/store/api/documentsApi";
import { useTriggerMcaV3ScrapeMutation } from "@app/store/api/scrapperApi";
import toast from "react-hot-toast";
import { eFormDocument } from "@app/types";
import { Button } from "@components/common/Button";
import { LastUpdatedTooltip } from "./LastUpdatedTooltip";
import { PollingPreview } from "@components/common/PollingPreview";

const POLLING_INTERVAL = 15000;

export const McaV3Table = () => {
  const [activeForm, setActiveForm] = useState<eFormDocument | null>(null);
  const [shouldStopPolling, toggleStopPolling] = useToggle(false);
  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [isOpen, toggleOpen] = useToggle();
  const params = useParams();

  const [triggerMcaV3Scrape, { isLoading: isScrapingLoading }] =
    useTriggerMcaV3ScrapeMutation();

  const { cin } = params;
  const {
    data: apiData,
    isLoading,
    isError,
    startedTimeStamp,
  } = useGetMc3DocumentQuery(
    {
      cin: cin as string,
      sort: "asc",
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: shouldStopPolling,
    },
  );

  const triggerFormNameScraping = async () => {
    try {
      if (!cin) return;
      await triggerMcaV3Scrape(cin).unwrap();
      toast.success("Scraping File Names Started");
    } catch {
      toast.error("Failed to start the Scraping");
    }
  };

  const [getMc3Document, { isFetching: isMc3ScrapeLoading }] =
    useLazyGetMc3DocumentQuery();

  const successCount = useMemo(() => {
    if (!apiData?.data?.data?.document_list) return 0;

    return apiData.data.data.document_list.reduce((count, el) => {
      return el.status ? count + 1 : count;
    }, 0);
  }, [apiData]);

  const sizeOfData = useMemo(() => {
    return apiData?.data.data.document_list.length;
  }, [apiData]);

  useEffect(() => {
    if (successCount === sizeOfData) {
      toggleStopPolling.on();
    }
  }, [successCount, sizeOfData]);

  const handleOpenFile = async (el: eFormDocument) => {
    const constructedUrl = `companies/${cin}/deep_data/mca_v3/${el.category}/${
      el.file_name.toLowerCase().includes(".pdf")
        ? el.file_name
        : `${el.file_name}.pdf`
    }`;
    toast.promise(
      createPresignedUrl({
        s3_url: constructedUrl,
      }).unwrap(),
      {
        loading: "Opening file...",
        success: (url) => {
          window.open(url.pre_signed_url, "_blank");
          return "File opened successfully";
        },
        error: () => {
          return "Failed to open file";
        },
      },
    );
  };

  const data = useMemo(
    () =>
      apiData?.data.data.document_list.map((el) => {
        return {
          category: el.category,
          status: (
            <StatusBox
              statusText={el.status ? "Done" : "Not Found"}
              color={el.status ? "bg-green-500" : "bg-orange-500"}
            />
          ),
          filing: el.date_of_filing,
          year: el.year,
          fileName: (
            <ToolTip value={el.file_name} handler={() => handleOpenFile(el)} />
          ),
          action: (
            <button
              type="button"
              className="p-2 text-blue-600"
              onClick={() => {
                setActiveForm(el);
                toggleOpen.on();
              }}
            >
              <Icon name="Upload" size={20} color="lightgray" />
            </button>
          ),
        };
      }),
    [apiData],
  );

  const startFormNameScraping = async () => {
    try {
      await getMc3Document({
        cin: cin as string,
        sort: "asc",
        force_fetch: true,
      }).unwrap();
      toast.success("Scraping started successfully");
    } catch (error) {
      toast.error("Failed to start scraping");
    }
  };

  if (isLoading) {
    return (
      <PageLoader
        className="w-full h-full"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 180px)" }}
      />
    );
  }

  if (isError || !apiData) {
    return (
      <div
        className="flex w-full border mt-5 rounded-lg justify-center items-center"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 180px)" }}
      >
        <div className="flex flex-col justify-center items-center gap-3">
          <h2 className="text-xl text-red-600">No Documents Found To Scrape</h2>
          <Button
            onClick={triggerFormNameScraping}
            isLoading={isScrapingLoading}
          >
            Scrape Documents Name
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-2">
      <UploadModal
        isOpen={isOpen}
        onClose={toggleOpen.off}
        documentName={activeForm?.file_name as string}
        category={activeForm?.category as string}
        version="v3"
      />
      <div className="flex justify-between w-full gap-2">
        <div>
          <div className="flex items-center gap-2">
            <div>
              <LastUpdatedTooltip
                lastUpdated={apiData?.data.metadata.last_updated as string}
              />
            </div>

            <Button
              onClick={startFormNameScraping}
              className="h-full"
              isLoading={isMc3ScrapeLoading}
            >
              Refresh Documents Name
            </Button>
            {startedTimeStamp && !shouldStopPolling && (
              <PollingPreview
                lastFetchedAt={startedTimeStamp}
                pollingInterval={POLLING_INTERVAL}
              />
            )}
          </div>
        </div>
        <div className="self-end border px-3 py-2 rounded-md bg-green-100 shadow-md">
          <h4 className="font-semibold text-green-700">
            Success : {successCount} / {sizeOfData}
          </h4>
        </div>
      </div>
      <div className="mt-2">
        <Table headers={headers} rows={data || []} className="max-h-[800px]" />
      </div>
    </div>
  );
};
