import * as Popover from "@radix-ui/react-popover";
import { RenderScrapingStatus } from "./RenderScrapetasks";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import {
  AdhocTaskStatus,
  ScrapingStatus,
} from "@app/store/api/qaTasksApi/types";
import { useMemo } from "react";
import { RenderAdhocStatus } from "@components/admin/company-requests/components/RenderAdhocStatus";
import {
  AdhocMetadata,
  ScrapingMetadata,
} from "@app/store/api/qaTasksApi/types";

type TaskStatus = ScrapingStatus | AdhocTaskStatus;

type Metadata = AdhocMetadata | ScrapingMetadata;

interface MetadataPopOverProps<T extends Metadata, S extends TaskStatus> {
  metadata: T;
  status: S;
  showWhen?: S[];
}
export const MetadataPopOver = <T extends Metadata, S extends TaskStatus>({
  metadata,
  status,
  showWhen,
}: MetadataPopOverProps<T, S>) => {
  const canShowMetadata = useMemo(() => {
    return showWhen ? showWhen.includes(status) : false;
  }, [status, showWhen]);

  const isAdhoc = useMemo(() => status.startsWith("ADHOC"), [status]);

  return (
    <Popover.Root>
      <Popover.Trigger disabled={!canShowMetadata}>
        {isAdhoc ? (
          <RenderAdhocStatus status={status as AdhocTaskStatus} />
        ) : (
          <RenderScrapingStatus status={status} />
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content>
          <div className="bg-white p-4 rounded-lg w-[500px] min-h-[100px] overflow-y-auto text-wrap shadow-lg">
            <ul className="list-disc pl-5 space-y-2">
              {metadata ? (
                Object.entries(metadata).map(([key, value]) => (
                  <li key={key} className="flex flex-col gap-1">
                    <span className="font-semibold text-gray-700 uppercase">
                      {removeUnderscores(key)}:
                    </span>
                    <span className="text-gray-600">{String(value)}</span>
                  </li>
                ))
              ) : (
                <li className="flex flex-col gap-1 items-center justify-center">
                  <span className="font-semibold text-gray-700 uppercase mt-5">
                    No Metadata Available
                  </span>
                </li>
              )}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
