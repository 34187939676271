import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { credhiveDataApiHandler } from "@app/config/apiHandler";
import { EntityApiResponse, UpdatePan, PanToName, DinResponse } from "./types";
import { QaDbApiResponse } from "@app/types";

interface PanResponse {
  input_name: string;
  matched_name: string;
  similarity_score: number;
  pan: string;
  gst_numbers: string[];
}

const entityApi = createApi({
  reducerPath: "entityApi",
  baseQuery: axiosBaseQuery(credhiveDataApiHandler),
  tagTypes: ["no-pan-company"],
  endpoints: (builder) => ({
    getEntityWIthOutPan: builder.query<
      QaDbApiResponse<EntityApiResponse[]>,
      void
    >({
      query: () => ({
        url: `/entity/identifier/get-all-entities-without-pan`,
        method: "GET",
      }),
      providesTags: ["no-pan-company"],
    }),
    updateEntity: builder.mutation<void, UpdatePan[]>({
      query: (payload) => ({
        url: "/entity/identifier/update",
        data: payload,
        method: "patch",
      }),
    }),
    getDirectorToPanInfo: builder.query<QaDbApiResponse<PanToName>, string>({
      query: (pan) => ({
        url: "/extras/director-pan-to-info",
        params: {
          pan,
        },
      }),
    }),
    getPANByIdentifier: builder.query<QaDbApiResponse<PanResponse>, string>({
      query: (identifier) => ({
        url: "extras/find-pan-by-identifier",
        params: {
          identifier,
        },
      }),
    }),
    getDirectorInfoWithDin: builder.query<QaDbApiResponse<DinResponse>, string>(
      {
        query: (din) => ({
          url: "/director/get-by-din",
          params: {
            din,
          },
        }),
      },
    ),
  }),
  refetchOnMountOrArgChange: true,
});

const {
  useLazyGetPANByIdentifierQuery,
  useGetEntityWIthOutPanQuery,
  useUpdateEntityMutation,
  useLazyGetDirectorToPanInfoQuery,
  useLazyGetDirectorInfoWithDinQuery,
} = entityApi;

export {
  useLazyGetPANByIdentifierQuery,
  useGetEntityWIthOutPanQuery,
  useUpdateEntityMutation,
  useLazyGetDirectorToPanInfoQuery,
  useLazyGetDirectorInfoWithDinQuery,
};

export default entityApi;
