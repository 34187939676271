import { icons } from "lucide-react";
import classNames from "classnames";

interface CommonIcon {
  name: keyof typeof icons;
  color?: string;
  size?: number;
  className?: string;
  onClick?: () => void;
}

export const Icon = ({ name, color, size, className, onClick }: CommonIcon) => {
  const LucideIcon = icons[name];
  const iconClasses = classNames(className);

  return (
    <LucideIcon
      color={color}
      size={size}
      className={iconClasses}
      onClick={onClick}
    />
  );
};
