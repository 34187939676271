import * as Popover from "@radix-ui/react-popover";

import { Icon } from "@components/common";
import { DropDown, Option } from "@components/common/dropdown";
import { createPortal } from "react-dom";

interface SortByPopoverProps {
  sortBy: Option<string>;
  orderBy: Option<string>;
  setSortBy: (option: Option<string>) => void;
  setOrderBy: (option: Option<string>) => void;
}

export const SortByPopover: React.FC<SortByPopoverProps> = ({
  sortBy,
  orderBy,
  setSortBy,
  setOrderBy,
}) => {
  return (
    <div className="flex justify-end mb-4">
      <Popover.Root>
        <Popover.Trigger className="flex items-center gap-2 border px-3 rounded-md py-2">
          <Icon name="ArrowDownUp" size={16} /> <span>Sort</span>
        </Popover.Trigger>
        {createPortal(
          <Popover.Content className="bg-white shadow-xl rounded-lg p-4 mt-2 mr-8 min-w-[250px]">
            <div className="mb-4">
              <label
                htmlFor="sortBy"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Sort By
              </label>
              <DropDown
                id="sortBy"
                options={[
                  { label: "Created At", value: "created_at" },
                  { label: "Updated At", value: "updated_at" },
                ]}
                onChange={(option) => {
                  setSortBy(option);
                }}
                value={sortBy}
              />
            </div>
            <div>
              <label
                htmlFor="orderBy"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Order By
              </label>
              <DropDown
                id="orderBy"
                options={[
                  { label: "Ascending", value: "asc" },
                  { label: "Descending", value: "desc" },
                ]}
                value={orderBy}
                onChange={(option) => {
                  setOrderBy(option);
                }}
              />
            </div>
          </Popover.Content>,
          document.body,
        )}
      </Popover.Root>
    </div>
  );
};
