import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { QaDbApiResponse } from "@app/types";
import { parserApiHandler } from "@app/config/apiHandler";
import {
  ShareHoldingMetaData,
  CommonShareHoldingGetPayload,
  ShareholdingOverview,
  UpdateShareHoldingOverView,
  ShareHoldingStructure,
  ShareholdingStructureResponse,
  UpdateShareholdingStructure,
  DirectorStructure,
  UpdateShareHoldingInfo,
  DirectorRemuneration,
  ShareHoldingKeys,
  JointVentureKeys,
  JointVenture,
  UpdateJointVenture,
  CommonShareHoldingCreatePayload,
  AddJointVenturePayload,
  UpdateDirectorRemunerations,
  OrNull,
} from "./types";

const shareHoldingsApi = createApi({
  reducerPath: "shareHoldingsApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: [
    "share-holding-metadata",
    "shareholding-overview",
    "shareholding-structure",
    "director-shareholding",
    "individual-shareholding",
    "company-share-holding",
    "other-shareholding",
    "company-share-holding",
    "director-remunerations",
    "joint-venture-shareholding",
  ],
  endpoints: (builder) => ({
    getShareHoldingMetaData: builder.query<
      QaDbApiResponse<ShareHoldingMetaData>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/metadata/shareholdings-metadata-for-cin",
        method: "GET",
        params: {
          cin: id,
        },
      }),
    }),
    getShareHoldingOverview: builder.query<
      QaDbApiResponse<ShareholdingOverview>,
      CommonShareHoldingGetPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/company-shareholding-overview",
        method: "GET",
        params: {
          ...payload,
        },
      }),
      providesTags: ["shareholding-overview"],
    }),
    updateShareHoldingOverview: builder.mutation<
      void,
      UpdateShareHoldingOverView
    >({
      query: (data) => {
        const { company_shareholding_overview_id, ...payload } = data;
        return {
          url: "/editing-platform-data/shareholder-editing/company-shareholding-overview",
          method: "PUT",
          data: payload,
          params: {
            company_shareholding_overview_id:
              data.company_shareholding_overview_id,
          },
        };
      },
      invalidatesTags: ["shareholding-overview"],
    }),
    getShareHoldingStructure: builder.query<
      QaDbApiResponse<ShareholdingStructureResponse>,
      ShareHoldingStructure
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/shareholding-structure",
        method: "GET",
        params: {
          ...payload,
        },
      }),
      providesTags: ["shareholding-structure"],
    }),
    updateShareHoldingStructure: builder.mutation<
      void,
      UpdateShareholdingStructure
    >({
      query: (data) => {
        const { shareholding_structure_id, ...payload } = data;
        return {
          url: "/editing-platform-data/shareholder-editing/shareholding-structure",
          method: "PUT",
          data: payload,
          params: {
            shareholding_structure_id,
          },
        };
      },
      invalidatesTags: ["shareholding-structure"],
    }),
    getShareHoldingInfo: builder.query<
      QaDbApiResponse<DirectorStructure[]>,
      CommonShareHoldingGetPayload & {
        entry_type: ShareHoldingKeys;
      }
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/director-individual-company-shareholding",
        method: "GET",
        params: {
          ...payload,
          entry_type: payload.entry_type,
        },
      }),
      providesTags: ["director-shareholding"],
    }),
    updateShareHoldingInfo: builder.mutation<void, UpdateShareHoldingInfo>({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/director-individual-company-shareholding",
        method: "PUT",
        data: {
          ...payload,
        },
        params: {
          director_individual_company_shareholding_id:
            payload.director_individual_company_shareholding_id,
        },
      }),
      invalidatesTags: [
        "director-shareholding",
        "individual-shareholding",
        "company-share-holding",
        "other-shareholding",
        "company-share-holding",
      ],
    }),
    addDirectorIndividualShareHolding: builder.mutation<
      void,
      DirectorStructure & CommonShareHoldingCreatePayload
    >({
      query: (payload) => {
        return {
          url: "/editing-platform-data/shareholder-editing/director-individual-company-shareholding",
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: [
        "director-shareholding",
        "individual-shareholding",
        "company-share-holding",
        "other-shareholding",
        "company-share-holding",
      ],
    }),
    deleteDirectorIndividualShareHolding: builder.mutation<
      void,
      {
        director_individual_company_shareholding_id: string;
      }
    >({
      query: (payload) => {
        return {
          url: "/editing-platform-data/shareholder-editing/director-individual-company-shareholding",
          method: "DELETE",
          params: {
            director_individual_company_shareholding_id:
              payload.director_individual_company_shareholding_id,
          },
        };
      },
    }),
    getDirectorRemunerations: builder.query<
      QaDbApiResponse<DirectorRemuneration[]>,
      CommonShareHoldingGetPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/key-personnel-remuneration",
        method: "GET",
        params: {
          ...payload,
        },
      }),
      providesTags: ["director-remunerations"],
    }),
    addDirectorRemunerations: builder.mutation<
      void,
      Partial<OrNull<DirectorRemuneration>>
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/key-personnel-remuneration",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["director-remunerations"],
    }),
    updateDirectorRemunerations: builder.mutation<
      void,
      UpdateDirectorRemunerations
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/key-personnel-remuneration",
        method: "PUT",
        data: payload,
        params: {
          key_personnel_remuneration_id: payload.key_personnel_remuneration_id,
        },
      }),
      invalidatesTags: ["director-remunerations"],
    }),
    deleteDirectorRemunerations: builder.mutation<
      void,
      {
        key_personnel_remuneration_id: string;
      }
    >({
      query: (payload) => {
        return {
          url: "/editing-platform-data/shareholder-editing/key-personnel-remuneration",
          method: "DELETE",
          params: {
            key_personnel_remuneration_id:
              payload.key_personnel_remuneration_id,
          },
        };
      },
    }),
    getJointVentureShareHolding: builder.query<
      QaDbApiResponse<JointVenture[]>,
      CommonShareHoldingGetPayload & {
        venture_type: JointVentureKeys;
      }
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/jv-holding-subsidiary-company",
        method: "GET",
        params: {
          ...payload,
        },
      }),
      providesTags: ["joint-venture-shareholding"],
    }),
    addJvHoldingInfo: builder.mutation<void, AddJointVenturePayload>({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/jv-holding-subsidiary-company",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["joint-venture-shareholding"],
    }),
    updateJvHoldingInfo: builder.mutation<void, UpdateJointVenture>({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/jv-holding-subsidiary-company",
        method: "PUT",
        data: {
          ...payload,
        },
        params: {
          jv_holding_subsidiary_company_id:
            payload.jv_holding_subsidiary_company_id,
        },
      }),
      invalidatesTags: ["joint-venture-shareholding"],
    }),
    deleteJvHoldingInfo: builder.mutation<void, string>({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/jv-holding-subsidiary-company",
        method: "DELETE",
        params: {
          jv_holding_subsidiary_company_id: payload,
        },
      }),
      invalidatesTags: ["joint-venture-shareholding"],
    }),
  }),
});

export const {
  useGetShareHoldingMetaDataQuery,
  useGetShareHoldingOverviewQuery,
  useUpdateShareHoldingOverviewMutation,
  useLazyGetShareHoldingStructureQuery,
  useGetShareHoldingStructureQuery,
  useGetShareHoldingInfoQuery,
  useUpdateShareHoldingInfoMutation,
  useAddDirectorIndividualShareHoldingMutation,
  useUpdateShareHoldingStructureMutation,
  useDeleteDirectorIndividualShareHoldingMutation,
  useGetDirectorRemunerationsQuery,
  useUpdateDirectorRemunerationsMutation,
  useAddDirectorRemunerationsMutation,
  useGetJointVentureShareHoldingQuery,
  useUpdateJvHoldingInfoMutation,
  useAddJvHoldingInfoMutation,
  useDeleteJvHoldingInfoMutation,
  useDeleteDirectorRemunerationsMutation,
} = shareHoldingsApi;

export default shareHoldingsApi;
