import { QaDbApiResponse } from "@app/types";

export const isDataEmpty = (
  data: QaDbApiResponse<any> | undefined,
): boolean => {
  if (!data) return false;
  if (Array.isArray(data.response_data)) {
    return data.response_data.length === 0;
  }
  return false;
};
