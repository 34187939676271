import { useEffect } from "react";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "@app/components/common/Input";
import toast from "react-hot-toast";
import { Button } from "@components/common/Button";
import classNames from "classnames";
import { DropDown } from "@app/components/common/dropdown";
import {
  FinancialTypes,
  useGetFinancialParamsQuery,
  useUpdateFinancialParamsMutation,
} from "@app/store/api/financialApi";
import { FinancialProps } from "./PrimaryAuditor";
import { PageLoader } from "@components/loader";

const dropdownOptions = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
];

interface FinancialParamsProps extends FinancialProps {
  isEditable: boolean;
  activeYear: string;
}

const schema = yup.object().shape({
  year: yup.number(),
  financial_year: yup.string(),
  earning_fc: yup.number(),
  expenditure_fc: yup
    .number()
    .required("Expenditure in foreign currency is required"),
  transaction_related_parties_as_18: yup.number(),
  prescribed_csr_expenditure: yup.number(),
  total_amount_csr_spent_for_financial_year: yup.number(),
  proposed_dividend: yup
    .number()
    .nullable()
    .typeError("Proposed dividend must be a number or null"),
  companies_caro_applicable: yup
    .string()
    .nullable()
    .oneOf(
      ["YES", "NO", null],
      "Companies (Auditor's Report) Order (CARO) applicability must be YES, NO, or null",
    ),
  highest_turnover_contributing_product_or_service_code: yup
    .number()
    .nullable()
    .typeError(
      "Highest turnover contributing product or service code must be a number or null",
    ),
});

type FinancialParams = yup.InferType<typeof schema>;

export const FinancialParams = ({
  formNature,
  activeInfo,
  activeYear,
  isEditable,
  toggleEditable,
}: FinancialParamsProps) => {
  if (!activeInfo || !formNature) return null;

  const { data: financialParams, isLoading } = useGetFinancialParamsQuery({
    document_id: activeInfo.id,
    year: activeYear as string,
    nature: formNature as FinancialTypes,
  });

  const [updateFinancialParams, { isLoading: isUpdating }] =
    useUpdateFinancialParamsMutation();

  const form = useForm<FinancialParams>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<FinancialParams> = (payload) => {
    toast.promise(
      updateFinancialParams({
        ...payload,
        companies_caro_applicable:
          payload.companies_caro_applicable === "YES" ? true : false,
        financial_params_id: financialParams?.response_data.id as string,
        highest_turnover_contributing_product_or_service_code: String(
          payload.highest_turnover_contributing_product_or_service_code,
        ),
      }).unwrap(),
      {
        loading: "Updating financial parameters...",
        success: "Financial parameters updated successfully",
        error: "Failed to update financial parameters",
      },
    );
    toggleEditable?.off();
  };

  useEffect(() => {
    if (!financialParams) return;
    form.reset({
      year: financialParams.response_data.year,
      financial_year: financialParams.response_data.financial_year,
      earning_fc: financialParams.response_data.earning_fc,
      expenditure_fc: financialParams.response_data.expenditure_fc,
      transaction_related_parties_as_18:
        financialParams.response_data.transaction_related_parties_as_18,
      prescribed_csr_expenditure:
        financialParams.response_data.prescribed_csr_expenditure,
      proposed_dividend: financialParams.response_data.proposed_dividend,
      companies_caro_applicable: financialParams.response_data
        .companies_caro_applicable
        ? "YES"
        : "NO",
      highest_turnover_contributing_product_or_service_code: Number(
        financialParams.response_data
          .highest_turnover_contributing_product_or_service_code,
      ),
      total_amount_csr_spent_for_financial_year:
        financialParams.response_data.total_amount_csr_spent_for_financial_year,
    });
  }, [financialParams]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <section className="flex flex-col gap-4 border mt-8 p-4 rounded-md w-full">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input name="year" label="Year" disabled />
          <Input
            name="financial_year"
            label="Financial Year"
            type="date"
            disabled={!isEditable}
          />
          <Input
            name="earning_fc"
            label="Earning FC"
            type="number"
            step="0.01"
            disabled={!isEditable}
          />
          <Input
            name="expenditure_fc"
            label="Expenditure FC"
            type="number"
            step="0.01"
            disabled={!isEditable}
          />
          <Input
            name="transaction_related_parties_as_18"
            label="Transaction related parties AS 18"
            type="number"
            step="0.01"
            disabled={!isEditable}
          />
          <Input
            name="prescribed_csr_expenditure"
            label="Prescribed CSR expenditure"
            type="number"
            disabled={!isEditable}
            step="0.01"
          />
          <Input
            name="total_amount_csr_spent_for_financial_year"
            label="Total amount CSR spent for financial year"
            type="number"
            disabled={!isEditable}
            step="0.01"
          />
          <Input
            name="proposed_dividend"
            label="Proposed Dividend"
            type="text"
            step="0.01"
            disabled={!isEditable}
          />

          <div className="flex flex-col gap-2 mt-3">
            <label className="block text-gray-700 mb-2 truncate">
              Companies CARO Applicable
            </label>
            <Controller
              control={form.control}
              name="companies_caro_applicable"
              render={({ field }) => (
                <DropDown
                  {...field}
                  options={dropdownOptions}
                  value={
                    dropdownOptions.find(
                      (option) => option.value === field.value,
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                  disabled={!isEditable}
                />
              )}
            />
          </div>
          <div className="mt-3">
            <Input
              name="highest_turnover_contributing_product_or_service_code"
              label="Highest Turnover Contributing Product or Service Code"
              type="number"
              disabled={!isEditable}
            />
          </div>
          {isEditable && (
            <div className="flex gap-2 mt-4 w-full">
              <Button
                type="button"
                onClick={toggleEditable?.off}
                variant="secondary"
                className="w-full"
              >
                Cancel
              </Button>
              <Button
                isLoading={isUpdating}
                type="submit"
                disabled={!form.formState.isDirty || isUpdating}
                className={classNames("w-full", {
                  "py-2": isUpdating,
                })}
              >
                Save
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </section>
  );
};
