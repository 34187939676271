import { DropDown, type Option } from "@components/common/dropdown";
import { Icon, Input } from "@components/common";
import {
  FormProvider,
  type SubmitHandler,
  useForm,
  Controller,
} from "react-hook-form";
import Modal from "react-modal";
import { useCallback } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { qaDbApiHandler } from "@app/config/apiHandler";
import toast from "react-hot-toast";
import { useToggle } from "@app/hooks/useToggle";
import qaUsersApi from "@app/store/api/qaUsersApi/qaUsersApi";
import { useDispatch } from "react-redux";

const rolesOptions: Option[] = [
  {
    label: "ADMIN",
    value: "ADMIN",
  },
  {
    label: "QA",
    value: "QA",
  },
  {
    label: "VERIFIER",
    value: "VERIFIER",
  },
];

const schema = Yup.object({
  name: Yup.string().required("Username is a required field"),
  email: Yup.string().email().required(),
  password: Yup.string().required().min(6),
  phone_number: Yup.number()
    .required("Phone number is a required field")
    .typeError("Phone number must be a number")
    .test(
      "len",
      "Phone number must be exactly 10 digits",
      (val) => Boolean(val) && val.toString().length === 10,
    ),
  user_type: Yup.string().required("User role is required"),
});

type ISchema = Yup.InferType<typeof schema>;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddUserModal = ({ isOpen, onClose }: Props) => {
  const [showPassWord, togglePassword] = useToggle();
  const form = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const onSubmit: SubmitHandler<ISchema> = async (data) => {
    try {
      await qaDbApiHandler.post("/users/create", {
        ...data,
        is_active: true,
        phone_number: `+91${data.phone_number}`,
        role: data.user_type,
        last_login: null,
        username: data.name,
      });
      dispatch(qaUsersApi.util.invalidateTags(["qa-users"]));
      toast.success("User Created Successfully");
    } catch {
      toast.error("User Creation Failed");
    } finally {
      handleClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="modal-content"
      overlayClassName="modal-overlay"
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
    >
      <section className="mt-1 w-[400px]">
        <div className="border-b flex justify-between items-center">
          <h4 className="py-2 text-md font-bold">Add New User</h4>
          <span onClick={handleClose}>
            <Icon
              name="X"
              size={25}
              className="border  p-1 rounded-full hover:cursor-pointer"
            />
          </span>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mt-4 flex flex-col">
              <Input name="name" label="Full Name" />
              <Input
                name="email"
                label="Email"
                placeholder="jhon@credhive.in"
              />
              <Input
                name="password"
                label="Password"
                type={showPassWord ? "text" : "password"}
                suffixIcon={showPassWord ? "EyeOff" : "Eye"}
                suffixIconHandler={togglePassword.toggle}
              />
              <Input name="phone_number" label="PhoneNumber" type="number" />
              <div>
                <label className="block text-gray-700 mb-2">User Role</label>
                <Controller
                  name="user_type"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <DropDown
                      options={rolesOptions}
                      onChange={(option: Option | null) =>
                        field.onChange(option?.value)
                      }
                      value={
                        rolesOptions.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>

              <button
                type="submit"
                className="text-md mt-4 inline-flex bg-blue-500 py-2 px-3 rounded-lg hover:bg-blue-400 w-full items-center justify-center text-white"
              >
                Create User
              </button>
            </div>
          </form>
        </FormProvider>
      </section>
    </Modal>
  );
};
