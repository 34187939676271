import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { qaDbApiHandler } from "@app/config/apiHandler";
import { QaDbApiResponse } from "@app/types";
import {
  QaChildsTasksApiResponse,
  QaProjectsCompletedApiResponse,
} from "./types";
import { Pagination } from "@app/types";

const qaProjectsApi = createApi({
  reducerPath: "qaProjectsApi",
  baseQuery: axiosBaseQuery(qaDbApiHandler),
  tagTypes: ["qa-completed-projects", "qa-incompleted-projects", "qa-tasks"],
  endpoints: (builder) => ({
    getQaProjectsChildTasks: builder.query<
      QaDbApiResponse<QaChildsTasksApiResponse>,
      {
        project_id: string;
      }
    >({
      query: (payload) => ({
        url: "company-project-tasks/get-all-child-details",
        method: "get",
        params: {
          project_id: payload.project_id,
        },
      }),
      providesTags: ["qa-tasks"],
    }),
    qaProjectsCompletedApi: builder.query<
      QaDbApiResponse<QaProjectsCompletedApiResponse[]>,
      Pagination & { sortBy: string; orderBy: string }
    >({
      query: (payload) => ({
        url: "company-project-tasks/get-all-completed-projects",
        method: "get",
        params: {
          limit: payload.limit,
          skip: payload.skip,
          sort_by: payload.sortBy,
          sort_order: payload.orderBy,
        },
      }),
      providesTags: ["qa-completed-projects"],
    }),
    qaProjectsInCompletedApi: builder.query<
      QaDbApiResponse<QaProjectsCompletedApiResponse[]>,
      Pagination & { sortBy: string; orderBy: string }
    >({
      query: (payload) => ({
        url: "company-project-tasks/get-all-incomplete-projects",
        method: "get",
        params: {
          limit: payload.limit,
          skip: payload.skip,
          sort_by: payload.sortBy,
          sort_order: payload.orderBy,
        },
      }),
      providesTags: ["qa-incompleted-projects"],
    }),
  }),
});

const {
  useGetQaProjectsChildTasksQuery,
  useQaProjectsCompletedApiQuery,
  useQaProjectsInCompletedApiQuery,
} = qaProjectsApi;

export {
  useGetQaProjectsChildTasksQuery,
  useQaProjectsCompletedApiQuery,
  useQaProjectsInCompletedApiQuery,
};

export default qaProjectsApi;
