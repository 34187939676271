import { createApi } from "@reduxjs/toolkit/query/react";
import type { QaDbApiResponse } from "@app/types";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import type {
  FinancialMetaDataResponse,
  BalanceSheetResponsePayload,
  GetAuditorPayload,
} from "@app/store/api/financialApi/types";
import {
  StatementOfIncomeAndExpenditureType,
  UpdateStatementOfIncomeAndExpenditure,
  StatementOfAssetsAndLiabilitiesType,
  UpdateStatementOfAssetsAndLiabilities,
} from "./types";

const llpFinancialsApi = createApi({
  reducerPath: "llpFinancials",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: [
    "llp-financials-metadata",
    "statement-of-income-and-expenditure",
    "statement-of-assets-and-liabilities",
  ],
  endpoints: (builder) => ({
    getLlpFinancialsMetaData: builder.query<
      QaDbApiResponse<FinancialMetaDataResponse>,
      string
    >({
      query: (id: string) => ({
        url: "/editing-platform-data/metadata/financial-metadata-for-llpin",
        method: "GET",
        params: {
          llpin: id,
        },
      }),
      providesTags: ["llp-financials-metadata"],
    }),
    getStatementOfIncomeAndExpenditure: builder.query<
      QaDbApiResponse<StatementOfIncomeAndExpenditureType>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/statement-of-income-and-expenditure",
        method: "GET",
        params: payload,
      }),
      providesTags: ["statement-of-income-and-expenditure"],
    }),
    updateStatementOfIncomeAndExpenditure: builder.mutation<
      void,
      UpdateStatementOfIncomeAndExpenditure
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/statement-of-income-and-expenditure",
        method: "PUT",
        params: {
          statement_of_income_and_expenditure_id:
            payload.statement_of_income_and_expenditure_id,
        },
        data: payload,
      }),
      invalidatesTags: ["statement-of-income-and-expenditure"],
    }),
    deleteStatementOfIncomeAndExpenditure: builder.mutation<
      void,
      BalanceSheetResponsePayload
    >({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/statement-of-income-and-expenditure",
        method: "DELETE",
        params: {
          statement_of_income_and_expenditure_id: id,
        },
      }),
      invalidatesTags: ["statement-of-income-and-expenditure"],
    }),
    getStatementOfAssetsAndLiabilities: builder.query<
      QaDbApiResponse<StatementOfAssetsAndLiabilitiesType>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/statement-of-assets-and-liabilities",
        method: "GET",
        params: payload,
      }),
      providesTags: ["statement-of-assets-and-liabilities"],
    }),
    updateStatementOfAssetsAndLiabilities: builder.mutation<
      void,
      UpdateStatementOfAssetsAndLiabilities
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/statement-of-assets-and-liabilities",
        method: "PUT",
        params: {
          statement_of_assets_and_liabilities_id:
            payload.statement_of_assets_and_liabilities_id,
        },
        data: payload,
      }),
      invalidatesTags: ["statement-of-assets-and-liabilities"],
    }),
    deleteStatementOfAssetsAndLiabilities: builder.mutation<
      void,
      BalanceSheetResponsePayload
    >({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/statement-of-assets-and-liabilities",
        method: "DELETE",
        params: {
          statement_of_assets_and_liabilities_id: id,
        },
      }),
      invalidatesTags: ["statement-of-assets-and-liabilities"],
    }),
  }),
});

export const {
  useGetLlpFinancialsMetaDataQuery,
  useGetStatementOfIncomeAndExpenditureQuery,
  useUpdateStatementOfIncomeAndExpenditureMutation,
  useDeleteStatementOfIncomeAndExpenditureMutation,
  useGetStatementOfAssetsAndLiabilitiesQuery,
  useUpdateStatementOfAssetsAndLiabilitiesMutation,
  useDeleteStatementOfAssetsAndLiabilitiesMutation,
} = llpFinancialsApi;

export default llpFinancialsApi;
