import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Target } from "lucide-react";
import Select from "react-select";
import Drawer from "react-modern-drawer";
import toast from "react-hot-toast";
import "react-modern-drawer/dist/index.css";

import { useAuth } from "@app/hooks/useAuth";
import { useWindowSize } from "@app/hooks/useWindowSize";
import { useProjectModal } from "@app/hooks/useProjectModal";
import { useActiveCompany } from "@app/hooks/useActiveCompany";

import { Button } from "@app/components/common/Button";
import { ToolTip } from "@app/components/common/table";
import { PageLoader } from "@components/loader";
import { RenderQaLabellingStatus } from "@components/tasks/pending/components/RenderQaLabellingStatus";
import { RenderScrapingStatus } from "@components/tasks/pending/components/RenderScrapetasks";

import { useGetQaProjectsChildTasksQuery } from "@app/store/api/qaProjects/qaProjectsApi";
import { useGetAllUsersQuery } from "@app/store/api/qaUsersApi/qaUsersApi";
import {
  useAssignLabellingTaskMutation,
  useAssignScrapeTaskMutation,
  useTriggerReparseTaskMutation,
  useRetryParsingTaskMutation,
} from "@app/store/api/qaTasksApi";
import { QaProjectChildTask } from "@app/store/api/qaProjects/types";

import { removeUnderscores } from "@app/utils/removeUnderScores";

import { TaskTable } from "./TaskTable";
import { TaskSection } from "./TaskSection";

type RenderRowProps = {
  task: QaProjectChildTask;
  index: number;
};

const ProjectsInfo: React.FC = () => {
  const { width } = useWindowSize();

  const { role } = useAuth();

  const canChangeAssignedUser =
    role.includes("Admin") || role.includes("Verifier");
  const navigate = useNavigate();
  const { activeCountry, activeCorporate } = useActiveCompany();
  const [assignLabellingTask] = useAssignLabellingTaskMutation();
  const [assignScrapeTask] = useAssignScrapeTaskMutation();
  const [searchParams] = useSearchParams();
  const { isProjectModalOpen, closeProjectModal } = useProjectModal();

  const projectId = searchParams.get("project_id");

  const [triggerReparseTask, { isLoading: isReparseLoading }] =
    useTriggerReparseTaskMutation();

  const [retryParsingTask, { isLoading: isRetryLoading }] =
    useRetryParsingTaskMutation();

  const { data, isLoading, refetch } = useGetQaProjectsChildTasksQuery(
    {
      project_id: projectId as string,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !projectId || !isProjectModalOpen,
    },
  );
  const { data: qaUsers } = useGetAllUsersQuery(undefined, {
    skip: !isProjectModalOpen,
  });

  const onLabellingAssign = async (id: string, taskId: string) => {
    try {
      if (!id || !taskId) throw new Error();
      await assignLabellingTask({
        task_id: taskId,
        user_id: id,
      }).unwrap();
      toast.success("User Assigned Successfully");
      refetch();
    } catch {
      toast.error("Failed to assign user");
    }
  };

  const onScrapeAssign = async (id: string, taskId: string) => {
    try {
      if (!id || !taskId) throw new Error();
      await assignScrapeTask({
        task_id: taskId,
        user_id: id,
      }).unwrap();
      toast.success("User Assigned Successfully");
      refetch();
      refetch();
    } catch {
      toast.error("Failed to assign user");
    }
  };

  const qaUsersWithNameAndId = useMemo(() => {
    if (!qaUsers) return [];

    return qaUsers.response_data.map((el) => ({
      label: el.username,
      value: el.id,
    }));
  }, [qaUsers]);

  const { companyName, companyCin } = useMemo(() => {
    const companyTask = data?.response_data?.company_scraping_tasks?.[0];

    return {
      companyName: companyTask?.company_name ?? "",
      companyCin: companyTask?.identifier_value ?? "",
    };
  }, [data]);

  const canShowParsingActionButton = useMemo(() => {
    if (!data) return false;

    return data.response_data.company_scraping_tasks.every((el) =>
      el.status.toLowerCase().includes("complete"),
    );
  }, [data]);

  const RenderParsingActionButton = (
    <Button
      className="px-2"
      rightIcon={Target}
      isLoading={isReparseLoading}
      onClick={() => {
        toast.promise(
          triggerReparseTask({
            project_id: projectId as string,
          }).unwrap(),
          {
            loading: "Triggering Parsing...",
            success: () => {
              refetch();
              return "Parsing Triggered Successfully";
            },
            error: "Failed to Trigger Parsing",
          },
        );
      }}
    >
      Trigger Parsing Manually
    </Button>
  );

  const RenderScrapingRow = ({ task, index }: RenderRowProps) => (
    <tr key={index}>
      <td className="border p-2">{removeUnderscores(task.task_nature_name)}</td>
      <td className="border p-2">
        <RenderScrapingStatus status={task.status} />
      </td>
      <td className="border p-2">{task.assigned_to_name}</td>
      <td className="border p-2">
        <Select
          options={qaUsersWithNameAndId}
          onChange={(el) => el?.value && onScrapeAssign(el.value, task.id)}
          isDisabled={!canChangeAssignedUser}
          maxMenuHeight={170}
          className="w-full min-w-60"
        />
      </td>
    </tr>
  );

  const RenderParsingRow = ({ task, index }: RenderRowProps) => (
    <tr key={index} className="border">
      <td className="border p-2">{removeUnderscores(task.task_nature_name)}</td>
      <td className="border p-2">{removeUnderscores(task.status)}</td>
      {!task.status.toLowerCase().includes("complete") && (
        <td className="border p-2">
          <Button
            className="px-2 w-full"
            isLoading={isRetryLoading}
            onClick={() => {
              toast.promise(retryParsingTask({ task_id: task.id }).unwrap(), {
                loading: "Retrying Parsing...",
                success: () => {
                  refetch();
                  return "Parsing Retried Successfully";
                },
                error: "Failed to Retry Parsing",
              });
            }}
          >
            Retry
          </Button>
        </td>
      )}
    </tr>
  );

  const RenderLabellingRow = ({ task, index }: RenderRowProps) => (
    <tr key={index}>
      <td className="border p-2">{removeUnderscores(task.task_nature_name)}</td>
      <td className="border p-2">
        <RenderQaLabellingStatus status={task.status} />
      </td>
      <td className="border p-2">{task.assigned_to_name}</td>
      <td className="border p-2">
        <Select
          options={qaUsersWithNameAndId}
          isDisabled={!canChangeAssignedUser}
          onChange={(el) => el?.value && onLabellingAssign(el.value, task.id)}
          maxMenuHeight={170}
          className="w-full min-w-60"
        />
      </td>
    </tr>
  );

  const { filteredParsingTasks, filteredCompileTasks } = useMemo(
    () => ({
      filteredParsingTasks:
        data?.response_data.company_parsing_tasks.filter(
          (task) =>
            task.task_nature_name !== "PARSER_COMPILE_INDIAN_REGISTERED",
        ) ?? [],
      filteredCompileTasks:
        data?.response_data.company_parsing_tasks.filter(
          (task) =>
            task.task_nature_name === "PARSER_COMPILE_INDIAN_REGISTERED",
        ) ?? [],
    }),
    [data?.response_data.company_parsing_tasks],
  );

  return (
    <Drawer
      open={isProjectModalOpen}
      onClose={closeProjectModal}
      direction="right"
      size={width > 1200 ? "880px" : "600px"}
      className="p-4 overflow-y-auto"
    >
      <div className="flex justify-between">
        <h2 className="text-xl font-bold mb-4 truncate">
          Project Tasks - {companyName}
        </h2>
        <div>
          <ToolTip
            value={`CIN:${companyCin}`}
            handler={() => {
              closeProjectModal();
              navigate(
                `/labelling/${activeCountry}/${activeCorporate}/${companyCin}/overview`,
              );
            }}
          />
        </div>
      </div>
      <div className="space-y-6">
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            <TaskSection title="Scraping Tasks">
              <TaskTable
                headers={["Task Nature", "Status", "Assigned To", "Assign"]}
                data={data?.response_data.company_scraping_tasks ?? []}
                renderRow={({ task, index }) => (
                  <RenderScrapingRow task={task} index={index} />
                )}
              />
            </TaskSection>

            <TaskSection
              title="Parsing Tasks"
              action={
                data?.response_data.company_parsing_tasks.length === 0 &&
                canShowParsingActionButton &&
                RenderParsingActionButton
              }
            >
              <TaskTable
                headers={["Task Nature", "Status", "Retry"]}
                data={filteredParsingTasks}
                renderRow={({ task, index }) => (
                  <RenderParsingRow task={task} index={index} />
                )}
              />
            </TaskSection>

            <TaskSection title="Labelling Tasks">
              <TaskTable
                headers={["Task Nature", "Status", "Assigned To", "Assign"]}
                data={data?.response_data.company_qa_labeling_tasks ?? []}
                renderRow={({ task, index }) => (
                  <RenderLabellingRow task={task} index={index} />
                )}
              />
            </TaskSection>

            <TaskSection title="Compilation Tasks">
              <TaskTable
                headers={["Task Nature", "Status", "Retry"]}
                data={filteredCompileTasks}
                renderRow={({ task, index }) => (
                  <RenderParsingRow task={task} index={index} />
                )}
              />
            </TaskSection>
          </>
        )}
      </div>
    </Drawer>
  );
};

export const ProjectsInfoDrawer = React.memo(ProjectsInfo);
