import { IdentifierTypeEnum } from "@app/types";
import { icons } from "lucide-react";

export interface LabellingLinksProps {
  name: string;
  to: (cin: string, activeCountry: string, activeCorporate: string) => string;
  children?: LabellingLinksProps[];
  supportedIdentifiers?: IdentifierTypeEnum[];
  icon?: keyof typeof icons;
}

// Helper function to generate URL
function generateUrl(
  path: string,
  cin: string,
  activeCountry: string,
  activeCorporate: string,
  hash?: string,
): string {
  const baseUrl = `/labelling/${activeCountry}/${activeCorporate}/${cin}/${path}`;
  return hash ? `${baseUrl}#${hash}` : baseUrl;
}

// Common supported identifiers
const DEFAULT_IDENTIFIERS = Object.values(IdentifierTypeEnum);
const CIN_ONLY = [IdentifierTypeEnum.CIN];

// Shareholding pattern sub-sections
const shareholdingChildren: LabellingLinksProps[] = [
  {
    name: "Total Shareholdings",
    to: (...args) =>
      generateUrl("shareholding-pattern", ...args, "total-shareholdings"),
    supportedIdentifiers: CIN_ONLY,
  },
  {
    name: "Shareholding structure",
    to: (...args) =>
      generateUrl("shareholding-pattern", ...args, "shareholding-structure"),
    supportedIdentifiers: CIN_ONLY,
  },
  {
    name: "Detailed shareholding",
    to: (...args) =>
      generateUrl("shareholding-pattern", ...args, "detailed-shareholding"),
    supportedIdentifiers: CIN_ONLY,
  },
  {
    name: "Detailed Remuneration",
    to: (...args) =>
      generateUrl("shareholding-pattern", ...args, "detailed-remuneration"),
  },
  {
    name: "Joint Ventures",
    to: (...args) =>
      generateUrl("shareholding-pattern", ...args, "joint-ventures"),
    supportedIdentifiers: CIN_ONLY,
  },
  {
    name: "Principle Business",
    to: (...args) =>
      generateUrl("shareholding-pattern", ...args, "principle-business"),
    supportedIdentifiers: [IdentifierTypeEnum.LLPIN],
  },
  {
    name: "Partners",
    to: (...args) => generateUrl("shareholding-pattern", ...args, "partners"),
    supportedIdentifiers: [IdentifierTypeEnum.LLPIN],
  },
];

export const LabellingLinks: LabellingLinksProps[] = [
  {
    name: "Overview",
    to: (...args) => generateUrl("overview", ...args),
    supportedIdentifiers: DEFAULT_IDENTIFIERS,
  },
  {
    name: "Shareholding Pattern",
    to: (...args) => generateUrl("shareholding-pattern", ...args),
    supportedIdentifiers: DEFAULT_IDENTIFIERS,
    children: shareholdingChildren,
  },
  {
    name: "Management",
    to: (...args) => generateUrl("management", ...args),
    supportedIdentifiers: DEFAULT_IDENTIFIERS,
  },
  {
    name: "Finances",
    to: (...args) => generateUrl("finances", ...args),
    supportedIdentifiers: DEFAULT_IDENTIFIERS,
  },
  {
    name: "Financial Parameter",
    to: (...args) => generateUrl("financial-parameter", ...args),
    supportedIdentifiers: CIN_ONLY,
  },
  {
    name: "Documents",
    to: (...args) => generateUrl("documents", ...args),
    supportedIdentifiers: [IdentifierTypeEnum.CIN, IdentifierTypeEnum.LLPIN],
  },
  {
    name: "Credit Ratings",
    to: (...args) => generateUrl("credit-ratings", ...args),
    supportedIdentifiers: Object.values(IdentifierTypeEnum).filter(
      (identifier) => identifier !== IdentifierTypeEnum.LLPIN,
    ),
  },
  {
    name: "Charges",
    to: (...args) => generateUrl("charges", ...args),
    supportedIdentifiers: [IdentifierTypeEnum.CIN, IdentifierTypeEnum.LLPIN],
  },
  {
    name: "Compliances",
    to: (...args) => generateUrl("compliances", ...args),
    supportedIdentifiers: [IdentifierTypeEnum.CIN, IdentifierTypeEnum.LLPIN],
  },
];
