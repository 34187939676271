import { Input } from "@components/common/Input";
import { useForm, FormProvider } from "react-hook-form";

export const LicenseBuilder = () => {
  const methods = useForm();
  return (
    <div className="mt-6 border rounded-md p-4">
      <h3 className="text-xl font-semibold mb-4">License</h3>
      <FormProvider {...methods}>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input
              name="entity_license_type"
              label="License Type"
              placeholder="Enter License Type"
            />
            <Input
              name="entity_license_value"
              label="License Number"
              placeholder="Enter License Number"
            />
            <Input
              name="entity_license_issue_date"
              label="Issue Date"
              type="date"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
