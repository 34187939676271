import { Button } from "@components/common/Button";
import { Table, ToolTip } from "@components/common/table";
import { RenderProjectStatus } from "@components/admin/projects";
import { useProjectModal } from "@app/hooks/useProjectModal";
import { useQaProjectsInCompletedApiQuery } from "@app/store/api/qaProjects/qaProjectsApi";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "@components/loader";
import { Pagination } from "@app/types";
import { headers } from "../common";
import { SortByPopover } from "./SortyByPopover";
import { formatDateWithSeconds } from "../utils";
import { useActiveCompany } from "@app/hooks/useActiveCompany";

export const IncompletedProjects = () => {
  const navigate = useNavigate();
  const { openProjectModal } = useProjectModal();
  const { activeCountry, activeCorporate } = useActiveCompany();

  const [inCompletedPagination, setInCompletedPagination] =
    useState<Pagination>({
      limit: 15,
      skip: 0,
    });

  const [sortBy, setSortBy] = useState({
    label: "Created At",
    value: "created_at",
  });
  const [orderBy, setOrderBy] = useState({
    label: "Descending",
    value: "desc",
  });

  const { data: inCompletedProjects, isLoading } =
    useQaProjectsInCompletedApiQuery(
      {
        ...inCompletedPagination,
        sortBy: sortBy.value,
        orderBy: orderBy.value,
      },
      {},
    );

  const handleNextPage = () => {
    if (!inCompletedProjects?.total) return;
    if (inCompletedPagination.limit >= inCompletedProjects.total) return;

    setInCompletedPagination((prev) => ({
      skip: prev.skip + prev.limit,
      limit: prev.limit,
    }));
  };

  const handlePrevPage = () => {
    if (inCompletedPagination.skip === 0) return;
    setInCompletedPagination((prev) => ({
      skip: Math.max(prev.skip - prev.limit, 0),
      limit: prev.limit,
    }));
  };

  const rows = useMemo(() => {
    if (!inCompletedProjects) return [];

    return inCompletedProjects.response_data.map((el) => ({
      companyCIN: (
        <ToolTip
          value={el.identifier_value}
          handler={() =>
            navigate(
              `/labelling/${activeCountry}/${activeCorporate}/${el.identifier_value}/overview`,
            )
          }
        />
      ),
      companyName: el.company_name,
      status: <RenderProjectStatus status={el.status} />,
      version: el.version,
      created_at: formatDateWithSeconds(el.created_at),
      updated_at: formatDateWithSeconds(el.updated_at),
      viewDetails: (
        <Button className="px-2" onClick={() => openProjectModal(el.id)}>
          View Details
        </Button>
      ),
    }));
  }, [inCompletedProjects]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <section>
      <div className="mt-1">
        <SortByPopover
          sortBy={sortBy}
          orderBy={orderBy}
          setSortBy={setSortBy}
          setOrderBy={setOrderBy}
        />
        <Table
          currentLimit={inCompletedPagination.limit}
          maxSize={inCompletedProjects?.total}
          nextHandler={handleNextPage}
          prevHandler={handlePrevPage}
          paginate
          headers={headers}
          rows={rows}
          skip={inCompletedPagination.skip}
        />
      </div>
    </section>
  );
};
