import Modal from "react-modal";
import {
  useTopUpTokenMutation,
  OrderReason,
} from "@app/store/api/accountManagementApi";
import { Button } from "@app/components/common/Button";
import toast from "react-hot-toast";
import { useAuth } from "@app/hooks/useAuth";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "@components/common/Input";
import { DropDown, Option } from "@app/components/common/dropdown";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { useGetCompanyMembersQuery } from "@app/store/api/useCustomerTeams";
import { useMemo } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
  companyId: string;
}

const reasonOptions = [
  {
    label: removeUnderscores(OrderReason.NEW_CUSTOMER),
    value: OrderReason.NEW_CUSTOMER,
  },
  {
    label: removeUnderscores(OrderReason.CUSTOMER_TOP_UP),
    value: OrderReason.CUSTOMER_TOP_UP,
  },
  {
    label: removeUnderscores(OrderReason.DISCOUNT),
    value: OrderReason.DISCOUNT,
  },
  { label: removeUnderscores(OrderReason.REFUND), value: OrderReason.REFUND },
];

const schema = yup.object().shape({
  tokenCount: yup
    .number()
    .typeError("Token count must be a number")
    .moreThan(0, "Token count must be greater than 0")
    .required("Token count is required"),
  tokenPrice: yup
    .number()
    .typeError("Token price must be a number")
    .moreThan(0, "Token price must be greater than 0")
    .required("Token price is required"),
  reason: yup
    .string()
    .required("Reason is required")
    .oneOf(Object.values(OrderReason)),
  customer_user_id: yup.string().required("Account is required"),
});

type FormValues = yup.InferType<typeof schema>;

export const PopUpTokenModal = ({
  isOpen,
  onClose,
  companyName,
  companyId,
}: Props) => {
  const [topUpToken, { isLoading }] = useTopUpTokenMutation();
  const { user, userDetails } = useAuth();

  const { data: companyMembers } = useGetCompanyMembersQuery(
    {
      customer_company_id: companyId,
    },
    {
      skip: !isOpen,
    },
  );

  const members = useMemo(() => {
    return companyMembers?.response_data.map((member) => ({
      label: member.name,
      value: member.id,
    }));
  }, [companyMembers]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tokenCount: 0,
      tokenPrice: 0,
    },
  });

  const { handleSubmit, watch } = methods;
  const tokenCount = watch("tokenCount");
  const tokenPrice = watch("tokenPrice");

  const totalCost = Number(tokenCount) * Number(tokenPrice) || 0;

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!user || !userDetails) {
      toast.error("User not found");
      return;
    }
    toast.promise(
      topUpToken({
        company_id: companyId,
        user_id: data.customer_user_id,
        number_of_tokens: data.tokenCount,
        token_price: data.tokenPrice,
        order_source: "ACCOUNT_MANAGER",
        order_reason: data.reason,
        order_source_metadata: {
          name: userDetails.name,
          email: userDetails.email,
          id: user,
        },
      }).unwrap(),
      {
        loading: "Adding tokens...",
        success: () => {
          onClose();
          methods.reset();
          return "Tokens added successfully!";
        },
        error: "Failed to add tokens. Please try again.",
      },
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      className="fixed inset-0 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[400px] min-h-46 py-2 flex flex-col rounded-md justify-between">
        <div className="border-b">
          <h4 className="ml-2 p-2 text-md font-bold">Add Tokens</h4>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex gap-0 flex-col p-4"
          >
            <p className="text-sm mb-4">
              You are about to add tokens to {companyName}'s account. Please
              specify the number of tokens and price per token.
            </p>
            <Input
              name="tokenPrice"
              label="Token Price"
              type="number"
              placeholder="Enter price per token"
            />
            <Input
              name="tokenCount"
              label="Number of Tokens"
              type="number"
              placeholder="Enter number of tokens"
            />
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Reason For Top Up
              </label>
              <Controller
                name="reason"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <DropDown
                    options={reasonOptions}
                    onChange={(option: Option | null) =>
                      field.onChange(option?.value)
                    }
                    value={
                      reasonOptions.find(
                        (option) => option.value === field.value,
                      ) || null
                    }
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Buyer Name</label>
              <Controller
                name="customer_user_id"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <DropDown
                    options={members || []}
                    onChange={(option: Option | null) => {
                      return field.onChange(option?.value);
                    }}
                    value={
                      members?.find((option) => option.value === field.value) ||
                      null
                    }
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
            <p className="text-sm font-semibold mt-2">
              You are adding {tokenCount || 0} token
              {Number(tokenCount) !== 1 ? "s" : ""} to {companyName}'s account
              at ₹{tokenPrice || 0} per token.
            </p>
            <p className="text-sm font-semibold mt-2">
              Total Cost: ₹{totalCost.toFixed(2)}
            </p>
            <div className="grid w-full grid-cols-2 gap-2 mt-2">
              <Button
                type="button"
                className="text-md inline-flex bg-gray-400 rounded-lg hover:bg-gray-500 w-full min-w-28 px-2 py-1 items-center justify-center"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="text-md inline-flex bg-blue-500 text-white  rounded-lg hover:bg-blue-400 w-full min-w-28 px-2 py-1 items-center justify-center"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Add Tokens
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
