export enum MCAVersionEnum {
  MCA_V2 = "mca_v2",
  MCA_V3 = "mca_v3",
}

export interface CreatePresignedUrlPayload {
  s3_url: string;
  is_download?: boolean;
}

export interface CreatePresignedUrlResponse {
  message: string;
  pre_signed_url: string;
}

export interface CreatePresignedUrlByDocumentIdPayload {
  document_id: string;
  is_download?: boolean;
}
