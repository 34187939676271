import { useEffect } from "react";
import type { StatementProps } from "./Statement";
import { FormProvider, useForm } from "react-hook-form";
import { InputGroup } from "@components/labelling/finances/components/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AssetsAndLiabilities,
  assetsAndLiabilitiesSchema,
  AssetsAndLiabilitiesType,
} from "../validation/assetsAndLiabilities";
import { toast } from "react-hot-toast";
import { PageLoader } from "@components/loader";
import { Button } from "@app/components/common/Button";

import {
  useGetStatementOfAssetsAndLiabilitiesQuery,
  useUpdateStatementOfAssetsAndLiabilitiesMutation,
} from "@app/store/api/llp-financialsApi";

import classNames from "classnames";

export const StateMentOfAssetsAndLiabilities = ({
  financialInfo,
  selectedYear,
  isEditable,
  toggleEditable,
  selectedNature,
  activePdf,
  isPdfOpen,
}: StatementProps) => {
  if (!financialInfo) {
    return null;
  }

  const form = useForm({
    resolver: yupResolver(assetsAndLiabilitiesSchema),
    mode: "all",
  });

  const [updateStatementOfAssetsAndLiabilities, { isLoading: isUpdating }] =
    useUpdateStatementOfAssetsAndLiabilitiesMutation();

  const { data, isLoading } = useGetStatementOfAssetsAndLiabilitiesQuery({
    document_id: financialInfo.id,
    year: selectedYear.value,
    nature: selectedNature,
  });

  useEffect(() => {
    if (data && data.response_data) {
      form.reset(data.response_data);
    }
  }, [data, form]);

  const onSave = (submittedData: Partial<AssetsAndLiabilitiesType>) => {
    toast.promise(
      updateStatementOfAssetsAndLiabilities({
        ...submittedData,
        statement_of_assets_and_liabilities_id: data?.response_data
          ?.id as string,
      }).unwrap(),
      {
        loading: "Updating...",
        success: () => {
          toast.success("Updated successfully");
          toggleEditable.off();
          return "Updated successfully";
        },
        error: () => {
          if (data) {
            form.reset(data.response_data);
          }
          toast.error("Failed to update");
          return "Failed to update";
        },
      },
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="mt-5">
      <div className="mt-5 flex">
        <div
          className={classNames("border p-4 rounded-md", {
            "w-1/2": isPdfOpen,
            "w-full": !isPdfOpen,
          })}
        >
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSave)}>
              <InputGroup
                isPdfOpen={false}
                title="Assets"
                fields={AssetsAndLiabilities.assets}
                isEditable={isEditable}
              />
              <InputGroup
                isPdfOpen={false}
                title="Sub Totals"
                fields={AssetsAndLiabilities.sub_totals}
                isEditable={isEditable}
              />
              <InputGroup
                isPdfOpen={false}
                title="Liabilities"
                fields={AssetsAndLiabilities.liabilities}
                isEditable={isEditable}
              />
              {isEditable && (
                <div className="flex gap-2 mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    className="w-full"
                    onClick={() => {
                      form.reset();
                      toggleEditable.off();
                    }}
                  >
                    Cancel
                  </Button>
                  {form.formState.isDirty && (
                    <Button
                      type="submit"
                      isLoading={isUpdating}
                      className="w-full"
                    >
                      Save
                    </Button>
                  )}
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        {isPdfOpen && activePdf && (
          <div className="w-1/2 pl-4">
            <object
              data={activePdf}
              type="application/pdf"
              className="w-full h-full"
            >
              <p>Form PDF</p>
            </object>
          </div>
        )}
      </div>
    </div>
  );
};
