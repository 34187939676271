import {
  useUpdateCreditRatingMutation,
  useDeleteCreditRatingMutation,
} from "@app/store/api/creditRating";
import { CreditRating } from "@app/store/api/creditRating/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { Button } from "@components/common/Button";
import { useToggle } from "@app/hooks/useToggle";
import { Edit, Trash } from "lucide-react";
import { useEffect } from "react";
import { RatingFields } from "./RatingFields";
import toast from "react-hot-toast";

export const ratingSchema = Yup.object().shape({
  rating: Yup.string().nullable(),
  ratings_date: Yup.string().nullable(),
  ratings_url: Yup.string().url().nullable(),
  rating_agency: Yup.string().nullable(),
  outlook: Yup.string().nullable(),
  instrument: Yup.string().nullable(),
  rating_amount: Yup.number().nullable(),
});

type FormPayload = Yup.InferType<typeof ratingSchema>;

export const RatingGroupEditor: React.FC<{
  group: CreditRating;
}> = ({ group }) => {
  const [isEditing, toggleEditable] = useToggle();
  const form = useForm<FormPayload>({
    resolver: yupResolver(ratingSchema),
  });

  const [updateCreditRating] = useUpdateCreditRatingMutation();
  const [deleteCreditRating] = useDeleteCreditRatingMutation();

  const handleDelete = () => {
    toast.promise(deleteCreditRating(group.id).unwrap(), {
      loading: "Deleting rating...",
      success: "Rating deleted successfully",
      error: "Failed to delete rating",
    });
  };

  useEffect(() => {
    if (group) {
      form.reset({
        ...group,
        ratings_date: group.ratings_date,
        rating_agency: group.rating_agency,
      });
    }
  }, []);

  const handleSave: SubmitHandler<FormPayload> = (data) => {
    toast.promise(
      updateCreditRating({
        ...data,
        ratings_date: data.ratings_date,
        rating_agency: data.rating_agency || null,
        outlook: data.outlook || null,
        credit_rating_id: group.id,
      }).unwrap(),
      {
        loading: "Updating rating...",
        success: "Rating updated successfully",
        error: "Failed to update rating",
      },
    );
  };

  if (!group) {
    return (
      <div className="mb-4 p-4 bg-gray-100 rounded">
        <p className="text-gray-600 text-center p-4 border rounded-md">
          No rating available for this group.
        </p>
      </div>
    );
  }

  return (
    <div className="mb-4 p-4 bg-gray-100 rounded">
      <div className="flex justify-end gap-3">
        {!isEditing && (
          <Button onClick={toggleEditable.on} rightIcon={Edit}>
            Edit
          </Button>
        )}
        <Button
          rightIcon={Trash}
          onClick={handleDelete}
          className="bg-red-500 text-white hover:bg-red-600"
        >
          Delete
        </Button>
      </div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSave)} className="mt-4">
          <div className="mb-2 bg-white rounded-md">
            <RatingFields control={form.control} />
          </div>

          {isEditing && (
            <div className="flex  gap-2 mt-4 w-full justify-between">
              <Button
                variant="secondary"
                onClick={toggleEditable.off}
                type="button"
                className="w-full"
              >
                Cancel
              </Button>
              <Button type="submit" className="w-full">
                Save
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
