import { useMemo } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import { addDays, isAfter } from "date-fns";
import classNames from "classnames";

export const LastUpdatedTooltip = ({
  lastUpdated,
}: {
  lastUpdated: string;
}) => {
  const isMoreThan15DaysOld = useMemo(() => {
    if (!lastUpdated) return false;
    return isAfter(new Date(), addDays(new Date(lastUpdated), 15));
  }, [lastUpdated]);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div
            className={classNames(
              "border shadow-md py-2 px-4 rounded font-bold",
              {
                "border-red-500 text-red-500": isMoreThan15DaysOld,
                "border-gray-300 text-gray-500": !isMoreThan15DaysOld,
              },
            )}
          >
            Last Updated:
            {lastUpdated ? new Date(lastUpdated).toLocaleDateString() : "N/A"}
          </div>
        </TooltipTrigger>
        {isMoreThan15DaysOld && (
          <TooltipContent className="bg-red-500 text-white rounded-md shadow-sm">
            <p>This document is more than 15 days old.</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
