import { useEffect } from "react";
import toast, { useToasterStore } from "react-hot-toast";

// USED TO LIMIT THE NUMBER OF TOAST ACTIVE AT A TIME
export function useMaxToasts(max: number) {
  const { toasts } = useToasterStore();

  useEffect(() => {
    let visibleCount = 0;

    for (const t of toasts) {
      if (t.visible) {
        if (visibleCount >= max) {
          toast.dismiss(t.id); // Dismiss – Use toast.remove(t.id) for no exit animation
        }
        visibleCount++;
      }
    }
  }, [toasts, max]);
}
