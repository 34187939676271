import * as Yup from "yup";

export const AssetsAndLiabilities = {
  assets: [
    "gross_fixed_assets",
    "depreciation_and_amortization",
    "investments",
    "loans_and_advances",
    "inventories",
    "trade_receivables",
    "cash_and_cash_equivalents",
    "other_assets",
    "net_fixed_assets",
  ],
  sub_totals: ["given_assets_total", "given_liabilities_total"],
  liabilities: [
    "contributions_received",
    "reserves_and_surplus",
    "secured_loans",
    "unsecured_loans",
    "short_term_borrowings",
    "trade_payables",
    "other_liabilities",
    "provisions_for_taxation",
    "provisions_for_contingencies",
    "provisions_for_insurance",
    "other_provisions",
  ],
};

export const assetsAndLiabilitiesSchema = Yup.object().shape({
  // Assets
  gross_fixed_assets: Yup.number().required("Gross fixed assets is required"),
  depreciation_and_amortization: Yup.number().required(
    "Depreciation and amortization is required",
  ),
  investments: Yup.number().required("Investments is required"),
  loans_and_advances: Yup.number().required("Loans and advances is required"),
  inventories: Yup.number().required("Inventories is required"),
  trade_receivables: Yup.number().required("Trade receivables is required"),
  cash_and_cash_equivalents: Yup.number().required(
    "Cash and cash equivalents is required",
  ),
  other_assets: Yup.number().required("Other assets is required"),
  net_fixed_assets: Yup.number().required("Net fixed assets is required"),

  // SubTotals
  given_assets_total: Yup.number().required("Given assets total is required"),
  given_liabilities_total: Yup.number().required(
    "Given liabilities total is required",
  ),

  // Liabilities
  contributions_received: Yup.number().required(
    "Contributions received is required",
  ),
  reserves_and_surplus: Yup.number().required(
    "Reserves and surplus is required",
  ),
  secured_loans: Yup.number().required("Secured loans is required"),
  unsecured_loans: Yup.number().required("Unsecured loans is required"),
  short_term_borrowings: Yup.number().required(
    "Short term borrowings is required",
  ),
  trade_payables: Yup.number().required("Trade payables is required"),
  other_liabilities: Yup.number().required("Other liabilities is required"),
  provisions_for_taxation: Yup.number().required(
    "Provisions for taxation is required",
  ),
  provisions_for_contingencies: Yup.number().required(
    "Provisions for contingencies is required",
  ),
  provisions_for_insurance: Yup.number().required(
    "Provisions for insurance is required",
  ),
  other_provisions: Yup.number().required("Other provisions is required"),
});

export type AssetsAndLiabilitiesType = Yup.InferType<
  typeof assetsAndLiabilitiesSchema
>;
