import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@app/store";
import { setProjectInfoModal } from "@app/store/slices/utilSlice";

export interface UseProjectModal {
  isProjectModalOpen: boolean;
  openProjectModal: (project_id: string) => void;
  closeProjectModal: () => void;
}

export const useProjectModal = (): UseProjectModal => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const isProjectModalOpen = useSelector(
    (globalState: RootState) => globalState.util.isProjectInfoModalOpen,
  );

  const openProjectModal = (projectId: string) => {
    searchParams.set("project_id", projectId);
    setSearchParams(searchParams);
    dispatch(setProjectInfoModal(true));
  };

  const closeProjectModal = () => {
    searchParams.delete("project_id");
    setSearchParams(searchParams);
    dispatch(setProjectInfoModal(false));
  };

  return { closeProjectModal, openProjectModal, isProjectModalOpen };
};
