import CryptoJS from "crypto-js";
import { ENV } from "@app/config/ENV";

const key = ENV.auth_secret_key;
const SECRET_KEY = key;

export const encryptToken = (token: string) => {
  return CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
};

export const decryptToken = (encryptedToken: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};
