import { useMemo, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { qaDbApiHandler } from "@app/config/apiHandler";
import type { Child } from "@app/types";
import toast from "react-hot-toast";
import { encryptToken } from "@app/utils/security";
import type { AxiosError } from "axios";
import { getToken } from "@app/config/interceptor";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import { useToggle } from "@app/hooks/useToggle";
import { PageLoader } from "@components/loader";
import { useDispatch } from "react-redux";
import { allRtkQuerySlices } from "@app/store";

export type Role = "QA" | "Admin" | "Verifier";

interface JwtPayloadWithRole extends JwtPayload {
  "cognito:groups": Role[];
  name: string;
  email: string;
  phone_number: string;
}

interface UserDetails {
  email: string;
  name: string;
  phone: string;
}

interface IAuthTypes {
  clearData: () => void;
  user: string | null;
  login: (email: string, password: string) => Promise<void>;
  logOut: () => void;
  role: Role[];
  isLoading: boolean;
  userDetails: UserDetails | null;
  changePassword: (
    currentPassword: string,
    newPassword: string,
  ) => Promise<void>;
  isPasswordUpdating: boolean;
}
export const AuthCtx = createContext({} as IAuthTypes);

export const AuthContext = ({ children }: Child): JSX.Element => {
  const [user, setUser] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [isPasswordUpdating, setIsPasswordUpdating] = useToggle();
  const [isLoading, toggleLoading] = useToggle(true);
  // TODO @sreehari-credhive - For now the lowest role is QA - This needs to be updated to lower role when new roles are introduced
  const [role, setRole] = useState<Role[]>(["QA"]);

  const dispatch = useDispatch();

  const router = useNavigate();

  useEffect(() => {
    const startRoute = new URL(window.location.href);
    const user = localStorage.getItem("userId") as string;
    if (!user) {
      router("/login");
    } else {
      setUser(user);
      if (startRoute.pathname === "/" || startRoute.pathname === "/login") {
        router("/labelling");
      } else {
        router(startRoute.pathname + startRoute.search);
      }
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const { data } = await qaDbApiHandler.post("/users/authenticate", {
        email,
        password,
      });

      const userInfo = jwtDecode(
        data?.response_data.token_details.IdToken,
      ) as JwtPayloadWithRole;

      if (userInfo["cognito:groups"]) {
        setRole(userInfo["cognito:groups"]);
      }

      setUserDetails({
        name: userInfo.name,
        email: userInfo.email,
        phone: userInfo.phone_number,
      });

      localStorage.setItem(
        "idToken",
        encryptToken(data?.response_data.token_details.IdToken),
      );
      localStorage.setItem(
        "accessToken",
        encryptToken(data?.response_data.token_details.AccessToken),
      );
      localStorage.setItem(
        "refreshToken",
        encryptToken(data?.response_data.token_details.RefreshToken),
      );
      localStorage.setItem("userId", data.response_data.user_details.id);
      setUser(data.response_data.user_details.id);
      router("/labelling");
      toast.success("Login Success");
    } catch (error) {
      const issue = error as AxiosError;
      const responseData = issue.response?.data as { detail?: string };
      toast.error(responseData?.detail ? responseData.detail : "Login Failed");
    }
  };

  // FOR ACCESSING USER ROLE FROM TOKEN
  useEffect(() => {
    try {
      toggleLoading.on();
      const token = getToken();
      if (token) {
        const userInfo = jwtDecode(token) as JwtPayloadWithRole;
        setUserDetails({
          name: userInfo.name,
          email: userInfo.email,
          phone: userInfo.phone_number,
        });
        setRole(userInfo["cognito:groups"]);
      } else {
        router("/login");
      }
    } finally {
      toggleLoading.off();
    }
  }, []);

  const clearData = () => {
    setUser(null);
    setUserDetails(null);
    setRole([]);
    // TODO @sreehari-credhive - This is a temporary solution to clear the data. We need to find a better solution to clear the data.
    // Ideally we should not have muliple rtk query slices.
    allRtkQuerySlices.forEach((slice) => {
      dispatch(slice.util.resetApiState());
    });
  };

  const logOut = () => {
    localStorage.clear();
    clearData();
    router("/login");
    toast.success("Logout Success");
  };

  const changePassword = async (
    currentPassword: string,
    newPassword: string,
  ) => {
    try {
      setIsPasswordUpdating.on();
      await qaDbApiHandler.post("/users/change-password", {
        current_password: currentPassword,
        new_password: newPassword,
      });
      toast.success("Password updated successfully");
      logOut();
    } catch (error) {
      const issue = error as AxiosError;
      const responseData = issue.response?.data as { detail?: string };
      toast.error(
        responseData?.detail ? responseData.detail : "Password update failed",
      );
    } finally {
      setIsPasswordUpdating.off();
    }
  };

  const response = useMemo(
    () => ({
      user,
      clearData,
      login,
      logOut,
      role,
      isLoading,
      userDetails,
      changePassword,
      isPasswordUpdating,
    }),
    [user, isLoading, userDetails, isPasswordUpdating],
  );

  return (
    <AuthCtx.Provider value={response}>
      {isLoading ? <PageLoader /> : children}
    </AuthCtx.Provider>
  );
};
