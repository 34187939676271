import { useListCustomerCompaniesQuery } from "@app/store/api/customerCompany";
import { NotFound } from "@components/common/NotFound";
import { PageLoader } from "@components/loader";
import { Table } from "@app/components/common/table";
import { useMemo, useState } from "react";
import { Button } from "@components/common/Button";
import { useToggle } from "@app/hooks/useToggle";
import {
  CreditHistory,
  PopUpTokenModal,
} from "@components/admin/account-manager";

type CompanyHistoryInfo = {
  companyName: string;
  companyId: string;
};

const AccountManager = () => {
  const { data, isLoading, isSuccess } = useListCustomerCompaniesQuery();
  const [openCreditHistory, toggleCreditHistory] = useToggle();
  const [openPopUpTokenModal, togglePopUpTokenModal] = useToggle();
  const [companyHistoryInfo, setCompanyHistoryInfo] =
    useState<CompanyHistoryInfo | null>(null);

  const customerData = useMemo(
    () =>
      data?.response_data.map((el) => ({
        name: el.name,
        contact_person: el.contact_person,
        contact_person_number: el.contact_number,
        contact_person_email: el.contact_email,
        history: (
          <Button
            variant="secondary"
            className="px-2 py-1"
            onClick={() => {
              setCompanyHistoryInfo({
                companyName: el.name,
                companyId: el.id,
              });
              toggleCreditHistory.on();
            }}
          >
            History
          </Button>
        ),
        addToken: (
          <Button
            className="px-2 py-1"
            onClick={() => {
              setCompanyHistoryInfo({
                companyName: el.name,
                companyId: el.id,
              });
              togglePopUpTokenModal.on();
            }}
          >
            Add Token
          </Button>
        ),
      })),
    [data],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (!data) {
    return <NotFound message="No companies found" />;
  }

  return (
    <section>
      {companyHistoryInfo && (
        <>
          <CreditHistory
            isOpen={openCreditHistory}
            onClose={toggleCreditHistory.off}
            companyName={companyHistoryInfo.companyName}
            companyId={companyHistoryInfo.companyId}
          />
          <PopUpTokenModal
            isOpen={openPopUpTokenModal}
            onClose={togglePopUpTokenModal.off}
            companyName={companyHistoryInfo.companyName}
            companyId={companyHistoryInfo.companyId}
          />
        </>
      )}
      <h1 className="text-2xl font-bold">
        Account Manager (Token Transactions)
      </h1>
      <div className="mt-10">
        {data.response_data && isSuccess && (
          <Table
            headers={[
              {
                name: "Company Name",
                value: "name",
              },
              {
                name: "Contact Person",
                value: "contact_person",
              },
              {
                name: "Contact Person Number",
                value: "contact_person_number",
              },
              {
                name: "Contact Person Email",
                value: "contact_person_email",
              },
              {
                name: "History",
                value: "history",
              },
              {
                name: "Action",
                value: "addToken",
              },
            ]}
            rows={customerData || []}
          />
        )}
      </div>
    </section>
  );
};

export default AccountManager;
