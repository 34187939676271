import { Accordion } from "@components/common/Accordion";
import { McaAccountData } from "./McaAccountData";
import { McaAccount } from "@app/store/api/mcaAccount/types";
import { QaDbApiResponse } from "@app/types";
interface AllMcaAccountsProps {
  mcaV2Data?: QaDbApiResponse<McaAccount[]>;
  mcaV2Skip: number;
  setMcaV2Skip: (skip: number) => void;
  mcaV2IsError: boolean;
  mcaV3Data?: QaDbApiResponse<McaAccount[]>;
  mcaV3Skip: number;
  setMcaV3Skip: (skip: number) => void;
  mcaV3IsError: boolean;
  isQa: boolean;
}

export const AllMcaAccounts = ({
  mcaV2Data,
  mcaV2Skip,
  setMcaV2Skip,
  mcaV2IsError,
  mcaV3Data,
  mcaV3Skip,
  setMcaV3Skip,
  mcaV3IsError,
  isQa,
}: AllMcaAccountsProps) => {
  return (
    <div className="space-y-4">
      <Accordion title="MCA V2 Accounts" defaultOpen>
        <McaAccountData
          isQa={isQa}
          data={mcaV2Data}
          skip={mcaV2Skip}
          setSkip={setMcaV2Skip}
          isError={mcaV2IsError}
        />
      </Accordion>

      <Accordion title="MCA V3 Accounts">
        <McaAccountData
          isQa={isQa}
          data={mcaV3Data}
          skip={mcaV3Skip}
          setSkip={setMcaV3Skip}
          isError={mcaV3IsError}
        />
      </Accordion>
    </div>
  );
};
