import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../axiosBase";
import { QaDbApiResponse } from "@app/types";
import { CompanyTeam } from "./customerCompany/types";

const customerCompaniesTeamsApi = createApi({
  reducerPath: "customerTeamsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["company-members"],
  endpoints: (builder) => ({
    searchCustomerTeams: builder.mutation<QaDbApiResponse<CompanyTeam>, string>(
      {
        query: (id) => ({
          url: `/customer-team/get?customer_team_id=${id}`,
          method: "GET",
        }),
      },
    ),
    getCompanyMembers: builder.query<
      QaDbApiResponse<CompanyMembers[]>,
      {
        customer_company_id: string;
      }
    >({
      query: ({ customer_company_id }) => ({
        url: "/customer-company/get-all-members-by-company-id",
        method: "GET",
        params: {
          customer_company_id: customer_company_id,
        },
      }),
      providesTags: ["company-members"],
    }),
  }),
});

const { useSearchCustomerTeamsMutation, useGetCompanyMembersQuery } =
  customerCompaniesTeamsApi;

export { useSearchCustomerTeamsMutation, useGetCompanyMembersQuery };
export default customerCompaniesTeamsApi;

interface CompanyMembers {
  name: string;
  email: string;
  phone_number: string;
  company_id: string;
  team_id: string;
  designation: string;
  id: string;
  cognito_user_id: string;
  created_at: string;
  updated_at: string;
  is_approved: boolean;
  is_active: boolean;
  last_login: string;
  team_name: string;
}
