import { Button } from "@components/common/Button";
import { ScrapingDataSource } from "@app/store/api/qaTasksApi/types";
import { RefreshCw } from "lucide-react";
import { animateRefresh } from "@app/utils/animateRefresh";

export const RenderDocumentCountAndStatus = ({
  task,
  projectIndex,
  taskIndex,
  scrapingStatus,
  getLoadingStatus,
  getStatus,
}: {
  task: {
    task_nature_name: string;
    identifier_value: string;
  };
  projectIndex: number;
  taskIndex: number;
  scrapingStatus: Array<{
    projectIndex: number;
    taskIndex: number;
    completed: number;
    total: number;
  }>;
  getLoadingStatus: (task: any) => boolean;
  getStatus: (
    identifierValue: string,
    taskNature:
      | ScrapingDataSource.SCRAPING_MCA_V2
      | ScrapingDataSource.SCRAPING_MCA_V3,
    projectIndex: number,
    taskIndex: number,
  ) => void;
}) => {
  const status = scrapingStatus.find(
    (el) => el.projectIndex === projectIndex && el.taskIndex === taskIndex,
  );

  if (
    task.task_nature_name === ScrapingDataSource.SCRAPING_MCA_V2 ||
    task.task_nature_name === ScrapingDataSource.SCRAPING_MCA_V3
  ) {
    return (
      <div className="flex items-center gap-2">
        {status?.total ? (
          <div className="flex border px-3 py-2 rounded-lg bg-green-50 shadow-sm w-full justify-center items-center gap-2">
            <span className="font-medium text-green-700">
              {status?.completed}
            </span>
            <span className="text-green-600">/</span>
            <span className="font-medium text-green-700">{status?.total}</span>
            <Button
              variant="transparent"
              onClick={(e) => {
                animateRefresh(e);
                getStatus(
                  task.identifier_value,
                  task.task_nature_name as
                    | ScrapingDataSource.SCRAPING_MCA_V2
                    | ScrapingDataSource.SCRAPING_MCA_V3,
                  projectIndex,
                  taskIndex,
                );
              }}
              className="h-8 w-8 hover:bg-transparent"
            >
              <RefreshCw className="h-4 w-4 refresh-icon" />
            </Button>
          </div>
        ) : (
          <Button
            isLoading={getLoadingStatus(task)}
            className="bg-white hover:bg-gray-50 border shadow-sm px-3 py-2 rounded-lg"
            onClick={() =>
              getStatus(
                task.identifier_value,
                task.task_nature_name as
                  | ScrapingDataSource.SCRAPING_MCA_V2
                  | ScrapingDataSource.SCRAPING_MCA_V3,
                projectIndex,
                taskIndex,
              )
            }
          >
            <span className="text-gray-700">Get Scraping Status</span>
          </Button>
        )}
      </div>
    );
  }

  return <span>-</span>;
};
