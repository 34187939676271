import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import { QaDbApiResponse } from "@app/types";

interface ScreenshotResponse {
  company_screenshot_presigned_url: string;
  company_screenshot_last_modified: string;
  director_screenshot_presigned_url: string;
  director_screenshot_last_modified: string;
}

export const screenshotsApi = createApi({
  reducerPath: "screenshots",
  baseQuery: axiosBaseQuery(parserApiHandler),
  endpoints: (builder) => ({
    getScreenshots: builder.query<QaDbApiResponse<ScreenshotResponse>, string>({
      query: (identifier_value) => ({
        url: `/editing-platform-data/screenshots/get-shallow-data-screenshots`,
        params: { identifier_value },
      }),
    }),
  }),
});

export const { useGetScreenshotsQuery } = screenshotsApi;
