import { NotFound } from "@components/common/NotFound";
import { PageLoader } from "@components/loader";
import { useEffect, lazy, Suspense, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { IdentifierTypeEnum } from "@app/types";
import { useGetShareHoldingMetaDataQuery } from "@app/store/api/shareHoldingsApi";
import { useGetLlpShareHoldingsMetadataQuery } from "@app/store/api/llpShareHoldingsApi";
import { Button } from "@components/common/Button";
import { FilePlus } from "lucide-react";
import { ListDocumentModal } from "@app/components/labelling/shareholder/components/create-documents/ListDocumentModal";
import { useToggle } from "@app/hooks/useToggle";

const ShareHoldingSummary = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/ShareHoldingSummary"
  ).then((module) => ({ default: module.ShareHoldingSummary })),
);
const ShareholdingPatternForm = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/ShareholdingPatternForm"
  ).then((module) => ({ default: module.ShareholdingPatternForm })),
);
const DirectorsHoldingTable = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/shareholding-tables/DirectorsHoldingTable"
  ).then((module) => ({ default: module.DirectorsHoldingTable })),
);
const CompanyHoldingTable = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/shareholding-tables/CompanyShareHoldingsTable"
  ).then((module) => ({ default: module.CompanyHoldingTable })),
);
const IndividualShareholdingsTable = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/shareholding-tables/IndividualShareholdingsTable"
  ).then((module) => ({ default: module.IndividualShareholdingsTable })),
);
const OtherShareHoldingTable = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/shareholding-tables/OtherShareholdingsTable"
  ).then((module) => ({ default: module.OtherShareHoldingTable })),
);
const DirectorRemuneration = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/shareholding-tables/DirectorRemuneration"
  ).then((module) => ({ default: module.DirectorRemuneration })),
);
const HoldingCompanies = lazy(() =>
  import(
    "@components/labelling/shareholder/components/joint-venture/HoldingCompanies"
  ).then((module) => ({ default: module.HoldingCompanies })),
);

const SubsidiaryCompanies = lazy(() =>
  import(
    "@components/labelling/shareholder/components/joint-venture/SubsidiaryCompanies"
  ).then((module) => ({ default: module.SubsidiaryCompanies })),
);

const JointVentureCompanies = lazy(() =>
  import(
    "@components/labelling/shareholder/components/joint-venture/JointVentureCompanies"
  ).then((module) => ({ default: module.JointVentureCompanies })),
);

const AssociateCompanies = lazy(() =>
  import(
    "@components/labelling/shareholder/components/joint-venture/AssociateCompanies"
  ).then((module) => ({ default: module.AssociateCompanies })),
);

const PrincipleBusinessActivity = lazy(() =>
  import("@app/components/labelling/llp/shareholding").then((module) => ({
    default: module.PrincipleBusinessActivity,
  })),
);

const CinPrincipleBusinessActivity = lazy(() =>
  import(
    "@app/components/labelling/shareholder/components/principle-business-activity/PrincipleBusinessActivity"
  ).then((module) => ({
    default: module.PrincipleBusinessActivity,
  })),
);

const ContributionAndDetailsIndividualPartners = lazy(() =>
  import("@app/components/labelling/llp/shareholding").then((module) => ({
    default: module.ContributionAndDetailsIndividualPartners,
  })),
);

const ContributionBodyCorporate = lazy(() =>
  import("@app/components/labelling/llp/shareholding").then((module) => ({
    default: module.ContributionBodyCorporate,
  })),
);

const ShareHoldingPattern = () => {
  const { pathname, hash, key } = useLocation();
  const { cin } = useParams();
  const [isDocumentCreationModalOpen, toggleDocumentCreationModal] =
    useToggle();

  const companyType = useSelector(
    (state: RootState) => state.company.typeOfCompany,
  );

  const {
    data: pvtCompanyMetadata,
    isLoading: pvtCompanyIsLoading,
    isError: pvtCompanyIsError,
  } = useGetShareHoldingMetaDataQuery(cin as string, {
    skip: companyType !== IdentifierTypeEnum.CIN,
  });

  const {
    data: llpMetadata,
    isLoading: llpIsLoading,
    isError: llpIsError,
  } = useGetLlpShareHoldingsMetadataQuery(cin as string, {
    skip: companyType !== IdentifierTypeEnum.LLPIN,
  });

  const metadata =
    companyType === IdentifierTypeEnum.CIN ? pvtCompanyMetadata : llpMetadata;

  const isLoading =
    companyType === IdentifierTypeEnum.CIN ? pvtCompanyIsLoading : llpIsLoading;

  const isError =
    companyType === IdentifierTypeEnum.CIN ? pvtCompanyIsError : llpIsError;

  const latestYearMetaData = useMemo(() => {
    if (
      !metadata ||
      !metadata.response_data ||
      Object.keys(metadata.response_data).length === 0
    )
      return null;

    return Object.keys(metadata?.response_data)
      .map((el) => Number(el))
      .sort((a, b) => b - a)[0]
      .toString();
  }, [metadata]);

  const activeMetaData = metadata?.response_data[latestYearMetaData as string];

  const isMetaDataEmpty = Object.keys(activeMetaData || {}).length === 0;

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || !activeMetaData || !latestYearMetaData || isMetaDataEmpty) {
    return (
      <NotFound message="Error Getting ShareHolding Info" className="h-full" />
    );
  }

  if (companyType === IdentifierTypeEnum.CIN) {
    return (
      <main>
        <section className="flex justify-between items-baseline">
          <ListDocumentModal
            identifierType="CIN"
            identifierValue={cin as string}
            isOpen={isDocumentCreationModalOpen}
            onRequestClose={toggleDocumentCreationModal.off}
            dataType="shareholdings"
          />
          <h2 className="text-2xl font-bold mb-6">
            Company ShareHolding Patterns
            <span className="text-sm ml-4 border p-2 rounded-md font-semibold">
              {activeMetaData.file_name}
            </span>
          </h2>
          <div className="flex items-center gap-4">
            <Button
              variant="secondary"
              className="px-4 py-2 text-sm font-medium rounded-md hover:bg-gray-10"
              rightIcon={FilePlus}
              onClick={toggleDocumentCreationModal.on}
            >
              Fill New Document
            </Button>
          </div>
        </section>

        <Suspense fallback={<PageLoader />}>
          <ShareHoldingSummary shareHoldingMetaData={activeMetaData} />
          <ShareholdingPatternForm shareHoldingMetaData={activeMetaData} />
        </Suspense>

        <Suspense fallback={<PageLoader />}>
          <div className="mt-6">
            <CinPrincipleBusinessActivity
              shareHoldingMetaData={activeMetaData}
            />
          </div>
        </Suspense>

        <section
          className="w-[calc(100vw-300px)] h-full order border-red-400"
          id="detailed-shareholding"
        >
          <Suspense fallback={<PageLoader />}>
            <DirectorsHoldingTable shareHoldingMetaData={activeMetaData} />
            <CompanyHoldingTable shareHoldingMetaData={activeMetaData} />
            <IndividualShareholdingsTable
              shareHoldingMetaData={activeMetaData}
            />
            <OtherShareHoldingTable shareHoldingMetaData={activeMetaData} />
          </Suspense>
        </section>
        <section
          className="mt-10 w-[calc(100vw-300px)]"
          id="detailed-remuneration"
        >
          <Suspense fallback={<PageLoader />}>
            <DirectorRemuneration shareHoldingMetaData={activeMetaData} />
          </Suspense>
        </section>
        <section className="mt-10 w-[calc(100vw-300px)]" id="joint-ventures">
          <Suspense fallback={<PageLoader />}>
            <HoldingCompanies shareHoldingMetaData={activeMetaData} />
            <SubsidiaryCompanies shareHoldingMetaData={activeMetaData} />
            <JointVentureCompanies shareHoldingMetaData={activeMetaData} />
            <AssociateCompanies shareHoldingMetaData={activeMetaData} />
          </Suspense>
        </section>
      </main>
    );
  }

  if (companyType === IdentifierTypeEnum.LLPIN) {
    return (
      <main>
        <section className="flex justify-between items-baseline">
          <ListDocumentModal
            identifierType="CIN"
            identifierValue={cin as string}
            isOpen={isDocumentCreationModalOpen}
            onRequestClose={toggleDocumentCreationModal.off}
            dataType="shareholdings"
          />
          <h2 className="text-2xl font-bold mb-6" id="principle-business">
            ShareHolding Patterns (LLP)
          </h2>
          <div className="flex items-center gap-4">
            <Button
              variant="secondary"
              className="px-4 py-2 text-sm font-medium rounded-md hover:bg-gray-10"
              rightIcon={FilePlus}
              onClick={toggleDocumentCreationModal.on}
            >
              Fill New Document
            </Button>
          </div>
        </section>
        <Suspense fallback={<PageLoader />}>
          <PrincipleBusinessActivity shareHoldingMetaData={activeMetaData} />
        </Suspense>
        <section className="border rounded-md p-4 mt-6">
          <div className="flex flex-col gap-2" id="partners">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold">
                Contribution and Details
              </h3>
            </div>
            <hr className="w-full p-4" />
            <Suspense fallback={<PageLoader />}>
              <ContributionAndDetailsIndividualPartners
                shareHoldingMetaData={activeMetaData}
              />
            </Suspense>
            <Suspense fallback={<PageLoader />}>
              <ContributionBodyCorporate
                shareHoldingMetaData={activeMetaData}
              />
            </Suspense>
          </div>
        </section>
      </main>
    );
  }
};

export default ShareHoldingPattern;
