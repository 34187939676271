import React from "react";
import { xbrlAsProfitAndLossWithHeading } from "./xbrlAsValidation";
import { useFormContext, FormProvider } from "react-hook-form";
import { InputGroup } from "../InputGroup";
import { XbrlAsBalanceSheetInputsProps } from "./XbrlAsBalanceSheetInput";

export const XbrlAsBalanceSheetInputs: React.FC<
  XbrlAsBalanceSheetInputsProps
> = ({ isEditable, isPdfOpen }) => {
  const form = useFormContext();

  return (
    <FormProvider {...form}>
      <InputGroup
        title="Line Items"
        fields={xbrlAsProfitAndLossWithHeading.line_items}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Revenue"
        fields={xbrlAsProfitAndLossWithHeading.revenue}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Expenses"
        fields={xbrlAsProfitAndLossWithHeading.expenses}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Revenue Breakup"
        fields={xbrlAsProfitAndLossWithHeading.revenue_breakup}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
    </FormProvider>
  );
};
