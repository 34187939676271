interface Props {
  count?: number;
}

export const Skelton = ({ count = 10 }: Props) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div className="border border-gray-200 rounded-lg p-4 mt-4" key={index}>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div className="h-4 w-20 bg-gray-200 animate-pulse rounded" />
              <div className="h-4 w-24 bg-gray-200 animate-pulse rounded" />
            </div>
            <div className="flex justify-between">
              <div className="h-4 w-16 bg-gray-200 animate-pulse rounded" />
              <div className="h-4 w-20 bg-gray-200 animate-pulse rounded" />
            </div>
            <div className="flex justify-between">
              <div className="h-4 w-24 bg-gray-200 animate-pulse rounded" />
              <div className="h-4 w-32 bg-gray-200 animate-pulse rounded" />
            </div>
            <div className="flex justify-between">
              <div className="h-4 w-20 bg-gray-200 animate-pulse rounded" />
              <div className="h-4 w-16 bg-gray-200 animate-pulse rounded" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
