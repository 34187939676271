import { useState } from "react";
import classNames from "classnames";
import { ApprovedUsers, UnApprovedUsers } from "@components/admin/users-list";
import { TeamDrawer } from "@app/components/admin/company-list";
import { Plus } from "lucide-react";
import { Button } from "@components/common/Button";
import { useToggle } from "@app/hooks/useToggle";
import { SupportedTeamDrawerTypes } from "@components/admin/company-list/components/TeamDrawer";

export type TeamDrawerCompany = {
  name: string;
  id: string;
};

export type UsersListDrawerType = Omit<SupportedTeamDrawerTypes, "team-info">;

const UsersList = () => {
  const [isTeamDrawerOpen, toggleTeamDrawerOpen] = useToggle();
  const [typeOfDrawer, setTypeOfDrawer] =
    useState<SupportedTeamDrawerTypes>("team-info");
  const [selectedCompany, setSelectedCompany] = useState<TeamDrawerCompany>({
    name: "",
    id: "",
  });
  const [activeTab, setActiveTab] = useState<"approved" | "unapproved">(
    "approved",
  );

  const openTeamDrawer = (
    type: UsersListDrawerType,
    company: TeamDrawerCompany = { name: "", id: "" },
  ) => {
    setSelectedCompany(company);
    setTypeOfDrawer(type as SupportedTeamDrawerTypes);
    toggleTeamDrawerOpen.on();
  };

  return (
    <div>
      <TeamDrawer
        typeOfDrawer={typeOfDrawer}
        isOpen={isTeamDrawerOpen}
        onClose={toggleTeamDrawerOpen.off}
        company={selectedCompany}
      />
      <div className="flex justify-between items-center">
        <h2 className="text-start text-2xl font-bold">Users List</h2>
        <Button
          rightIcon={Plus}
          variant="secondary"
          onClick={() => {
            setTypeOfDrawer("create-company");
            toggleTeamDrawerOpen.on();
          }}
        >
          Add Customer Company
        </Button>
      </div>
      <div className=" mt-2">
        <div className="flex border-b border-gray-300">
          <button
            type="button"
            className={classNames(
              "flex-1 py-2 text-center transition-colors duration-300",
              {
                "border-b-2 border-blue-500 text-blue-500":
                  activeTab === "approved",
                "text-gray-500 hover:text-blue-500": activeTab !== "approved",
              },
            )}
            onClick={() => setActiveTab("approved")}
          >
            Approved Users
          </button>
          <button
            type="button"
            className={classNames(
              "flex-1 py-2 text-center transition-colors duration-300",
              {
                "border-b-2 border-blue-500 text-blue-500":
                  activeTab === "unapproved",
                "text-gray-500 hover:text-blue-500": activeTab !== "unapproved",
              },
            )}
            onClick={() => setActiveTab("unapproved")}
          >
            Unapproved Users
          </button>
        </div>
        <div className="py-2">
          {activeTab === "approved" && <ApprovedUsers />}
          {activeTab === "unapproved" && (
            <UnApprovedUsers openTeamDrawer={openTeamDrawer} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersList;
