import {
  BalanceSheet,
  ProfitAndLoss,
  PrimaryAuditor,
  AdditionalAuditor,
  RelatedParty,
} from "@app/components/labelling/finances";
import { useToggle } from "@app/hooks/useToggle";
import { Icon } from "@components/common";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import type { Option } from "@app/components/common/dropdown";
import { useParams } from "react-router-dom";
import { NotFound } from "@components/common/NotFound";
import {
  FinancialTypes,
  useGetFinancialMetaDataForCinQuery,
} from "@app/store/api/financialApi";
import { useGetLlpFinancialsMetaDataQuery } from "@app/store/api/llp-financialsApi";
import { useCreatePresignedUrlMutation } from "@app/store/api/documentsApi";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";

import {
  FinancialNavBar,
  Statement,
} from "@app/components/labelling/llp/financials";
import { IdentifierTypeEnum } from "@app/types";
import toast from "react-hot-toast";
import { valueOrEmpty } from "@app/utils/valueOrEmpty";

export type FormNature = "STANDALONE" | "CONSOLIDATED";

const FinancialPage = () => {
  const { cin } = useParams();
  const companyType = useSelector(
    (state: RootState) => state.company.typeOfCompany,
  );

  const {
    data: financialMetaData,
    isLoading: isFinancialMetaDataLoading,
    isError: isFinancialMetaDataError,
  } = useGetFinancialMetaDataForCinQuery(cin as string, {
    skip: companyType !== IdentifierTypeEnum.CIN,
  });

  const {
    data: llpFinancialMetaData,
    isLoading: isLlpFinancialMetaDataLoading,
    isError: isLlpFinancialMetaDataError,
  } = useGetLlpFinancialsMetaDataQuery(cin as string, {
    skip: companyType !== IdentifierTypeEnum.LLPIN,
  });

  const isLoading =
    companyType === IdentifierTypeEnum.CIN
      ? isFinancialMetaDataLoading
      : isLlpFinancialMetaDataLoading;

  const isError =
    companyType === IdentifierTypeEnum.CIN
      ? isFinancialMetaDataError
      : isLlpFinancialMetaDataError;

  const computedFinancialMetaData =
    companyType === IdentifierTypeEnum.CIN
      ? financialMetaData
      : llpFinancialMetaData;

  const [isEditable, toggleEditable] = useToggle();

  const [isNotificationExist, toggleNotification] = useToggle();
  // PreSignedUrl is stored here
  const [activeDocument, setActiveDocument] = useState<string>("");
  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [selectedFile, setSelectedFile] = useState<string>("");

  const isPdfOpen = activeDocument.length !== 0;

  const [selectedYear, setSelectedYear] = useState<Option>({
    label: "",
    value: "",
  });

  const [selectedType, setSelectedType] = useState<Option<FormNature>>({
    label: "STANDALONE",
    value: "STANDALONE",
  });

  const [activeTab, setActiveTab] = useState<"profit-loss" | "balance-sheet">(
    "balance-sheet",
  );

  const activeFormType =
    selectedType.value === "STANDALONE"
      ? FinancialTypes.STANDALONE
      : FinancialTypes.CONSOLIDATED;

  const generatePreSignedUrl = (s3Url: string) => {
    // This is used to force the pdf viewer (PdfViewer.jsx) to reload the pdf
    // TODO @sreehari-credhive : This is expensive and should be fixed
    setActiveDocument("");
    toast.promise(
      createPresignedUrl({
        s3_url: s3Url,
      }).unwrap(),
      {
        loading: "Opening Pdf",
        error: "Error Loading Pdf",
        success: (data) => {
          setActiveDocument(data.pre_signed_url);
          return "";
        },
      },
    );

    return "";
  };

  useEffect(() => {
    const notificationShown = sessionStorage.getItem("financial-notification");
    if (!notificationShown) {
      toggleNotification.on();
      sessionStorage.setItem("financial-notification", "true");
    }
  }, []);

  const availableYears = useMemo((): Option<string>[] => {
    if (!computedFinancialMetaData?.response_data) return [];

    const sortedAvailableYears = Object.keys(
      computedFinancialMetaData.response_data,
    )
      .map((year) => Number(year))
      .sort((a, b) => b - a);

    return sortedAvailableYears.map((year) => ({
      label: year.toString(),
      value: year.toString(),
    }));
  }, [computedFinancialMetaData]);

  // Default value for year
  useEffect(() => {
    if (availableYears.length && selectedYear.value.length === 0) {
      setSelectedYear(availableYears[0]);
    }
  }, [availableYears]);

  const financialInfo = useMemo(() => {
    if (
      !computedFinancialMetaData ||
      !computedFinancialMetaData.response_data ||
      !selectedYear.value.length
    )
      return null;

    // // TODO Remove the ts "as"
    return computedFinancialMetaData.response_data[selectedYear.value][
      selectedType.value.toLowerCase() as FinancialTypes
    ];
  }, [selectedYear, selectedType, computedFinancialMetaData]);

  const availableDocuments = useMemo(() => {
    const documents = [];

    documents.push({
      fileName: valueOrEmpty(financialInfo?.file_name),
      s3Url: valueOrEmpty(financialInfo?.s3_url),
    });

    financialInfo?.attachments.forEach((docs) => {
      documents.push({
        fileName: valueOrEmpty(docs.file_name),
        s3Url: valueOrEmpty(docs?.s3_url),
      });
    });

    return documents;
  }, [financialInfo]);

  const availableFormType: Option<FormNature>[] = useMemo(() => {
    // Retrieve financial types for the selected year
    const financialTypes =
      computedFinancialMetaData?.response_data?.[selectedYear.value];

    const options: Option<FormNature>[] = [];

    // Check if financialTypes is defined and is an object
    if (!financialTypes || typeof financialTypes !== "object") {
      return [
        {
          label: FinancialTypes.STANDALONE,
          value: FinancialTypes.STANDALONE,
        },
      ];
    }

    // Use Object.entries to iterate and filter the entries
    Object.entries(financialTypes)
      .filter(([, value]) => typeof value === "object" && value !== null)
      .forEach(([key]) => {
        // Custom labels for specific keys
        if (key === "standalone") {
          options.push({
            label: FinancialTypes.STANDALONE,
            value: FinancialTypes.STANDALONE,
          });
        }
        if (key === "consolidated") {
          options.push({
            label: FinancialTypes.CONSOLIDATED,
            value: FinancialTypes.CONSOLIDATED,
          });
        }
      });

    return options;
  }, [selectedYear, computedFinancialMetaData]);

  useEffect(() => {
    if (financialInfo) {
      generatePreSignedUrl(financialInfo.s3_url);
    }
  }, [financialInfo]);

  useEffect(() => {
    if (availableDocuments) {
      setSelectedFile(availableDocuments[0].fileName);
    }
  }, [availableDocuments]);

  if (isLoading) {
    return (
      <section className="flex flex-col">
        <h2 className="text-start text-2xl font-bold">Company Finances</h2>
        <div className="h-screen flex justify-center items-center">
          <Icon name="Loader" className="animate-spin" />
        </div>
      </section>
    );
  }

  if (isError) {
    return (
      <section>
        <h2 className="text-start text-2xl font-bold text-nowrap">
          Company Finances
        </h2>
        <div className="mt-40">
          <NotFound message="No Financial Data Found" />
        </div>
      </section>
    );
  }

  if (companyType === IdentifierTypeEnum.CIN) {
    return (
      <main>
        {isNotificationExist && (
          <div className="bg-green-400 px-3 py-3 rounded-md flex justify-between mb-2">
            <h4 className="font-semibold">
              Some form might not have both standalone and consolidated info
            </h4>
            <div
              onClick={() => {
                toggleNotification.off();
              }}
            >
              <Icon name="X" className="cursor-pointer" />
            </div>
          </div>
        )}
        <FinancialNavBar
          setSelectedFile={setSelectedFile}
          availableYears={availableYears}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          availableDocuments={availableDocuments}
          selectedFile={selectedFile}
          generatePreSignedUrl={generatePreSignedUrl}
          isEditable={isEditable}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          isPdfOpen={isPdfOpen}
          closePdf={() => setActiveDocument("")}
          toggleEditable={toggleEditable}
          formNatureOptions={availableFormType}
        />
        <div className="flex border-b mt-4">
          <button
            className={classNames("flex-1 py-2 text-center", {
              "border-b-2 border-blue-500 text-blue-500":
                activeTab === "balance-sheet",
              "text-gray-500": activeTab !== "balance-sheet",
            })}
            onClick={() => {
              toggleEditable.off();
              setActiveTab("balance-sheet");
            }}
            type="button"
          >
            Balance Sheet
          </button>
          <button
            type="button"
            className={classNames("flex-1 py-2 text-center", {
              "border-b-2 border-blue-500 text-blue-500":
                activeTab === "profit-loss",
              "text-gray-500": activeTab !== "profit-loss",
            })}
            onClick={() => {
              toggleEditable.off();
              setActiveTab("profit-loss");
            }}
          >
            Profit And Loss
          </button>
        </div>
        {activeTab === "balance-sheet" && financialInfo && (
          <BalanceSheet
            isEditable={isEditable}
            closeEdit={toggleEditable.off}
            activeInfo={financialInfo}
            formNature={activeFormType}
            isPdfOpen={isPdfOpen}
            activePdf={activeDocument}
            activeYear={selectedYear.value}
          />
        )}
        {activeTab === "profit-loss" && financialInfo && (
          <ProfitAndLoss
            isEditable={isEditable}
            closeEdit={toggleEditable.off}
            activeInfo={financialInfo}
            formNature={selectedType.value}
            isPdfOpen={isPdfOpen}
            activePdf={activeDocument}
            activeYear={selectedYear.value}
          />
        )}
        {financialInfo && (
          <>
            <PrimaryAuditor
              activeInfo={financialInfo}
              formNature={activeFormType}
            />
            <AdditionalAuditor
              activeInfo={financialInfo}
              formNature={activeFormType}
            />
            <RelatedParty
              activeInfo={financialInfo}
              formNature={activeFormType}
            />
          </>
        )}
      </main>
    );
  }

  if (companyType === IdentifierTypeEnum.LLPIN) {
    return (
      <main className="flex flex-col  mt-5">
        <FinancialNavBar
          formNatureOptions={availableFormType}
          availableYears={availableYears}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          availableDocuments={[]}
          selectedFile={selectedFile}
          generatePreSignedUrl={generatePreSignedUrl}
          isEditable={isEditable}
          isPdfOpen={isPdfOpen}
          closePdf={() => setActiveDocument("")}
          toggleEditable={toggleEditable}
          setSelectedFile={setSelectedFile}
        />
        {financialInfo && (
          <>
            <Statement
              selectedNature={activeFormType}
              financialInfo={financialInfo}
              selectedYear={selectedYear}
              activePdf={activeDocument}
              isPdfOpen={isPdfOpen}
              isEditable={isEditable}
              toggleEditable={toggleEditable}
            />
            <PrimaryAuditor
              activeInfo={financialInfo}
              formNature={activeFormType}
            />
          </>
        )}
      </main>
    );
  }
};

export default FinancialPage;
