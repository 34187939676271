import { useGetScreenshotsQuery } from "@app/store/api/screenshotsApi";
import { useParams } from "react-router-dom";
import { PageLoader } from "@components/loader";
import { AlertCircle } from "lucide-react";
import { formatDate } from "@app/utils/formatDate";

const Management = () => {
  const { cin } = useParams();
  const { data, isLoading, isError } = useGetScreenshotsQuery(cin as string);

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || !data) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-10rem)]">
        <AlertCircle size={16} className="text-red-500 mb-4" />
        <h3 className="text-xl font-semibold">Failed to load screenshots</h3>
      </div>
    );
  }

  return (
    <main className="p-6">
      <h2 className="text-2xl font-bold mb-6">Company Management</h2>
      <div className="grid grid-cols-1 gap-6">
        {/* Director Screenshot */}
        <div className="border rounded-lg overflow-hidden">
          <div className="bg-gray-50 p-4 border-b">
            <h3 className="font-semibold">Director Screenshot</h3>
            <p className="text-sm text-gray-600">
              Last updated:{" "}
              {formatDate(data.response_data.director_screenshot_last_modified)}
            </p>
          </div>
          <div className="p-4">
            <img
              src={data.response_data.director_screenshot_presigned_url}
              alt="Director Screenshot"
              className="w-full h-auto rounded"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Management;
