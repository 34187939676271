import { useGetApprovedUsersQuery } from "@app/store/api/usersList/usersList";
import { Table } from "@components/common/table/Table";
import { PageLoader } from "@components/loader";
import { useMemo } from "react";
import type { Header } from "@components/common/table/table.types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@app/components/common/ToolTip";
import toast from "react-hot-toast";
import { StatusBox } from "@components/common";
import { CognitoUser } from "@app/store/api/usersList/types";

const headers: Header<CognitoUser>[] = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone Number", value: "phone_number" },
  { name: "Number Verified", value: "phone_number_verified" },
  { name: "Email Verified", value: "email_verified" },
  { name: "Country", value: "custom:country" },
  { name: "Company", value: "custom:company" },
  { name: "Team", value: "custom:team" },
  { name: "State", value: "custom:state" },
  { name: "City", value: "custom:city" },
  { name: "User ID", value: "user_id" },
  { name: "Team ID", value: "custom:customer_team_id" },
  { name: "Company ID", value: "custom:customer_company_id" },
];

type Key = keyof CognitoUser;

export const ApprovedUsers = () => {
  const { data, isLoading, isError } = useGetApprovedUsersQuery();

  const copyId = (id: string, name: Key) => {
    window.navigator.clipboard.writeText(id);
    const keyToName = headers.find((el) => el.value === name)?.name;
    toast.success(`${keyToName} Was Copied To Clipboard`);
  };

  const rows = useMemo(
    () =>
      data?.response_data.map((user) => ({
        ...user,
        user_id: user.user_id && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className="px-2 py-1 border rounded-md hover:cursor-pointer flex justify-center items-center gap-2 max-w-96"
                  onClick={() => copyId(user.user_id, "user_id")}
                >
                  <span className="truncate">{user.user_id}</span>
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-800 text-xs text-white">
                {user.user_id}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        "custom:customer_team_id": user["custom:customer_team_id"] && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className="px-2 py-1 border rounded-md hover:cursor-pointer flex justify-center items-center gap-2 max-w-96"
                  onClick={() =>
                    copyId(
                      user["custom:customer_team_id"],
                      "custom:customer_team_id",
                    )
                  }
                >
                  <span className="truncate">
                    {user["custom:customer_team_id"]}
                  </span>
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-800 text-xs text-white">
                {user["custom:customer_team_id"]}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        phone_number_verified: (
          <StatusBox
            statusText={
              user.phone_number_verified === "true"
                ? "VERIFIED"
                : "NOT-VERIFIED"
            }
            color={
              user.phone_number_verified === "true"
                ? "bg-green-500"
                : "bg-red-500"
            }
          />
        ),
        email_verified: (
          <StatusBox
            statusText={
              user.email_verified === "true" ? "VERIFIED" : "NOT-VERIFIED"
            }
            color={
              user.email_verified === "true" ? "bg-green-500" : "bg-red-500"
            }
          />
        ),
        "custom:customer_company_id": user["custom:customer_company_id"] && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className="px-2 py-1 border rounded-md hover:cursor-pointer flex justify-center items-center gap-2 max-w-96"
                  onClick={() =>
                    copyId(
                      user["custom:customer_company_id"],
                      "custom:customer_company_id",
                    )
                  }
                >
                  <span className="truncate">
                    {user["custom:customer_company_id"]}
                  </span>
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-800 text-xs text-white">
                {user["custom:customer_company_id"]}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
      })) || [],
    [data],
  );

  if (isLoading) {
    return (
      <PageLoader
        className="w-full h-full"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 285px)" }}
      />
    );
  }

  if (isError) {
    return <h1>Error</h1>;
  }

  return (
    <section className="mt-4">
      <Table headers={headers} rows={rows} isLoading={false} />
    </section>
  );
};
