import { OrNull } from "../shareHoldingsApi/types";

export interface CreditRating {
  rating: string;
  ratings_date: string;
  ratings_url: string;
  rating_agency: keyof typeof CreditAgency;
  outlook: string | null;
  instrument: string;
  rating_amount: number | null;
  entity_id: string;
  id: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateCreditRatingPayload
  extends Omit<Partial<OrNull<CreditRating>>, "rating_agency"> {
  rating_agency: string | null;
  credit_rating_id: string;
}

export type RatingType = "InstrumentRating" | "BankLoanRating" | "CreditRating";

export const CreditAgency = {
  BRICKWORK: "BRICKWORK",
  CARE: "CARE",
  CRISIL: "CRISIL",
  ICRA: "ICRA",
  INDIA: "INDIA",
  INFOMERICS: "INFOMERICS",
};

export interface RatingGroup {
  [key: string]: CreditRating[];
}

export interface CreateCreditRating {
  rating: string;
  ratings_date: string;
  ratings_url: string;
  rating_agency: string;
  outlook: string;
  instrument: string;
  rating_amount: number;
  identifier_value: string;
}
