import { useState, type FC } from "react";
import classNames from "classnames";
import { useOutsideClick } from "@app/hooks/useOutsideClick";

export interface Option<T = string> {
  value: T;
  label: T;
}

interface DropdownProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  options: Option[];
  value: Option | null;
  onChange: (option: Option) => void;
  disabled?: boolean;
  error?: string;
}

export const DropDown: FC<DropdownProps> = ({
  options,
  value,
  onChange,
  className,
  disabled = false,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => setIsOpen(false));

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: Option) => {
    if (!disabled) {
      onChange(option);
      setIsOpen(false);
    }
  };

  return (
    <div ref={dropdownRef} className={classNames("relative w-full", className)}>
      <button
        type="button"
        className={classNames(
          "inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50",
          {
            "cursor-not-allowed bg-neutral-100": disabled,
            "border border-red-500": error,
          },
        )}
        onClick={handleToggle}
        disabled={disabled}
      >
        {value?.label ?? "Select an option"}
        <span className="ml-2">&#9660;</span>
      </button>
      {isOpen && !disabled && (
        <div className="absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1 max-h-60 overflow-auto">
            {options.map((option) => (
              <button
                type="button"
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};
