export const headers = [
  {
    name: "Company CIN",
    value: "companyCIN",
  },
  {
    name: "Company Name",
    value: "companyName",
  },
  {
    name: "Version",
    value: "version",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Created At",
    value: "created_at",
  },
  {
    name: "Updated At",
    value: "updated_at",
  },
  {
    name: "",
    value: "viewDetails",
  },
];
