import React from "react";
import { ListDocumentModal } from "@app/components/labelling/shareholder/components/create-documents/ListDocumentModal";
import { DropDown, Option } from "@app/components/common/dropdown";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { IdentifierTypeEnum } from "@app/types";
import { Icon } from "@components/common/Icon";
import { FormNature } from "@pages/FinancialPage";
import { Toggle, useToggle } from "@app/hooks/useToggle";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button } from "@components/common/Button";
import { FilePlus } from "lucide-react";
import classNames from "classnames";

interface AvailableDocuments {
  fileName: string;
  s3Url: string;
}
interface Props {
  availableYears: Option[];
  setSelectedYear: (year: Option) => void;
  availableDocuments: AvailableDocuments[];
  selectedFile: string;
  setSelectedFile: React.Dispatch<string>;
  generatePreSignedUrl: (s3Url: string) => void;
  isEditable: boolean;
  selectedYear: Option;
  selectedType?: Option<FormNature>;
  setSelectedType?: (type: Option<FormNature>) => void;
  isPdfOpen: boolean;
  closePdf: () => void;
  toggleEditable: Toggle;
  title?: string;
  formNatureOptions: Option<FormNature>[];
  showDocumentCreationModal?: boolean;
}

export const FinancialNavBar = ({
  availableYears,
  selectedYear,
  setSelectedYear,
  availableDocuments,
  selectedFile,
  generatePreSignedUrl,
  isEditable,
  selectedType,
  setSelectedType,
  isPdfOpen,
  closePdf,
  toggleEditable,
  title,
  formNatureOptions,
  setSelectedFile,
  showDocumentCreationModal = true,
}: Props) => {
  const companyType = useSelector(
    (state: RootState) => state.company.typeOfCompany,
  );

  const { cin } = useParams();
  const [isDocumentCreationModalOpen, toggleDocumentCreationModal] =
    useToggle();

  return (
    <nav className="flex justify-between items-start">
      <ListDocumentModal
        identifierType="CIN"
        identifierValue={cin as string}
        isOpen={isDocumentCreationModalOpen}
        onRequestClose={toggleDocumentCreationModal.off}
        dataType="financials"
      />
      <div
        className={classNames(
          "flex gap-1",
          showDocumentCreationModal ? "flex-col" : "flex-row",
        )}
      >
        <h2 className="text-2xl font-bold text-nowrap">
          {title ? title : "Company Financials"}
          {companyType === IdentifierTypeEnum.LLPIN ? "(LLP)" : ""}
        </h2>
        {selectedFile && (
          <DropDown
            className="w-full md:w-auto max-w-96 max-h-10 mb-3"
            options={availableDocuments.map((el) => ({
              label: el.fileName ?? "",
              value: el.fileName ?? "",
            }))}
            value={{
              label: selectedFile,
              value: selectedFile,
            }}
            onChange={(e) => {
              const document = availableDocuments.find(
                (el) => el.fileName == e.value,
              );
              if (document?.fileName && document.s3Url) {
                setSelectedFile(document?.fileName);
                generatePreSignedUrl(document.s3Url);
              } else {
                toast.error("Error Opening File");
              }
            }}
          />
        )}
      </div>
      <div className="flex gap-3">
        {showDocumentCreationModal && (
          <Button
            variant="secondary"
            rightIcon={FilePlus}
            onClick={toggleDocumentCreationModal.on}
          >
            Fill New Document
          </Button>
        )}
        {isPdfOpen && (
          <button
            onClick={closePdf}
            type="button"
            className="w-full md:w-auto bg-blue-500 text-white px-3 rounded-lg py-2 hover:bg-blue-600 inline-flex justify-center items-center gap-2"
          >
            Close Pdf
            <Icon name="X" size={18} />
          </button>
        )}
        {selectedType && setSelectedType && (
          <DropDown
            className="w-full md:w-auto"
            options={formNatureOptions}
            value={selectedType ?? null}
            onChange={(e) => setSelectedType(e as Option<FormNature>)}
            disabled={formNatureOptions.length === 1}
          />
        )}
        <DropDown
          className="w-full md:w-auto min-w-32"
          options={availableYears}
          value={selectedYear}
          onChange={(e) => setSelectedYear(e)}
        />
        {!isEditable && (
          <button
            onClick={toggleEditable.on}
            type="button"
            className="w-full md:w-auto bg-blue-500 text-white px-3 rounded-lg py-2 hover:bg-blue-600 inline-flex justify-center items-center gap-2"
          >
            Edit
            <Icon name="Pen" size={18} />
          </button>
        )}
      </div>
    </nav>
  );
};
