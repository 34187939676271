import { useEffect, useState } from "react";
import { Clock } from "lucide-react";
import { Icon } from "./Icon";
import classNames from "classnames";

interface PollingPreviewProps {
  lastFetchedAt: number;
  pollingInterval: number;
  className?: string;
}

export function PollingPreview({
  lastFetchedAt,
  pollingInterval,
  className = "",
}: PollingPreviewProps) {
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const startTime = new Date(lastFetchedAt).getTime();
    const endTime = startTime + pollingInterval;

    const updateProgress = () => {
      const now = Date.now();
      const elapsed = now - startTime;
      const remaining = endTime - now;

      const newProgress = Math.min((elapsed / pollingInterval) * 100, 100);
      setProgress(newProgress);

      if (remaining > 0) {
        const seconds = Math.ceil(remaining / 1000);
        setTimeLeft(`${seconds}s`);
      } else {
        setTimeLeft("Fetching...");
      }
    };

    updateProgress();
    const interval = setInterval(updateProgress, 100);

    return () => clearInterval(interval);
  }, [lastFetchedAt, pollingInterval]);

  const circumference = 2 * Math.PI * 8;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div
      className={classNames(
        "inline-flex items-center gap-3 px-3 py-2 rounded-md border border-gray-100",
        className,
      )}
    >
      <div className="relative h-6 w-6">
        <svg className="h-full w-full -rotate-90" viewBox="0 0 20 20">
          <Icon name="Circle" className="stroke-gray-100" />
          <circle
            className="stroke-blue-500 transition-all duration-300 ease-in-out"
            strokeWidth="2.5"
            strokeLinecap="round"
            fill="none"
            r="8"
            cx="10"
            cy="10"
            style={{
              strokeDasharray: circumference,
              strokeDashoffset,
            }}
          />
        </svg>
        <Clock className="absolute left-1/2 top-1/2 h-3.5 w-3.5 -translate-x-1/2 -translate-y-1/2 text-blue-600" />
      </div>
      <span className="text-sm font-medium text-blue-600">{timeLeft}</span>
    </div>
  );
}
