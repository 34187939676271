import type React from "react";
import { useFormContext } from "react-hook-form";
import { icons } from "lucide-react";
import classNames from "classnames";
import { useEffect } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefixIcon?: keyof typeof icons | undefined;
  prefixIconSize?: number;
  suffixIconSize?: number;
  suffixIcon?: keyof typeof icons | undefined;
  label: string;
  name: string;
  prefixIconHandler?: () => void;
  suffixIconHandler?: () => void;
  prefixIconStyle?: React.ComponentProps<"div">["className"];
  suffixIconStyle?: React.ComponentProps<"div">["className"];
  errorMessageStyle?: React.ComponentProps<"div">["className"];
}

export const Input: React.FC<InputProps> = ({
  name,
  type = "text",
  prefixIcon,
  label,
  suffixIcon,
  prefixIconHandler,
  prefixIconSize = 20,
  suffixIconSize = 20,
  suffixIconHandler,
  prefixIconStyle,
  suffixIconStyle,
  className,
  errorMessageStyle,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const handleWheel = () => {
      if (
        document.activeElement &&
        (document.activeElement as HTMLInputElement).type === "number"
      ) {
        (document.activeElement as HTMLInputElement).blur();
      }
    };

    document.addEventListener("wheel", handleWheel);

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const error = errors[name]?.message as string;
  const PrefixIcon = prefixIcon ? icons[prefixIcon] : undefined;
  const SuffixIcon = suffixIcon ? icons[suffixIcon] : undefined;

  return (
    <div className="relative">
      <label className="block text-gray-700 mb-2 truncate" htmlFor={name}>
        {label}
      </label>
      <div className="relative">
        {PrefixIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <PrefixIcon
              size={prefixIconSize}
              onClick={prefixIconHandler}
              className={classNames(
                "hover:cursor-pointer",
                prefixIconStyle && prefixIconStyle,
              )}
            />
          </div>
        )}
        <input
          id={name}
          type={type}
          {...register(name)}
          {...rest}
          className={classNames(
            "w-full py-2 border rounded-lg focus:outline-none px-2",
            error ? "border-red-500" : "ring-blue-500 focus:ring-2",
            prefixIcon && "pl-8",
            suffixIcon && "pr-10",
            className && className,
          )}
        />
        {SuffixIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <SuffixIcon
              size={suffixIconSize}
              onClick={suffixIconHandler}
              className={classNames(
                "hover:cursor-pointer",
                suffixIconStyle && suffixIconStyle,
              )}
            />
          </div>
        )}
      </div>
      {error && (
        <p
          className={classNames("text-red-500 text-sm mt-1", errorMessageStyle)}
        >
          {error || "Validation error"}
        </p>
      )}
    </div>
  );
};
