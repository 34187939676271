import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { QaDbApiResponse } from "@app/types";
import { parserApiHandler } from "@app/config/apiHandler";
import {
  GetDocumentCreationListPayload,
  CreateFinancialDocumentRequest,
} from "./types";
import { FinancialMetaDataResponseObject } from "../financialApi/types";

const documentCreationApi = createApi({
  reducerPath: "documentCreationApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: ["documents"],
  endpoints: (builder) => ({
    getDocumentCreationData: builder.query<
      QaDbApiResponse<FinancialMetaDataResponseObject[]>,
      GetDocumentCreationListPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/documents/documents-by-entity-identifier",
        method: "GET",
        params: payload,
      }),
    }),
    createFinancialDocument: builder.mutation<
      QaDbApiResponse<FinancialMetaDataResponseObject>,
      CreateFinancialDocumentRequest
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/create-financials-for-document-id",
        method: "POST",
        params: payload,
      }),
    }),
    createShareholderDocument: builder.mutation<
      QaDbApiResponse<FinancialMetaDataResponseObject>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/shareholder-editing/create-shareholdings-for-document-id",
        method: "POST",
        params: {
          document_id: id,
        },
      }),
    }),
  }),
});

export const {
  useGetDocumentCreationDataQuery,
  useCreateFinancialDocumentMutation,
  useCreateShareholderDocumentMutation,
} = documentCreationApi;

export default documentCreationApi;
