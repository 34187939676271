import { useGetChargesQuery } from "@app/store/api/chargesApi";
import { IdentifierTypeEnum } from "@app/types";
import { Button } from "@components/common/Button";
import { NotFound } from "@components/common/NotFound";
import { PageLoader } from "@components/loader";
import { Edit, Plus } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EditOrConnectChargesModal } from "@components/labelling/charges";
import { useToggle } from "@app/hooks/useToggle";
import { Charges as ChargesType } from "@app/store/api/chargesApi/types";
import { TypeOfModal } from "@components/labelling/shareholder/components/types";
import { GroupedChargesTable } from "@components/labelling/charges/components/GroupedChargesTable";
import { Table } from "@components/common/table";
import classNames from "classnames";
import { getBadgeStyle } from "@components/labelling/charges/components/getBadge";
import { isEmpty } from "@app/utils/isEmpty";

const headers = [
  { name: "Charge ID", value: "charge_id", classes: "w-40 font-normal" },
  { name: "Date", value: "date", classes: "w-20 font-normal" },
  {
    name: "Holder Name",
    value: "holder_name",
    classes: "w-40 font-normal ",
  },
  { name: "Amount", value: "amount", classes: "w-20 font-normal" },
  { name: "Event Type", value: "event_type", classes: "w-20 font-normal" },
  { name: "Edit", value: "edit", classes: "w-20 font-normal" },
];
const Charges = () => {
  const { cin } = useParams();
  const [isEditOrConnectChargesModalOpen, setIsEditOrConnectChargesModalOpen] =
    useToggle();
  const [assignedSkip, setAssignedSkip] = useState(0);
  const [unassignedSkip, setUnassignedSkip] = useState(0);
  const [editableCharges, setEditableCharges] = useState<Partial<ChargesType>>(
    {},
  );
  const [typeOfModal, setTypeOfModal] = useState<TypeOfModal>("ADD");
  const limit = 15;

  const generateJsxForTable = useCallback(
    (item: ChargesType) => ({
      charge_id: item.charge_id && (
        <span className="truncate">{item.charge_id}</span>
      ),
      date: <span className="truncate">{item.date}</span>,
      modification_particulars: item.modification_particulars && (
        <span className="truncate">{item.modification_particulars}</span>
      ),
      holder_name: <span className="truncate">{item.holder_name}</span>,
      amount: <span className="truncate">{item.amount}</span>,
      event_type: (
        <span
          className={classNames(
            "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
            getBadgeStyle(item.event_type),
          )}
        >
          {item.event_type}
        </span>
      ),
      edit: (
        <Button
          variant="primary"
          leftIcon={Edit}
          onClick={() => {
            setEditableCharges(item);
            setTypeOfModal("EDIT");
            setIsEditOrConnectChargesModalOpen.on();
          }}
        >
          Edit
        </Button>
      ),
    }),
    [setEditableCharges, setTypeOfModal, setIsEditOrConnectChargesModalOpen],
  );

  const {
    data: unassignedChargesData,
    isLoading: isUnassignedChargesLoading,
    isError: isUnassignedChargesError,
  } = useGetChargesQuery({
    identifier_type: IdentifierTypeEnum.CIN,
    identifier_value: cin as string,
    unassigned: true,
  });

  const {
    data: assignedChargesData,
    isLoading: isAssignedChargesLoading,
    isError: isAssignedChargesError,
  } = useGetChargesQuery({
    identifier_type: IdentifierTypeEnum.CIN,
    identifier_value: cin as string,
    unassigned: false,
  });

  const {
    totalCount: unassignedTotalCount,
    currentPreviewableData: unassignedCurrentPreviewableData,
  } = useMemo(() => {
    const totalCount = unassignedChargesData?.response_data?.length;
    const currentPreviewableData = unassignedChargesData?.response_data
      .slice(unassignedSkip, unassignedSkip + limit)
      .map(generateJsxForTable);
    return { totalCount, currentPreviewableData };
  }, [unassignedChargesData, unassignedSkip]);

  const handleEditCharge = (charge: ChargesType) => {
    setEditableCharges(charge);
    setTypeOfModal("EDIT");
    setIsEditOrConnectChargesModalOpen.on();
  };

  if (isUnassignedChargesLoading || isAssignedChargesLoading)
    return <PageLoader />;

  if (isUnassignedChargesError || isAssignedChargesError)
    return <NotFound message="No Charges Found" />;

  return (
    <section className="p-6">
      <EditOrConnectChargesModal
        isOpen={isEditOrConnectChargesModalOpen}
        onClose={setIsEditOrConnectChargesModalOpen.off}
        typeOfModal={typeOfModal}
        data={editableCharges}
      />
      <div className="flex justify-between pb-4">
        <h1 className="text-2xl font-bold">Charges</h1>
        <Button
          variant="secondary"
          className="h-8"
          rightIcon={Plus}
          onClick={() => {
            setEditableCharges({});
            setTypeOfModal("ADD");
            setIsEditOrConnectChargesModalOpen.on();
          }}
        >
          Create Charges
        </Button>
      </div>

      {!isEmpty(unassignedCurrentPreviewableData) ? (
        <>
          <h2 className="text-lg font-bold w-full mb-2">Unassigned Charges</h2>
          <Table
            headers={headers}
            rows={unassignedCurrentPreviewableData || []}
            paginate
            skip={unassignedSkip}
            currentLimit={limit}
            maxSize={unassignedTotalCount || 0}
            nextHandler={() => setUnassignedSkip(unassignedSkip + limit)}
            prevHandler={() => setUnassignedSkip(unassignedSkip - limit)}
          />
        </>
      ) : (
        <NotFound
          message="No Unassigned Charges Found"
          className="h-[calc(100vh-200px)]"
        />
      )}
      {assignedChargesData?.response_data &&
        assignedChargesData.response_data.length > 0 && (
          <>
            <h2 className="text-lg font-semibold mb-4 mt-8">
              Assigned Charges
            </h2>
            <GroupedChargesTable
              charges={assignedChargesData.response_data}
              onEdit={handleEditCharge}
              onPageChange={(page) => setAssignedSkip(page)}
              skip={assignedSkip}
              limit={limit}
            />
          </>
        )}
    </section>
  );
};

export default Charges;
