import { FC, ReactNode } from "react";
import { QaProjectChildTask } from "@app/store/api/qaProjects/types";

interface TaskTableProps {
  headers: string[];
  data: QaProjectChildTask[];
  renderRow: (props: { task: QaProjectChildTask; index: number }) => ReactNode;
  emptyMessage?: string;
}

export const TaskTable: FC<TaskTableProps> = ({
  headers,
  data,
  renderRow,
  emptyMessage = "No tasks available",
}): JSX.Element => (
  <table className="w-full border-collapse">
    <thead>
      <tr className="bg-gray-100">
        {headers.map((header, index) => (
          <th key={index} className="border p-2">
            {header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.length > 0 ? (
        data.map((task, index) => renderRow({ task, index }))
      ) : (
        <tr>
          <td
            colSpan={headers.length}
            className="border p-2 text-center text-gray-500 py-10"
          >
            {emptyMessage}
          </td>
        </tr>
      )}
    </tbody>
  </table>
);
