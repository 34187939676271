import * as Yup from "yup";

/*
  - In order to render financial we are storing the keys which is heading of each section
  to a object and its value as array
  - The value which start with "COMPUTED" is a computed property and should not be editable
  - We only add yup validation for keys which does not start with "COMPUTED"
  - This is done to filter out computed keys when sending to backend
  - Computed keys are computed in backend on fly
*/

export const xbrlIndAsBalanceSheetSchema = Yup.object().shape({
  // Non-current assets
  property_plant_equipment: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  right_of_use_assets: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  investment_property: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  biological_assets_other_bearer_plants: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  investments_accounted_for_using_equity_method: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  goodwill: Yup.number().typeError("Must be a number").required("Required"),
  other_intangible_assets: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  tangible_assets_capital_work_in_progress: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  intangible_assets_under_development: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  deferred_tax_assets_net: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  noncurrent_loans: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  other_non_current_financial_assets: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  trade_receivables_non_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  current_tax_assets_non_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  contract_assets_non_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),

  // Current assets
  current_investments: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  inventories: Yup.number().typeError("Must be a number").required("Required"),
  trade_receivables: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  bank_balances_other_than_cash_cash_equivalents: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  short_term_loans_and_advances: Yup.number()
    .typeError("Must be a number")
    .required("Required"),

  // Equity
  share_capital: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  reserves_and_surplus: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  minority_interest: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  deferred_government_grants_non_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  money_received_against_share_warrants: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  share_application_money_pending_allotment: Yup.number()
    .typeError("Must be a number")
    .required("Required"),

  // Non-current liabilities
  long_term_borrowings: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  trade_payables_non_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  deferred_tax_liabilities_net: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  other_non_current_financial_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  noncurrent_lease_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  non_current_financial_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  contract_liabilities_non_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  other_non_current_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  long_term_provisions: Yup.number()
    .typeError("Must be a number")
    .required("Required"),

  // Current liabilities
  short_term_borrowings: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  trade_payables: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  short_term_provisions: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  current_lease_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  other_current_financial_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  current_tax_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  contract_liabilities_current: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  liabilities_associated_with_assets_classified_as_held_for_sale: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  regulatory_deferred_liabilities: Yup.number()
    .typeError("Must be a number")
    .required("Required"),

  // Sub totals (excluding COMPUTED fields)
  given_assets_total: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  given_liabilities_total: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
});

export const xbrlIndAsBalanceSheetWIthHeading = {
  non_current_assets: [
    "property_plant_equipment",
    "right_of_use_assets",
    "investment_property",
    "biological_assets_other_bearer_plants",
    "investments_accounted_for_using_equity_method",
    "COMPUTED_tangible_assets",
    "goodwill",
    "other_intangible_assets",
    "COMPUTED_intangible_assets",
    "tangible_assets_capital_work_in_progress",
    "intangible_assets_under_development",
    "deferred_tax_assets_net",
    "noncurrent_loans",
    "other_non_current_financial_assets",
    "COMPUTED_long_term_loans_and_advances",
    "other_non_current_financial_assets",
    "trade_receivables_non_current",
    "current_tax_assets_non_current",
    "contract_assets_non_current",
    "COMPUTED_other_noncurrent_assets",
  ],
  current_assets: [
    "current_investments",
    "inventories",
    "trade_receivables",
    "bank_balances_other_than_cash_cash_equivalents",
    "COMPUTED_cash_and_bank_balances",
    "short_term_loans_and_advances",
    "COMPUTED_other_current_assets",
  ],
  liabilities: {
    equity: [
      "share_capital",
      "reserves_and_surplus",
      "minority_interest",
      "deferred_government_grants_non_current",
      "COMPUTED_total_equity",
      "money_received_against_share_warrants",
      "share_application_money_pending_allotment",
    ],
    non_current_liabilities: [
      "long_term_borrowings",
      "trade_payables_non_current",
      "deferred_tax_liabilities_net",
      "other_non_current_financial_liabilities",
      "noncurrent_lease_liabilities",
      "non_current_financial_liabilities",
      "contract_liabilities_non_current",
      "other_non_current_liabilities",
      "long_term_provisions",
      "COMPUTED_total_non_current_liabilities",
      "COMPUTED_other_long_term_liabilities",
    ],
    current_liabilities: [
      "short_term_borrowings",
      "trade_payables",
      "short_term_provisions",
      "current_lease_liabilities",
      "other_current_financial_liabilities",
      "current_tax_liabilities",
      "contract_liabilities_current",
      "liabilities_associated_with_assets_classified_as_held_for_sale",
      "regulatory_deferred_liabilities",
      "COMPUTED_other_current_liabilities",
    ],
  },
  sub_totals: [
    "given_assets_total",
    "given_liabilities_total",
    "COMPUTED_total_equity",
    "COMPUTED_total_non_current_liabilities",
    "COMPUTED_total_current_liabilities",
    "COMPUTED_net_fixed_assets",
    "COMPUTED_total_current_assets",
    "COMPUTED_total_debt",
    "COMPUTED_total_other_non_current_assets",
    "COMPUTED_difference_value_of_assets",
    "COMPUTED_difference_value_of_liabilities",
  ],
};

export type XbrlIndAsValidationType = Yup.InferType<
  typeof xbrlIndAsBalanceSheetSchema
>;

export const xblInddAsprofitAndLossWithHeading = {
  line_items: [
    "COMPUTED_operating_profit",
    "depreciation_and_amortization_expense",
    "COMPUTED_profit_before_interest_and_tax",
    "finance_cost",
    "profit_before_tax_and_exceptional_items_before_tax",
    "exceptional_items_before_tax",
    "profit_before_tax",
    "income_tax",
    "profit_after_tax",
    "COMPUTED_difference_in_value_line_items",
  ],
  revenue: [
    "sales_of_goods_manufactured_domestic",
    "sales_of_goods_traded_domestic",
    "sales_of_services_domestic",
    "sales_of_goods_manufactured_export",
    "sales_of_goods_traded_export",
    "sales_of_services_export",
    "other_income",
    "COMPUTED_net_revenue",
  ],
  expenses: [
    "cost_material_consumed",
    "purchases_stock_traded",
    "total_changes_in_inventories_or_finished_goods",
    "employee_benefits_expense",
    "total_other_expenses",
    "total_expenses",
  ],
  revenue_breakup: [
    "revenue_from_operations",
    "revenue_from_sale_of_products",
    "COMPUTED_revenue_from_sale_of_services",
  ],
};

export const xblIndAsprofitAndLossSchema = Yup.object().shape({
  // Line items

  depreciation_and_amortization_expense: Yup.number()
    .typeError("Depreciation must be a number")
    .required("Depreciation is required"),
  finance_cost: Yup.number()
    .typeError("Finance cost must be a number")
    .required("Finance cost is required"),
  profit_before_tax_and_exceptional_items_before_tax: Yup.number()
    .typeError("Profit before tax and exceptional items must be a number")
    .required("Profit before tax and exceptional items is required"),
  exceptional_items_before_tax: Yup.number()
    .typeError("Exceptional items before tax must be a number")
    .required("Exceptional items before tax is required"),
  profit_before_tax: Yup.number()
    .typeError("Profit before tax must be a number")
    .required("Profit before tax is required"),
  income_tax: Yup.number()
    .typeError("Income tax must be a number")
    .required("Income tax is required"),
  profit_after_tax: Yup.number()
    .typeError("Profit after tax must be a number")
    .required("Profit after tax is required"),

  // Revenue
  sales_of_goods_manufactured_domestic: Yup.number()
    .typeError("Domestic manufactured goods sales must be a number")
    .required("Domestic manufactured goods sales is required"),
  sales_of_goods_traded_domestic: Yup.number()
    .typeError("Domestic traded goods sales must be a number")
    .required("Domestic traded goods sales is required"),
  sales_of_services_domestic: Yup.number()
    .typeError("Domestic services sales must be a number")
    .required("Domestic services sales is required"),
  sales_of_goods_manufactured_export: Yup.number()
    .typeError("Export manufactured goods sales must be a number")
    .required("Export manufactured goods sales is required"),
  sales_of_goods_traded_export: Yup.number()
    .typeError("Export traded goods sales must be a number")
    .required("Export traded goods sales is required"),
  sales_of_services_export: Yup.number()
    .typeError("Export services sales must be a number")
    .required("Export services sales is required"),
  other_income: Yup.number()
    .typeError("Other income must be a number")
    .required("Other income is required"),

  // Expenses
  cost_material_consumed: Yup.number()
    .typeError("Cost of material consumed must be a number")
    .required("Cost of material consumed is required"),
  purchases_stock_traded: Yup.number()
    .typeError("Purchases of stock-in-trade must be a number")
    .required("Purchases of stock-in-trade is required"),
  total_changes_in_inventories_or_finished_goods: Yup.number()
    .typeError("Changes in inventories must be a number")
    .required("Changes in inventories is required"),
  employee_benefits_expense: Yup.number()
    .typeError("Employee benefit expense must be a number")
    .required("Employee benefit expense is required"),
  total_other_expenses: Yup.number()
    .typeError("Total other expenses must be a number")
    .required("Total other expenses is required"),
  total_expenses: Yup.number()
    .typeError("Total expenses must be a number")
    .required("Total expenses is required"),

  // Revenue breakup
  revenue_from_operations: Yup.number()
    .typeError("Revenue from operations must be a number")
    .required("Revenue from operations is required"),
  revenue_from_sale_of_products: Yup.number()
    .typeError("Revenue from sale of products must be a number")
    .required("Revenue from sale of products is required"),
});

export type XbrlIndAsProfitAndLossType = Yup.InferType<
  typeof xblIndAsprofitAndLossSchema
>;
