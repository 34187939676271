import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, lazy, Suspense } from "react";
import toast from "react-hot-toast";
import { Aoc4ProfitAndLossInputs } from "./aoc4/Aoc4ProfitAndLossInputs";
import { PageLoader } from "@components/loader";
import {
  aoc4ProfitAndLossSchema,
  A0c4ProfitAndLossType,
} from "./aoc4/aoc4Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  xblIndAsprofitAndLossSchema,
  XbrlIndAsProfitAndLossType,
} from "./xbrl-ind-as/xbrlIndAsValidation";
import { XbrRlIndAsProfitAndLossInputs } from "./xbrl-ind-as/XbrlindAsprofitAndLose";
import { XbrlAsBalanceSheetInputs } from "./xbrl-as/XbrlAsProfitAndLose";
import {
  xbrlAsProfitAndLoseType,
  xbrlAsProfitAndLossSchema,
} from "./xbrl-as/xbrlAsValidation";
import classNames from "classnames";
import { FinancialTypes, MCAFormType } from "@app/store/api/financialApi/types";
import {
  useGetProfitAndLossQuery,
  useUpdateProfitAndLoseMutation,
} from "@app/store/api/financialApi";
import type { FinancialProps } from "./BalanceSheet";

const PdfViewer = lazy(() => import("@components/pdf-viewer/PdfViewer"));

export type ProfitAndLossType =
  | A0c4ProfitAndLossType
  | XbrlIndAsProfitAndLossType
  | xbrlAsProfitAndLoseType;

export const ProfitAndLoss = ({
  isPdfOpen,
  activePdf,
  isEditable,
  closeEdit,
  activeInfo,
  formNature,
  activeYear,
}: FinancialProps) => {
  const [updateProfitAndLose] = useUpdateProfitAndLoseMutation();

  const { data, isLoading } = useGetProfitAndLossQuery({
    document_id: activeInfo.id,
    year: activeYear,
    nature: formNature as FinancialTypes,
  });

  //@ts-expect-error Dynamic schema
  const schemaToTriggerFomValidation: Yup.ObjectSchema<any> = useMemo(() => {
    switch (activeInfo?.document_type) {
      case MCAFormType.AOC4:
        return aoc4ProfitAndLossSchema;
      case MCAFormType.AOC4_XBRL_AS:
        return xbrlAsProfitAndLossSchema;
      case MCAFormType.AOC4_XBRL_IND_AS:
        return xblIndAsprofitAndLossSchema;
      default:
        return aoc4ProfitAndLossSchema;
    }
  }, [activeInfo]);

  const form = useForm<Partial<ProfitAndLossType>>({
    resolver: yupResolver(schemaToTriggerFomValidation),
    mode: "all",
  });

  useEffect(() => {
    if (data?.response_data) {
      form.reset(data.response_data);
    }
  }, [data]);

  const { isDirty } = form.formState;

  const onSave: SubmitHandler<Partial<ProfitAndLossType>> = async (payload) => {
    try {
      if (!activeInfo || !payload) return;

      await updateProfitAndLose({
        ...payload,
        profit_and_loss_id: data?.response_data.id as string,
      }).unwrap();

      toast.success("Data updated successfully");
    } catch {
      toast.error("Error updating the data");
    } finally {
      closeEdit();
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="mt-5">
      <div className="mt-5 flex">
        <div
          className={classNames("border p-4 rounded-md", {
            "w-1/2": isPdfOpen,
            "w-full": !isPdfOpen,
          })}
        >
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(onSave)}
              className={classNames(
                "overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100",
                {
                  "max-h-[calc(100vh-200px)]": isPdfOpen,
                  "max-h-none": !isPdfOpen,
                },
              )}
            >
              {activeInfo?.document_type === MCAFormType.AOC4 && (
                <Aoc4ProfitAndLossInputs
                  isEditable={isEditable}
                  isPdfOpen={isPdfOpen}
                />
              )}
              {activeInfo?.document_type === MCAFormType.AOC4_XBRL_AS && (
                <XbrlAsBalanceSheetInputs
                  isEditable={isEditable}
                  isPdfOpen={isPdfOpen}
                />
              )}
              {activeInfo?.document_type === MCAFormType.AOC4_XBRL_IND_AS && (
                <XbrRlIndAsProfitAndLossInputs
                  isEditable={isEditable}
                  isPdfOpen={isPdfOpen}
                />
              )}
              {isEditable && (
                <div className="flex gap-2 sticky bottom-0 bg-white py-2">
                  <button
                    className="px-2 py-2 w-full border text-blue-600 border-blue-600 text-md rounded-md font-semibold mt-2 hover:border-blue-500 hover:text-blue-500"
                    type="reset"
                    onClick={() => {
                      form.reset();
                      closeEdit();
                    }}
                  >
                    Cancel
                  </button>
                  {isDirty && (
                    <button
                      className="px-2 py-2 w-full bg-blue-600 text-md text-white rounded-md font-semibold mt-2 hover:bg-blue-500"
                      type="submit"
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        {isPdfOpen && activePdf && (
          <div className="w-1/2 pl-4 relative">
            <Suspense fallback={<PageLoader />}>
              <PdfViewer
                pdfLink={activePdf}
                className="h-full overflow-y-auto"
              />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
};
