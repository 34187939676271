import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { qaDbApiHandler } from "@app/config/apiHandler";
import { QaUser, PerformanceMetrics } from "./types";
import { QaDbApiResponse } from "@app/types";

const qaUsersApi = createApi({
  reducerPath: "qaUsersApi",
  baseQuery: axiosBaseQuery(qaDbApiHandler),
  tagTypes: ["qa-users", "performance-metrics"],
  endpoints: (builder) => ({
    getAllUsers: builder.query<QaDbApiResponse<QaUser[]>, void>({
      query: () => ({
        url: "users/get-all",
        method: "get",
      }),
      providesTags: ["qa-users"],
    }),
    getPerformanceMetricsByUserId: builder.query<
      QaDbApiResponse<PerformanceMetrics[]>,
      string
    >({
      query: (userId) => ({
        url: "performance-metrics/get-by-user-id",
        method: "get",
        params: {
          user_id: userId,
        },
      }),
      providesTags: ["performance-metrics"],
    }),
  }),
});

const { useGetAllUsersQuery, useGetPerformanceMetricsByUserIdQuery } =
  qaUsersApi;

export { useGetAllUsersQuery, useGetPerformanceMetricsByUserIdQuery };
export default qaUsersApi;
