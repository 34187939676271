import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { QaDbApiResponse } from "@app/types";
import { parserApiHandler } from "@app/config/apiHandler";
import { OrNull, ShareHoldingMetaData } from "../shareHoldingsApi/types";
import {
  PrincipleBusinessActivity,
  UpdatePrincipleBusinessActivity,
  UpdateLlpPartners,
  LlpPartner,
  LlpPartnerQuery,
  CreateLlpPartner,
} from "./types";

const llpShareHoldingsApi = createApi({
  reducerPath: "llpShareHoldingsApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: [
    "llp-share-holdings-metadata",
    "llp-partners",
    "llp-principal-business-activity",
  ],
  endpoints: (builder) => ({
    getLlpShareHoldingsMetadata: builder.query<
      QaDbApiResponse<ShareHoldingMetaData>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/metadata/shareholdings-metadata-for-llpin",
        method: "GET",
        params: {
          llpin: id,
        },
      }),
      providesTags: ["llp-share-holdings-metadata"],
    }),
    getPrincipleBusinessActivity: builder.query<
      QaDbApiResponse<PrincipleBusinessActivity[]>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/shareholder-editing/llp-principal-business-activity",
        method: "GET",
        params: {
          document_id: id,
        },
      }),
      providesTags: ["llp-principal-business-activity"],
    }),
    updatePrincipleBusinessActivity: builder.mutation<
      void,
      UpdatePrincipleBusinessActivity
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/llp-principal-business-activity",
        method: "PUT",
        data: payload,
        params: {
          llp_principal_business_activity_id:
            payload.llp_principal_business_activity_id,
        },
      }),
      invalidatesTags: ["llp-principal-business-activity"],
    }),
    addPrincipleBusinessActivity: builder.mutation<
      void,
      Partial<OrNull<PrincipleBusinessActivity>>
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/llp-principal-business-activity",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["llp-principal-business-activity"],
    }),
    deletePrincipleBusinessActivity: builder.mutation<void, string>({
      query: (id) => ({
        url: "/editing-platform-data/shareholder-editing/llp-principal-business-activity",
        method: "DELETE",
        params: {
          llp_principal_business_activity_id: id,
        },
      }),
      invalidatesTags: ["llp-principal-business-activity"],
    }),
    getLlpPartners: builder.query<
      QaDbApiResponse<LlpPartner[]>,
      LlpPartnerQuery
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/llp-partners",
        method: "GET",
        params: payload,
      }),
      providesTags: ["llp-partners"],
    }),
    addLlpPartners: builder.mutation<void, CreateLlpPartner>({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/llp-partners",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["llp-partners"],
    }),
    updateLlpPartners: builder.mutation<void, UpdateLlpPartners>({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/llp-partners",
        method: "PUT",
        data: payload,
        params: {
          llp_partner_id: payload.llp_partner_id,
        },
      }),
      invalidatesTags: ["llp-partners"],
    }),
    deleteLlpPartners: builder.mutation<void, string>({
      query: (id) => ({
        url: "/editing-platform-data/shareholder-editing/llp-partners",
        method: "DELETE",
        params: {
          llp_partner_id: id,
        },
      }),
      invalidatesTags: ["llp-partners"],
    }),
  }),
});

export const {
  useGetLlpShareHoldingsMetadataQuery,
  useGetPrincipleBusinessActivityQuery,
  useAddPrincipleBusinessActivityMutation,
  useUpdatePrincipleBusinessActivityMutation,
  useDeletePrincipleBusinessActivityMutation,
  useGetLlpPartnersQuery,
  useAddLlpPartnersMutation,
  useUpdateLlpPartnersMutation,
  useDeleteLlpPartnersMutation,
} = llpShareHoldingsApi;

export default llpShareHoldingsApi;
