import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { indiaNonCorpApiHandler } from "@app/config/apiHandler";
import {
  IndiaNonCorpEntity,
  IndiaNonCorpEntityAddress,
  IndiaNonCorpEntityContact,
} from "./types";
import { Pagination } from "@app/types";
import { OrNull } from "@app/store/api/shareHoldingsApi/types";

const entitiesApi = createApi({
  reducerPath: "entitiesApi",
  baseQuery: axiosBaseQuery(indiaNonCorpApiHandler),
  endpoints: (builder) => ({
    createEntity: builder.mutation<void, IndiaNonCorpEntity>({
      query: (body) => ({
        url: "/entity/create",
        method: "POST",
        data: body,
      }),
    }),
    getEntity: builder.query<void, { entity_id: string }>({
      query: ({ entity_id }) => ({
        url: `/entity/get`,
        params: { entity_id },
      }),
    }),
    getAllEntities: builder.query<Pagination, Pagination>({
      query: (params) => ({
        url: "/entity/get-all",
        params,
      }),
    }),
    updateEntity: builder.mutation<void, Partial<OrNull<IndiaNonCorpEntity>>>({
      query: (body) => ({
        url: "/entity/update",
        method: "POST",
        data: body,
      }),
    }),
    getEntityAddresses: builder.query<void, { entity_id: string }>({
      query: ({ entity_id }) => ({
        url: "/entity-address/get",
        params: { entity_id },
      }),
    }),
    getAllEntityAddresses: builder.query<Pagination, Pagination>({
      query: (params) => ({
        url: "/entity-address/get-all",
        params,
      }),
    }),
    createEntityAddress: builder.mutation<void, IndiaNonCorpEntityAddress>({
      query: (body) => ({
        url: "/entity-address/create",
        method: "POST",
        data: body,
      }),
    }),
    updateEntityAddress: builder.mutation<
      void,
      Partial<OrNull<IndiaNonCorpEntityAddress>>
    >({
      query: (body) => ({
        url: "/entity-address/update",
        method: "POST",
        data: body,
      }),
    }),
    getEntityContacts: builder.query<void, { entity_id: string }>({
      query: ({ entity_id }) => ({
        url: "/entity-contact/get",
        params: { entity_id },
      }),
    }),
    getAllEntityContacts: builder.query<Pagination, Pagination>({
      query: (params) => ({
        url: "/entity-contact/get-all",
        params,
      }),
    }),
    updateEntityContact: builder.mutation<
      void,
      Partial<OrNull<IndiaNonCorpEntityContact>>
    >({
      query: (body) => ({
        url: "/entity-contact/update",
        method: "POST",
        data: body,
      }),
    }),
    createEntityContact: builder.mutation<void, IndiaNonCorpEntityContact>({
      query: (body) => ({
        url: "/entity-contact/create",
        method: "POST",
        data: body,
      }),
    }),
  }),
});

export const {
  useCreateEntityMutation,
  useGetEntityQuery,
  useGetAllEntitiesQuery,
  useUpdateEntityMutation,
  useGetEntityAddressesQuery,
  useGetAllEntityAddressesQuery,
  useCreateEntityAddressMutation,
  useUpdateEntityAddressMutation,
  useGetEntityContactsQuery,
  useGetAllEntityContactsQuery,
  useUpdateEntityContactMutation,
  useCreateEntityContactMutation,
} = entitiesApi;

export default entitiesApi;
