import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { qaDbApiHandler } from "@app/config/apiHandler";
import { TriggerScrapingTasksPayload } from "./types";

const gengisScraperApi = createApi({
  reducerPath: "gengisScraper",
  baseQuery: axiosBaseQuery(qaDbApiHandler),
  endpoints: (builder) => ({
    uploadInvoiceAndTriggerMCAv2OrV3Scrape: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: "/scraping-tasks/trigger-mca-scraping-task",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    triggerScrapingTasks: builder.mutation<void, TriggerScrapingTasksPayload>({
      query: (payload) => ({
        url: "/scraping-tasks/trigger-other-scraping-tasks",
        method: "POST",
        data: payload,
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useUploadInvoiceAndTriggerMCAv2OrV3ScrapeMutation,
  useTriggerScrapingTasksMutation,
} = gengisScraperApi;

export default gengisScraperApi;
