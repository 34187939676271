import { formatInTimeZone } from "date-fns-tz";
import { parseISO } from "date-fns";

export const formatDateWithSeconds = (date: string) => {
  if (!date) return "-";

  const utcDate = parseISO(date);

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return formatInTimeZone(utcDate, localTimeZone, "dd MMM yyyy hh:mm:ss a");
};
