import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import {
  FinancialMetaDataResponse,
  BalanceSheetResponse,
  BalanceSheetResponsePayload,
  GetAuditorPayload,
  AuditorResponse,
  UpdateAuditor,
  ProfitAndLoseResponse,
  UpdateProfitAndLose,
  FinancialParams,
  UpdateFinancialParams,
  AddAuditor,
  RelatedParty,
} from "./types";
import { QaDbApiResponse } from "@app/types";
import { OrNull } from "../shareHoldingsApi/types";

const financialApi = createApi({
  reducerPath: "financialApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: ["auditor", "balance-sheet", "profit-and-lose", "financial-params"],
  endpoints: (builder) => ({
    getFinancialMetaDataForCin: builder.query<
      QaDbApiResponse<FinancialMetaDataResponse>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/metadata/financial-metadata-for-cin",
        method: "GET",
        params: {
          cin: id,
        },
      }),
    }),
    getFinancialParamsMetaData: builder.query<
      QaDbApiResponse<FinancialMetaDataResponse>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/metadata/financial-params-metadata-for-cin",
        method: "GET",
        params: {
          cin: id,
        },
      }),
    }),
    getBalanceSheet: builder.query<
      QaDbApiResponse<BalanceSheetResponse>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/balance-sheet-by-document-id-year-and-nature",
        method: "GET",
        params: payload,
      }),
      providesTags: ["balance-sheet"],
    }),
    updateBalanceSheet: builder.mutation<void, BalanceSheetResponsePayload>({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/balance-sheet",
        method: "PUT",
        params: {
          balance_sheet_id: payload.balance_sheet_id,
        },
        data: payload,
      }),
      invalidatesTags: ["balance-sheet"],
    }),
    deleteBalanceSheet: builder.mutation<void, string>({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/balance-sheet",
        method: "PUT",
        params: {
          balance_sheet_id: id,
        },
      }),
      invalidatesTags: ["balance-sheet"],
    }),
    getProfitAndLoss: builder.query<
      QaDbApiResponse<ProfitAndLoseResponse>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/profit-and-loss-by-document-id-year-and-nature",
        method: "GET",
        params: payload,
      }),
      providesTags: ["profit-and-lose"],
    }),
    updateProfitAndLose: builder.mutation<void, UpdateProfitAndLose>({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/profit-and-loss",
        method: "PUT",
        params: {
          profit_and_loss_id: payload.profit_and_loss_id,
        },
        data: payload,
      }),
      invalidatesTags: ["profit-and-lose"],
    }),
    deleteProfitAndLose: builder.mutation<void, string>({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/profit-and-loss",
        method: "DELETE",
        params: {
          profit_and_loss_id: id,
        },
      }),
      invalidatesTags: ["profit-and-lose"],
    }),
    getAuditor: builder.query<
      QaDbApiResponse<AuditorResponse[]>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/auditor",
        method: "GET",
        params: payload,
      }),
      providesTags: ["auditor"],
    }),
    addAuditor: builder.mutation<void, Partial<OrNull<AddAuditor>>>({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/auditor",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["auditor"],
    }),
    updateAuditor: builder.mutation<void, UpdateAuditor>({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/auditor",
        method: "PUT",
        params: {
          auditor_id: payload.auditor_id,
        },
        data: payload,
      }),
      invalidatesTags: ["auditor"],
    }),
    deleteAuditor: builder.mutation<void, string>({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/auditor",
        method: "DELETE",
        params: {
          auditor_id: id,
        },
      }),
      invalidatesTags: ["auditor"],
    }),
    getAuditorComments: builder.query<
      QaDbApiResponse<BalanceSheetResponse>,
      string
    >({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/auditor-comments",
        method: "GET",
        params: {
          auditor_comments_id: id,
        },
      }),
    }),
    updateAuditorComments: builder.mutation<void, BalanceSheetResponsePayload>({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/auditor-comments",
        method: "PUT",
        params: {
          auditor_id: id,
        },
      }),
    }),
    deleteAuditorComment: builder.mutation<void, string>({
      query: (id) => ({
        url: "/editing-platform-data/financial-editing/auditor-comments",
        method: "DELETE",
        params: {
          auditor_comments_id: id,
        },
      }),
    }),
    getFinancialParams: builder.query<
      QaDbApiResponse<FinancialParams>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-params-editing/financial-params-by-document-id-year-and-nature",
        method: "GET",
        params: payload,
      }),
      providesTags: ["financial-params"],
    }),
    updateFinancialParams: builder.mutation<void, UpdateFinancialParams>({
      query: (payload) => ({
        url: "/editing-platform-data/financial-params-editing/financial-params",
        method: "PUT",
        params: {
          financial_params_id: payload.financial_params_id,
        },
        data: payload,
      }),
      invalidatesTags: ["financial-params"],
    }),
    deleteFinancialParams: builder.mutation<void, BalanceSheetResponsePayload>({
      query: (id) => ({
        url: "/editing-platform-data/financial-params-editing/financial-params",
        method: "DELETE",
        params: {
          financial_params_id: id,
        },
      }),
      invalidatesTags: ["financial-params"],
    }),
    getRelatedParty: builder.query<
      QaDbApiResponse<RelatedParty[]>,
      GetAuditorPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/financial-editing/related-party-information",
        method: "GET",
        params: payload,
      }),
    }),
  }),
});

export const {
  useGetFinancialMetaDataForCinQuery,
  useGetFinancialParamsMetaDataQuery,
  useGetBalanceSheetQuery,
  useUpdateBalanceSheetMutation,
  useDeleteBalanceSheetMutation,
  useGetProfitAndLossQuery,
  useUpdateProfitAndLoseMutation,
  useDeleteProfitAndLoseMutation,
  useGetAuditorQuery,
  useAddAuditorMutation,
  useUpdateAuditorMutation,
  useDeleteAuditorMutation,
  useGetAuditorCommentsQuery,
  useUpdateAuditorCommentsMutation,
  useDeleteAuditorCommentMutation,
  useGetFinancialParamsQuery,
  useUpdateFinancialParamsMutation,
  useDeleteFinancialParamsMutation,
  useGetRelatedPartyQuery,
} = financialApi;

export default financialApi;
