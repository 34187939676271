import { Input } from "@components/common/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { RotateCcw, Save } from "lucide-react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { useBlocker, useParams } from "react-router-dom";
import { CompanyHeader } from "./CompanyHeader";
import { WarningModal } from "./WarningModal";
import { type IOverViewSchema, overViewSchema } from "./validator";
import { useSearchCompanyByCinQuery } from "@app/store/api/cinSearchApi";
import { PageLoader } from "@components/loader";
import { Company, IdentifierTypeEnum } from "@app/types";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { NotFound } from "@components/common/NotFound/NotFound";
import { AddressBuilder } from "./non-corp/AddressBuilder";
import { LicenseBuilder } from "./non-corp/LicenseBuilder";
import { EntityInfo } from "./non-corp/EntityInfo";
import { BusinessInfo } from "./non-corp/BusinessInfo";
import { useEffect } from "react";
import { useGetScreenshotsQuery } from "@app/store/api/screenshotsApi";
import { formatDate } from "@app/utils/formatDate";

export const OverView = () => {
  const { cin } = useParams();
  const { activeCorporate, activeTypeOfCompany } = useActiveCompany();

  const shouldCallCinOverview =
    activeTypeOfCompany === IdentifierTypeEnum.CIN ||
    activeTypeOfCompany === IdentifierTypeEnum.LLPIN;

  const { data, isLoading, isError } = useSearchCompanyByCinQuery(
    cin as string,
    {
      skip: !shouldCallCinOverview,
    },
  );
  const {
    data: screenshots,
    isLoading: isScreenshotsLoading,
    isError: isScreenshotsError,
  } = useGetScreenshotsQuery(cin as string);

  // Create initial form values based on API data
  const getInitialValues = () => ({
    typeOfEntity: "Private Limited Company",
    status: "Unlisted",
    totalDirectors: 0,
    authorizedCapital: 0,
    paidUpCapital: 0,
    yearOfIncorporation: new Date(),
    address: "",
    lastAGM: new Date(),
  });

  const methods = useForm<IOverViewSchema>({
    defaultValues: getInitialValues(),
    resolver: yupResolver(overViewSchema),
    mode: "onChange",
  });

  // Update form when API data is available
  useEffect(() => {
    if (data) {
      const formattedData = {
        typeOfEntity: data.classification,
        status: data.status,
        totalDirectors: data.number_of_directors,
        authorizedCapital: data.authorized_capital,
        paidUpCapital: data.paid_up_capital,
        yearOfIncorporation:
          data.incorporation_date?.split("T")[0] ||
          new Date().toISOString().split("T")[0],
        address: data.registered_address?.full_address || "",
        lastAGM:
          data.last_agm_date?.split("T")[0] ||
          new Date().toISOString().split("T")[0],
      };

      methods.reset(formattedData);
    }
  }, [data]);

  const {
    formState: { isDirty, dirtyFields, errors },
  } = methods;

  // Prevent user from leaving if form is unsaved
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const onSubmit: SubmitHandler<IOverViewSchema> = (data) => {
    // Handle form submission to save the edited details
  };

  const triggerSubmission = (key: keyof IOverViewSchema) => {
    methods.trigger(key);
    if (dirtyFields) return;
    const updatedValue = methods.getValues(key);

    // TODO: send update api call
  };

  const shouldRenderSaveIcon = (key: keyof IOverViewSchema) =>
    dirtyFields[key] && !errors[key] ? "Check" : undefined;

  if (isLoading) {
    return (
      <PageLoader
        style={{
          width: "calc(100vw - 285px)",
          height: "calc(100vh - 50px)",
        }}
      />
    );
  }

  if (isError) {
    return (
      <div className="h-[90vh] flex justify-center items-center">
        <NotFound message="Company Not Found" className="h-screen" />
      </div>
    );
  }

  return (
    <div>
      <WarningModal
        isOpen={blocker?.state === "blocked"}
        proceed={() => blocker?.proceed?.()}
        cancel={() => blocker?.reset?.()}
      />
      <h2 className="text-2xl font-bold mb-6 uppercase">
        Company Overview - {activeCorporate}
      </h2>
      {activeTypeOfCompany === IdentifierTypeEnum.PAN && (
        <>
          <EntityInfo />
          <AddressBuilder />
          <LicenseBuilder />
          <BusinessInfo />

          <div className="flex justify-end gap-2 mt-2">
            {isDirty && (
              <button
                type="button"
                onClick={() => methods.reset()}
                className="border-blue-500 border px-2 py-1 rounded text-blue-500 self-center items-center inline-flex gap-2"
              >
                <RotateCcw size={15} />
                <span>Reset</span>
              </button>
            )}
            {isDirty && Object.keys(dirtyFields).length > 1 && (
              <button
                type="submit"
                onClick={() => {}}
                className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 self-center items-center flex gap-2"
              >
                <Save size={15} />
                <span>Save All</span>
              </button>
            )}
          </div>
        </>
      )}
      {(activeTypeOfCompany === IdentifierTypeEnum.CIN ||
        activeTypeOfCompany === IdentifierTypeEnum.LLPIN) && (
        <>
          <CompanyHeader company={data as Company} />
          <FormProvider {...methods}>
            <form className="mt-5">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {data?.classification && (
                  <Input
                    name="typeOfEntity"
                    label="Type of Entity"
                    disabled
                    placeholder="Type of Entity"
                    suffixIcon={shouldRenderSaveIcon("typeOfEntity")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() => triggerSubmission("typeOfEntity")}
                  />
                )}
                {data?.status && (
                  <Input
                    name="status"
                    label="Status"
                    disabled
                    placeholder="Company Status"
                    suffixIcon={shouldRenderSaveIcon("status")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() => triggerSubmission("status")}
                  />
                )}
                {data?.number_of_directors !== undefined && (
                  <Input
                    name="totalDirectors"
                    label="Total Directors"
                    type="number"
                    disabled
                    placeholder="Total Directors"
                    suffixIcon={shouldRenderSaveIcon("totalDirectors")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() =>
                      triggerSubmission("totalDirectors")
                    }
                  />
                )}
                {data?.authorized_capital !== undefined && (
                  <Input
                    name="authorizedCapital"
                    label="Authorized Capital"
                    prefixIcon="IndianRupee"
                    prefixIconSize={14}
                    prefixIconStyle="hover:cursor-default"
                    type="number"
                    disabled
                    placeholder="Authorized Capital"
                    suffixIcon={shouldRenderSaveIcon("authorizedCapital")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() =>
                      triggerSubmission("authorizedCapital")
                    }
                  />
                )}
                {data?.paid_up_capital !== undefined && (
                  <Input
                    name="paidUpCapital"
                    label="Paid up Capital"
                    prefixIconSize={14}
                    prefixIconStyle="hover:cursor-default"
                    type="number"
                    disabled
                    placeholder="Paid up Capital"
                    suffixIcon={shouldRenderSaveIcon("paidUpCapital")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() => triggerSubmission("paidUpCapital")}
                  />
                )}
                {data?.incorporation_date && (
                  <Input
                    name="yearOfIncorporation"
                    label="Year of Incorporation"
                    type="date"
                    disabled
                    placeholder="Year of Incorporation"
                    suffixIcon={shouldRenderSaveIcon("yearOfIncorporation")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() =>
                      triggerSubmission("yearOfIncorporation")
                    }
                  />
                )}
                {data?.last_agm_date && (
                  <Input
                    name="lastAGM"
                    label="Last AGM"
                    type="date"
                    disabled
                    placeholder="Last Annual General Meeting"
                    suffixIcon={shouldRenderSaveIcon("lastAGM")}
                    suffixIconStyle="border shadow-sm rounded-md text-md"
                    suffixIconHandler={() => triggerSubmission("lastAGM")}
                  />
                )}
              </div>
            </form>
          </FormProvider>
          {isScreenshotsLoading && <PageLoader />}
          {screenshots && (
            <div className="border rounded-lg overflow-hidden mt-4">
              <div className="bg-gray-50 p-4 border-b">
                <h3 className="font-semibold">Company Screenshot</h3>
                <p className="text-sm text-gray-600">
                  Last updated:{" "}
                  {formatDate(
                    screenshots.response_data.company_screenshot_last_modified,
                  )}
                </p>
              </div>
              <div className="p-4">
                <img
                  src={
                    screenshots.response_data.company_screenshot_presigned_url
                  }
                  alt="Company Screenshot"
                  className="w-full h-auto rounded"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
