import "./shimmer.css";
import classNames from "classnames";
import { AppLogo } from "@app/components/customIcons/CredHiveLogo";

interface PageLoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const PageLoader: React.FC<PageLoaderProps> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "backdrop-blur-md loading flex h-screen justify-center items-center w-full",
        className,
      )}
      {...props}
    >
      <div className="ease-in duration-300 loading-content">
        <div className="shimmer-container flex-col">
          <AppLogo className="text-md" />
          <div className="shimmer-message">
            cred<span className="font-normal">hive</span>
          </div>
        </div>
      </div>
    </div>
  );
};
