import { ReactNode } from "react";

interface TaskSectionProps {
  title: string;
  action?: ReactNode;
  children: ReactNode;
}

export const TaskSection: React.FC<TaskSectionProps> = ({
  title,
  action,
  children,
}) => (
  <div>
    <div className="flex justify-between mb-2">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      {action}
    </div>
    {children}
  </div>
);
