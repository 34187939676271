import { Icon } from "@app/components/common";
import { Button } from "@components/common/Button";
import Modal from "react-modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  info: {
    cinId: string;
    version: string;
    account: string;
    srn: string;
    taskNature: string;
    company: string;
    download_primary: boolean;
    download_secondary: boolean;
  };
}

Modal.setAppElement("#root");

export const WarningModal = ({
  isOpen,
  onClose,
  onConfirm,
  info,
  isLoading,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      className="modal-content"
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div className="bg-white w-[400px] h-46 2 flex flex-col rounded-md justify-between">
        <div className="border-b">
          <h4 className="text-md font-bold text-red-600 inline-flex gap-2 items-center mb-2">
            Warning{" "}
            <Icon
              name="OctagonAlert"
              size={15}
              className="self-center text-red-600"
            />
          </h4>
        </div>
        <div className="flex gap-2 flex-col mt-2">
          <h4 className="text-sm font-bold">
            Are you sure you want to scrape the page?
          </h4>
          <ul className="text-sm">
            <li className="font-semibold">CIN ID - {info.cinId}</li>
            <li className="font-semibold">Version - MCA {info.version}</li>
            <li className="font-semibold">Account To Use - {info.account}</li>
            <li className="font-semibold">SRN ID - {info.srn}</li>
            <li className="font-semibold">Task Nature - {info.taskNature}</li>
            <li className="font-semibold">Company - {info.company}</li>
            <li className="font-semibold">
              Download Primary Documents -{" "}
              {info.download_primary ? "Yes" : "No"}
            </li>
            <li className="font-semibold">
              Download Secondary Documents -{" "}
              {info.download_secondary ? "Yes" : "No"}
            </li>
          </ul>
        </div>
        <div className="self-end p-2 flex gap-2 mt-4">
          <button
            type="button"
            className="text-md inline-flex bg-gray-200 py-2 px-2 rounded-lg hover:bg-gray-100"
            onClick={onClose}
          >
            Cancel
          </button>
          <Button
            isLoading={isLoading}
            type="button"
            className="text-md inline-flex bg-red-500 text-white py-2 px-3 rounded-lg hover:bg-red-400"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
