import type {
  AdhocScrapingTaskInfo,
  ScrapeTask,
} from "@app/store/api/qaTasksApi/types";

type TaskType = "scraping" | "adhoc";
type TaskMetadata =
  | ScrapeTask["scraping_metadata"]
  | AdhocScrapingTaskInfo["adhoc_metadata"];

interface McaAccountJobsTaskListProps<T extends TaskType> {
  tasks: T extends "scraping" ? ScrapeTask[] : AdhocScrapingTaskInfo[];
  title: string;
  typeOfTask: T;
}

export function McaAccountJobsTaskList<T extends TaskType>({
  tasks,
  title,
}: McaAccountJobsTaskListProps<T>) {
  if (!tasks?.length) return null;

  function getTaskMetadata(
    task: ScrapeTask | AdhocScrapingTaskInfo,
  ): TaskMetadata {
    if ("scraping_metadata" in task) return task.scraping_metadata;
    return task.adhoc_metadata;
  }

  return (
    <div className="border-b pb-4 last:border-b-0">
      <h3 className="font-semibold text-lg mb-2">{title}</h3>
      <div className="space-y-4">
        {tasks.map((task) => {
          const metadata = getTaskMetadata(task);

          return (
            <div
              key={task.id}
              className="space-y-2 p-2 hover:bg-gray-50 rounded-md"
            >
              <p className="text-sm text-gray-600">
                Assigned to: {task.assigned_to_name || "Unassigned"}
              </p>
              {metadata && (
                <>
                  <p className="text-sm">Company: {metadata.company_name}</p>
                  <p className="text-sm text-gray-500">
                    Identifier Type: {metadata.identifier_type}
                    <br />
                    Identifier Value: {metadata.identifier_value}
                  </p>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
