/**
 * Debounce function that delays invoking a function until after a specified wait time.
 * This is useful for limiting the rate at which a function can fire, especially for performance-intensive operations.
 *
 * @param fn The function to debounce
 * @param ms The number of milliseconds to delay (default: 2000ms)
 * @returns A debounced version of the input function that returns a Promise
 */
export const debounce = <F extends (...args: any[]) => any>(
  fn: F,
  ms = 2000,
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (
    this: ThisParameterType<F>,
    ...args: Parameters<F>
  ): Promise<ReturnType<F>> {
    clearTimeout(timeoutId);
    return new Promise((resolve) => {
      timeoutId = setTimeout(() => {
        const result = fn.apply(this, args);
        if (result instanceof Promise) {
          result.then(resolve).catch(resolve);
        } else {
          resolve(result);
        }
      }, ms);
    });
  };
};
