import { AddUserModal } from "@app/components/admin/platform-users";
import { useToggle } from "@app/hooks/useToggle";
import { useGetAllUsersQuery } from "@app/store/api/qaUsersApi/qaUsersApi";
import { formatDate } from "@app/utils/formatDate";
import { Icon, StatusBox } from "@components/common";
import { Table, ToolTip } from "@components/common/table";
import { PageLoader } from "@components/loader";
import { useMemo, useState } from "react";
import { PerformanceChart } from "@app/components/admin/platform-users";

const headers = [
  {
    name: "Email",
    value: "email",
  },
  {
    name: "User Name",
    value: "username",
  },
  {
    name: "Role",
    value: "role",
  },
  {
    name: "Active",
    value: "active",
  },
  {
    name: "User ID",
    value: "userId",
  },
  {
    name: "Last Login",
    value: "lastLogin",
  },
  {
    name: "Performance",
    value: "performance",
  },
];

const PlatformUsers = () => {
  const [isAddUserOpen, toggleAddUser] = useToggle();
  const { data, isLoading } = useGetAllUsersQuery();
  const [isPerformanceChartOpen, togglePerformanceChart] = useToggle();
  const [activeUser, setActiveUser] = useState<{
    userId: string;
    userName: string;
  } | null>(null);

  const rows = useMemo(() => {
    if (!data) return [];
    return data.response_data.map((el) => ({
      email: el.email,
      username: el.username,
      role: el.role,
      active: (
        <StatusBox
          statusText={el.is_active ? "ACTIVE" : "INACTIVE"}
          color={el.is_active ? "bg-green-500" : "bg-red-500"}
        />
      ),
      userId: <ToolTip value={el.cognito_user_id} />,
      lastLogin: formatDate(el.last_login as string),
      performance: (
        <button
          type="submit"
          className="self-end mt-4 text-md inline-flex bg-blue-500 py-2 px-3 rounded-lg hover:bg-blue-400 items-center justify-center text-white gap-2"
          onClick={() => {
            setActiveUser({
              userId: el.id,
              userName: el.username,
            });
            togglePerformanceChart.on();
          }}
        >
          View Performance
          <Icon name="ArrowRight" size={15} />
        </button>
      ),
    }));
  }, [data]);

  return (
    <div>
      <AddUserModal isOpen={isAddUserOpen} onClose={toggleAddUser.off} />
      {activeUser && (
        <PerformanceChart
          isOpen={isPerformanceChartOpen}
          onClose={togglePerformanceChart.off}
          data={activeUser}
        />
      )}
      <h2 className="text-start text-2xl font-bold">
        All CredHive Platform Users
      </h2>
      {isLoading ? (
        <PageLoader
          className="w-full h-full"
          style={{ width: "calc(100% - 50px)", height: "calc(100vh - 185px)" }}
        />
      ) : (
        <section className="flex flex-col">
          <button
            type="submit"
            className="self-end mt-4 text-md inline-flex bg-blue-500 py-2 px-3 rounded-lg hover:bg-blue-400 items-center justify-center text-white gap-2"
            onClick={() => toggleAddUser.on()}
          >
            Add User
            <Icon name="Plus" size={15} />
          </button>
          <div className="mt-10">
            <Table
              headers={headers}
              rows={rows}
              tableDivClassname="w-full border border-gray-200 !p-0 rounded-lg"
              tableHeaderClassname="bg-gray-100 px-2 py-2 text-md font-bold text-gray-600 truncate"
              tableBodyClassname="truncate"
            />
          </div>
        </section>
      )}
    </div>
  );
};

export default PlatformUsers;
