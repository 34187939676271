import Modal from "react-modal";
import toast from "react-hot-toast";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import { Button } from "@components/common/Button";
import { Icon, Input } from "@components/common";

import {
  AdhocTaskStatus,
  ScrapingStatus,
  useUpdateScrapeTaskMutation,
} from "@app/store/api/qaTasksApi";
import { useUpdateAdhocTaskStatusForAdminMutation } from "@app/store/api/qaTasksApi/qaTasksApi";
import { ScrapingTaskType } from "@app/store/api/gengisScraperApi/types";

import { ProjectInfo } from "./UploadInvoiceModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { removeUnderscores } from "@app/utils/removeUnderScores";

const schema = yup.object({
  reason: yup.string().required("Reason is required"),
});

type FormData = yup.InferType<typeof schema>;

interface ChangeStatusModalProps<T extends ScrapingTaskType> {
  isOpen: boolean;
  onClose: () => void;
  projectInfo: ProjectInfo;
  scrapingType: T;
  status: T extends ScrapingTaskType.ADHOC ? AdhocTaskStatus : ScrapingStatus;
}

export const ChangeStatusModal = <T extends ScrapingTaskType>({
  isOpen,
  onClose,
  projectInfo,
  scrapingType,
  status,
}: ChangeStatusModalProps<T>) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const [updateScrapeTask, { isLoading: isMarkingAsComplete }] =
    useUpdateScrapeTaskMutation();
  const [updateAdhocTaskStatus, { isLoading: isUpdatingAdhocTask }] =
    useUpdateAdhocTaskStatusForAdminMutation();

  const isLoading = isMarkingAsComplete || isUpdatingAdhocTask;

  const changeStatus: SubmitHandler<FormData> = async (data) => {
    if (!projectInfo.task_id) {
      toast.error("Please enter the Task ID");
      return;
    }

    const metadataKey: Record<string, string> = {
      [AdhocTaskStatus.ADHOC_FORCE_COMPLETED]: "force_completed_reason",
      [ScrapingStatus.SCRAPING_FORCE_COMPLETED]: "force_completed_reason",
      [ScrapingStatus.SCRAPING_FORCE_FAILED]: "force_failed_reason",
      [AdhocTaskStatus.ADHOC_FORCE_FAILED]: "force_failed_reason",
    };

    const keyToUpdate = metadataKey[status];
    try {
      if (scrapingType === ScrapingTaskType.ADHOC) {
        if (!projectInfo.identifier_type) {
          toast.error("Please select the identifier type");
          return;
        }

        updateAdhocTaskStatus({
          adhoc_task_id: projectInfo.task_id,
          status: status as AdhocTaskStatus,
          adhoc_metadata: {
            [keyToUpdate]: data.reason,
          },
        }).unwrap();
      }

      if (scrapingType === ScrapingTaskType.NORMAL) {
        updateScrapeTask({
          status: status as ScrapingStatus,
          task_id: projectInfo.task_id,
          scraping_metadata: {
            cin: projectInfo.cinId,
            [keyToUpdate]: data.reason,
          },
        }).unwrap();
      }

      toast.success(`Marked as ${removeUnderscores(status)}`);
      onClose();
    } catch {
      toast.error(`Failed to mark as ${removeUnderscores(status)}`);
    } finally {
      methods.reset();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="modal-content"
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <main className="w-[400px] md:w-[600px] min-h-[300px] p-3 bg-white rounded-lg">
        <div className="flex justify-between ">
          <h4 className="text-2xl font-semibold mb-4 text-gray-800 uppercase">
            MARK AS {removeUnderscores(status)}
          </h4>
          <div onClick={onClose}>
            <Icon name="X" size={15} className="cursor-pointer" />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-gray-600">
            Change the status of the task for{" "}
            <span className="font-medium text-gray-800 text-nowrap">
              {projectInfo.company}
            </span>
          </p>
          <p className="flex items-center">
            <span className="font-medium text-gray-700 mr-2">Task Nature:</span>
            <span className="text-gray-600 font-semibold">
              {removeUnderscores(projectInfo.taskNature)}
            </span>
          </p>
          <p className="flex items-center">
            <span className="font-medium text-gray-700 mr-2">Company ID:</span>
            <span className="text-gray-600">{projectInfo.cinId}</span>
          </p>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(changeStatus)}>
              <Input name="reason" label="Reason" placeholder="Enter reason" />
              <Button
                className="w-full mt-4 bg-red-500 text-white hover:bg-red-600"
                variant="destructive"
                isLoading={isLoading}
                type="submit"
              >
                Change Status
              </Button>
            </form>
          </FormProvider>
        </div>
      </main>
    </Modal>
  );
};
