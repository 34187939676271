import { Charges } from "@app/store/api/chargesApi/types";
import { getBadgeStyle } from "./getBadge";
import { Button } from "@components/common/Button";
import { Edit } from "lucide-react";
import classNames from "classnames";
import { useMemo } from "react";
import { Pagination, usePagination } from "@components/common/Pagination";

interface GroupedChargesTableProps {
  charges: Charges[];
  onEdit: (charge: Charges) => void;
  skip?: number;
  limit?: number;
  maxPages?: number;
  onPageChange: (page: number) => void;
}

export function GroupedChargesTable({
  charges,
  onEdit,
  skip = 0,
  limit = 10,
  onPageChange,
}: GroupedChargesTableProps) {
  // Group charges by charge_id
  const groupedCharges: Record<string, Charges[]> = {};
  charges.forEach((charge) => {
    const chargeId = charge.charge_id;
    if (!chargeId) return;
    if (!groupedCharges[chargeId]) {
      groupedCharges[chargeId] = [];
    }
    groupedCharges[chargeId].push(charge);
  });

  const { currentPreviewableData, totalCount } = useMemo(() => {
    const totalCount = Object.keys(groupedCharges).length;
    const chargeGroups = Object.entries(groupedCharges);
    const currentPreviewableData = chargeGroups
      .slice(skip, skip + limit)
      .reduce(
        (acc, [chargeId, charges]) => {
          acc[chargeId] = charges;
          return acc;
        },
        {} as Record<string, Charges[]>,
      );
    return { totalCount, currentPreviewableData };
  }, [groupedCharges, skip, limit]);

  const { currentPage, totalPages } = usePagination(totalCount, skip, limit);

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full border rounded-sm">
        <table className="w-full border-collapse">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                ID
              </th>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                Date
              </th>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                Holder Name
              </th>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                Status
              </th>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                Amount
              </th>
              <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {Object.entries(currentPreviewableData).map(([_, chargeGroup]) =>
              chargeGroup.map((charge, index) => (
                <>
                  {index === 0 && (
                    <tr className="bg-gray-100">
                      <td
                        colSpan={6}
                        className="px-4 py-2 text-sm font-medium text-gray-700"
                      >
                        Charge ID: {charge.charge_id}
                      </td>
                    </tr>
                  )}
                  <tr
                    key={`${charge.charge_id}-${index}`}
                    className="bg-white hover:bg-gray-50"
                  >
                    <td className="px-4 py-3 text-sm text-gray-900">
                      {charge.charge_id}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900">
                      {charge.date}
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900">
                      {charge.holder_name}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      <span
                        className={classNames(
                          "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
                          getBadgeStyle(charge.event_type),
                        )}
                      >
                        {charge.event_type}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-sm text-gray-900">
                      {charge.amount}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      <Button
                        variant="primary"
                        onClick={() => onEdit(charge)}
                        leftIcon={Edit}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                </>
              )),
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => onPageChange((page - 1) * limit)}
        className="mb-3 self-end"
      />
    </div>
  );
}
