import { axiosBaseQuery } from "@app/store/axiosBase";
import { createApi } from "@reduxjs/toolkit/query/react";
import { indiaNonCorpApiHandler } from "@app/config/apiHandler";
import { CreateIndiaNonCorpCreditRatings } from "./types";
import { Pagination } from "@app/types";
import { OrNull } from "@app/store/api/shareHoldingsApi/types";

const indiaNonCorpCreditRatingsApi = createApi({
  reducerPath: "indiaNonCorpCreditRatingsApi",
  baseQuery: axiosBaseQuery(indiaNonCorpApiHandler),
  tagTypes: [
    "get-india-noncorp-credit-ratings",
    "get-all-india-noncorp-credit-ratings",
  ],
  endpoints: (builder) => ({
    getNonCorpCreditRatings: builder.query<any, { entity_id: string }>({
      query: ({ entity_id }) => ({
        url: `/credit-rating/get`,
        params: { entity_id },
      }),
      providesTags: ["get-india-noncorp-credit-ratings"],
    }),
    getAllNonCorpCreditRatings: builder.query<any, Pagination>({
      query: (params) => ({
        url: "/credit-rating/get-all",
        params,
      }),
      providesTags: ["get-all-india-noncorp-credit-ratings"],
    }),
    createNonCorpCreditRatings: builder.mutation<
      void,
      Partial<OrNull<CreateIndiaNonCorpCreditRatings>>
    >({
      query: (body) => ({
        url: "/credit-rating/create",
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["get-all-india-noncorp-credit-ratings"],
    }),
    updateNonCorpCreditRatings: builder.mutation<
      void,
      Partial<OrNull<CreateIndiaNonCorpCreditRatings>>
    >({
      query: (body) => ({
        url: "/credit-rating/update",
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["get-all-india-noncorp-credit-ratings"],
    }),
  }),
});

export default indiaNonCorpCreditRatingsApi;

export const {
  useGetNonCorpCreditRatingsQuery,
  useCreateNonCorpCreditRatingsMutation,
  useUpdateNonCorpCreditRatingsMutation,
  useGetAllNonCorpCreditRatingsQuery,
} = indiaNonCorpCreditRatingsApi;
