import { useGetUnapprovedUsersQuery } from "@app/store/api/usersList/usersList";
import { PageLoader } from "@components/loader";
import { Table } from "@components/common/table/Table";
import type { Header } from "@components/common/table/table.types";
import { useMemo, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@app/components/common/ToolTip";
import toast from "react-hot-toast";
import { StatusBox } from "@components/common";
import { useToggle } from "@app/hooks/useToggle";
import { ApproveUserModal } from "./ApproveUserModal";
import { CognitoUser } from "@app/store/api/usersList/types";
import { TeamDrawerCompany, UsersListDrawerType } from "@pages/UsersList";

const unApprovedHeaders: Header<CognitoUser>[] = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone Number", value: "phone_number" },
  { name: "Number Verified", value: "phone_number_verified" },
  {
    name: "Email Verified",
    value: "email_verified",
  },
  { name: "QA Verified", value: "qa_verified" },
  { name: "Country", value: "custom:country" },
  { name: "State", value: "custom:state" },
  { name: "City", value: "custom:city" },
  { name: "Company", value: "custom:company" },
  { name: "Team", value: "custom:team" },
  { name: "User ID", value: "user_id" },
  { name: "Action", value: "btn" },
];

interface ModalProps {
  name: string;
  email: string;
  user_id: string;
  phone_number: string;
}

type OpenTeamDrawer = (
  type: UsersListDrawerType,
  company: TeamDrawerCompany,
) => void;

type Key = keyof CognitoUser;

export const UnApprovedUsers = ({
  openTeamDrawer,
}: {
  openTeamDrawer: OpenTeamDrawer;
}) => {
  const { data, isLoading, isError } = useGetUnapprovedUsersQuery();
  const [userInfo, setUserInfo] = useState<ModalProps | null>(null);
  const [isModalOpen, toggleModal] = useToggle();
  const copyId = (id: string, name: Key) => {
    window.navigator.clipboard.writeText(id);
    const keyToName = unApprovedHeaders.find((el) => el.value === name)?.name;
    toast.success(`${keyToName} Was Copied To Clipboard`);
  };

  const rows = useMemo(
    () =>
      data?.response_data.map((user) => ({
        ...user,
        user_id: user.user_id && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className="px-2 py-1 border rounded-md hover:cursor-pointer flex justify-center items-center gap-2 max-w-96"
                  onClick={() => copyId(user.user_id, "user_id")}
                >
                  <span className="truncate">{user.user_id}</span>
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-800 text-xs text-white">
                {user.user_id}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        phone_number_verified: (
          <StatusBox
            statusText={
              user.phone_number_verified === "true"
                ? "VERIFIED"
                : "NOT-VERIFIED"
            }
            color={
              user.phone_number_verified === "true"
                ? "bg-green-500"
                : "bg-red-500"
            }
          />
        ),
        email_verified: (
          <StatusBox
            statusText={
              user.email_verified === "true" ? "VERIFIED" : "NOT-VERIFIED"
            }
            color={
              user.email_verified === "true" ? "bg-green-500" : "bg-red-500"
            }
          />
        ),
        qa_verified: (
          <StatusBox
            statusText={
              user["custom:customer_company_id"] ? "VERIFIED" : "NOT-VERIFIED"
            }
            color={
              user["custom:customer_company_id"] ? "bg-green-500" : "bg-red-500"
            }
          />
        ),
        btn: (
          <button
            disabled={Boolean(user["custom:customer_company_id"])}
            onClick={() => {
              toggleModal.on();
              setUserInfo({
                user_id: user.user_id,
                name: user.name,
                email: user.email,
                phone_number: user.phone_number,
              });
            }}
            type="button"
            className="text-sm inline-flex bg-blue-500 text-white py-1 px-1 rounded-lg hover:bg-blue-400"
          >
            Approve
          </button>
        ),
      })) || [],
    [data],
  );

  if (isLoading) {
    return (
      <PageLoader
        className="w-full h-full"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 285px)" }}
      />
    );
  }

  if (isError) {
    return <h1>Error</h1>;
  }
  return (
    <section className="mt-4">
      <ApproveUserModal
        openTeamDrawer={openTeamDrawer}
        isOpen={isModalOpen}
        onClose={toggleModal.off}
        data={userInfo as ModalProps}
      />
      <Table headers={unApprovedHeaders} rows={rows} isLoading={false} />
    </section>
  );
};
