import { TaskStatus } from "@app/store/api/qaTasksApi";
import classNames from "classnames";

interface RenderQaLabellingStatusProps {
  status: TaskStatus;
  className?: string;
}

export function RenderQaLabellingStatus({
  status,
  className,
}: RenderQaLabellingStatusProps) {
  const getStatusConfig = (
    status: TaskStatus,
  ): {
    label: string;
    bgClassName: string;
    textClassName: string;
  } => {
    switch (status) {
      case TaskStatus.QA_LABELING_COMPLETED:
        return {
          label: "Completed",
          bgClassName: "bg-green-100",
          textClassName: "text-green-600",
        };
      case TaskStatus.QA_LABELING_IN_PROGRESS:
        return {
          label: "In Progress",
          bgClassName: "bg-sky-50",
          textClassName: "text-sky-600",
        };
      default:
        return {
          label: "Pending",
          bgClassName: "bg-gray-100",
          textClassName: "text-gray-700",
        };
    }
  };

  const config = getStatusConfig(status);

  return (
    <span
      className={classNames(
        "group inline-flex items-center rounded-md px-2.5 py-1 text-xs font-medium",
        "transition-all duration-300 ease-in-out",
        "shadow-sm border border-transparent",
        config.bgClassName,
        config.textClassName,
        className,
      )}
    >
      {config.label}
    </span>
  );
}
