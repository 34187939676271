import React, { useMemo } from "react";
import { Input } from "@app/components/common/Input";
import classNames from "classnames";
import "./input-group.css";

interface InputGroupProps {
  title: string;
  fields: string[];
  isEditable: boolean;
  isPdfOpen: boolean;
}

export const InputGroup: React.FC<InputGroupProps> = ({
  title,
  fields,
  isEditable,
  isPdfOpen,
}) => {
  const nonEditableFields = useMemo(() => {
    if (!fields.length) return [];
    return fields.filter((field) => field.startsWith("COMPUTED"));
  }, [fields]);

  return (
    <div>
      <h3 className="font-bold">{title}</h3>
      <div
        className={classNames("grid-cols-3 grid gap-y-1 gap-x-4 mt-2", {
          "grid grid-cols-2 gap-x-3 gap-y-1 mt-2": isPdfOpen,
        })}
      >
        {fields.map((el) => {
          if (nonEditableFields.includes(el)) {
            return (
              <Input
                key={el}
                name={el}
                label={el.split("_").join(" ").toUpperCase()}
                type="number"
                step="0.001"
                disabled={true}
                suffixIcon="Calculator"
                suffixIconSize={15}
              />
            );
          }

          return (
            <Input
              key={el}
              name={el}
              label={el.split("_").join(" ").toUpperCase()}
              type="number"
              step="0.001"
              disabled={!isEditable}
            />
          );
        })}
      </div>
    </div>
  );
};
