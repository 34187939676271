import {
  AdhocMetadata,
  AdhocTaskNature,
  AdhocTaskStatus,
  useGetAdhocTasksQuery,
} from "@app/store/api/qaTasksApi";
import { PageLoader } from "@components/loader";
import { Table } from "@components/common/table";
import { headers } from "./PendingCompanyRequest";
import { useMemo, useState } from "react";
import { RenderAdhocStatus } from "./RenderAdhocStatus";
import { useToggle } from "@app/hooks/useToggle";
import { CompanyInfoModal } from "./CompanyInfoModal";
import { Button } from "@components/common/Button";
import { NotFound } from "@components/common/NotFound";

const LIMIT = 15;

export const CompletedCompanyRequest = () => {
  const [isMoreInfoOpen, toggleMoreInfoOpen] = useToggle();
  const [selectedCompany, setSelectedCompany] = useState<
    Partial<AdhocMetadata>
  >({});

  const [skip, setSkip] = useState(0);

  const { data, isLoading } = useGetAdhocTasksQuery(
    {
      skip,
      limit: LIMIT,
      status: [AdhocTaskStatus.ADHOC_COMPLETED],
      task_nature_name: AdhocTaskNature.REQUEST_NEW_COMPANY,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const rows = useMemo(() => {
    if (!data) return [];
    return data?.response_data.map((company) => ({
      requested_company: company.adhoc_metadata.company_name,
      customer_name: company.adhoc_metadata.customer_user?.name,
      identifier_value: company.adhoc_metadata.identifier_value,
      identifier_type: company.adhoc_metadata.identifier_type,
      status: <RenderAdhocStatus status={company.status} />,
      info: (
        <Button
          variant="primary"
          onClick={() => {
            setSelectedCompany(company.adhoc_metadata);
            toggleMoreInfoOpen.on();
          }}
        >
          More Info
        </Button>
      ),
    }));
  }, [data]);

  if (isLoading) return <PageLoader />;

  if (!data?.response_data.length)
    return (
      <div className="h-[calc(100vh-10rem)] flex justify-center items-center">
        <NotFound message="No Requests Found" />;
      </div>
    );

  return (
    <div className="mt-6">
      <CompanyInfoModal
        isOpen={isMoreInfoOpen}
        onClose={toggleMoreInfoOpen.off}
        data={selectedCompany}
      />
      <Table
        paginate
        maxSize={data?.total}
        headers={headers.slice(0, 5)}
        rows={rows}
        currentLimit={LIMIT}
        skip={skip}
        nextHandler={() => {
          if (skip + LIMIT >= data.total) return;
          setSkip(skip + LIMIT);
        }}
        prevHandler={() => {
          if (skip === 0) return;
          setSkip(skip - LIMIT);
        }}
      />
    </div>
  );
};
