import { useState, type ChangeEvent, type DragEvent } from "react";
import { Upload as UploadIcon } from "lucide-react";
import classNames from "classnames";
import toast from "react-hot-toast";

export enum FileTypes {
  PDF = "application/pdf",
  JPEG = "image/jpeg",
  PNG = "image/png",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

const FileTypeNames = {
  [FileTypes.PDF]: "PDF",
  [FileTypes.JPEG]: "JPEG",
  [FileTypes.PNG]: "PNG",
  [FileTypes.DOC]: "DOC",
  [FileTypes.DOCX]: "DOCX",
};

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  error?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  iconClassName?: string;
  textClassName?: string;
  fileNameClassName?: string;
  accept?: FileTypes[];
}

const validateAccept = (accept: FileTypes[], file: File) => {
  if (file?.type && accept.includes(file.type as FileTypes)) {
    return true;
  }

  return false;
};

export const Upload: React.FC<FileUploadProps> = ({
  onFileSelect,
  error,
  className,
  labelClassName,
  inputClassName,
  iconClassName,
  textClassName,
  fileNameClassName,
  accept = [],
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (validateAccept(accept, file)) {
        setSelectedFile(file);
        onFileSelect(file);
      }
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      if (validateAccept(accept, file)) {
        setSelectedFile(file);
        onFileSelect(file);
      } else {
        toast.error("Unsupported File type");
      }
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <div>
      <div
        className={classNames(
          "flex items-center flex-col justify-center h-64 border-2 border-dashed rounded-lg transition-colors duration-300",
          className,
          {
            "border-gray-300 hover:border-blue-500": !error && !isDragging,
            "border-red-500": error,
            "border-blue-500": isDragging,
          },
        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <label
          className={classNames(
            "flex flex-col items-center justify-center w-full h-full cursor-pointer",
            labelClassName,
          )}
        >
          <UploadIcon
            className={classNames("w-12 h-12", iconClassName, {
              "text-blue-500": !error && !isDragging,
              "text-red-500": error,
            })}
          />
          <span
            className={classNames(
              "mt-2 text-base leading-normal",
              textClassName,
              {
                "text-gray-600": !error && !isDragging,
                "text-red-600": error,
                "text-blue-600": isDragging,
              },
            )}
          >
            {isDragging ? "Drop to Upload" : "Select or Drag File to Upload"}
          </span>
          <input
            type="file"
            className={classNames("hidden", inputClassName)}
            onChange={handleFileChange}
            accept={accept.join(",")}
          />
        </label>
        {selectedFile && (
          <span
            className={classNames(
              "mt-2 text-base leading-normal",
              fileNameClassName,
            )}
          >
            {selectedFile.name}
          </span>
        )}
        <span className="self-start ml-4 text-xs mb-2">
          Supported files:{" "}
          {accept.map((type) => FileTypeNames[type]).join(", ")}
        </span>
      </div>
      {error && <span className="text-red-500 mt-8  text-sm">{error}</span>}
    </div>
  );
};
