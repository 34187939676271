import React from "react";

export const AppLogo = React.forwardRef<
  SVGSVGElement,
  {
    className: string;
  }
>(({ className }, ref) => (
  <svg
    className={className}
    ref={ref}
    width="25"
    height="39"
    viewBox="0 0 35 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0811 12.9532L17.5319 11.4834L19.9629 12.9015L17.5319 14.3195L15.0811 12.9532Z"
      fill="#E9BA42"
    />
    <path
      d="M22.3293 11.5103L27.472 14.3166L22.5354 17.1264L17.5352 14.3166L22.3293 11.5103Z"
      fill="#FEDA6C"
    />
    <path
      d="M12.6396 11.6016L17.5713 14.3138L12.6396 17.1254L7.75684 14.3138L12.6396 11.6016Z"
      fill="#FEDA6C"
    />
    <path
      d="M7.7732 14.3193L12.6128 17.0798L12.6129 28.4906L7.65919 25.6025L7.7732 14.3193Z"
      fill="#E9BA42"
    />
    <path
      d="M17.5303 14.3135L22.5358 17.0806V28.553L20.033 29.9862L17.5303 25.6515L17.5303 14.3135Z"
      fill="#D5A83C"
    />
    <path
      d="M17.5312 14.3096L12.5775 17.0932V28.4752L17.5312 31.1988L17.5312 14.3096Z"
      fill="#C19535"
    />
    <path
      d="M27.4551 14.333L22.5012 17.0935L22.5013 28.6177L27.4551 25.7295V14.333Z"
      fill="#C19535"
    />
    <path
      d="M15.0547 18.5408L17.5989 17.0566L20.1431 18.5408L17.5989 20.0249L15.0547 18.5408Z"
      fill="url(#paint0_linear_207_17593)"
    />
    <path
      d="M15.0547 18.5405L17.5989 20.0084V31.3119L15.0547 29.8451L15.0547 18.5405Z"
      fill="#D5A83C"
    />
    <path
      d="M17.5996 31.3687L20.1438 29.9008V18.5412L17.5996 20.0081V31.3687Z"
      fill="#C19535"
    />
    <path
      d="M20.0752 12.8394L17.5305 14.3096L15.0044 12.9042L17.5305 11.3414L20.0752 12.8394Z"
      fill="url(#paint1_linear_207_17593)"
    />
    <path
      d="M2.20878 19.7735L2.20878 28.0504C2.20878 28.4277 2.41009 28.7764 2.73688 28.9651L17.0729 37.242C17.3997 37.4306 17.8023 37.4306 18.1291 37.242L32.4651 28.9651C32.7919 28.7764 32.9932 28.4277 32.9932 28.0504L32.9932 11.4966C32.9932 11.1193 32.7919 10.7706 32.4651 10.5819L18.1291 2.30502C17.8023 2.11635 17.3997 2.11635 17.0729 2.30502L9.90488 6.44347"
      stroke="#EAEAEA"
      strokeOpacity="0.37"
      strokeWidth="3.16862"
    />
    <path
      d="M25.2973 33.1034L18.1293 37.2418C17.8025 37.4305 17.3999 37.4305 17.0731 37.2418L2.73709 28.965C2.4103 28.7763 2.20898 28.4276 2.20898 28.0503V11.4965C2.20898 11.1191 2.4103 10.7705 2.73709 10.5818L17.0731 2.3049C17.3999 2.11623 17.8025 2.11623 18.1293 2.3049L25.2973 6.44334"
      stroke="url(#paint2_linear_207_17593)"
      strokeWidth="3.16862"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2973 33.1034L18.1293 37.2418C17.8025 37.4305 17.3999 37.4305 17.0731 37.2418L2.73709 28.965C2.4103 28.7763 2.20898 28.4276 2.20898 28.0503V11.4965C2.20898 11.1191 2.4103 10.7705 2.73709 10.5818L17.0731 2.3049C17.3999 2.11623 17.8025 2.11623 18.1293 2.3049L25.2973 6.44334"
      stroke="url(#paint3_linear_207_17593)"
      strokeOpacity="0.2"
      strokeWidth="3.16862"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_207_17593"
        x1="17.5989"
        y1="16.6783"
        x2="17.5989"
        y2="20.0249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A07300" />
        <stop offset="1" stopColor="#E9BA42" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_207_17593"
        x1="17.5305"
        y1="14.6879"
        x2="17.5305"
        y2="11.3414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A07300" />
        <stop offset="1" stopColor="#E9BA42" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_207_17593"
        x1="-14.5497"
        y1="-8.43021"
        x2="21.9361"
        y2="47.5148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5A83C" />
        <stop offset="1" stopColor="#F8CD4F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_207_17593"
        x1="27.0206"
        y1="8.02079"
        x2="21.016"
        y2="8.77136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6F6F6" />
        <stop offset="1" stopColor="#D5A83C" />
      </linearGradient>
    </defs>
  </svg>
));
