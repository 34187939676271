import { IdentifierTypeEnum, Pagination } from "@app/types";

export enum TaskStatus {
  QA_LABELING_COMPLETED = "QA_LABELING_COMPLETED",
  QA_LABELING_IN_PROGRESS = "QA_LABELING_IN_PROGRESS",
  QA_LABELING_NOT_STARTED = "QA_LABELING_NOT_STARTED",
}

export interface Task extends SystemFields {
  project_id: string;
  task_nature_id: string;
  status: TaskStatus;
  verified_at: string;
  verified_by: string;
  assigned_to: string;
  id: string;
  company_name: string;
  task_nature_name: string;
  assigned_to_name: string;
  verified_by_name: string;
  identifier_value: string;
  identifier_type: IdentifierTypeEnum;
}

export interface LabellingTaskWithVersion extends Task {
  project_version: string;
}

export interface TaskByStatusProps {
  id: string;
  status: TaskStatus;
}

export interface ScrapeTaskByStatusProps extends Pagination {
  status: ScrapingStatus | ScrapingStatus[];
  task_nature_name?: ScrapingDataSource;
}

export interface ScrapeTask extends SystemFields {
  project_id: string;
  task_nature_id: string;
  status: ScrapingStatus;
  scraping_metadata: ScrapingMetadata;
  result_reference: string;
  assigned_to: string;
  id: string;
  company_name: string;
  task_nature_name: ScrapingDataSource;
  assigned_to_name: string;
  identifier_value: string;
  project_version: string;
}

export enum ScrapingDataSource {
  SCRAPING_MCA_V2 = "SCRAPING_MCA_V2",
  SCRAPING_INDIAN_CREDIT_RATINGS = "SCRAPING_INDIAN_CREDIT_RATINGS",
  SCRAPING_MCA_V3 = "SCRAPING_MCA_V3",
  SCRAPING_EPFO = "SCRAPING_EPFO",
  SCRAPING_LEGALS = "SCRAPING_LEGALS",
}

export enum ScrapingStatus {
  SCRAPING_NOT_STARTED = "SCRAPING_NOT_STARTED",
  SCRAPING_INITIALISED = "SCRAPING_INITIALISED",
  SCRAPING_IN_PROGRESS = "SCRAPING_IN_PROGRESS",
  SCRAPING_COMPLETED = "SCRAPING_COMPLETED",
  SCRAPING_FAILED = "SCRAPING_FAILED",
  SCRAPING_COMPLETED_WITH_ERRORS = "SCRAPING_COMPLETED_WITH_ERRORS",
  SCRAPING_FORCE_COMPLETED = "SCRAPING_FORCE_COMPLETED",
  SCRAPING_FORCE_FAILED = "SCRAPING_FORCE_FAILED",
}

export interface TriggerLabellingUpdateStatusPayload {
  task_id: string;
  status: TaskStatus;
  project_id: string;
  identifier_value: string;
  identifier_type: IdentifierTypeEnum;
}

interface SystemFields {
  created_at: string;
  updated_at: string;
  started_at: string;
  completed_at: string;
}

export interface UpdateScrapeTaskPayload {
  task_id: string;
  status: ScrapingStatus;
  scraping_metadata: Record<string, string>;
}

export enum ParsingStatusEnum {
  PARSING_NOT_STARTED = "PARSING_NOT_STARTED",
  PARSING_INITIALISED = "PARSING_INITIALISED",
  PARSING_IN_PROGRESS = "PARSING_IN_PROGRESS",
  PARSING_COMPLETED = "PARSING_COMPLETED",
  PARSING_FAILED = "PARSING_FAILED",
  PARSING_COMPLETED_WITH_ERRORS = "PARSING_COMPLETED_WITH_ERRORS",
}

export enum AdhocTaskStatus {
  ADHOC_NOT_STARTED = "ADHOC_NOT_STARTED",
  ADHOC_INITIALISED = "ADHOC_INITIALISED",
  ADHOC_IN_PROGRESS = "ADHOC_IN_PROGRESS",
  ADHOC_COMPLETED = "ADHOC_COMPLETED",
  ADHOC_FAILED = "ADHOC_FAILED",
  ADHOC_FORCE_COMPLETED = "ADHOC_FORCE_COMPLETED",
  ADHOC_FORCE_FAILED = "ADHOC_FORCE_FAILED",
}
interface CustomerUser {
  id: string;
  email: string;
  name: string;
}

interface CustomerCompany {
  id: string;
  name: string;
}

export interface AdhocMetadata {
  identifier_value: string;
  identifier_type: string;
  customer_user: CustomerUser;
  customer_company: CustomerCompany;
  company_name: string;
  account_name?: string;
}

export interface AdhocTask {
  id: string;
  status: AdhocTaskStatus;
  adhoc_metadata: AdhocMetadata;
  created_at: string;
  updated_at: string;
}

export enum AdhocTaskNature {
  REQUEST_NEW_COMPANY = "REQUEST_NEW_COMPANY",
  SCRAPING_MCA_V3_TASK = "SCRAPING_MCA_V3",
  SCRAPING_MCA_V2_TASK = "SCRAPING_MCA_V2",
  SCRAPING_LEGALS_TASK = "SCRAPING_LEGALS",
  SCRAPING_INDIAN_CREDIT_RATING_TASK = "SCRAPING_INDIAN_CREDIT_RATINGS",
  SCRAPING_EPFO_TASK = "SCRAPING_EPFO",
}

export interface UpdateAdhocTaskStatus {
  status: AdhocTaskStatus;
  identifier_type: IdentifierTypeEnum;
  identifier_value: string;
  task_id: string;
}

export interface CreateAdhocTaskPayLoad {
  task_nature_name: AdhocTaskNature;
  adhoc_metadata: Record<string, string>;
}

export interface UpdateAdhocTaskPayload {
  status?: AdhocTaskStatus;
  task_nature_id?: string;
  adhoc_metadata?: Record<string, unknown>;
  mca_account_id?: string;
  adhoc_task_id: string;
}

export interface AdhocScrapingTaskInfo extends SystemFields {
  task_nature_id: string;
  status: AdhocTaskStatus;
  scraping_metadata?: ScrapingMetadata;
  adhoc_metadata?: ScrapingMetadata;
  result_reference?: string;
  assigned_to?: string;
  mca_account_id: string;
  id: string;
  task_nature_name: string;
  assigned_to_name: string;
}
export interface ScrapingMetadata {
  company_name: string;
  identifier_type: string;
  identifier_value: string;
  account_name: string;
  srn: string;
  file_path: string;
  download_primary: boolean;
  download_secondary: boolean;
}

export interface ScrapingTasksHistoryPayload {
  identifier_value: string;
  identifier_type: IdentifierTypeEnum;
  scraping_type: Exclude<AdhocTaskNature, AdhocTaskNature.REQUEST_NEW_COMPANY>;
}
