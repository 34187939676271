export interface McaAccount {
  name: string;
  status: McaAccountStatus;
  mca_version: GengisMcaAccountVersion;
  account_owner?: string;
  id: string;
  created_at: string;
  updated_at: string;
  account_owner_name?: string;
  total_tasks?: number;
}

export enum McaAccountStatus {
  ALLOCATED = "ALLOCATED",
  FREE = "FREE",
  IN_USE = "IN_USE",
  LOCKED = "LOCKED",
}

export enum GengisMcaAccountVersion {
  MCA_V2 = "MCA_V2",
  MCA_V3 = "MCA_V3",
}

export interface CreateMcaAccountPayload {
  name: string;
  status: McaAccountStatus;
  mca_version: GengisMcaAccountVersion;
  account_owner: string;
}
