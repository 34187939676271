import type { Role } from "@app/context/AuthContext";
import { useAuth } from "@app/hooks/useAuth";
import { useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  allowedRoles: Role[];
}

export const AuthGuard = ({ children, allowedRoles }: Props) => {
  const { user, role, isLoading } = useAuth();
  const router = useNavigate();

  const isRouteAccessible = useMemo(
    () => role.some((el) => allowedRoles.includes(el)),
    [allowedRoles, role],
  );

  if (!user) {
    router("/login");
  }
  if (!isRouteAccessible && !isLoading) return <Navigate to="/labelling" />;

  return children;
};
