import { AdhocTaskStatus } from "@app/store/api/qaTasksApi";
import classNames from "classnames";
import React from "react";

interface StatusBadgeProps {
  status: AdhocTaskStatus;
}

export const RenderAdhocStatus: React.FC<StatusBadgeProps> = ({ status }) => {
  const getStatusConfig = (
    status: AdhocTaskStatus,
  ): {
    label: string;
    bgClassName: string;
    textClassName: string;
  } => {
    switch (status) {
      case AdhocTaskStatus.ADHOC_NOT_STARTED:
        return {
          label: "Not Started",
          bgClassName: "bg-gray-100",
          textClassName: "text-gray-700",
        };
      case AdhocTaskStatus.ADHOC_INITIALISED:
        return {
          label: "Initialised",
          bgClassName: "bg-amber-50",
          textClassName: "text-amber-600",
        };
      case AdhocTaskStatus.ADHOC_IN_PROGRESS:
        return {
          label: "In Progress",
          bgClassName: "bg-sky-50",
          textClassName: "text-sky-600",
        };
      case AdhocTaskStatus.ADHOC_COMPLETED:
        return {
          label: "Completed",
          bgClassName: "bg-green-100",
          textClassName: "text-green-600",
        };
      case AdhocTaskStatus.ADHOC_FAILED:
        return {
          label: "Failed",
          bgClassName: "bg-rose-50",
          textClassName: "text-rose-600",
        };
      case AdhocTaskStatus.ADHOC_FORCE_COMPLETED:
        return {
          label: "Force Completed",
          bgClassName: "bg-teal-50",
          textClassName: "text-teal-600",
        };
      case AdhocTaskStatus.ADHOC_FORCE_FAILED:
        return {
          label: "Force Failed",
          bgClassName: "bg-red-50",
          textClassName: "text-red-600",
        };
    }
  };

  const config = getStatusConfig(status);

  return (
    <span
      className={classNames(
        "group inline-flex items-center rounded-md px-2.5 py-1 text-xs font-medium",
        "transition-all duration-300 ease-in-out",
        "shadow-sm border",
        config.bgClassName,
        config.textClassName,
        "border-transparent",
      )}
    >
      {config.label}
    </span>
  );
};
