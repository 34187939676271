import React from "react";
import { xbrlIndAsBalanceSheetWIthHeading } from "./xbrlIndAsValidation";
import { useFormContext, FormProvider } from "react-hook-form";
import { InputGroup } from "../InputGroup";

interface XbrlIndAsBalanceSheetInputsProps {
  isEditable: boolean;
  isPdfOpen: boolean;
}

export const XbrlIndAsBalanceSheetInputs: React.FC<
  XbrlIndAsBalanceSheetInputsProps
> = ({ isEditable, isPdfOpen }) => {
  const form = useFormContext();
  const {
    non_current_assets,
    current_assets,
    sub_totals,
    liabilities: { equity, non_current_liabilities, current_liabilities },
  } = xbrlIndAsBalanceSheetWIthHeading;

  return (
    <FormProvider {...form}>
      <InputGroup
        title="Non-Current Assets"
        fields={non_current_assets}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Current Assets"
        fields={current_assets}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Liabilities Equity"
        fields={equity}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Non-Current Liabilities"
        fields={non_current_liabilities}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Current Liabilities"
        fields={current_liabilities}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Sub Totals"
        fields={sub_totals}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
    </FormProvider>
  );
};
