import * as Yup from "yup";

export const overViewSchema = Yup.object({
  typeOfEntity: Yup.string(),
  status: Yup.string(),
  totalDirectors: Yup.number()
    .min(0, "Total directors should not be less than 0")
    .typeError("Please enter valid value"),
  authorizedCapital: Yup.number()
    .min(0, "Authorized capital must be at least 0")
    .typeError("Please enter valid value"),
  paidUpCapital: Yup.number()
    .min(0, "Paid up capital must be at least 0")
    .typeError("Please enter valid value"),
  yearOfIncorporation: Yup.mixed<Date | string>()
    .test("valid-date", "Invalid date", (value) => {
      if (!value) return true;
      const date = new Date(value);
      return !isNaN(date.getTime());
    })
    .test(
      "not-future",
      "Year of incorporation cannot be in the future",
      (value) => {
        if (!value) return true;
        const date = new Date(value);
        return date <= new Date();
      },
    ),
  registrarOfCompany: Yup.string(),
  address: Yup.string(),
  lastAGM: Yup.mixed<Date | string>()
    .test("valid-date", "Invalid date", (value) => {
      if (!value) return true;
      const date = new Date(value);
      return !isNaN(date.getTime());
    })
    .test("not-future", "Last AGM cannot be in the future", (value) => {
      if (!value) return true;
      const date = new Date(value);
      return date <= new Date();
    }),
  about: Yup.string().max(500, "About must be at most 500 characters"),
});

export type IOverViewSchema = Yup.InferType<typeof overViewSchema>;
