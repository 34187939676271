import { TextArea } from "@components/common/TextArea";
import { useForm, FormProvider } from "react-hook-form";
export const BusinessInfo = () => {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <form>
        <div className="mt-6 border rounded-md p-4">
          <h3 className="text-xl font-semibold mb-4">Business</h3>
          <TextArea
            name="business_activity"
            label="Business Activity"
            rows={3}
          />
          <TextArea
            name="business_description"
            label="Business Description"
            rows={3}
          />
        </div>
      </form>
    </FormProvider>
  );
};
