import { type icons } from "lucide-react";

export interface Route {
  name: string;
  to: string;
  icon: keyof typeof icons;
  children?: Route[];
}

export const LabellingSideBar: Route[] = [
  {
    name: "Edit Companies",
    to: "/labelling",
    icon: "Pencil",
  },
  {
    name: "Task List",
    to: "/labelling/task/pending-tasks",
    icon: "CircleCheckBig",
  },
  {
    name: "My MCA Accounts",
    to: "/qa/mca-accounts",
    icon: "User",
  },
];

export const AdminSidebar: Route[] = [
  {
    name: "Customer Company",
    to: "/admin/company",
    icon: "Building2",
    children: [
      {
        name: "Company List",
        to: "/admin/company-list",
        icon: "List",
      },
    ],
  },
  {
    name: "Customer Users",
    to: "/admin/users",
    icon: "User",
    children: [
      {
        name: "User List",
        to: "/admin/users-list",
        icon: "List",
      },
      {
        name: "Edit User",
        to: "/admin/edit-user",
        icon: "UserPlus",
      },
    ],
  },
  {
    name: "QA - Internal Use",
    to: "/",
    icon: "Building2",
    children: [
      {
        name: "QA - Users",
        to: "/admin/platform-users",
        icon: "Building2",
      },
      {
        name: "Account Management",
        to: "/admin/account-management",
        icon: "Wallet",
      },
      {
        name: "MCA Accounts",
        to: "/admin/mca-accounts",
        icon: "Building2",
      },
    ],
  },
];

export const VerifierSidebar: Route[] = [
  {
    name: "Projects",
    to: "/verifier/projects",
    icon: "File",
  },
  {
    name: "Company requests",
    to: "/verifier/company-requests",
    icon: "MessageSquare",
  },
  {
    name: "Scraper",
    to: "/verifier/scraper",
    icon: "Bot",
  },
];
