import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import type { CognitoUser } from "./types";
import { QaDbApiResponse } from "@app/types";

const usersListApi = createApi({
  reducerPath: "usersList",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["approved-users", "unapproved-users"],
  endpoints: (builder) => ({
    getApprovedUsers: builder.query<QaDbApiResponse<CognitoUser[]>, void>({
      query: () => ({
        url: "/user-management/get-cognito-approved-user-list",
        method: "GET",
      }),
      providesTags: ["approved-users"],
    }),
    getUnapprovedUsers: builder.query<QaDbApiResponse<CognitoUser[]>, void>({
      query: () => ({
        url: "/user-management/get-cognito-unapproved-user-list",
        method: "GET",
      }),
      providesTags: ["unapproved-users"],
    }),
  }),
  refetchOnMountOrArgChange: true,
});

const { useGetApprovedUsersQuery, useGetUnapprovedUsersQuery } = usersListApi;

export { useGetApprovedUsersQuery, useGetUnapprovedUsersQuery };
export default usersListApi;
