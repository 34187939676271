import {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosInstance,
} from "axios";
import { decryptToken, encryptToken } from "@app/utils/security";
import { qaDbApiHandler } from "./apiHandler";

let isRefreshing = false;
let refreshQueue: Array<(token: string) => void> = [];

export const getToken = (): string | null => {
  const localEncryptedToken = localStorage.getItem("idToken");
  if (localEncryptedToken) {
    return decryptToken(localEncryptedToken);
  }
  return null;
};

const getAccessToken = (): string | null => {
  const localEncryptedAccessToken = localStorage.getItem("accessToken");
  if (localEncryptedAccessToken) {
    return decryptToken(localEncryptedAccessToken);
  }
  return null;
};

const getRefreshToken = (): string | null => {
  const localEncryptedRefreshToken = localStorage.getItem("refreshToken");
  if (localEncryptedRefreshToken) {
    return decryptToken(localEncryptedRefreshToken);
  }
  return null;
};

const getUserId = (): string | null => {
  const localUserId = localStorage.getItem("userId");
  if (localUserId) {
    return localUserId;
  }
  return null;
};

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = getToken();
      const accessToken = getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (accessToken) {
        config.headers.Authentication = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const originalRequest = error.config as AxiosRequestConfig & {
        _retry?: boolean;
      };

      if (
        error.response?.status === 401 &&
        originalRequest.url === `/editing-platform-auth/reauthenticate`
      ) {
        localStorage.clear();
        window.location.href = "/";
        return Promise.reject(error);
      }

      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url !== `/editing-platform-auth/reauthenticate` &&
        originalRequest.url !== `/users/authenticate`
      ) {
        if (!isRefreshing) {
          originalRequest._retry = true;
          isRefreshing = true;

          try {
            const refreshToken = getRefreshToken();
            const userId = getUserId();

            if (!refreshToken || !userId) {
              localStorage.clear();
              window.location.href = "/";
              return Promise.reject(error);
            }

            const { data } = await qaDbApiHandler.post(
              "/users/re-authenticate",
              {
                user_id: userId,
                refresh_token: refreshToken,
              },
            );

            localStorage.setItem(
              "idToken",
              encryptToken(data.response_data.token_details.id_token),
            );
            localStorage.setItem(
              "accessToken",
              encryptToken(data.response_data.token_details.access_token),
            );

            axiosInstance.defaults.headers.common["Authorization"] =
              `Bearer ${data.response_data.token_details.id_token}`;
            axiosInstance.defaults.headers.common["Authentication"] =
              `Bearer ${data.response_data.token_details.access_token}`;

            // Process the queued requests
            refreshQueue.forEach((callback) => callback(data.id_token));
            refreshQueue = [];

            return axiosInstance(originalRequest);
          } catch (refreshError) {
            refreshQueue = [];
            // Do not clear localStorage or redirect
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        }

        const retryOriginalRequest = new Promise<AxiosResponse>((resolve) => {
          refreshQueue.push((token: string) => {
            if (originalRequest.headers) {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
            }
            resolve(axiosInstance(originalRequest));
          });
        });

        return retryOriginalRequest;
      }

      return Promise.reject(error);
    },
  );
};
