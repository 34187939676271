import { CreditAgency } from "@app/store/api/creditRating/types";
import { Input } from "@components/common/Input";
import { Controller, Control } from "react-hook-form";
import Select from "react-select";

type RatingFieldsProps = {
  control: Control;
};

export const RatingFields: React.FC<RatingFieldsProps> = ({ control }) => {
  return (
    <div className="border p-3 rounded-md h-full">
      <Input
        className="border rounded px-2 py-1 mr-2 mb-2"
        placeholder="Rating"
        label="Rating"
        name="rating"
      />
      <Input
        className="border rounded px-2 py-1 mr-2 mb-2"
        placeholder="Ratings Date"
        label="Ratings Date"
        name="ratings_date"
        type="date"
      />
      <Input
        className="border rounded px-2 py-1 mr-2 mb-2"
        placeholder="Ratings URL"
        label="Ratings URL"
        name="ratings_url"
      />
      <label className="block text-gray-700 truncate">Rating Agency</label>
      <Controller
        name="rating_agency"
        control={control}
        render={({ field }) => (
          <Select
            className="rounded mr-2 mb-4 mt-3"
            maxMenuHeight={200}
            isSearchable
            options={
              Object.values(CreditAgency).map((el) => ({
                label: el,
                value: el,
              })) || []
            }
            value={{
              label: field.value?.toString() || "",
              value: field.value?.toString() || "",
            }}
            onChange={(selectedOption) => {
              field.onChange(selectedOption?.value);
            }}
          />
        )}
      />
      <Input
        className="border rounded px-2 py-1 mr-2 mb-2"
        placeholder="Outlook"
        label="Outlook"
        name="outlook"
      />
      <Input
        className="border rounded px-2 py-1 mr-2 mb-2"
        placeholder="Instrument Type"
        label="Instrument"
        name="instrument"
      />
      <Input
        className="border rounded px-2 py-1 mr-2 mb-2"
        placeholder="Rating Amount"
        label="Rating Amount"
        name="rating_amount"
      />
    </div>
  );
};
