import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LabellingPage from "./pages/DashboardPage";
import OverViewPage from "./pages/OverViewPage";
import DocumentsPage from "./pages/DocumentsPage";
import FallbackPage from "./pages/FallbackPage";
import CompanyList from "./pages/CompanyList";
import UsersList from "./pages/UsersList";
import PendingTasks from "./pages/PendingTasks";
import BackgroundTasks from "./pages/BackgroundTasks";
import CompletedTasks from "./pages/CompletedTasks";
import ShareHoldingPattern from "./pages/ShareHoldingPattern";
import PlatformUsers from "./pages/PlatformUsers";
import FinancialPage from "./pages/FinancialPage";
import EditUser from "./pages/EditUser";
import Management from "./pages/Management";
import CreditRatings from "./pages/CreditRatings";
import ProjectsPage from "./pages/Projects";
import AccountManager from "./pages/AccountManager";
import CompanyRequests from "./pages/CompanyRequests";
import FinancialParameter from "./pages/FinancialParameter";
import Compliances from "@pages/Compliances";
import McaAccounts from "@pages/McaAccounts";
import QaMcaAccounts from "@pages/QaMcaAccounts";
import Charges from "./pages/Charges";

import { DashBoardLayout } from "./layout/DashBoardLayout";
import { HomeLayout } from "./layout/HomeLayout";
import { TaskListLayout } from "./layout/TaskListLayout";
import { AuthGuard } from "@app/acl";
import { PageLoader } from "@components/loader";

const Settings = lazy(() => import("./pages/Settings"));
const ScraperPage = lazy(() => import("./pages/ScraperPage"));

export type AppRouteObject = RouteObject & {
  path?: string;
  element: React.ReactNode;
  children?: AppRouteObject[];
};

export const routes: AppRouteObject[] = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/settings",
    element: (
      <AuthGuard allowedRoles={["Admin", "QA", "Verifier"]}>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<PageLoader />}>
            <Settings />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/qa",
    element: (
      <AuthGuard allowedRoles={["Admin", "QA", "Verifier"]}>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "mca-accounts",
        element: <QaMcaAccounts />,
      },
    ],
  },
  {
    path: "/labelling",
    element: (
      <AuthGuard allowedRoles={["Admin", "QA", "Verifier"]}>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <LabellingPage />,
      },
    ],
  },
  {
    path: "/labelling/task",
    element: (
      <AuthGuard allowedRoles={["Admin", "QA", "Verifier"]}>
        <TaskListLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "pending-tasks",
        element: <PendingTasks />,
      },
      {
        path: "completed-tasks",
        element: <CompletedTasks />,
      },
      {
        path: "background-tasks",
        element: <BackgroundTasks />,
      },
    ],
  },
  {
    path: "/labelling/:activeCountry/:corporateType",
    element: (
      <AuthGuard allowedRoles={["Admin", "QA", "Verifier"]}>
        <DashBoardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":cin/overview",
        element: <OverViewPage />,
      },
      {
        path: ":cin/shareholding-pattern",
        element: <ShareHoldingPattern />,
      },
      {
        path: ":cin/management",
        element: <Management />,
      },
      {
        path: ":cin/finances",
        element: <FinancialPage />,
      },
      {
        path: ":cin/financial-parameter",
        element: <FinancialParameter />,
      },
      {
        path: ":cin/documents",
        element: <DocumentsPage />,
      },
      {
        path: ":cin/credit-ratings",
        element: <CreditRatings />,
      },
      {
        path: ":cin/charges",
        element: <Charges />,
      },
      {
        path: ":cin/compliances",
        element: <Compliances />,
      },
    ],
  },
  {
    path: "/verifier",
    element: (
      <AuthGuard allowedRoles={["Verifier", "Admin"]}>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "projects",
        element: <ProjectsPage />,
      },
      {
        path: "company-requests",
        element: <CompanyRequests />,
      },
      {
        path: "scraper",
        element: (
          <Suspense fallback={<PageLoader />}>
            <ScraperPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <AuthGuard allowedRoles={["Admin"]}>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "company-list",
        element: <CompanyList />,
      },
      {
        path: "users-list",
        element: <UsersList />,
      },
      {
        path: "edit-user",
        element: <EditUser />,
      },
      {
        path: "platform-users",
        element: <PlatformUsers />,
      },
      {
        path: "account-management",
        element: <AccountManager />,
      },
      {
        path: "mca-accounts",
        element: <McaAccounts />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <FallbackPage />,
  },
];
