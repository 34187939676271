import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { apiHandler } from "@app/config/apiHandler";
import {
  TokenOrderLedger,
  TokenOrder,
  TokenOrderResponse,
  ReportOrder,
  ReportOrderStatus,
  ReportOrderResponse,
} from "./types";
import { QaDbApiResponse } from "@app/types";

export const accountManagementApi = createApi({
  reducerPath: "accountManagementApi",
  baseQuery: axiosBaseQuery(apiHandler),
  endpoints: (builder) => ({
    getCompanyHistory: builder.query<
      QaDbApiResponse<TokenOrderResponse[]>,
      TokenOrderLedger
    >({
      query: (payload: TokenOrderLedger) => ({
        url: "/customer-company-token-order-ledger/get-all",
        method: "GET",
        params: payload,
      }),
    }),
    topUpToken: builder.mutation<unknown, TokenOrder>({
      query: (payload) => ({
        url: "/customer-company-token-order-ledger/create",
        method: "POST",
        data: {
          ...payload,
          juspay_order_status: "COMPLETED",
        },
      }),
    }),
    createOrderForCustomer: builder.mutation<unknown, ReportOrder>({
      query: (payload) => ({
        url: "/customer-user-report-order-ledger/place-report-order-for-user",
        method: "POST",
        data: {
          ...payload,
          is_fresh_report: true,
          company_report_type: "BASIC REPORT",
        },
      }),
    }),
    getReportOrderStatus: builder.query<
      QaDbApiResponse<ReportOrderResponse>,
      ReportOrderStatus
    >({
      query: (payload) => ({
        url: "/customer-user-report-order-ledger/get-report-status-for-user",
        method: "GET",
        params: payload,
      }),
    }),
  }),
});

export const {
  useGetCompanyHistoryQuery,
  useTopUpTokenMutation,
  useCreateOrderForCustomerMutation,
  useLazyGetReportOrderStatusQuery,
} = accountManagementApi;

export default accountManagementApi;
