import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import { QaDbApiResponse } from "@app/types";
import {
  CompliancesPayload,
  EPFOData,
  GSTDetail,
  LegalHistoryData,
} from "./types";

export const compliancesApi = createApi({
  reducerPath: "compliancesApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: ["compliances"],
  endpoints: (builder) => ({
    getEPFODetails: builder.query<
      QaDbApiResponse<EPFOData[]>,
      CompliancesPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/compliances/epfo",
        method: "GET",
        params: payload,
      }),
      providesTags: ["compliances"],
    }),
    getGSTDetails: builder.query<
      QaDbApiResponse<GSTDetail[]>,
      CompliancesPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/compliances/gst",
        method: "GET",
        params: payload,
      }),
      providesTags: ["compliances"],
    }),
    getLegalHistory: builder.query<
      QaDbApiResponse<LegalHistoryData>,
      CompliancesPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/compliances/legal-history",
        method: "GET",
        params: payload,
      }),
      providesTags: ["compliances"],
    }),
  }),
});

export const {
  useGetEPFODetailsQuery,
  useGetGSTDetailsQuery,
  useGetLegalHistoryQuery,
} = compliancesApi;

export default compliancesApi;
