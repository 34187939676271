import { useGetAllLabellingTasksGroupByProjectsQuery } from "@app/store/api/qaTasksApi/qaTasksApi";
import { formatDate } from "@app/utils/formatDate";
import { NotFound } from "@components/common/NotFound";
import { ToolTip } from "@components/common/table";
import { PageLoader } from "@components/loader";
import { useNavigate } from "react-router-dom";
import { findRoutes } from "@components/tasks/pending/components/findRoute";
import { useActiveCompany } from "@app/hooks/useActiveCompany";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import {
  LabellingTaskWithVersion,
  TaskStatus,
} from "@app/store/api/qaTasksApi";
import { useGroupedTasks } from "@app/hooks/useGroupedTasks";
import { GroupedTaskAccordion } from "@components/tasks/pending/components/GroupedTaskAccordion";
import { useState } from "react";
import { formatDateWithSeconds } from "@components/admin/projects/utils";

export const POLLING_INTERVAL = 15000;

const LABELLING_HEADERS = [
  { name: "Task Nature", value: "task_nature" },
  { name: "Created At", value: "created_at" },
  { name: "Company CIN ID", value: "cin_id" },
  { name: "Completed At", value: "completed_at" },
];

const LIMIT = 5;
export const Labelling = () => {
  const [skip, setSkip] = useState(0);
  const { activeCountry, activeCorporate } = useActiveCompany();
  const navigate = useNavigate();
  const {
    data: completedTasks,
    isLoading,
    isError,
    startedTimeStamp,
  } = useGetAllLabellingTasksGroupByProjectsQuery(
    {
      status: [TaskStatus.QA_LABELING_COMPLETED],
      skip: skip,
      limit: LIMIT,
    },
    { pollingInterval: POLLING_INTERVAL },
  );

  const { dataGroupedByProject } = useGroupedTasks<LabellingTaskWithVersion>(
    completedTasks?.response_data || {},
  );

  if (isLoading) {
    return (
      <PageLoader
        className="w-full h-full"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 185px)" }}
      />
    );
  }

  if (isError) {
    return (
      <NotFound
        message="No Completed Labelling Tasks"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 185px)" }}
      />
    );
  }
  return (
    <main>
      {dataGroupedByProject.length > 0 ? (
        <GroupedTaskAccordion<LabellingTaskWithVersion>
          startedTimeStamp={startedTimeStamp || 0}
          data={dataGroupedByProject}
          headers={LABELLING_HEADERS}
          skip={skip}
          setSkip={setSkip}
          limit={LIMIT}
          total={completedTasks?.total || 0}
          renderContent={(task) => {
            return {
              created_at: formatDateWithSeconds(task.created_at),
              task_nature: (
                <ToolTip
                  value={removeUnderscores(task.task_nature_name)}
                  trimValue={35}
                />
              ),
              cin_id: (
                <ToolTip
                  value={task.identifier_value}
                  handler={() =>
                    navigate(
                      findRoutes[
                        task.task_nature_name as keyof typeof findRoutes
                      ](task.identifier_value, activeCountry, activeCorporate),
                    )
                  }
                />
              ),
              completed_at: formatDate(task.completed_at),
            };
          }}
        />
      ) : (
        <NotFound
          message="No Completed Labelling Tasks"
          className="h-[calc(100vh-200px)]"
        />
      )}
    </main>
  );
};
