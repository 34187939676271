import { Button } from "@components/common/Button";
import { User, Mail, Users, Clock, Copy, MoveRight } from "lucide-react";
import toast from "react-hot-toast";

interface CustomerCardProps {
  name: string;
  id: string;
  email: string;
  teamName: string;
  lastActive: string;
  toggleIsCreateOrderForCustomerModalOpen: () => void;
}

export const CustomerCard = ({
  name,
  id,
  email,
  teamName,
  lastActive,
  toggleIsCreateOrderForCustomerModalOpen,
}: CustomerCardProps) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(id);
      toast.success("ID Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 max-w-md hover:shadow-lg transition-shadow max-h-[260px]">
      <div className="space-y-4">
        <div className="flex items-center space-x-3">
          <div className="bg-blue-100 p-2 rounded-full">
            <User className="w-5 h-5 text-blue-600" />
          </div>
          <div className="flex items-center justify-between w-full">
            <h3 className="font-semibold text-lg text-gray-900">{name}</h3>
            <button
              onClick={copyToClipboard}
              className="flex items-center space-x-1 text-sm text-gray-500 hover:text-blue-600 transition-colors"
              title="Copy ID"
            >
              <Copy className="w-4 h-4" />
              <span>Copy ID</span>
            </button>
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex items-center space-x-2 text-gray-600">
            <Mail className="w-4 h-4" />
            <span className="text-sm">{email}</span>
          </div>

          <div className="flex items-center space-x-2 text-gray-600">
            <Users className="w-4 h-4" />
            <span className="text-sm">{teamName}</span>
          </div>
        </div>
        <div className="pt-2 border-t">
          <div className="flex items-center space-x-2 text-gray-500">
            <Clock className="w-4 h-4" />
            <span className="text-sm">Last active: {lastActive}</span>
          </div>
        </div>
        <Button
          variant="primary"
          rightIcon={MoveRight}
          className="w-full"
          onClick={toggleIsCreateOrderForCustomerModalOpen}
        >
          Place Order
        </Button>
      </div>
    </div>
  );
};
