import { Status } from "@app/store/api/chargesApi/types";
import classNames from "classnames";
import { getBadgeStyle } from "./getBadge";
import { Button } from "@components/common/Button";

interface ChargeCardProps {
  charge_id?: string;
  date: string;
  holder_name: string;
  amount?: string;
  fillCharges: (chargeId: string) => void;
  openPdf: (documentId: string) => void;
  active: boolean;
  status: Status;
  shouldOpenPDf: boolean;
  document_id: string;
}

export const ChargeCard = ({
  charge_id,
  date,
  holder_name,
  amount,
  fillCharges,
  openPdf,
  active,
  shouldOpenPDf,
  document_id,
  status,
}: ChargeCardProps) => {
  const onClickHandler = () => {
    if (shouldOpenPDf) {
      openPdf(document_id as string);
    } else {
      fillCharges(charge_id as string);
    }
  };

  return (
    <div
      key={charge_id}
      className={classNames(
        "border border-gray-200 rounded-lg p-4 mt-4 hover:cursor-pointer hover:bg-gray-100",
        active && "bg-gray-200",
      )}
      onClick={onClickHandler}
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span className="text-sm font-medium">Charge ID:</span>
          <span className="text-sm">{charge_id}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm font-medium">Date:</span>
          <span className="text-sm">{date}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm font-medium">Status:</span>
          <span
            className={classNames(
              getBadgeStyle(status),
              "px-2 py-1 rounded-md",
            )}
          >
            {status}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm font-medium">Holder Name:</span>
          <span className="text-sm truncate">{holder_name}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm font-medium">Amount:</span>
          <span className="text-sm">{amount}</span>
        </div>
        {shouldOpenPDf && (
          <Button variant="secondary" className="w-full">
            Open Document
          </Button>
        )}
      </div>
    </div>
  );
};
