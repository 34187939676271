import { createApi } from "@reduxjs/toolkit/query/react";
import type { Company } from "@app/types";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";

const outStandingDuesApi = createApi({
  reducerPath: "outStandingDues",
  baseQuery: axiosBaseQuery(parserApiHandler),
  endpoints: (builder) => ({
    getOutStandingDues: builder.query<Company, string>({
      query: (id: string) => ({
        url: "/editing-platform-data/outstanding-dues-editing-db/outstanding-dues-db",
        method: "GET",
        params: {
          document_id: id,
        },
      }),
    }),
  }),
});

export default outStandingDuesApi;
