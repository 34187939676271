import { Input } from "@components/common/Input";
import { useForm, FormProvider } from "react-hook-form";

export const EntityInfo = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 border rounded-md p-4">
          <Input
            name="entity_name"
            label="Entity Name"
            placeholder="Enter Entity Name"
          />
          <Input
            name="entity_type"
            label="Entity Type"
            suffixIconStyle="border shadow-sm rounded-md text-md"
            placeholder="Enter Entity Type"
          />
          <Input
            name="entity_status"
            label="Entity Status"
            placeholder="Enter Entity Status"
          />
          <Input
            name="industry_nature"
            label="Industry Nature"
            placeholder="Enter Industry Nature"
          />
          <Input
            name="registration_date"
            label="Registration Date"
            type="date"
          />
        </div>
      </form>
    </FormProvider>
  );
};
