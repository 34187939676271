import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import { Pagination } from "@app/types";
import {
  CreditRating,
  UpdateCreditRatingPayload,
  CreateCreditRating,
} from "./types";
import { QaDbApiResponse } from "@app/types";
import { OrNull } from "../shareHoldingsApi/types";

export const creditRatingApi = createApi({
  reducerPath: "creditRatingApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: ["creditRating"],
  endpoints: (builder) => ({
    getCreditRating: builder.query<
      QaDbApiResponse<CreditRating[]>,
      {
        identifier_value: string;
      } & Pagination
    >({
      query: ({ identifier_value, skip, limit }) => ({
        url: "/editing-platform-data/credit-ratings",
        method: "GET",
        params: {
          identifier_value,
          skip,
          limit,
        },
      }),
      providesTags: ["creditRating"],
    }),
    updateCreditRating: builder.mutation<void, UpdateCreditRatingPayload>({
      query: (payload) => ({
        url: "/editing-platform-data/credit-ratings",
        method: "PUT",
        data: payload,
        params: {
          credit_rating_id: payload.credit_rating_id,
        },
      }),
      invalidatesTags: ["creditRating"],
    }),
    createCreditRating: builder.mutation<
      void,
      Partial<OrNull<CreateCreditRating>>
    >({
      query: (payload) => ({
        url: "/editing-platform-data/credit-ratings",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["creditRating"],
    }),
    deleteCreditRating: builder.mutation<void, string>({
      query: (credit_rating_id) => ({
        url: "/editing-platform-data/credit-ratings",
        method: "DELETE",
        params: {
          credit_rating_id,
        },
      }),
      invalidatesTags: ["creditRating"],
    }),
  }),
});

const {
  useGetCreditRatingQuery,
  useUpdateCreditRatingMutation,
  useCreateCreditRatingMutation,
  useDeleteCreditRatingMutation,
} = creditRatingApi;

export {
  useGetCreditRatingQuery,
  useUpdateCreditRatingMutation,
  useCreateCreditRatingMutation,
  useDeleteCreditRatingMutation,
};
export default creditRatingApi;
