import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@app/store/axiosBase";
import { QaDbApiResponse } from "@app/types";
import { parserApiHandler } from "@app/config/apiHandler";
import { Charges, ChargesPayload, UpdateChargesPayload } from "./types";
import { OrNull } from "../shareHoldingsApi/types";

const chargesApi = createApi({
  reducerPath: "chargesApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: ["charges"],
  endpoints: (builder) => ({
    getCharges: builder.query<QaDbApiResponse<Charges[]>, ChargesPayload>({
      query: (payload) => ({
        url: "/editing-platform-data/charges/grouped-charges",
        method: "GET",
        params: payload,
      }),
      providesTags: ["charges"],
    }),
    updateCharges: builder.mutation<void, UpdateChargesPayload>({
      query: (payload) => ({
        url: "/editing-platform-data/charges/charges",
        method: "PUT",
        data: payload,
        params: {
          charge_id: payload.id,
        },
      }),
      invalidatesTags: ["charges"],
    }),
    createCharges: builder.mutation<
      void,
      Partial<OrNull<Charges>> & { identifier_value: string }
    >({
      query: (payload) => ({
        url: "/editing-platform-data/charges/charges",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["charges"],
    }),
  }),
});

export const {
  useGetChargesQuery,
  useUpdateChargesMutation,
  useCreateChargesMutation,
} = chargesApi;

export default chargesApi;
