import classNames from "classnames";

export interface StatusBoxProps {
  statusText: string;
  color?: string;
  className?: string;
}

export const StatusBox = ({ statusText, color, className }: StatusBoxProps) => {
  if (!color) {
    color =
      statusText.toLocaleLowerCase() === "active"
        ? "bg-green-500"
        : "bg-red-500";
  }

  return (
    <div
      className={classNames(
        "text-xs inline-flex items-center gap-1 p-1 border rounded-md border-gray-300",
        {
          className,
        },
      )}
    >
      <div className={`w-2 h-2 rounded-full ${color}`} />
      {statusText}
    </div>
  );
};
