import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { scraperApiHandler } from "@app/config/apiHandler";
import { UpdatePanPayload, UpdateWebsitePayload } from "./types";

const scraperApi = createApi({
  reducerPath: "credhiveScraper",
  baseQuery: axiosBaseQuery(scraperApiHandler),
  endpoints: (builder) => ({
    updatePanInfo: builder.mutation<void, UpdatePanPayload>({
      query: (payload) => ({
        url: "/search-data/company-pan",
        method: "post",
        data: payload,
      }),
    }),
    getWebsiteInfoByCin: builder.mutation<
      {
        website: string;
      },
      string
    >({
      query: (id) => ({
        url: "credhive-scraper/company-website/find-company-website",
        method: "post",
        data: {
          cin: id,
        },
      }),
    }),
    updateWebsiteInfo: builder.mutation<void, UpdateWebsitePayload>({
      query: (payload) => ({
        url: "/search-data/company-pan",
        method: "post",
        data: payload,
      }),
    }),
    triggerFormNameScraping: builder.mutation<void, string>({
      query: (cin) => ({
        url: "/credhive-scraper/mca-docs/upload-mca-docs-v2",
        method: "POST",
        data: {
          cin,
        },
      }),
    }),
    forceUploadDocument: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: "/credhive-scraper/mca-docs/force-upload-document",
        data: payload,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    triggerMcaV3Scrape: builder.mutation<void, string>({
      query: (cin) => ({
        url: "/credhive-scraper/mca-docs/upload-mca-docs-v3",
        method: "POST",
        data: { cin },
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useUpdatePanInfoMutation,
  useGetWebsiteInfoByCinMutation,
  useUpdateWebsiteInfoMutation,
  useTriggerFormNameScrapingMutation,
  useForceUploadDocumentMutation,
  useTriggerMcaV3ScrapeMutation,
} = scraperApi;

export default scraperApi;
