import { LabellingTaskWithVersion } from "@app/store/api/qaTasksApi";
import { ScrapeTask } from "@app/store/api/qaTasksApi";
import { isAfter } from "date-fns";
import { useMemo } from "react";

type TaskTypes = LabellingTaskWithVersion | ScrapeTask;

export function useGroupedTasks<T extends TaskTypes>(
  tasks: Record<string, T[]>,
) {
  const dataGroupedByProject = useMemo(
    () =>
      Object.entries(tasks || {})
        .map(([key, value]) => {
          // Find latest task date for each project
          const latestTask = value.reduce<T>((latest, current) => {
            if (!latest) return current;
            return isAfter(
              new Date(current.created_at),
              new Date(latest.created_at),
            )
              ? current
              : latest;
          }, value[0]);

          return {
            project_name: key.split("_")[0],
            version: value[0].project_version,
            tasks: value,
            latestTaskDate: latestTask.created_at,
            project_id: latestTask.project_id,
          };
        })
        .sort((a, b) => {
          if (!a.latestTaskDate || !b.latestTaskDate) return 0;
          return isAfter(new Date(a.latestTaskDate), new Date(b.latestTaskDate))
            ? -1
            : 1;
        })
        .map(({ project_name, version, tasks, project_id }) => ({
          project_name,
          version,
          tasks,
          project_id,
        })),
    [tasks],
  );

  return { dataGroupedByProject };
}
