import React, { useState, useMemo } from "react";
import { GSTDetail, GSTFiling } from "@app/store/api/compliancesApi/types";
import { Table } from "@components/common/table";

interface GSTEntryProps {
  gstDetail: GSTDetail;
}

const headers = [
  { name: "Date of Filing", value: "date_of_filing", classes: "p-4 text-sm" },
  { name: "Financial Year", value: "financial_year", classes: "p-4 text-sm" },
  { name: "Method of Filing", value: "methodOfFiling", classes: "p-4 text-sm" },
  { name: "Return Type", value: "return_type", classes: "p-4 text-sm" },
  { name: "Status", value: "status", classes: "p-4 text-sm" },
  { name: "Tax Period", value: "tax_period", classes: "p-4 text-sm" },
];

export const GSTEntry: React.FC<GSTEntryProps> = ({ gstDetail }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const createRows = useMemo(
    () => (filings: GSTFiling[]) => {
      return filings.map((filing: GSTFiling) => ({
        date_of_filing: filing.date_of_filing || "",
        financial_year: filing.financial_year || "",
        methodOfFiling: filing.methodOfFiling || "",
        return_type: filing.return_type || "",
        status: filing.status || "",
        tax_period: filing.tax_period || "",
      }));
    },
    [],
  );

  const rows = createRows(gstDetail.filings);
  const totalPages = Math.ceil(rows.length / itemsPerPage);
  const paginatedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <div>
      <h3 className="text-md font-semibold text-heading mb-4">
        GST Entry Details
      </h3>
      <div className="grid grid-cols-12 gap-3 my-4 content-between w-auto">
        {Object.entries(gstDetail).map(([key, value], index) => {
          if (key !== "filings") {
            return (
              <div
                key={key}
                className={`col-span-4 my-1 ${
                  index % 3 === 0 ? "col-start-1" : ""
                }`}
              >
                <p className="font-normal text-sm mb-1 text-tertiary-600">
                  {key
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </p>
                <p className="text-xs font-medium text-secondary-700">
                  {value || "-"}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
      <Table
        headers={headers}
        rows={paginatedRows}
        tableDivClassname="w-full border border-gray-200 !p-0 rounded mt-4"
        paginate
        skip={(currentPage - 1) * itemsPerPage}
        currentLimit={itemsPerPage}
        maxSize={rows.length}
        nextHandler={() =>
          setCurrentPage((prev) => Math.min(prev + 1, totalPages))
        }
        prevHandler={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
      />
    </div>
  );
};

export default GSTEntry;
