import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { qaDbApiHandler } from "@app/config/apiHandler";
import { QaDbApiResponse } from "@app/types";
import {
  TaskByStatusProps,
  Task,
  ScrapeTask,
  ScrapeTaskByStatusProps,
  TriggerLabellingUpdateStatusPayload,
  ScrapingDataSource,
  AdhocTask,
  AdhocTaskStatus,
  AdhocTaskNature,
  UpdateAdhocTaskStatus,
  ScrapingStatus,
  CreateAdhocTaskPayLoad,
  UpdateAdhocTaskPayload,
  ScrapingTasksHistoryPayload,
  AdhocScrapingTaskInfo,
  UpdateScrapeTaskPayload,
  TaskStatus,
  LabellingTaskWithVersion,
} from "./types";
import { Pagination } from "@app/types";

const qaTasksApi = createApi({
  reducerPath: "qaTaskApi",
  baseQuery: axiosBaseQuery(qaDbApiHandler),
  tagTypes: [
    "qa-tasks",
    "scrape-tasks",
    "qa-scrape-tasks",
    "incomplete-label-tasks",
    "adhoc-tasks",
    "scrape-tasks-group-by-project",
  ],
  endpoints: (builder) => ({
    getAllTasks: builder.query<QaDbApiResponse<Task[]>, void>({
      query: () => ({
        url: "qa-labeling-tasks/get-all-incomplete-tasks?limit=100",
        method: "get",
      }),
      providesTags: ["qa-tasks"],
    }),
    getAllTaskForQaByStatus: builder.query<
      QaDbApiResponse<Task[]>,
      TaskByStatusProps
    >({
      query: (props) => ({
        url: `qa-labeling-tasks/get-task-by-user-id-and-status?user_id=${props.id}&status=${props.status}&limit=100`,
        method: "get",
      }),
    }),
    getAllIncompletedTasksForQa: builder.query<
      QaDbApiResponse<Task[]>,
      {
        user_id: string;
      }
    >({
      query: (props) => ({
        url: `qa-labeling-tasks/get-all-incomplete-tasks-by-user-id?user_id=${props.user_id}&limit=100`,
        method: "get",
      }),
      providesTags: ["incomplete-label-tasks"],
    }),
    getAllCompletedLabelingTasksForAdmin: builder.query<
      QaDbApiResponse<Task[]>,
      Pagination
    >({
      query: (payload) => ({
        url: "qa-labeling-tasks/get-all-completed-tasks",
        method: "GET",
        params: {
          skip: payload.skip,
          limit: payload.limit,
        },
      }),
    }),
    getAllIncompletedScrapeTasksForQa: builder.query<
      QaDbApiResponse<ScrapeTask[]>,
      {
        user_id: string;
        task_nature_name?: ScrapingDataSource;
      } & Pagination
    >({
      query: (props) => ({
        url: `scraping-tasks/get-all-incomplete-tasks-by-user-id`,
        method: "get",
        params: {
          skip: props.skip,
          limit: props.limit,
          task_nature_name: props.task_nature_name,
          user_id: props.user_id,
        },
      }),
      providesTags: ["qa-scrape-tasks"],
    }),
    getAllScrapingTasks: builder.query<
      QaDbApiResponse<ScrapeTask[]>,
      Pagination
    >({
      query: (payload) => ({
        url: `scraping-tasks/get-all-incomplete-tasks?skip=${payload.skip}&limit=${payload.limit}`,
        method: "get",
      }),
      providesTags: ["scrape-tasks"],
    }),
    getAllScrapingTasksGroupByProjects: builder.query<
      QaDbApiResponse<Record<string, ScrapeTask[]>>,
      Pagination & {
        status: ScrapingStatus[];
      }
    >({
      query: (payload) => {
        const statusKey = new URLSearchParams();
        payload.status.forEach((status) => {
          statusKey.append("status", status);
        });
        return {
          url: `/scraping-tasks/get-tasks-by-status-grouped-by-project-for-user?${statusKey}`,
          method: "GET",
          params: {
            skip: payload.skip,
            limit: payload.limit,
          },
        };
      },
      providesTags: ["scrape-tasks-group-by-project"],
    }),
    getAllLabellingTasksGroupByProjects: builder.query<
      QaDbApiResponse<Record<string, LabellingTaskWithVersion[]>>,
      Pagination & {
        status: TaskStatus[];
      }
    >({
      query: (payload) => {
        const statusKey = new URLSearchParams();
        payload.status.forEach((status) => {
          statusKey.append("status", status);
        });
        return {
          url: `/qa-labeling-tasks/get-tasks-by-status-grouped-by-project-for-user?${statusKey}`,
          method: "GET",
          params: {
            skip: payload.skip,
            limit: payload.limit,
          },
        };
      },
    }),
    getAllScrapeTasksByStatus: builder.query<
      QaDbApiResponse<ScrapeTask[]>,
      ScrapeTaskByStatusProps
    >({
      query: (payload) => {
        const statusKey = new URLSearchParams();
        if (Array.isArray(payload.status)) {
          payload.status.forEach((status) => {
            statusKey.append("status", status);
          });
        }
        const url = Array.isArray(payload.status)
          ? `/scraping-tasks/get-task-by-user-id-and-status?${statusKey}`
          : `/scraping-tasks/get-task-by-user-id-and-status?${payload.status}`;
        return {
          url,
          method: "GET",
          params: {
            task_nature_name: payload.task_nature_name,
            skip: payload.skip,
            limit: payload.limit,
          },
        };
      },
    }),
    getAllCompletedScarpingTasksForAdmin: builder.query<
      QaDbApiResponse<ScrapeTask[]>,
      Pagination
    >({
      query: (payload) => ({
        url: `scraping-tasks/get-all-completed-tasks`,
        method: "GET",
        params: {
          skip: payload.skip,
          limit: payload.limit,
        },
      }),
    }),
    triggerLabellingUpdateStatus: builder.mutation<
      QaDbApiResponse<Task>,
      TriggerLabellingUpdateStatusPayload
    >({
      query: (payload) => ({
        url: "qa-labeling-tasks/trigger-update-status",
        method: "POST",
        data: {
          project_id: payload.project_id,
          status: payload.status,
          task_id: payload.task_id,
          identifier_value: payload.identifier_value,
          identifier_type: payload.identifier_type,
        },
      }),
    }),
    assignLabellingTask: builder.mutation<
      QaDbApiResponse<Task>,
      { task_id: string; user_id: string }
    >({
      query: (payload) => ({
        url: `/qa-labeling-tasks/assign`,
        method: "PUT",
        params: {
          task_id: payload.task_id,
          user_id: payload.user_id,
        },
      }),
      invalidatesTags: ["qa-tasks"],
    }),
    assignScrapeTask: builder.mutation<
      QaDbApiResponse<ScrapeTask>,
      { task_id: string; user_id: string }
    >({
      query: (payload) => ({
        url: `/scraping-tasks/assign`,
        method: "PUT",
        params: {
          task_id: payload.task_id,
          user_id: payload.user_id,
        },
      }),
      invalidatesTags: ["scrape-tasks"],
    }),
    markAsTaskCompleted: builder.mutation<void, { task_id: string }>({
      query: (payload) => ({
        url: `/qa-labeling-tasks/complete`,
        method: "PUT",
        params: {
          task_id: payload.task_id,
        },
      }),
    }),
    updateScrapeTask: builder.mutation<void, UpdateScrapeTaskPayload>({
      query: (payload) => ({
        url: `/scraping-tasks/update-status-and-create-parsing-tasks-on-completion`,
        method: "PUT",
        params: {
          task_id: payload.task_id,
        },
        data: {
          status: payload.status,
          scraping_metadata: payload.scraping_metadata,
        },
      }),
      invalidatesTags: ["qa-scrape-tasks", "scrape-tasks-group-by-project"],
    }),
    triggerReparseTask: builder.mutation<void, { project_id: string }>({
      query: (payload) => ({
        url: `/parsing-tasks/create-parsing-tasks-for-project`,
        method: "POST",
        data: {
          project_id: payload.project_id,
        },
      }),
    }),
    retryParsingTask: builder.mutation<void, { task_id: string }>({
      query: (payload) => ({
        url: `/parsing-tasks/retry-parsing-task`,
        method: "PUT",
        params: {
          task_id: payload.task_id,
        },
      }),
    }),
    getAdhocTasks: builder.query<
      QaDbApiResponse<AdhocTask[]>,
      Pagination & {
        status: AdhocTaskStatus[];
        task_nature_name: AdhocTaskNature;
      }
    >({
      query: (payload) => {
        const statusKey = new URLSearchParams();
        payload.status.forEach((status) => {
          statusKey.append("status", status);
        });
        const url = `/adhoc-tasks/get-all?${statusKey}`;
        return {
          url,
          method: "GET",
          params: {
            limit: payload.limit,
            skip: payload.skip,
            task_nature_name: payload.task_nature_name,
          },
        };
      },
      providesTags: ["adhoc-tasks"],
    }),
    createAdhocScraperTask: builder.mutation<void, CreateAdhocTaskPayLoad>({
      query: (payload) => ({
        url: "/adhoc-tasks/create-adhoc-and-task-nature",
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["adhoc-tasks"],
    }),
    updateAdhocTaskStatusForRequestCompany: builder.mutation<
      void,
      UpdateAdhocTaskStatus
    >({
      query: (payload) => ({
        url: `/adhoc-tasks/update-request-company-identifier`,
        method: "PATCH",
        params: {
          adhoc_task_id: payload.task_id,
        },
        data: {
          status: payload.status,
          identifier_type: payload.identifier_type,
          identifier_value: payload.identifier_value,
        },
      }),
      invalidatesTags: ["adhoc-tasks"],
    }),
    updateAdhocTaskStatusForAdmin: builder.mutation<
      void,
      UpdateAdhocTaskPayload
    >({
      query: (payload) => ({
        url: `/adhoc-tasks/update-and-release-account`,
        method: "PATCH",
        params: {
          adhoc_task_id: payload.adhoc_task_id,
        },
        data: payload,
      }),
      invalidatesTags: ["adhoc-tasks"],
    }),
    getMcaAccountsJobStatus: builder.query<
      QaDbApiResponse<{
        scraping_tasks: ScrapeTask[];
        adhoc_scraping_tasks: AdhocScrapingTaskInfo[];
      }>,
      string
    >({
      query: (accountId) => ({
        url: "/scraping-tasks/get-incomplete-normal-and-adhoc-tasks",
        method: "GET",
        params: {
          mca_account_id: accountId,
        },
      }),
    }),
    getAllScrapingTasksHistory: builder.query<
      QaDbApiResponse<{
        scraping_tasks: ScrapeTask[];
        adhoc_scraping_tasks: AdhocScrapingTaskInfo[];
      }>,
      ScrapingTasksHistoryPayload
    >({
      query: (payload) => ({
        url: "/scraping-tasks/get-all-scraping-tasks-for-identifier",
        method: "GET",
        params: payload,
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});
export const {
  useGetAllTasksQuery,
  useGetAllTaskForQaByStatusQuery,
  useGetAllIncompletedTasksForQaQuery,
  useGetAllIncompletedScrapeTasksForQaQuery,
  useLazyGetAllScrapingTasksQuery,
  useGetAllScrapeTasksByStatusQuery,
  useGetAllScrapingTasksGroupByProjectsQuery,
  useGetAllLabellingTasksGroupByProjectsQuery,
  useTriggerLabellingUpdateStatusMutation,
  useGetAllCompletedScarpingTasksForAdminQuery,
  useGetAllCompletedLabelingTasksForAdminQuery,
  useAssignLabellingTaskMutation,
  useAssignScrapeTaskMutation,
  useMarkAsTaskCompletedMutation,
  useUpdateScrapeTaskMutation,
  useTriggerReparseTaskMutation,
  useRetryParsingTaskMutation,
  useGetAdhocTasksQuery,
  useCreateAdhocScraperTaskMutation,
  useUpdateAdhocTaskStatusForRequestCompanyMutation,
  useUpdateAdhocTaskStatusForAdminMutation,
  useGetMcaAccountsJobStatusQuery,
  useGetAllScrapingTasksHistoryQuery,
} = qaTasksApi;

export default qaTasksApi;
