import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

interface PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: PopoverPrimitive.PopoverContentProps["side"];
  align?: PopoverPrimitive.PopoverContentProps["align"];
}

export const Popover: React.FC<PopoverProps> = ({
  children,
  content,
  side = "bottom",
  align = "center",
}) => {
  return (
    <PopoverPrimitive.Root>
      <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Content side={side} align={align} className="z-10">
        {content}
        <PopoverPrimitive.Arrow className="popover-arrow" />
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Root>
  );
};
