import React from "react";
import { xbrlAsBalanceSheetWIthHeading } from "./xbrlAsValidation";
import { useFormContext, FormProvider } from "react-hook-form";
import { InputGroup } from "../InputGroup";

export interface XbrlAsBalanceSheetInputsProps {
  isEditable: boolean;
  isPdfOpen: boolean;
}

export const XbrlAsBalanceSheetInputs: React.FC<
  XbrlAsBalanceSheetInputsProps
> = ({ isEditable, isPdfOpen }) => {
  const form = useFormContext();

  return (
    <FormProvider {...form}>
      <InputGroup
        title="Non-Current Assets"
        fields={xbrlAsBalanceSheetWIthHeading.non_current_assets}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Current Assets"
        fields={xbrlAsBalanceSheetWIthHeading.current_assets}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Extra Assets"
        fields={xbrlAsBalanceSheetWIthHeading.extra_assets}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Liabilities Equity"
        fields={xbrlAsBalanceSheetWIthHeading.liabilities.equity}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Current Liabilities"
        fields={xbrlAsBalanceSheetWIthHeading.liabilities.current_liabilities}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Non-Current Liabilities"
        fields={
          xbrlAsBalanceSheetWIthHeading.liabilities.non_current_liabilities
        }
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
      <InputGroup
        title="Sub Totals"
        fields={xbrlAsBalanceSheetWIthHeading.subTotals}
        isEditable={isEditable}
        isPdfOpen={isPdfOpen}
      />
    </FormProvider>
  );
};
