import { HolderLoader } from "@components/labelling/shareholder/components/HolderLoader";
import { FinancialProps } from "./PrimaryAuditor";
import { NotFound } from "@components/common/NotFound";
import { AuditorGroup } from "./AuditorGroup";
import { useGetAuditorQuery } from "@app/store/api/financialApi";
import { useId, useMemo } from "react";
import { Icon } from "@components/common";
import { useToggle } from "@app/hooks/useToggle";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@app/components/common/ToolTip";

export const AdditionalAuditor: React.FC<FinancialProps> = ({
  formNature,
  activeInfo,
}) => {
  if (!activeInfo || !formNature) return null;

  const [canShowNew, toggleNewAuditor] = useToggle(false);

  const { data, isLoading, isError } = useGetAuditorQuery({
    document_id: activeInfo.id,
    year: new Date(activeInfo.financial_year).getFullYear().toString(),
    nature: formNature,
  });

  const uniqueId = useId();

  const nonPrimaryAuditor = useMemo(() => {
    return data?.response_data.filter((info) => !info.is_primary) || [];
  }, [data]);

  const isAuditorsEmpty = nonPrimaryAuditor.length === 0;

  if (isLoading) return <HolderLoader title="Additional Auditor" />;

  if (isError)
    return <NotFound message="Error Getting Additional Auditor Info" />;

  if (isAuditorsEmpty) {
    return (
      <div className="border mt-8 rounded-md p-3">
        <h2 className="text-xl font-bold mb-4">Additional Auditor</h2>
        <AuditorGroup
          key={uniqueId}
          data={{}}
          formNature={formNature}
          activeInfo={activeInfo}
          isNew
        />
      </div>
    );
  }

  return (
    <div className="border mt-8 rounded-md p-3">
      <nav className="flex justify-between">
        <h2 className="text-xl font-bold">Additional Auditor</h2>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Icon
                name={canShowNew ? "Minus" : "Plus"}
                className="border rounded-md cursor-pointer"
                onClick={toggleNewAuditor.toggle}
              />
            </TooltipTrigger>
            <TooltipContent className="bg-gray-800 text-xs text-white">
              {canShowNew ? "Close Editor" : "Open Editor"}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </nav>
      {canShowNew && (
        <AuditorGroup
          key={uniqueId}
          data={{}}
          formNature={formNature}
          activeInfo={activeInfo}
          isNew
          onCancel={toggleNewAuditor.off}
        />
      )}
      {nonPrimaryAuditor.map((auditor, index) => (
        <AuditorGroup
          key={index}
          data={auditor}
          formNature={formNature}
          activeInfo={activeInfo}
          isNew={false}
        />
      ))}
    </div>
  );
};
