import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import usersList from "./api/usersList/usersList";
import customerCompaniesApi from "./api/customerCompany/useCustomerCompany";
import customerCompaniesTeamsApi from "./api/useCustomerTeams";
import scraperApi from "./api/scrapperApi/scraperApi";
import qaUsersApi from "./api/qaUsersApi/qaUsersApi";
import qaTasksApi from "./api/qaTasksApi/qaTasksApi";
import mcDocumentsApi from "./api/mcDocuments/mcDocuments";
import creditRatingApi from "./api/creditRating/creditRating";
import entityApi from "./api/entityApi/entityApi";
import cinSearchApi from "./api/cinSearchApi";
import documentsApi from "./api/documentsApi/documentsApi";
import qaProjectsApi from "./api/qaProjects/qaProjectsApi";
import accountManagementApi from "./api/accountManagementApi/accountManagementApi";
import financialApi from "./api/financialApi/financialApi";
import shareHoldingsApi from "./api/shareHoldingsApi/shareHoldingsApi";
import outStandingDuesApi from "./api/outStandingDuesApi/outStandingApi";
import llpFinancialsApi from "./api/llp-financialsApi/llpFinancialsApi";
import llpShareHoldingsApi from "./api/llpShareHoldingsApi/llpShareHoldingsApi";
import principleBusinessActivityApi from "./api/principleBusinessActivitiApi/principleBusinessActivityApi";
import documentCreationApi from "./api/documentCreationApi/documentCreationApi";
import chargesApi from "./api/chargesApi/chargesApi";
import indiaNonCorpCreditRatingsApi from "./api/indiaNonCorpApi/credit-ratings/creditRatingsApi";
import indiaNonCorpEntitiesApi from "./api/indiaNonCorpApi/entities/entitiesApi";
import mcaAccountsApi from "./api/mcaAccount/mcaAccountsApi";
import gengisScraperApi from "./api/gengisScraperApi/gengisScraperApi";
import companySlice from "./slices/companySlice";
import utilSlice from "./slices/utilSlice";
import compliancesApi from "./api/compliancesApi/compliancesApi";
import { screenshotsApi } from "./api/screenshotsApi";

export const allRtkQuerySlices = [
  usersList,
  customerCompaniesApi,
  scraperApi,
  customerCompaniesTeamsApi,
  qaUsersApi,
  qaTasksApi,
  mcDocumentsApi,
  creditRatingApi,
  entityApi,
  cinSearchApi,
  documentsApi,
  qaProjectsApi,
  accountManagementApi,
  financialApi,
  shareHoldingsApi,
  outStandingDuesApi,
  llpFinancialsApi,
  llpShareHoldingsApi,
  principleBusinessActivityApi,
  documentCreationApi,
  chargesApi,
  indiaNonCorpCreditRatingsApi,
  indiaNonCorpEntitiesApi,
  mcaAccountsApi,
  gengisScraperApi,
  compliancesApi,
  screenshotsApi,
];

export const store = configureStore({
  reducer: {
    [usersList.reducerPath]: usersList.reducer,
    [customerCompaniesApi.reducerPath]: customerCompaniesApi.reducer,
    [scraperApi.reducerPath]: scraperApi.reducer,
    [customerCompaniesTeamsApi.reducerPath]: customerCompaniesTeamsApi.reducer,
    [qaUsersApi.reducerPath]: qaUsersApi.reducer,
    [qaTasksApi.reducerPath]: qaTasksApi.reducer,
    [mcDocumentsApi.reducerPath]: mcDocumentsApi.reducer,
    [creditRatingApi.reducerPath]: creditRatingApi.reducer,
    [entityApi.reducerPath]: entityApi.reducer,
    [cinSearchApi.reducerPath]: cinSearchApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [qaProjectsApi.reducerPath]: qaProjectsApi.reducer,
    [accountManagementApi.reducerPath]: accountManagementApi.reducer,
    [financialApi.reducerPath]: financialApi.reducer,
    [shareHoldingsApi.reducerPath]: shareHoldingsApi.reducer,
    [outStandingDuesApi.reducerPath]: outStandingDuesApi.reducer,
    [llpFinancialsApi.reducerPath]: llpFinancialsApi.reducer,
    [llpShareHoldingsApi.reducerPath]: llpShareHoldingsApi.reducer,
    [principleBusinessActivityApi.reducerPath]:
      principleBusinessActivityApi.reducer,
    [documentCreationApi.reducerPath]: documentCreationApi.reducer,
    [chargesApi.reducerPath]: chargesApi.reducer,
    [indiaNonCorpCreditRatingsApi.reducerPath]:
      indiaNonCorpCreditRatingsApi.reducer,
    [indiaNonCorpEntitiesApi.reducerPath]: indiaNonCorpEntitiesApi.reducer,
    [mcaAccountsApi.reducerPath]: mcaAccountsApi.reducer,
    [gengisScraperApi.reducerPath]: gengisScraperApi.reducer,
    [compliancesApi.reducerPath]: compliancesApi.reducer,
    [screenshotsApi.reducerPath]: screenshotsApi.reducer,
    company: companySlice.reducer,
    util: utilSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersList.middleware)
      .concat(customerCompaniesApi.middleware)
      .concat(scraperApi.middleware)
      .concat(customerCompaniesTeamsApi.middleware)
      .concat(qaUsersApi.middleware)
      .concat(qaTasksApi.middleware)
      .concat(mcDocumentsApi.middleware)
      .concat(creditRatingApi.middleware)
      .concat(entityApi.middleware)
      .concat(cinSearchApi.middleware)
      .concat(documentsApi.middleware)
      .concat(qaProjectsApi.middleware)
      .concat(accountManagementApi.middleware)
      .concat(financialApi.middleware)
      .concat(shareHoldingsApi.middleware)
      .concat(outStandingDuesApi.middleware)
      .concat(llpFinancialsApi.middleware)
      .concat(llpShareHoldingsApi.middleware)
      .concat(principleBusinessActivityApi.middleware)
      .concat(documentCreationApi.middleware)
      .concat(chargesApi.middleware)
      .concat(indiaNonCorpCreditRatingsApi.middleware)
      .concat(indiaNonCorpEntitiesApi.middleware)
      .concat(mcaAccountsApi.middleware)
      .concat(gengisScraperApi.middleware)
      .concat(compliancesApi.middleware)
      .concat(screenshotsApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
