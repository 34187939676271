import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  aoc4BalanceSheetSchema,
  Aoc4BalanceSheet,
} from "./aoc4/aoc4Validation";
import { useEffect, useMemo, lazy, Suspense } from "react";
import { PageLoader } from "@components/loader";
import toast from "react-hot-toast";
import type { FormNature } from "@app/pages/FinancialPage";
import { Aoc4BalanceSheetInputs } from "./aoc4/Aoc4BalanceSheetInputs";
import { XbrlAsBalanceSheetInputs } from "./xbrl-as/XbrlAsBalanceSheetInput";
import {
  xbrlIndAsBalanceSheetSchema,
  type XbrlIndAsValidationType,
} from "./xbrl-ind-as/xbrlIndAsValidation";
import { XbrlIndAsBalanceSheetInputs } from "./xbrl-ind-as/XbrlIndAsInput";
import {
  xbrlAsBalanceSheetType,
  xbrlAsBalanceSheetSchema,
} from "./xbrl-as/xbrlAsValidation";
import * as Yup from "yup";
import classNames from "classnames";
import {
  FinancialMetaDataResponseObject,
  MCAFormType,
  useGetBalanceSheetQuery,
  useUpdateBalanceSheetMutation,
  FinancialTypes,
} from "@app/store/api/financialApi";

const PdfViewer = lazy(() => import("@components/pdf-viewer/PdfViewer"));

export interface FinancialProps {
  formNature: FormNature | null;
  isEditable: boolean;
  closeEdit: () => void;
  activeInfo: FinancialMetaDataResponseObject;
  isPdfOpen: boolean;
  activePdf: string;
  activeYear: string;
}

export type BalanceSheetType =
  | Aoc4BalanceSheet
  | xbrlAsBalanceSheetType
  | XbrlIndAsValidationType;

export const BalanceSheet = ({
  isEditable,
  closeEdit,
  activeInfo,
  formNature,
  isPdfOpen,
  activePdf,
  activeYear,
}: FinancialProps) => {
  const [updateFinancialBalance] = useUpdateBalanceSheetMutation();
  const { data, isLoading } = useGetBalanceSheetQuery({
    document_id: activeInfo.id,
    year: activeYear,
    nature: formNature as FinancialTypes,
  });
  //@ts-expect-error Dynamic schema
  const schemaToTriggerFomValidation: Yup.ObjectSchema<any> = useMemo(() => {
    switch (activeInfo?.document_type) {
      case MCAFormType.AOC4:
        return aoc4BalanceSheetSchema;
      case MCAFormType.AOC4_XBRL_AS:
        return xbrlAsBalanceSheetSchema;
      case MCAFormType.AOC4_XBRL_IND_AS:
        return xbrlIndAsBalanceSheetSchema;
      default:
        return aoc4BalanceSheetSchema;
    }
  }, [activeInfo]);

  const form = useForm<Partial<BalanceSheetType>>({
    resolver: yupResolver(schemaToTriggerFomValidation),
    mode: "all",
  });

  useEffect(() => {
    if (data?.response_data) {
      form.reset(data.response_data as Partial<BalanceSheetType>);
    }
  }, [data]);

  const { isDirty } = form.formState;

  const onSave: SubmitHandler<Partial<BalanceSheetType>> = async (payload) => {
    if (!activeInfo) return;
    try {
      if (!payload || !formNature) {
        throw new Error();
      }
      await updateFinancialBalance({
        ...payload,
        balance_sheet_id: data?.response_data.id as string,
      }).unwrap();

      toast.success("Data updated successfully");
    } catch {
      toast.error("Error updating the data");
    } finally {
      closeEdit();
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="mt-5">
      <div className="mt-5 flex">
        <div
          className={classNames("border p-4 rounded-md", {
            "w-1/2": isPdfOpen,
            "w-full": !isPdfOpen,
          })}
        >
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(onSave)}
              className={classNames(
                "overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100",
                {
                  "max-h-[calc(100vh-200px)]": isPdfOpen,
                  "max-h-none": !isPdfOpen,
                },
              )}
            >
              {activeInfo?.document_type === MCAFormType.AOC4 && (
                <Aoc4BalanceSheetInputs
                  isEditable={isEditable}
                  isPdfOpen={isPdfOpen}
                />
              )}
              {activeInfo?.document_type === MCAFormType.AOC4_XBRL_AS && (
                <XbrlAsBalanceSheetInputs
                  isEditable={isEditable}
                  isPdfOpen={isPdfOpen}
                />
              )}
              {activeInfo?.document_type === MCAFormType.AOC4_XBRL_IND_AS && (
                <XbrlIndAsBalanceSheetInputs
                  isEditable={isEditable}
                  isPdfOpen={isPdfOpen}
                />
              )}

              {isEditable && (
                <div className="flex gap-2 sticky bottom-0 bg-white py-2">
                  <button
                    className="px-2 py-2 w-full border text-blue-600 border-blue-600 text-md rounded-md font-semibold mt-2 hover:border-blue-500 hover:text-blue-500"
                    type="reset"
                    onClick={() => {
                      form.reset();
                      closeEdit();
                    }}
                  >
                    Cancel
                  </button>
                  {isDirty && (
                    <button
                      className="px-2 py-2 w-full bg-blue-600 text-md text-white rounded-md font-semibold mt-2 hover:bg-blue-500"
                      type="submit"
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        {isPdfOpen && activePdf && (
          <div className="w-1/2 pl-4 relative">
            <Suspense fallback={<PageLoader />}>
              <PdfViewer
                pdfLink={activePdf}
                className="h-full overflow-y-auto"
              />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
};
