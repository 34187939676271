export enum EntityIdentifierType {
  GSTIN = "GSTIN",
  PAN = "PAN",
}

export enum EntityType {
  Partnership = "Partnership",
  Proprietorship = "Proprietorship",
}

export enum EntityStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRED = "EXPIRED",
}

export enum AddressType {
  REGISTERED = "REGISTERED",
  BUSINESS = "BUSINESS",
  UNKNOWN = "UNKNOWN",
}
