import Modal from "react-modal";
import { Building2, Mail, Briefcase, X, Info, User } from "lucide-react";
import { AdhocMetadata } from "@app/store/api/qaTasksApi";
import { ToolTip } from "@components/common/table";
import toast from "react-hot-toast";

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: Partial<AdhocMetadata>;
}

export const CompanyInfoModal = ({ isOpen, onClose, data }: InfoModalProps) => {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success("Copied to clipboard");
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black/50 backdrop-blur-sm"
    >
      <div className="bg-white min-w-96 rounded-lg shadow-xl">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">
            Company Details
          </h2>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div className="p-6 space-y-6">
          {data.customer_company?.name && (
            <div className="space-y-2 flex justify-between">
              <div className="flex items-center text-gray-500">
                <Building2 className="h-4 w-4 mr-2" />
                <span>Requested Company</span>
              </div>
              <p className="text-lg font-medium text-gray-900 pl-6">
                {data.company_name}
              </p>
            </div>
          )}
          <div className="flex items-start justify-between">
            <div className="py-1 rounded-md flex items-center">
              <Briefcase className="h-4 w-4 text-gray-500 mr-2" />
              <span>Requested {data.identifier_type}</span>
            </div>
            <span className="text-sm font-medium text-gray-900 mt-2">
              {data.identifier_value}
            </span>
          </div>
          {data.customer_company?.name && (
            <div className="space-y-2 flex justify-between">
              <div className="flex items-center text-gray-500">
                <Building2 className="h-4 w-4 mr-2" />
                <span className="text-sm">Customer Company</span>
              </div>
              <p className="text-lg font-medium text-gray-900 pl-6">
                {data.customer_company.name}
              </p>
            </div>
          )}
          {data.customer_user?.email && (
            <div className="space-y-2 flex justify-between">
              <div className="flex items-center text-gray-500">
                <Mail className="h-4 w-4 mr-2" />
                <span className="text-sm">Customer Contact Email</span>
              </div>
              <a
                href={`mailto:${data.customer_user.email}`}
                className="text-blue-600 hover:text-blue-700 pl-6 block transition-colors"
              >
                {data.customer_user.email}
              </a>
            </div>
          )}
          {data.customer_user?.name && (
            <div className="space-y-2 flex justify-between">
              <div className="flex items-center text-gray-500">
                <User className="h-4 w-4 mr-2" />
                <span className="text-sm">Customer Name</span>
              </div>
              <p className="font-medium text-gray-900 pl-6 text-sm truncate cursor-pointer">
                {data.customer_user.name}
              </p>
            </div>
          )}
          {data.customer_user?.id && (
            <div className="space-y-2 flex justify-between">
              <div className="flex items-center text-gray-500 mr-10">
                <Info className="h-4 w-4 mr-2" />
                <span className="text-sm">Customer ID</span>
              </div>
              <ToolTip
                value={data.customer_user.id}
                handler={() =>
                  data.customer_user?.id &&
                  copyToClipboard(data.customer_user.id)
                }
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
