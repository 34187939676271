import type { icons } from "lucide-react";

interface Route {
  name: string;
  to: string;
  icon?: keyof typeof icons;
}

export const TaskListLinks: Route[] = [
  {
    name: "Pending Tasks",
    to: "/labelling/task/pending-tasks",
    icon: "ListTodo",
  },
  {
    name: "Completed Tasks",
    to: "/labelling/task/completed-tasks",
    icon: "ListChecks",
  },
  {
    name: "Background Tasks",
    to: "/labelling/task/background-tasks",
    icon: "PackageCheck",
  },
];
