import { ModalProps } from "@app/types";
import { Button } from "@components/common/Button";
import { X } from "lucide-react";
import Modal from "react-modal";

interface AdditionalInfoProps extends ModalProps {
  data: Record<string, string>;
  id: string;
}

export const AdditionalInfo = ({
  isOpen,
  toggleModal,
  data,
}: AdditionalInfoProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal.off}
      overlayClassName="modal-overlay"
      className="modal-content w-full max-w-2xl"
    >
      <main>
        <div className="flex justify-between items-center border-b pb-4">
          <h1 className="text-2xl font-bold ">Additional Information</h1>
          <Button variant="destructive" rightIcon={X} onClick={toggleModal.off}>
            Close
          </Button>
        </div>

        <div className="mt-4">
          <pre className="text-sm text-gray-500">
            {Object.entries(data)
              .map(([key, value]) => `${key}: ${value}`)
              .join("\n")}
          </pre>
        </div>
      </main>
    </Modal>
  );
};
