import { PanTasks, WebsiteTask } from "@components/tasks/bg-tasks";
import classNames from "classnames";
import { useState } from "react";

const BackgroundTasks = () => {
  const [activeTab, setActiveTab] = useState<"pan" | "website">("pan");

  return (
    <div className="h-full w-full">
      <h2 className="text-start text-3xl font-bold mb-2">Background Tasks</h2>
      <div className="h-[80vh] flex w-full gap-2 flex-col">
        <div className="flex border-b">
          <button
            className={classNames("flex-1 py-2 text-center", {
              "border-b-2 border-blue-500 text-blue-500": activeTab === "pan",
              "text-gray-500": activeTab !== "pan",
            })}
            onClick={() => {
              setActiveTab("pan");
            }}
            type="button"
          >
            Pan Info Tasks
          </button>
          <button
            type="button"
            className={classNames("flex-1 py-2 text-center", {
              "border-b-2 border-blue-500 text-blue-500":
                activeTab === "website",
              "text-gray-500": activeTab !== "website",
            })}
            onClick={() => {
              setActiveTab("website");
            }}
          >
            Website Info Tasks
          </button>
        </div>
        {activeTab === "pan" && <PanTasks />}
        {activeTab === "website" && <WebsiteTask />}
      </div>
    </div>
  );
};

export default BackgroundTasks;
