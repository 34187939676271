import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format, eachDayOfInterval, isSameDay } from "date-fns";
import { TokenOrderResponse } from "@app/store/api/accountManagementApi/types";
import { DateRange } from "react-day-picker";

interface LineGraphProps {
  data: TokenOrderResponse[];
  dateRange: DateRange;
}

export const LineGraph: React.FC<LineGraphProps> = ({ data, dateRange }) => {
  const chartData = useMemo(() => {
    if (!dateRange.from || !dateRange.to) return [];

    const dailyData = eachDayOfInterval({
      start: dateRange.from,
      end: dateRange.to,
    }).map((date) => ({
      date,
      tokens: 0,
      price: 0,
      total: 0,
    }));

    data.forEach((item) => {
      const itemDate = new Date(item.created_at);
      const dailyIndex = dailyData.findIndex((day) =>
        isSameDay(day.date, itemDate),
      );
      if (dailyIndex !== -1) {
        dailyData[dailyIndex].tokens += item.number_of_tokens;
        dailyData[dailyIndex].price = item.token_price;
        dailyData[dailyIndex].total += item.number_of_tokens * item.token_price;
      }
    });

    return dailyData.map((item) => ({
      ...item,
      date: format(item.date, "MMM dd"),
    }));
  }, [data, dateRange]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="tokens"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="total"
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
