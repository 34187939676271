import React from "react";
import {
  CompanyDetails,
  PaymentDetail,
} from "@app/store/api/compliancesApi/types";
import { format } from "date-fns";
import classNames from "classnames";

interface CompanyDetailsSectionProps {
  details: CompanyDetails;
  latestPayment?: PaymentDetail;
}

export const CompanyDetailsSection: React.FC<CompanyDetailsSectionProps> = ({
  details,
  latestPayment,
}) => {
  enum EstablishmentField {
    EstCode = "est_code",
    EstName = "est_name",
    City = "city",
    NumberOfEmployees = "numberOfEmployees",
    Amount = "amount",
    LatestWageMonth = "latestWageMonth",
    LatestDateOfCredit = "latestDateOfCredit",
    PrimaryBusinessActivity = "primary_business_activity",
    DateOfSetup = "date_of_setup",
    PfExemptionStatus = "pf_exemption_status",
    PensionExemptionStatus = "pension_exemption_status",
    EdliExemptionStatus = "edli_exemption_status",
  }

  const DISPLAY_ORDER = [
    EstablishmentField.EstCode,
    EstablishmentField.EstName,
    EstablishmentField.City,
    EstablishmentField.NumberOfEmployees,
    EstablishmentField.Amount,
    EstablishmentField.LatestWageMonth,
    EstablishmentField.LatestDateOfCredit,
    EstablishmentField.PrimaryBusinessActivity,
    EstablishmentField.DateOfSetup,
    EstablishmentField.PfExemptionStatus,
    EstablishmentField.PensionExemptionStatus,
    EstablishmentField.EdliExemptionStatus,
  ] as const;

  const LABEL_MAP: Record<EstablishmentField, string> = {
    [EstablishmentField.EstCode]: "Establishment ID",
    [EstablishmentField.EstName]: "Establishment Name",
    [EstablishmentField.City]: "City",
    [EstablishmentField.NumberOfEmployees]: "Number of Employees",
    [EstablishmentField.Amount]: "Amount (₹)",
    [EstablishmentField.LatestWageMonth]: "Latest Wage Month",
    [EstablishmentField.LatestDateOfCredit]: "Latest Date of Credit",
    [EstablishmentField.PrimaryBusinessActivity]: "Primary Business Activity",
    [EstablishmentField.DateOfSetup]: "Date of Setup",
    [EstablishmentField.PfExemptionStatus]: "PF Exemption Status",
    [EstablishmentField.PensionExemptionStatus]: "Pension Exemption Status",
    [EstablishmentField.EdliExemptionStatus]: "EDLI Exemption Status",
  };

  const getValue = (key: string): string => {
    switch (key) {
      case "numberOfEmployees":
        return latestPayment?.number_of_employee?.toString() || "-";
      case "amount":
        return latestPayment?.amount?.toString() || "-";
      case "latestWageMonth":
        return latestPayment?.wage_month || "-";
      case "latestDateOfCredit":
        return latestPayment?.date_of_credit
          ? format(new Date(latestPayment.date_of_credit), "dd MMM yyyy")
          : "-";
      default:
        return details[key as keyof CompanyDetails]?.toString() || "-";
    }
  };

  return (
    <div className="grid grid-cols-12 gap-3 my-4 content-between w-auto">
      {DISPLAY_ORDER.map((key, index) => (
        <div
          key={key}
          className={classNames(
            "col-span-4 my-1",
            index % 3 === 0 ? "col-start-1" : "",
          )}
        >
          <p className="font-normal text-sm mb-1 text-tertiary-600">
            {LABEL_MAP[key]}
          </p>
          <p className="text-xs font-medium text-secondary-700">
            {getValue(key)}
          </p>
        </div>
      ))}
    </div>
  );
};
