import React, { useState, useCallback } from "react";
import { RatingGroup } from "@app/store/api/creditRating/types";
import { RatingGroupEditor } from "@components/labelling/credit-ratings";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";
import { Button } from "@components/common/Button";
import classNames from "classnames";
import { AddRatingModal } from "./AddRatingModal";
import { useToggle } from "@app/hooks/useToggle";
import { Pagination } from "@app/components/common/Pagination";

export const CreditRatingEditor: React.FC<{
  data: RatingGroup;
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  total: number;
}> = ({ data, skip, setSkip, limit, total }) => {
  // Initialize openAccordions with indices of all accordions initially open
  const [openAccordions, setOpenAccordions] = useState<number[]>(
    Object.keys(data || {}).map((_, index) => index),
  );
  // Toggle state for the Add Rating Modal
  const [isAddRatingModalOpen, toggleAddRatingModal] = useToggle();

  // Function to toggle the state of an accordion
  const toggleAccordion = useCallback(
    (index: number) => {
      // Update openAccordions state by adding or removing the index based on its presence
      setOpenAccordions((prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index],
      );
    },
    [openAccordions],
  );

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="p-4">
        <p className="text-xl font-semibold text-gray-600">
          No credit rating data available.
        </p>
      </div>
    );
  }
  const totalPages = Math.ceil(total / limit);
  const currentPage = Math.floor(skip / limit) + 1;

  return (
    <div className="px-4 py-2">
      <div className="flex justify-between items-center">
        <h4 className="text-2xl font-bold mb-4">Credit Ratings</h4>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setSkip((page - 1) * limit)}
          className="mt-4"
        />
      </div>

      <AddRatingModal
        isOpen={isAddRatingModalOpen}
        onClose={toggleAddRatingModal.off}
        setSkip={setSkip}
      />
      {Object.entries(data).map(([agency, ratings], agencyIndex) => {
        return (
          <div
            key={agencyIndex}
            className="mb-4 border rounded-lg overflow-hidden mt-4"
          >
            <button
              className="w-full p-4 text-left bg-blue-600 text-white font-bold text-xl flex justify-between items-center"
              onClick={() => toggleAccordion(agencyIndex)}
            >
              {agency}
              {openAccordions.includes(agencyIndex) ? (
                <ChevronUp size={24} />
              ) : (
                <ChevronDown size={24} />
              )}
            </button>
            {openAccordions.includes(agencyIndex) && (
              <div className="p-4">
                <div className="flex mb-4 justify-end">
                  <Button
                    variant="secondary"
                    className={classNames(
                      "px-4 py-2 w-auto text-wrap flex-grow-0",
                    )}
                    rightIcon={Plus}
                    onClick={toggleAddRatingModal.on}
                  >
                    Add Credit Ratings
                  </Button>
                </div>
                {ratings.map((rating) => (
                  <RatingGroupEditor group={rating} />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
