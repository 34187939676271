import { useToggle } from "@app/hooks/useToggle";
import { Input, Icon } from "@components/common";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuditorSchema, Auditor } from "./PrimaryAuditor";
import { useEffect } from "react";
import {
  AuditorResponse,
  FinancialMetaDataResponseObject,
  FinancialTypes,
} from "@app/store/api/financialApi/types";
import { TextArea } from "@app/components/common/TextArea";
import {
  useUpdateAuditorMutation,
  useDeleteAuditorMutation,
  useAddAuditorMutation,
} from "@app/store/api/financialApi";
import toast from "react-hot-toast";

export const AuditorGroup = ({
  data,
  formNature,
  activeInfo,
  isNew,
  onCancel,
}: {
  data: Partial<AuditorResponse>;
  formNature: FinancialTypes;
  activeInfo: FinancialMetaDataResponseObject;
  isNew: boolean;
  onCancel?: () => void;
}) => {
  if (!formNature || !activeInfo) return;
  const [isEditable, toggleEditable] = useToggle(isNew);

  const [addAuditor] = useAddAuditorMutation();
  const [updateAuditor] = useUpdateAuditorMutation();
  const [deleteAuditor] = useDeleteAuditorMutation();

  const form = useForm<Auditor>({
    resolver: yupResolver(AuditorSchema),
    defaultValues: data,
  });

  const handleSubmit = (payload: Auditor) => {
    try {
      if (isNew) {
        addAuditor({
          ...payload,
          is_primary: false,
          document_id: activeInfo.id,
          entity_id: activeInfo.entity_id,
          year: new Date(activeInfo.financial_year).getFullYear(),
          nature: formNature,
          financial_year: activeInfo.financial_year,
        }).unwrap();
        toast.success("Data Created successfully");
      } else {
        updateAuditor({
          auditor_id: data.id as string,
          ...payload,
        }).unwrap();
        toast.success("Data updated successfully");
      }
    } catch {
      toast.error("Error Processing Request");
      form.reset(data);
    }

    toggleEditable.off();
  };

  const handleDeleteAuditor = (id: string) => {
    toast.promise(deleteAuditor(id).unwrap(), {
      loading: "Deleting Auditor",
      error: "Error Deleting Auditor",
      success: "Successfully Deleted Auditor",
    });
  };

  useEffect(() => {
    if (data) {
      form.reset(data);
    }
  }, [data]);

  return (
    <div className="border mt-8 rounded-md p-3 hover:bg-gray-50">
      <div className="flex gap-2 justify-end">
        {!isNew && (
          <button
            onClick={() => handleDeleteAuditor(data.id as string)}
            type="button"
            className="bg-red-500 text-white px-3 rounded-lg py-2 hover:bg-blue-60 inline-flex gap-2 justify-end"
          >
            Delete
            <Icon name="Trash2" size={18} className="self-center" />
          </button>
        )}

        {!isEditable && !isNew && (
          <button
            onClick={toggleEditable.on}
            type="button"
            className="bg-blue-500 text-white px-3 rounded-lg py-2 hover:bg-blue-60 inline-flex gap-2 justify-end"
          >
            Edit
            <Icon name="Pen" size={18} className="self-center" />
          </button>
        )}
      </div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="mt-4">
          <div className="flex flex-col gap-1">
            <Input
              name="auditor_name"
              label="Auditor Name"
              disabled={!isEditable}
            />
            <Input
              name="auditor_firm_name"
              label="Auditor Firm Name"
              disabled={!isEditable}
            />
            <Input name="pan" label="PAN" disabled={!isEditable} />
            <Input
              name="membership_number"
              label="Membership Number"
              disabled={!isEditable}
            />
            <Input
              name="firm_registration_number"
              label="Firm Registration Number"
              disabled={!isEditable}
            />
            <TextArea name="address" label="Address" disabled={!isEditable} />
            <TextArea
              name="address_line1"
              label="Address line 1"
              disabled={!isEditable}
            />
            <TextArea
              name="address_line2"
              label="Address line 2"
              disabled={!isEditable}
            />
            <Input name="country" label="Country" disabled={!isEditable} />
            <Input name="city" label="city" disabled={!isEditable} />
            <Input name="state" label="state" disabled={!isEditable} />
            <Input name="pincode" label="pincode" disabled={!isEditable} />
            <Input name="email" label="email" disabled={!isEditable} />
          </div>
          <div>
            {isEditable && (
              <div className="flex gap-2">
                <button
                  className="px-2 py-2 w-full border text-blue-600 border-blue-600 text-md rounded-md font-semibold mt-2 hover:border-blue-500 hover:text-blue-500"
                  type="reset"
                  onClick={() => {
                    toggleEditable.off();
                    if (onCancel) {
                      onCancel();
                    }
                  }}
                >
                  Cancel
                </button>
                {form.formState.isDirty && (
                  <button
                    className="px-2 py-2 w-full bg-blue-600 text-md text-white rounded-md font-semibold mt-2 hover:bg-blue-500"
                    type="submit"
                  >
                    Save
                  </button>
                )}
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
