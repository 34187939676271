import { FormProvider, useFormContext } from "react-hook-form";
import { InputGroup } from "../InputGroup";
import { aoc4BalanceSheetWithHeading } from "./aoc4Validation";

interface Aoc4BalanceSheetInputsProps {
  isEditable: boolean;
  isPdfOpen: boolean;
}

export const Aoc4BalanceSheetInputs: React.FC<Aoc4BalanceSheetInputsProps> = ({
  isEditable,
  isPdfOpen,
}) => {
  const form = useFormContext();
  return (
    <FormProvider {...form}>
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Non-Current Assets"
        fields={aoc4BalanceSheetWithHeading.non_current_assets}
        isEditable={isEditable}
      />
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Current Assets"
        fields={aoc4BalanceSheetWithHeading.current_assets}
        isEditable={isEditable}
      />
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Extra Assets"
        fields={aoc4BalanceSheetWithHeading.extra_assets}
        isEditable={isEditable}
      />
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Liabilities Equity"
        fields={aoc4BalanceSheetWithHeading.liabilities.equity}
        isEditable={isEditable}
      />
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Non-Current Liabilities"
        fields={aoc4BalanceSheetWithHeading.liabilities.non_current_liabilities}
        isEditable={isEditable}
      />
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Current Liabilities"
        fields={aoc4BalanceSheetWithHeading.liabilities.current_liabilities}
        isEditable={isEditable}
      />
      <InputGroup
        isPdfOpen={isPdfOpen}
        title="Sub Totals"
        fields={aoc4BalanceSheetWithHeading.computed_properties}
        isEditable={isEditable}
      />
    </FormProvider>
  );
};
