export const statuses = [
  { text: "Done", color: "bg-green-500" },
  { text: "Failed", color: "bg-red-500" },
  { text: "Not Started", color: "bg-yellow-500" },
];

export const headers = [
  {
    name: "Category",
    value: "category",
  },
  {
    name: "Year of Filing",
    value: "year",
  },
  {
    name: "Date of Filing",
    value: "filing",
  },

  {
    name: "File Name",
    value: "fileName",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Action",
    value: "action",
  },
];

export const sortOptions = [
  {
    value: "asc",
    label: "Ascending",
  },
  {
    value: "desc",
    label: "Descending",
  },
];

export type SortProps = "asc" | "desc";
