import { IdentifierTypeEnum } from "@app/types";

export interface TokenOrderLedger {
  company_id: string;
  from_datetime: string;
  to_datetime: string;
}

export interface TokenOrder {
  user_id: string;
  company_id: string;
  number_of_tokens: number;
  token_price: number;
  order_source: "ACCOUNT_MANAGER";
  order_reason: OrderReason;
  order_source_metadata: {
    name: string;
    email: string;
    id: string;
  };
}

export interface TokenOrderResponse {
  user_id: string | null;
  company_id: string;
  number_of_tokens: number;
  token_price: number;
  order_source: string;
  order_reason: string;
  order_source_metadata: {
    name: string;
    email: string;
    id: string;
  } & Record<string, any>;
  id: string;
  created_at: string;
  updated_at: string;
}

export enum OrderReason {
  NEW_CUSTOMER = "NEW_CUSTOMER",
  CUSTOMER_TOP_UP = "CUSTOMER_TOP_UP",
  DISCOUNT = "DISCOUNT",
  REFUND = "REFUND",
}

export interface ReportOrder {
  identifier_value: string;
  identifier_type: IdentifierTypeEnum;
  company_name: string;
  user_id: string;
}

export interface ReportOrderStatus {
  user_id: string;
  identifier_type: IdentifierTypeEnum;
  identifier_value: string;
}

export interface ReportOrderResponse {
  exists: boolean;
  last_updated_date: string | null;
  last_purchased_date: string | null;
  last_purchased_type: string | null;
}
