import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { parse, compareAsc, compareDesc } from "date-fns";
import { scraperApiHandler } from "@app/config/apiHandler";
import { DocumentScrapResponse, eFormDocument } from "./types";

const sortDocumentsByCategory = (
  documents: eFormDocument[],
): eFormDocument[] => {
  return documents.sort((a, b) => a.category.localeCompare(b.category));
};

const sortDocumentsByDate = (
  documents: eFormDocument[],
  sort: "asc" | "desc",
): eFormDocument[] => {
  return documents.sort((a, b) => {
    const dateA = parse(a.date_of_filing, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date_of_filing, "dd/MM/yyyy", new Date());
    return sort === "asc"
      ? compareAsc(dateA, dateB)
      : compareDesc(dateA, dateB);
  });
};

const groupDocumentsByCategory = (
  documents: eFormDocument[],
): Record<string, eFormDocument[]> => {
  return documents.reduce(
    (acc, doc) => {
      if (!acc[doc.category]) {
        acc[doc.category] = [];
      }
      acc[doc.category].push(doc);
      return acc;
    },
    {} as Record<string, eFormDocument[]>,
  );
};

const transformResponse = (
  response: DocumentScrapResponse,
  sort: "asc" | "desc",
): DocumentScrapResponse => {
  const sortedByCategory = sortDocumentsByCategory(
    response.data.data.document_list,
  );
  const groupedByCategory = groupDocumentsByCategory(sortedByCategory);

  const finalSortedList = Object.values(groupedByCategory).flatMap((docs) =>
    sortDocumentsByDate(docs, sort),
  );

  return {
    data: {
      data: {
        cin: response.data.data.cin,
        latest_year: response.data.data.latest_year,
        document_list: finalSortedList,
      },
      metadata: response.data.metadata,
    },
  };
};

const mc2DocumentsApi = createApi({
  reducerPath: "mcCompaniesApi",
  baseQuery: axiosBaseQuery(scraperApiHandler),
  endpoints: (builder) => ({
    getMc2Document: builder.query<
      DocumentScrapResponse,
      { cin: string; sort: "asc" | "desc" }
    >({
      query: ({ cin }) => ({
        url: `/credhive-scraper/mca-docs/get-mca-docs-v2?cin=${cin}`,
        method: "get",
      }),
      transformResponse: (response: DocumentScrapResponse, _, arg) =>
        transformResponse(response, arg.sort),
    }),
    getMc3Document: builder.query<
      DocumentScrapResponse,
      { cin: string; sort: "asc" | "desc"; force_fetch?: boolean }
    >({
      query: ({ cin, force_fetch = false }) => ({
        url: `/credhive-scraper/mca-docs/get-mca-docs-v3`,
        method: "get",
        params: { force_fetch, cin },
      }),
      transformResponse: (response: DocumentScrapResponse, _, arg) =>
        transformResponse(response, arg.sort),
    }),
  }),

  refetchOnMountOrArgChange: true,
});

export const {
  useGetMc2DocumentQuery,
  useGetMc3DocumentQuery,
  useLazyGetMc2DocumentQuery,
  useLazyGetMc3DocumentQuery,
} = mc2DocumentsApi;

export default mc2DocumentsApi;
