export enum ScrapingTaskType {
  NORMAL = "NORMAL",
  ADHOC = "ADHOC",
}

export interface TriggerScrapingTasksPayload {
  task_id: string;
  scraping_type: ScrapingTaskType;
  exact_company_name?: string;
}
