import { ProjectStatus } from "@app/store/api/qaProjects/types";
import classNames from "classnames";

interface Props {
  status: ProjectStatus;
}

export const RenderProjectStatus = ({ status }: Props) => {
  return (
    <span
      className={classNames(
        "px-2 py-1 rounded-md text-sm font-semibold border",
        {
          "bg-blue-100 text-blue-800": status === "INITIALISED_PROJECT",
          "bg-yellow-100 text-yellow-800": status === "IN_PROGRESS",
          "bg-green-100 text-green-800":
            status === "COMPLETED_SUCCESSFULLY" ||
            status === "COMPLETED_AND_VERIFIED",
          "bg-red-100 text-red-800": status === "COMPLETED_WITH_ERRORS",
          "bg-gray-100 text-gray-800": ![
            "INITIALISED_PROJECT",
            "IN_PROGRESS",
            "COMPLETED_SUCCESSFULLY",
            "COMPLETED_WITH_ERRORS",
          ].includes(status),
        },
      )}
    >
      {status.replace(/_/g, " ")}
    </span>
  );
};
