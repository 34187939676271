import { useGetMcaAccountByStatusQuery } from "@app/store/api/mcaAccount";
import { PageLoader } from "@components/loader";
import { useState } from "react";
import { AllMcaAccounts } from "@components/admin/mca-accounts";
import {
  GengisMcaAccountVersion,
  McaAccountStatus,
} from "@app/store/api/mcaAccount/types";

export default function QaMcaAccounts() {
  const [mcaV2Skip, setMcaV2Skip] = useState(0);
  const [mcaV3Skip, setMcaV3Skip] = useState(0);

  const {
    data: mcaV2Data,
    isLoading: mcaV2IsLoading,
    isError: mcaV2IsError,
  } = useGetMcaAccountByStatusQuery({
    status: Object.values(McaAccountStatus),
    mcaVersion: GengisMcaAccountVersion.MCA_V2,
  });

  const {
    data: mcaV3Data,
    isLoading: mcaV3IsLoading,
    isError: mcaV3IsError,
  } = useGetMcaAccountByStatusQuery({
    mcaVersion: GengisMcaAccountVersion.MCA_V3,
    status: Object.values(McaAccountStatus),
  });

  if (mcaV2IsLoading || mcaV3IsLoading) {
    return (
      <div className="p-6">
        <div>
          <h2 className="text-start text-2xl font-bold">MCA Accounts</h2>
        </div>
        <PageLoader
          className="w-full h-full"
          style={{ width: "calc(100% - 50px)", height: "calc(100vh - 285px)" }}
        />
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-start text-2xl font-bold">My MCA Accounts</h2>
      </div>
      <AllMcaAccounts
        isQa={true}
        mcaV2Data={mcaV2Data}
        mcaV2Skip={mcaV2Skip}
        setMcaV2Skip={setMcaV2Skip}
        mcaV2IsError={mcaV2IsError}
        mcaV3Data={mcaV3Data}
        mcaV3Skip={mcaV3Skip}
        setMcaV3Skip={setMcaV3Skip}
        mcaV3IsError={mcaV3IsError}
      />
    </div>
  );
}
