import classNames from "classnames";
import { Scraping, Labelling } from "@app/components/tasks/pending";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const PendingTasks = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || "labelling";

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: "labelling" });
    }
  }, []);

  const handleTabChange = (tab: "labelling" | "scraping") => {
    setSearchParams({ tab });
  };

  return (
    <div>
      <h2 className="text-start text-3xl font-bold">Pending Tasks</h2>
      <div className="flex border-b">
        <button
          className={classNames("flex-1 py-2 text-center", {
            "border-b-2 border-blue-500 text-blue-500":
              activeTab === "labelling",
            "text-gray-500": activeTab !== "labelling",
          })}
          onClick={() => handleTabChange("labelling")}
          type="button"
        >
          Labelling
        </button>
        <button
          type="button"
          className={classNames("flex-1 py-2 text-center", {
            "border-b-2 border-blue-500 text-blue-500":
              activeTab === "scraping",
            "text-gray-500": activeTab !== "scraping",
          })}
          onClick={() => handleTabChange("scraping")}
        >
          Scraping
        </button>
      </div>
      {activeTab === "labelling" && <Labelling />}
      {activeTab === "scraping" && <Scraping />}
    </div>
  );
};

export default PendingTasks;
