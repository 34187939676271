import { Button } from "@components/common/Button";
import { FinancialProps } from "../PrimaryAuditor";
import {
  FinancialTypes,
  useGetRelatedPartyQuery,
} from "@app/store/api/financialApi";
import { Eye, Pencil, Plus } from "lucide-react";
import { Table } from "@components/common/table";
import { useMemo, useState } from "react";
import { AdditionalInfo } from "./AdditionalInfo";
import { useToggle } from "@app/hooks/useToggle";

const replatedPartyHeader = [
  {
    name: "Filing Type",
    value: "filing_type",
  },
  {
    name: "Name of Related Party",
    value: "name_of_related_party",
  },
  {
    name: "Country of Incorporation or Residence",
    value: "country_of_incorporation_or_residence",
  },
  {
    name: "PAN",
    value: "pan",
  },
  {
    name: "CIN",
    value: "cin",
  },
  {
    name: "Description of Nature of Related Party",
    value: "description_of_nature_of_transaction_with_related_party",
  },
  {
    name: "Amounts Payable",
    value: "amounts_payable",
  },
  {
    name: "Transfers under Finance Agreements from Entity",
    value: "transfers_under_finance_agreements_from_entity",
  },
  {
    name: "Transfers under Finance Agreements to Entity",
    value: "transfers_under_finance_agreements_to_entity",
  },
  {
    name: "Purchase of Goods",
    value: "purchase_of_goods",
  },
  {
    name: "Expense Recognised during Period for Bad and Doubtful Debts",
    value: "expense_recognised_during_period_for_bad_and_doubtful_debts",
  },
  {
    name: "Income",
    value: "income",
  },
  {
    name: "Transactions Expenses",
    value: "transactions_expenses",
  },
  {
    name: "Contributions Made",
    value: "contributions_made",
  },
  {
    name: "Contributions Received",
    value: "contributions_received",
  },
  {
    name: "Additional Information",
    value: "additional_information",
  },
  {
    name: "Edit",
    value: "edit",
  },
];

export const RelatedParty = ({ activeInfo, formNature }: FinancialProps) => {
  const [isOpen, toggleModal] = useToggle();
  const [activeAdditionalInfo, setActiveAdditionalInfo] = useState<
    Record<string, string>
  >({});
  const { data, isLoading } = useGetRelatedPartyQuery({
    document_id: activeInfo?.id,
    year: new Date(activeInfo.financial_year).getFullYear().toString(),
    nature: formNature as FinancialTypes,
  });

  const relatedPartyData = useMemo(() => {
    if (!data) return [];

    return data?.response_data.map((item) => {
      return {
        ...item,
        additional_information: (
          <Button
            variant="primary"
            rightIcon={Eye}
            onClick={() => {
              setActiveAdditionalInfo(item.additional_information);
              toggleModal.on();
            }}
          >
            View
          </Button>
        ),
        edit: (
          <Button variant="destructive" rightIcon={Pencil}>
            Edit
          </Button>
        ),
      };
    });
  }, [data]);

  return (
    <section className="border rounded-md p-3 mt-10">
      <AdditionalInfo
        isOpen={isOpen}
        toggleModal={toggleModal}
        data={activeAdditionalInfo}
        id={activeInfo?.id}
      />
      <div className="flex justify-between">
        <h2 className="text-xl font-bold">Related Party</h2>
        <Button rightIcon={Plus}>Add</Button>
      </div>
      <div className="flex flex-col gap-2 mt-3">
        <Table
          isLoading={isLoading}
          headers={replatedPartyHeader}
          rows={relatedPartyData}
          className="w-full"
          tableBodyClassname="text-wrap"
        />
      </div>
    </section>
  );
};
