import {
  AdhocMetadata,
  AdhocTaskNature,
  AdhocTaskStatus,
  useGetAdhocTasksQuery,
} from "@app/store/api/qaTasksApi";
import { Table } from "@components/common/table";
import { PageLoader } from "@components/loader";
import { useMemo, useState } from "react";
import { RenderAdhocStatus } from "./RenderAdhocStatus";
import { CompanyInfoModal } from "./CompanyInfoModal";
import { useToggle } from "@app/hooks/useToggle";
import { Button } from "@components/common/Button";
import { NotFound } from "@components/common/NotFound";
import { ArrowRight } from "lucide-react";
import { CreateOrderForCustomerModal } from "@app/components/admin/company-list";
import { IdentifierTypeEnum } from "@app/types";
import { PollingPreview } from "@components/common/PollingPreview";

export const headers = [
  {
    name: "Customer Name",
    value: "customer_name",
  },
  {
    name: "Requested Company Name",
    value: "requested_company",
  },
  {
    name: "Identifier Value",
    value: "identifier_value",
  },
  {
    name: "Identifier Type",
    value: "identifier_type",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Info",
    value: "info",
  },
  {
    name: "Action",
    value: "action",
  },
];

export interface CompanyRequestCustomerInfo {
  id: string;
  identifier_value: string;
  identifier_type: IdentifierTypeEnum;
  task_id: string;
}

const LIMIT = 15;
const POLLING_INTERVAL = 15000;

export const PendingCompanyRequest = () => {
  const [isMoreInfoOpen, toggleMoreInfoOpen] = useToggle();
  const [isCreateOrderOpen, toggleCreateOrderOpen] = useToggle();
  const [customerInfo, setCustomerInfo] = useState<CompanyRequestCustomerInfo>({
    id: "",
    identifier_value: "",
    identifier_type: IdentifierTypeEnum.CIN,
    task_id: "",
  });
  const [selectedCompany, setSelectedCompany] = useState<
    Partial<AdhocMetadata>
  >({});

  const [skip, setSkip] = useState(0);

  const { data, isLoading, startedTimeStamp } = useGetAdhocTasksQuery(
    {
      skip,
      limit: LIMIT,
      status: [
        AdhocTaskStatus.ADHOC_NOT_STARTED,
        AdhocTaskStatus.ADHOC_IN_PROGRESS,
        AdhocTaskStatus.ADHOC_FAILED,
      ],
      task_nature_name: AdhocTaskNature.REQUEST_NEW_COMPANY,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVAL,
    },
  );

  const rows = useMemo(() => {
    if (!data) return [];
    return data?.response_data.map((company) => ({
      requested_company: company.adhoc_metadata.company_name,
      customer_name: company.adhoc_metadata.customer_user.name,
      identifier_value: company.adhoc_metadata.identifier_value,
      identifier_type: company.adhoc_metadata.identifier_type,
      status: <RenderAdhocStatus status={company.status} />,
      info: (
        <Button
          variant="secondary"
          onClick={() => {
            setSelectedCompany(company.adhoc_metadata);
            toggleMoreInfoOpen.on();
          }}
        >
          More Info
        </Button>
      ),
      action: (
        <Button
          variant="primary"
          rightIcon={ArrowRight}
          onClick={() => {
            setCustomerInfo({
              id: company.adhoc_metadata.customer_user.id,
              identifier_value: company.adhoc_metadata.identifier_value,
              identifier_type: company.adhoc_metadata
                .identifier_type as IdentifierTypeEnum,
              task_id: company.id,
            });
            toggleCreateOrderOpen.on();
          }}
        >
          Buy Report
        </Button>
      ),
    }));
  }, [data]);

  if (isLoading)
    return (
      <PageLoader
        className="w-full h-full"
        style={{ width: "calc(100% - 50px)", height: "calc(100vh - 185px)" }}
      />
    );

  if (!data?.response_data.length)
    return (
      <div className="h-[calc(100vh-10rem)] flex justify-center items-center">
        <NotFound message="No Requests Found" />;
      </div>
    );

  return (
    <div className="mt-6">
      <CompanyInfoModal
        isOpen={isMoreInfoOpen}
        onClose={toggleMoreInfoOpen.off}
        data={selectedCompany}
      />
      <CreateOrderForCustomerModal
        isOpen={isCreateOrderOpen}
        onClose={toggleCreateOrderOpen.off}
        userId={customerInfo.id}
        customerInfo={customerInfo}
        taskId={customerInfo.task_id}
      />
      <PollingPreview
        lastFetchedAt={startedTimeStamp ?? 0}
        pollingInterval={POLLING_INTERVAL}
      />
      <main className="mt-6">
        <Table
          paginate
          maxSize={data?.total}
          headers={headers}
          rows={rows}
          tableDivClassname="w-full border border-gray-200 !p-0 rounded-lg"
          tableHeaderClassname="bg-gray-100 px-2 py-2 text-md font-bold text-gray-600 truncate"
          tableBodyClassname="truncate"
          currentLimit={LIMIT}
          skip={skip}
          nextHandler={() => {
            if (skip + LIMIT >= data.total) return;
            setSkip(skip + LIMIT);
          }}
          prevHandler={() => {
            if (skip === 0) return;
            setSkip(skip - LIMIT);
          }}
        />
      </main>
    </div>
  );
};
