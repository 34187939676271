import { useGetMcaAccountsJobStatusQuery } from "@app/store/api/qaTasksApi";
import { McaAccountJobsTaskList } from "./McaAccountJobsTaskList";
import { Skelton } from "@components/common/Skelton";

interface McaAccountJobsInfoProps {
  accountId: string;
}

export function McaAccountJobsInfo({ accountId }: McaAccountJobsInfoProps) {
  const { data, isLoading } = useGetMcaAccountsJobStatusQuery(accountId);
  const scrapingTasks = data?.response_data?.scraping_tasks ?? [];
  const adhocTasks = data?.response_data?.adhoc_scraping_tasks ?? [];

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 min-w-[400px] max-h-[600px] outline-none overflow-y-auto">
      {isLoading ? (
        <Skelton count={2} />
      ) : (
        <div className="space-y-4">
          <McaAccountJobsTaskList
            typeOfTask="scraping"
            tasks={scrapingTasks}
            title="Scraping Tasks"
          />
          <McaAccountJobsTaskList
            typeOfTask="adhoc"
            tasks={adhocTasks}
            title="Adhoc Tasks"
          />
          {!scrapingTasks.length && !adhocTasks.length && (
            <p className="text-gray-500 text-center py-4">No tasks found</p>
          )}
        </div>
      )}
    </div>
  );
}
