import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { TabProps } from "./types";
import { Icon } from "@app/components/common/Icon";
import classNames from "classnames";

export const Tab = <T extends string>({
  content,
  queryParamKey = "tab",
}: TabProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getInitialActiveTab = useCallback((): T => {
    const urlTab = searchParams.get(queryParamKey);
    if (urlTab && content.some((header) => header.label === urlTab)) {
      return urlTab as T;
    }
    return content[0]?.label || ("" as T);
  }, [searchParams, content, queryParamKey]);

  const [internalActiveTab, setInternalActiveTab] =
    useState<T>(getInitialActiveTab);

  useEffect(() => {
    const urlTab = searchParams.get(queryParamKey);
    if (
      urlTab &&
      urlTab !== internalActiveTab &&
      content.some((header) => header.label === urlTab)
    ) {
      setInternalActiveTab(urlTab as T);
    }
  }, [searchParams, content, internalActiveTab, queryParamKey]);

  const handleTabClick = (label: T) => {
    if (label !== internalActiveTab) {
      setInternalActiveTab(label);
      searchParams.set(queryParamKey, label);
      setSearchParams(searchParams, { replace: true });
    }
  };

  const Component = content.find((header) => header.label === internalActiveTab)
    ?.children as React.ReactElement;

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200">
        {content.map((header) => (
          <button
            key={header.label}
            onClick={() => handleTabClick(header.label)}
            className={classNames(
              "inline-flex items-center gap-2 px-4 py-2 transition-colors duration-200 w-full justify-center",
              {
                "text-blue-600 border-b-2 border-blue-600":
                  internalActiveTab === header.label,
                "text-gray-600 hover:text-gray-800":
                  internalActiveTab !== header.label,
              },
            )}
          >
            {header.icon && <Icon name={header.icon} />}
            <span className="capitalize">{header.label}</span>
          </button>
        ))}
      </div>
      <div className="mt-4">{Component && Component}</div>
    </div>
  );
};
