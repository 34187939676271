import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import {
  CreatePresignedUrlPayload,
  CreatePresignedUrlResponse,
  CreatePresignedUrlByDocumentIdPayload,
} from "./types";

const documentsApi = createApi({
  reducerPath: "documentsApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  endpoints: (builder) => ({
    createPresignedUrl: builder.mutation<
      CreatePresignedUrlResponse,
      CreatePresignedUrlPayload
    >({
      query: (body) => ({
        url: "/editing-platform-data/documents/create-presigned-url",
        method: "POST",
        data: body,
      }),
    }),
    createPresignedUrlByDocumentId: builder.mutation<
      CreatePresignedUrlResponse,
      CreatePresignedUrlByDocumentIdPayload
    >({
      query: (body) => ({
        url: "/editing-platform-data/metadata/create-presigned-url",
        method: "POST",
        data: {
          document_id: body.document_id,
          is_download: body.is_download || false,
        },
      }),
    }),
    getDocumentStatus: builder.query<
      { total: number; completed: number },
      { type: "mcav2" | "mcav3"; cin: string }
    >({
      query: ({ type, cin }) => ({
        url: `/credhive-scraper/mca-docs/get-mca-docs-${
          type === "mcav3" ? "v3" : "v2"
        }`,
        method: "GET",
        params: { cin },
      }),
      transformResponse: (response: any) => {
        const documentList = response?.data?.data?.document_list || [];
        const total = documentList.length;
        const completed = documentList.filter(
          (doc: { status: boolean }) => doc.status,
        ).length;
        return { total, completed };
      },
    }),
  }),

  refetchOnMountOrArgChange: true,
});

export const {
  useCreatePresignedUrlMutation,
  useLazyGetDocumentStatusQuery,
  useCreatePresignedUrlByDocumentIdMutation,
} = documentsApi;

export default documentsApi;
