import { ScrapingStatus } from "@app/store/api/qaTasksApi";
import classNames from "classnames";
import React from "react";

interface Props {
  status: ScrapingStatus | string;
  className?: string;
  onClick?: () => void;
}

export const RenderScrapingStatus: React.FC<Props> = ({
  status,
  className,
}) => {
  const getStatusConfig = (
    status: ScrapingStatus | string,
  ): {
    label: string;
    bgClassName: string;
    textClassName: string;
  } => {
    switch (status) {
      case ScrapingStatus.SCRAPING_COMPLETED:
        return {
          label: "Completed",
          bgClassName: "bg-green-100",
          textClassName: "text-green-600",
        };
      case ScrapingStatus.SCRAPING_IN_PROGRESS:
        return {
          label: "In Progress",
          bgClassName: "bg-sky-50",
          textClassName: "text-sky-600",
        };
      case ScrapingStatus.SCRAPING_INITIALISED:
        return {
          label: "Initialised",
          bgClassName: "bg-amber-50",
          textClassName: "text-amber-600",
        };
      case ScrapingStatus.SCRAPING_FAILED:
        return {
          label: "Failed",
          bgClassName: "bg-rose-50",
          textClassName: "text-rose-600",
        };
      case ScrapingStatus.SCRAPING_COMPLETED_WITH_ERRORS:
        return {
          label: "Completed with Errors",
          bgClassName: "bg-yellow-100",
          textClassName: "text-yellow-600",
        };
      case ScrapingStatus.SCRAPING_NOT_STARTED:
        return {
          label: "Not Started",
          bgClassName: "bg-gray-100",
          textClassName: "text-gray-700",
        };
      case ScrapingStatus.SCRAPING_FORCE_COMPLETED:
        return {
          label: "Force Completed",
          bgClassName: "bg-teal-50",
          textClassName: "text-teal-600",
        };
      case ScrapingStatus.SCRAPING_FORCE_FAILED:
        return {
          label: "Force Failed",
          bgClassName: "bg-red-50",
          textClassName: "text-red-600",
        };
      default:
        return {
          label: status as string,
          bgClassName: "bg-gray-100",
          textClassName: "text-gray-700",
        };
    }
  };

  const config = getStatusConfig(status);

  return (
    <span
      className={classNames(
        "group inline-flex items-center rounded-md px-2.5 py-1 text-xs font-medium",
        "transition-all duration-300 ease-in-out",
        "shadow-sm border border-transparent",
        config.bgClassName,
        config.textClassName,
        className,
      )}
    >
      {config.label}
    </span>
  );
};
