export const findRoutes = {
  QA_LABELING_FINANCIALS: (
    cin: string,
    activeCountry: string,
    activeCorporate: string,
  ) => `/labelling/${activeCountry}/${activeCorporate}/${cin}/finances`,
  QA_LABELING_SHAREHOLDINGS: (
    cin: string,
    activeCountry: string,
    activeCorporate: string,
  ) =>
    `/labelling/${activeCountry}/${activeCorporate}/${cin}/shareholding-pattern`,
  QA_LABELING_VERIFIED: (
    cin: string,
    activeCountry: string,
    activeCorporate: string,
  ) => `/labelling/${activeCountry}/${activeCorporate}/${cin}/overview`,
};
