import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../axiosBase";
import { parserApiHandler } from "@app/config/apiHandler";
import { QaDbApiResponse } from "@app/types";
import { PbaPayload, PrincipleBusinessActivity } from "./types";
import { OrNull } from "@app/store/api/shareHoldingsApi/types";

const principleBusinessActivityApi = createApi({
  reducerPath: "principleBusinessActivityApi",
  baseQuery: axiosBaseQuery(parserApiHandler),
  tagTypes: ["business-activities"],
  endpoints: (builder) => ({
    getPrincipleBusinessActivities: builder.query<
      QaDbApiResponse<PrincipleBusinessActivity[]>,
      PbaPayload
    >({
      query: (payload) => ({
        url: "/editing-platform-data/shareholder-editing/principal-business-activity",
        method: "GET",
        params: payload,
      }),
      providesTags: ["business-activities"],
    }),

    createPrincipleBusinessActivity: builder.mutation<
      void,
      Omit<PrincipleBusinessActivity, "id" | "created_at" | "updated_at">
    >({
      query: (data) => ({
        url: "/editing-platform-data/shareholder-editing/principal-business-activity",
        method: "POST",
        data,
      }),
      invalidatesTags: ["business-activities"],
    }),

    updatePrincipleBusinessActivity: builder.mutation<
      QaDbApiResponse<PrincipleBusinessActivity>,
      Partial<OrNull<PrincipleBusinessActivity>> & {
        principal_business_activity_id: string;
      }
    >({
      query: (data) => ({
        url: `/principle-business-activities/update/${data.id}`,
        method: "PUT",
        data,
        params: {
          principal_business_activity_id: data.principal_business_activity_id,
        },
      }),
      invalidatesTags: ["business-activities"],
    }),

    deletePrincipleBusinessActivity: builder.mutation<
      QaDbApiResponse<void>,
      string
    >({
      query: (id) => ({
        url: `/editing-platform-data/shareholder-editing/principal-business-activity`,
        method: "DELETE",
        params: {
          principal_business_activity_id: id,
        },
      }),
      invalidatesTags: ["business-activities"],
    }),
  }),
});

export const {
  useGetPrincipleBusinessActivitiesQuery,
  useCreatePrincipleBusinessActivityMutation,
  useUpdatePrincipleBusinessActivityMutation,
  useDeletePrincipleBusinessActivityMutation,
} = principleBusinessActivityApi;

export default principleBusinessActivityApi;
