import classNames from "classnames";
import React from "react";
import "./style.css";

interface Error extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
}

export const NotFound: React.FC<Error> = ({ message, className, ...props }) => {
  return (
    <div
      className={classNames(
        "flex justify-center w-full ml-2 items-center",
        className,
      )}
      {...props}
    >
      <div className="flex text-center justify-center w-[40vw] min-h-80 h-auto items-center mt-4 text-slate-400 noDataSection">
        {message}
      </div>
    </div>
  );
};
