import * as Yup from "yup";

export const IncomeAndExpenditure = {
  line_items: [
    "net_revenue",
    "total_cost_of_materials_consumed",
    "total_purchases_of_stock_in_trade",
    "total_changes_in_inventories_or_finished_goods",
    "total_employee_benefit_expense",
    "other_income",
    "depreciation",
    "interest",
    "income_tax",
    "consumptions_of_stores_and_spares",
    "other_expenses",
    "power_and_fuel",
    "administrative_expenses",
    "selling_expenses",
    "insurance_expenses",
    "payment_to_auditors",
    "total_income",
    "COMPUTED_profit_before_interest_and_tax",
    "COMPUTED_profit_before_tax_and_exceptional_items_before_tax",
    "COMPUTED_profit_before_tax",
    "COMPUTED_profit_after_tax",
    "COMPUTED_total_other_expenses",
    "COMPUTED_total_expenses",
    "COMPUTED_operating_profit",
  ],
  revenue_breakup: [
    "sales_of_goods_manufactured_domestic",
    "sales_of_goods_traded_domestic",
    "sales_or_supply_of_services_domestic",
    "sales_of_goods_manufactured_export",
    "sales_of_goods_traded_export",
    "sales_or_supply_of_services_export",
  ],
};

const nullableNumber = Yup.number().nullable().typeError("should be a number");

export const incomeAndExpenditureSchema = Yup.object().shape({
  net_revenue: Yup.number().required("Net revenue is required"),
  total_cost_of_materials_consumed: Yup.number().required(
    "Total cost of materials consumed is required",
  ),
  total_purchases_of_stock_in_trade: Yup.number().required(
    "Total purchases of stock in trade is required",
  ),
  total_changes_in_inventories_or_finished_goods: Yup.number().required(
    "Total changes in inventories or finished goods is required",
  ),
  total_employee_benefit_expense: Yup.number().required(
    "Total employee benefit expense is required",
  ),
  other_income: Yup.number().required("Other income is required"),
  depreciation: Yup.number().required("Depreciation is required"),
  interest: Yup.number().required("Interest is required"),
  income_tax: Yup.number().required("Income tax is required"),
  sale_of_goods_manufactured_domestic: nullableNumber,
  sale_of_goods_traded_domestic: nullableNumber,
  sale_or_supply_of_services_domestic: nullableNumber,
  sale_of_goods_manufactured_export: nullableNumber,
  sale_of_goods_traded_export: nullableNumber,
  sale_or_supply_of_services_export: nullableNumber,
  consumptions_of_stores_and_spares: nullableNumber,
  other_expenses: nullableNumber,
  power_and_fuel: nullableNumber,
  administrative_expenses: nullableNumber,
  selling_expenses: nullableNumber,
  insurance_expenses: nullableNumber,
  payment_to_auditors: nullableNumber,
  total_income: nullableNumber,
  profit_before_interest_and_tax: nullableNumber,
  profit_before_tax_and_exceptional_items_before_tax: nullableNumber,
  profit_before_tax: nullableNumber,
  profit_after_tax: nullableNumber,
  total_other_expenses: nullableNumber,
  total_expenses: nullableNumber,
  operating_profit: nullableNumber,
});

export type IncomeAndExpenditureType = Yup.InferType<
  typeof incomeAndExpenditureSchema
>;
