import Modal from "react-modal";
import {
  IdentifierTypeForDocumentCreation,
  useGetDocumentCreationDataQuery,
  useCreateShareholderDocumentMutation,
} from "@app/store/api/documentCreationApi";
import { Icon } from "@components/common";
import { PageLoader } from "@components/loader";
import { MCAFormType } from "@app/store/api/financialApi";
import React, { useCallback, useEffect, useState } from "react";
import { DropDown } from "@components/common/dropdown";
import { Pagination, usePagination } from "@components/common/Pagination";
import { Button } from "@components/common/Button";
import { ArrowRight } from "lucide-react";
import { RenderParsingTask } from "@components/tasks/completed";
import { FillFinancialYearModal } from "./FillFinancialYearModal";
import { useToggle } from "@app/hooks/useToggle";
import { FinancialMetaDataResponseObject } from "@app/store/api/financialApi/types";
import { useCreatePresignedUrlMutation } from "@app/store/api/documentsApi";
import toast from "react-hot-toast";

interface ListDocumentModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  identifierType: IdentifierTypeForDocumentCreation;
  identifierValue: string;
  dataType: "shareholdings" | "financials";
}

const LIMIT = 10;

export const ListDocumentModal = ({
  identifierType,
  identifierValue,
  isOpen,
  onRequestClose,
  dataType,
}: ListDocumentModalProps) => {
  const [accordionOpen, setAccordionOpen] = useState<Array<boolean>>([]);
  const [skip, setSkip] = useState(0);
  const [isFillFinancialYearModalOpen, setIsFillFinancialYearModalOpen] =
    useToggle();
  const [selectedFormType, setSelectedFormType] = useState<MCAFormType>(
    MCAFormType.MGT7,
  );

  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [createShareholderDocument] = useCreateShareholderDocumentMutation();

  const openPresignedUrl = async (url: string) => {
    await toast.promise(
      createPresignedUrl({
        s3_url: url,
      }).unwrap(),
      {
        loading: "Generating document URL...",
        success: (data) => {
          window.open(data.pre_signed_url, "_blank");
          return "Document opened successfully";
        },
        error: "Failed to generate URL",
      },
    );
  };

  const [selectedDocument, setSelectedDocument] =
    useState<FinancialMetaDataResponseObject | null>(null);

  const { data, isLoading } = useGetDocumentCreationDataQuery(
    {
      identifier_type: identifierType,
      identifier_value: identifierValue,
      document_type: selectedFormType,
      skip,
      limit: LIMIT,
    },
    {
      skip: !isOpen,
    },
  );

  const { totalPages, currentPage } = usePagination(
    data?.total || 0,
    skip,
    LIMIT,
  );

  useEffect(() => {
    setAccordionOpen(Array(data?.response_data.length || 0).fill(false));
  }, [data?.response_data.length, selectedFormType]);

  const toggleAccordion = useCallback(
    (documentIndex: number) => {
      setAccordionOpen(
        accordionOpen.map((_, index) => (index === documentIndex ? !_ : _)),
      );
    },
    [accordionOpen, selectedFormType],
  );

  const handleSetSelectedDocument = (
    document: FinancialMetaDataResponseObject,
  ) => {
    if (dataType === "shareholdings") {
      handleCreateShareholderDocument(document);
    } else {
      if (dataType === "financials") {
        setSelectedDocument(document);
        setIsFillFinancialYearModalOpen.on();
      }
    }
  };

  const handleCreateShareholderDocument = (
    document: FinancialMetaDataResponseObject,
  ) => {
    if (dataType === "shareholdings") {
      if (!document.financial_year) {
        setSelectedDocument(document);
        setIsFillFinancialYearModalOpen.on();
        return;
      }

      toast.promise(createShareholderDocument(document.id).unwrap(), {
        loading: "Creating document...",
        error: "Failed to create document",
        success: () => {
          onRequestClose();
          return "Document created successfully";
        },
      });
    }
  };

  const closeModal = (forceClose: boolean = false) => {
    if (forceClose) {
      onRequestClose();
    }
    setIsFillFinancialYearModalOpen.off();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content w-11/12 max-w-10xl h-[calc(100vh-5rem)] overflow-y-scroll overflow-x-hidden"
      overlayClassName="modal-overlay"
    >
      <FillFinancialYearModal
        isOpen={isFillFinancialYearModalOpen}
        onRequestClose={closeModal}
        data={selectedDocument}
        dataType={dataType}
      />
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">Create Data For Documents</h2>
        <span className=" border rounded-full p-1">
          <Icon
            name="X"
            className="cursor-pointer hover:opacity-70 transition-opacity"
            onClick={onRequestClose}
          />
        </span>
      </div>
      <hr className="my-4" />
      {isLoading ? (
        <PageLoader className="h-[calc(100vh-20rem)]" />
      ) : (
        <section className="flex flex-col gap-4">
          <div className="flex gap-4 justify-between">
            <DropDown
              className="max-w-xs min-w-xs"
              options={Object.values(MCAFormType).map((type) => ({
                label: type,
                value: type,
              }))}
              value={{
                label: selectedFormType,
                value: selectedFormType,
              }}
              onChange={(value) => {
                setSelectedFormType(value.value as MCAFormType);
              }}
            />
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => setSkip((page - 1) * LIMIT)}
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 border">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    File
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    File Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Filing Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Financial Year
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Add Data
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 border rounded-md">
                {data?.response_data.length === 0 && (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-center h-[calc(100vh-20rem)]"
                    >
                      <span className="text-gray-500 text-2xl font-bold">
                        No documents found
                      </span>
                    </td>
                  </tr>
                )}
                {data?.response_data.map((document, documentIndex) => {
                  return (
                    <React.Fragment key={document.id}>
                      <tr>
                        <td
                          className="pl-6 cursor-pointer"
                          onClick={() => openPresignedUrl(document.s3_url)}
                        >
                          <Icon name="File" className="w-4 h-4" />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate max-w-[400px]">
                          {document.file_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {document.filing_date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {document.financial_year}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <RenderParsingTask status={document.status} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => toggleAccordion(documentIndex)}
                            disabled={document.attachments.length === 0}
                            className="text-blue-600 hover:text-blue-800 flex items-center gap-2 disabled:text-gray-500 disabled:cursor-not-allowed border rounded-md px-2 py-1"
                          >
                            {document.attachments.length === 0
                              ? "No attachments found"
                              : "Show Attachments"}
                            <Icon
                              name={
                                accordionOpen[documentIndex]
                                  ? "ChevronUp"
                                  : "ChevronDown"
                              }
                              className="w-4 h-4"
                            />
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Button
                            rightIcon={ArrowRight}
                            onClick={() => handleSetSelectedDocument(document)}
                          >
                            Initialize Data
                          </Button>
                        </td>
                      </tr>
                      {accordionOpen[documentIndex] &&
                        document.attachments.length > 0 && (
                          <tr>
                            <td colSpan={9} className="bg-gray-50">
                              <div className="px-8 py-4 bg-white divide-y divide-gray-200">
                                <table className="min-w-full">
                                  <tbody className="">
                                    {document.attachments.map((attachment) => (
                                      <tr key={attachment.id}>
                                        <td
                                          className="px-4 py-2 cursor-pointer"
                                          onClick={() =>
                                            openPresignedUrl(attachment.s3_url)
                                          }
                                        >
                                          <Icon
                                            name="File"
                                            className="w-4 h-4"
                                          />
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600  truncate max-w-[400px]">
                                          {attachment.file_name}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600">
                                          {attachment.filing_date}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600">
                                          {attachment.financial_year}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-600">
                                          <RenderParsingTask
                                            status={attachment.status}
                                          />
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                          <Button
                                            variant="secondary"
                                            rightIcon={ArrowRight}
                                            onClick={() =>
                                              handleSetSelectedDocument(
                                                document,
                                              )
                                            }
                                          >
                                            Initialize Data
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </Modal>
  );
};
