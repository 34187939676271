import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IdentifierTypeEnum } from "@app/types";

export type SupportedCountry = "in" | "hk";

export const supportedCountry: SupportedCountry[] = ["in", "hk"];

export enum CorporateType {
  NonCorporate = "non-corporate",
  Corporate = "corporate",
}

type InitialCompanySlice = {
  activeCountry: SupportedCountry;
  corporateType: CorporateType;
  typeOfCompany: IdentifierTypeEnum;
};

const initialState: InitialCompanySlice = {
  activeCountry: "in",
  corporateType: CorporateType.Corporate,
  typeOfCompany: IdentifierTypeEnum.CIN,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCurrentCompany: (state, action: PayloadAction<IdentifierTypeEnum>) => {
      state.typeOfCompany = action.payload;
    },
    setActiveCountry: (state, action: PayloadAction<SupportedCountry>) => {
      state.activeCountry = action.payload;
    },
    setCorporateType: (state, action: PayloadAction<CorporateType>) => {
      state.corporateType = action.payload;
    },
  },
});

export const { setCurrentCompany, setActiveCountry, setCorporateType } =
  companySlice.actions;

export default companySlice;
